import {WidgetVisualDto} from 'api/generated/admin-api';
import {yupArrayStrings, yupObject, yupString, yupStringRequired} from 'utils/validation';

import {FieldStructure} from 'components/Form/types';
import {widgetsColumns} from 'modules/widgets/columns';

export const fields: FieldStructure<WidgetVisualDto>[] = [
  {
    fields: [
      {
        type: 'text',
        name: 'name',
        label: 'Название',
      },
      {
        type: 'text',
        name: 'containerName',
        label: 'Контейнер',
      },
      {
        type: 'dialogSelect',
        name: 'widgetsId',
        getName: item => item.name,
        label: 'Источники данных',
        queryName: 'widgetsIndex',
        columns: widgetsColumns(),
        multiple: true,
      },
    ],
  },
];

export const initialValues: WidgetVisualDto = {
  name: '',
  widgetsId: [],
  containerName: '',
};

export const validation = yupObject({
  name: yupStringRequired,
  containerName: yupString,
  widgetsId: yupArrayStrings,
});
