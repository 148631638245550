import {compose} from 'lodash/fp';
import {atom, useAtom} from 'jotai';
import {useUpdateAtom} from 'jotai/utils';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';

import {ConfirmDialogProps, ConfirmationDialogProps} from './types';
import {VFC} from 'react';

const dialogState = atom<ConfirmationDialogProps | undefined>(undefined);

export function useConfirmationDialog<T = undefined>(
  fn: (data: T) => void,
  defaultOptions?: ConfirmDialogProps<T>
): (data: T, options?: ConfirmDialogProps<T>) => void {
  const setDialogState = useUpdateAtom(dialogState);
  return (data, options) =>
    setDialogState({data, onConfirm: () => fn(data), ...(defaultOptions || {}), ...(options || {})});
}

const ConfirmationDialogContents: VFC<ConfirmationDialogProps & {onClose: () => void}> = ({
  title,
  onClose,
  data,
  onConfirm,
  text,
  align,
  btnTitle,
  cancelBtnTitle,
  btnType,
}) => {
  const dialogTitle = title ? (typeof title === 'string' ? title : title(data)) : undefined;
  return (
    <>
      <Box p={3} pb={0}>
        {dialogTitle && (
          <Typography variant="h6" align={align}>
            {dialogTitle}
          </Typography>
        )}
        {text && (
          <Typography variant="body2" align={align} mt={2} whiteSpace="pre-line">
            {text}
          </Typography>
        )}
      </Box>
      <DialogActions sx={{p: 3, justifyContent: align === 'center' ? 'center' : 'flex-end'}}>
        <Button
          size="small"
          variant="contained"
          sx={{px: 3}}
          onClick={onClose}
          color={btnType === 'error' ? 'primary' : 'secondary'}
        >
          {cancelBtnTitle || 'Отмена'}
        </Button>
        <Button variant="contained" color={btnType} size="small" onClick={compose(onClose, onConfirm)} sx={{px: 3}}>
          {btnTitle || 'Да'}
        </Button>
      </DialogActions>
    </>
  );
};

export const ConfirmationDialog = () => {
  const [state, setDialogState] = useAtom(dialogState);
  const onClose = () => setDialogState(undefined);
  return (
    <Dialog
      open={!!state}
      onClose={onClose}
      onBackdropClick={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{sx: {borderRadius: 2}}}
    >
      {state && <ConfirmationDialogContents {...state} onClose={onClose} />}
    </Dialog>
  );
};
