import _ from 'lodash';
import {flatten} from 'flat';
import {createSelector} from '@reduxjs/toolkit';

import {usersApi} from 'api';
import {Users, WidgetsInfoApiArg, WidgetsVisualsDesigns} from 'api/generated/users-api';
import {PostIdProp, WidgetVisualsDesignCustomOptions} from './types';
import {shallowEqual, useSelector} from 'react-redux';
import {skipToken} from '@reduxjs/toolkit/query';

export const selectPost = createSelector(
  (state: any) => state,
  (_: any, postId: string) => postId,
  (state: any, postId: string) => {
    const data = usersApi.endpoints.publicationIndex.select({postId})(state).data;
    if (!data) throw new Error('no post found');
    return data;
  }
);

export const selectPostStageId = createSelector(selectPost, post => ({
  stageId: post.attachments?.find(item => item.referenceName === 'Stages' && item.enabled)?.referenceId,
}));

export const selectPostAchievmentId = createSelector(selectPost, post => ({
  achievmentId: post.attachments?.find(item => item.referenceName === 'Achievements' && item.enabled)?.referenceId,
}));

export const useGetStageInfo = ({postId}: PostIdProp) => {
  const {stageId} = useSelector<any>(state => selectPostStageId(state, postId), shallowEqual) as ReturnType<
    typeof selectPostStageId
  >;
  const {questName, questType, questFileId, questId, isLoading} = usersApi.endpoints.stageIndex.useQuery(
    stageId ? {stageId, partial: true} : skipToken,
    {
      selectFromResult: ({data, isLoading, isUninitialized}) => ({
        isLoading: isLoading || isUninitialized,
        questName: data?.stage.quest?.design?.hint || data?.stage.quest?.design?.header || data?.stage.quest?.name,
        questType: data?.stage.questType,
        questId: data?.stage.questId,
        questFileId: data?.stage.quest?.design?.fileId,
      }),
    }
  );

  return {questName, questType, isLoading, questFileId, questId, stageId};
};

export const selectWidgetValues = createSelector(
  (state: any) => state,
  (_: any, query: WidgetsInfoApiArg) => query,
  (_: any, __: WidgetsInfoApiArg, widgetId?: string) => widgetId,
  (_: any, __: WidgetsInfoApiArg, widgetId?: string, widgetAttrId?: string) => widgetAttrId,
  (state: any, {_id, ...query}: WidgetsInfoApiArg, widgetId?: string, widgetAttrId?: string) => {
    if (!widgetId || !widgetAttrId) return null;
    const widget = usersApi.endpoints.widgetInfo.select({...query, widgetId})(state).data;
    if (!widget) return undefined;

    return flatten(
      _.uniqBy(
        _.flatten(
          _.flatten(widget.values.map(item => item.values))
            .filter(item => item.widgetAttrId === widgetAttrId)
            .map(item =>
              item.values.map(value => ({
                type: item.type,
                widgetAttrId: item.widgetAttrId,
                ...value,
              }))
            )
        ),
        item => JSON.stringify(item)
      )
    );
  }
);

export const getWidgetVisualDesignOptions = (data: WidgetsVisualsDesigns): WidgetVisualsDesignCustomOptions =>
  data.customJSONString ? JSON.parse(data.customJSONString) : {};

export const getUserName = (user?: Users) => (user ? `${user.name}${user.surname ? ` ${user.surname}` : ''}` : '');
