import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Users} from 'api/generated/admin-api';
import {AuthState} from '../types';
import {MODULE_NAME} from '../constants';

const initialState: AuthState = {
  isLogged: false,
  authInit: false,
  user: null,
};

const slice = createSlice({
  name: MODULE_NAME,
  initialState,
  reducers: {
    setIsAuth: (state, {payload}: PayloadAction<boolean>) => ({...state, isLogged: payload, authInit: true}),
    setUser: (state, {payload}: PayloadAction<Users | null>) => ({...state, user: payload}),
  },
});

const result = {[MODULE_NAME]: slice.reducer};

export const {actions} = slice;
export default result;
