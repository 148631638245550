import {useParams} from 'react-router';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {WidgetForm} from 'modules/widgets/containers/WidgetForm';

export const WidgetPage = () => {
  const {widgetId} = useParams<'widgetId'>();

  if (!widgetId) return null;
  return (
    <Card>
      <CardContent>
        <WidgetForm widgetId={widgetId} />
      </CardContent>
    </Card>
  );
};
