import styled from '@emotion/styled';
import {LinearProgress} from '@mui/material';
import {NotionRenderer} from 'react-notion-x';

import {adminApi} from 'api';

import 'react-notion-x/src/styles.css';
import 'prismjs/themes/prism-tomorrow.css';
import 'rc-dropdown/assets/index.css';
import 'katex/dist/katex.min.css';

const NotionWrapper = styled('div')`
  & .notion-viewport {
    position: absolute;
  }
`;

export const NotionPageViewer = ({pageId}: {pageId: string}) => {
  const {data, isLoading} = adminApi.endpoints.notionPageIndex.useQuery({pageId});

  if (isLoading) return <LinearProgress />;
  if (!data) return <div>Не найдено</div>;
  return (
    <NotionWrapper>
      <NotionRenderer recordMap={data as any} fullPage={false} />
    </NotionWrapper>
  );
};

export default NotionPageViewer;
