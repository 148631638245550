import {adminApi} from 'api';
import {PropsWithChildren} from 'react';
import {Outlet} from 'react-router';
import Page403 from '__theme/pages/Page403';

type AccessGuardProps = PropsWithChildren<{
  endpoint: keyof typeof adminApi.endpoints;
  disableBlockScreen?: boolean;
}>;

export const useHasAccess = (endpoint?: string) => {
  const {hasAccess} = adminApi.endpoints.appRoutes.useQueryState(undefined, {
    selectFromResult: ({data}) => {
      const hasAccess =
        (!!endpoint &&
          !!data?.find(item => item.operationId.replace('_', '').toLowerCase() === endpoint.toLowerCase())) ||
        false;
      return {hasAccess};
    },
  });
  return hasAccess;
};

export const AccessGuard = ({endpoint, children, disableBlockScreen = false}: AccessGuardProps) => {
  const hasAccess = useHasAccess(endpoint);
  if (!hasAccess) {
    if (!disableBlockScreen) return <Page403 />;
    return null;
  }
  return children ? <>{children}</> : <Outlet />;
};
