import {pick} from 'lodash';
import {useCallback} from 'react';
import {useNavigate} from 'react-router';

import {adminApi} from 'api';
import {WidgetsVisuals, WidgetVisualDto} from 'api/generated/admin-api';

import {initialValues} from './fields';

export const useCreateWidgetVisual = (data?: WidgetsVisuals, onSuccess?: (visualId: string) => void) => {
  const navigate = useNavigate();
  const [update] = adminApi.endpoints.widgetsVisualUpdate.useMutation();
  const [create] = adminApi.endpoints.widgetsVisualsAdd.useMutation();

  const onSubmit = useCallback(
    async (widgetVisualDto: WidgetVisualDto) => {
      let result;
      if (data && data._id) result = await update({visualId: `${data._id}`, widgetVisualDto});
      else result = await create({widgetVisualDto});
      if ('data' in result && result.data._id) {
        if (onSuccess) onSuccess(result.data._id);
        else if (!data) navigate(`./${result.data._id}`);
      }
      return result;
    },
    [data, update, create, navigate, onSuccess]
  );

  const initials = data ? (pick(data, Object.keys(initialValues)) as WidgetVisualDto) : initialValues;

  return {initials, onSubmit};
};
