import {FC, MouseEventHandler} from 'react';
import {Button} from '@mui/material';
import {adminApi} from 'api';
import {FileIdProp, VideoPlayerProp} from 'modules/files/types';

export const AddTimecode: FC<FileIdProp & VideoPlayerProp> = ({videoPlayerRef, fileId}) => {
  const [createTimecode] = adminApi.endpoints.timecodesAdd.useMutation();

  const addTimecode: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    if (videoPlayerRef.current) {
      const currentTime = videoPlayerRef.current.currentTime();
      if (typeof currentTime === 'number')
        createTimecode({timecodeDto: {fileId, time: Math.round(currentTime * 1000), enabled: true}});
    }
  };

  return (
    <Button fullWidth onClick={addTimecode}>
      Добавить таймкод
    </Button>
  );
};
