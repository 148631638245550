import {GridToolbar, GridToolbarContainerProps} from '@mui/x-data-grid';

import {Box, IconButton, Stack, Tooltip} from '@mui/material';
import {Icon} from '@iconify/react';
import restoreFill from '@iconify/icons-eva/refresh-fill';

export const CustomToolbar = ({onResetFilters, ...props}: GridToolbarContainerProps & {onResetFilters: () => void}) => (
  <Stack alignItems="center" direction="row">
    <GridToolbar {...props} />
    <Box height={1} pt={1}>
      <Tooltip title="Сбросить фильтры">
        <IconButton size="small" onClick={onResetFilters}>
          <Icon icon={restoreFill} />
        </IconButton>
      </Tooltip>
    </Box>
  </Stack>
);
