import {useState, ReactNode} from 'react';
import {Navigate, useLocation} from 'react-router-dom';

import {useIsAuthentificated} from 'modules/auth';
import AuthLogin from 'modules/auth/containers/AuthLogin';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({children}: AuthGuardProps) {
  const isAuthenticated = useIsAuthentificated();
  const {pathname} = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <AuthLogin />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
