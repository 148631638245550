import {ReactElement} from 'react';
import {useLocation} from 'react-use';
import {Link} from 'react-router-dom';

import {Avatar, Box, Stack, Tab, Tabs, Typography} from '@mui/material';
import {alpha} from '@mui/material/styles';
import createAvatar from '__theme/utils/createAvatar';
import {Icon} from '@iconify/react';
import roundAccountBox from '@iconify/icons-ic/round-account-box';
import heartFill from '@iconify/icons-eva/heart-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import roundPermMedia from '@iconify/icons-ic/round-perm-media';

import {Users} from 'api/generated/admin-api';
import {croppedImagePath} from 'utils';

const TabLink = ({value, label, icon}: {value: string; label: string; icon?: ReactElement | string}) => (
  <Tab component={Link} to={value} value={value} label={label} icon={icon} />
);

export const UserCardHeader = ({user}: {user: Users}) => {
  const {pathname} = useLocation();
  const tab = pathname?.split('/')[5];
  const currentTab = ['activity', 'info', 'permissions', 'payments', 'rewards'].includes(tab || '') ? tab : '';
  const title = `${user.name || ''} ${user.surname || ''}`;

  const UserTabs = [
    {
      value: '',
      label: 'Информация',
      icon: <Icon icon={heartFill} width={20} height={20} />,
    },
    {
      value: 'activity',
      label: 'Активность',
      icon: <Icon icon={roundAccountBox} width={20} height={20} />,
    },
    {
      value: 'permissions',
      label: 'Права доступа',
      icon: <Icon icon={peopleFill} width={20} height={20} />,
    },
    {
      value: 'payments',
      label: 'Платежи',
      icon: <Icon icon={roundPermMedia} width={20} height={20} />,
    },
  ];

  return (
    <Stack
      position="relative"
      borderRadius={2}
      width="100%"
      height={176}
      justifyContent="end"
      bgcolor={theme => alpha(theme.palette.primary.darker, 0.72)}
      boxShadow={theme => theme.customShadows.z16}
    >
      <Stack
        position="absolute"
        direction="row"
        alignItems="center"
        px={3}
        bottom={24}
        width={1}
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" spacing={3}>
          <Box
            component={Avatar}
            height={128}
            width={128}
            color={user.fileId ? 'default' : createAvatar(user.name || '').color}
            src={user.fileId ? croppedImagePath(user.fileId, {ratio: '1:1', maxWidth: 200}) : undefined}
            border="2px solid"
            borderColor="background.default"
          />
          <Stack>
            <Typography variant="h4" color="common.white">
              {title}
            </Typography>
            <Typography color="common.white" sx={{opacity: 0.72}}>
              {user?.userLogin?.value && `@${user.userLogin.value}`}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={3}>
          {[
            {
              label: 'Подписки',
              count: user.stats?.subscribesCount,
            },
            {
              label: 'Подписчики',
              count: user.stats?.subscribersCount,
            },
          ].map(i => (
            <Stack key={i.label} alignItems="end">
              <Typography variant="h4" color="common.white">
                {i.count}
              </Typography>
              <Typography color="common.white" sx={{opacity: 0.72}}>
                {i.label}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Stack
        alignItems="end"
        width="100%"
        px={3}
        overflow="hidden"
        sx={{backgroundColor: 'background.default', borderRadius: '0 0 8px 8px'}}
      >
        <Tabs value={currentTab}>
          {UserTabs.map(i => (
            <TabLink key={i.value} {...i} />
          ))}
        </Tabs>
      </Stack>
    </Stack>
  );
};
