import {FC} from 'react';
import {useController} from 'react-hook-form';

import {CommonFieldProps} from '../types';
import {LexicalComposer} from 'components/LexicalComposer';
import {LexicalFieldOptions} from './types';
import {useDispatch} from 'react-redux';

export const LexicalField: FC<CommonFieldProps & LexicalFieldOptions> = ({name, label, updateFn}) => {
  const {field} = useController({name});
  const dispatch = useDispatch();
  const onValueChange = (text: string) => {
    updateFn?.(text, {dispatch});
    field.onChange(text);
  };
  return <LexicalComposer label={label} onValueChange={onValueChange} initialValue={field.value} />;
};
