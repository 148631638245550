import {useCallback} from 'react';
import {useNavigate, useParams} from 'react-router';
import {pick} from 'lodash';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {adminApi} from 'api';
import {Access, AccessDto} from 'api/generated/admin-api';

import {initialValues} from './fields';

export const useGetAccessElem = () => {
  const {accessId} = useParams<'accessId'>();
  return adminApi.endpoints.accessElemIndex.useQuery(accessId ? {accessId} : skipToken);
};

export const useCreateAccess = (data?: Access) => {
  const navigate = useNavigate();
  const [create] = adminApi.endpoints.accessAdd.useMutation();
  const [update] = adminApi.endpoints.accessElemUpdate.useMutation();

  const onSubmit = useCallback(
    async (accessDto: AccessDto) => {
      if (data && data._id) return update({accessId: `${data._id}`, accessDto});
      else {
        const result = await create({accessDto});
        if ('data' in result) navigate(`../${result.data._id}`);
        return result;
      }
    },
    [data, update, create, navigate]
  );

  const initials = data ? (pick(data, Object.keys(initialValues)) as AccessDto) : initialValues;

  return {initials, onSubmit};
};
