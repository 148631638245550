import {DOMConversionMap, ElementNode, LexicalNode, SerializedElementNode} from 'lexical';

export type SerializedQuoteContentNode = SerializedElementNode;
export type QuoteContentPayload = string;

export class QuoteContentNode extends ElementNode {
  static getType(): string {
    return 'quote-content';
  }

  static clone(node: QuoteContentNode): QuoteContentNode {
    return new QuoteContentNode(node.__key);
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('div');
    dom.classList.add('quote__content');
    return dom;
  }

  updateDOM(): boolean {
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return {};
  }

  static importJSON(): QuoteContentNode {
    return $createQuoteContentNode();
  }

  /**
   * @returns {boolean} Whether or not this node can be empty.
   */
  canBeEmpty(): boolean {
    return false;
  }

  exportJSON(): SerializedQuoteContentNode {
    return {
      ...super.exportJSON(),
      type: 'quote-content',
      version: 1,
    };
  }
}

export function $createQuoteContentNode(): QuoteContentNode {
  return new QuoteContentNode();
}

export function $isQuoteContentNode(node: LexicalNode | null | undefined): node is QuoteContentNode {
  return node instanceof QuoteContentNode;
}
