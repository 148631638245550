import {Controller} from 'react-hook-form';

import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import TextField, {TextFieldProps} from '@mui/material/TextField';

import {CommonFieldProps} from '../types';
import {DateTimeSelectProps} from './types';

export const DateTimeSelect = ({label, name, onlyDate, onlyTime, disabled}: DateTimeSelectProps & CommonFieldProps) => {
  return (
    <Controller
      name={name}
      render={fieldProps => {
        const commonProps = {
          label,
          disabled,
          ...fieldProps.field,
          renderInput: (props: TextFieldProps) => <TextField {...props} error={Boolean(fieldProps.fieldState.error)} />,
        };
        if (onlyTime) return <TimePicker {...commonProps} inputFormat="HH:mm" />;
        if (onlyDate) return <DatePicker {...commonProps} inputFormat="dd.MM.yyyy" />;
        return <DateTimePicker {...commonProps} inputFormat="dd.MM.yyyy HH:mm" />;
      }}
    />
  );
};
