import {PropsWithChildren, useCallback, ReactNode} from 'react';
import {useToggle} from 'react-use';
import {isUndefined} from 'lodash';
import {Dialog, DialogActions, DialogContent, DialogProps, DialogTitle} from '@mui/material';
import {SxProps} from '@mui/system';

export type DialogPropsType = PropsWithChildren<{
  title: string;
  action?: ReactNode;
  open?: boolean;
  onClose?: () => void;
  contentSx?: SxProps;
  maxWidth?: DialogProps['maxWidth'];
}>;

export const useDialog = (): [(props: DialogPropsType) => JSX.Element, () => void, () => void] => {
  const [open, toggle] = useToggle(false);
  const MyDialog = useCallback(
    ({children, title, action, open: forceOpen, onClose, contentSx, maxWidth}: DialogPropsType) => (
      <Dialog
        open={forceOpen ?? open}
        onClose={isUndefined(forceOpen) ? toggle : onClose}
        fullWidth
        maxWidth={maxWidth}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={{paddingTop: '8px !important', ...contentSx}}>{children}</DialogContent>
        {action && <DialogActions>{action}</DialogActions>}
      </Dialog>
    ),
    [open, toggle]
  );
  return [MyDialog, () => toggle(true), () => toggle(false)];
};

export default useDialog;
