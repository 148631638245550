import {FC, useState} from 'react';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {LexicalComposer as LexicalOriginComposer} from '@lexical/react/LexicalComposer';

import {ListPlugin} from '@lexical/react/LexicalListPlugin';
import {TabIndentationPlugin} from '@lexical/react/LexicalTabIndentationPlugin';

import {Box, Typography} from '@mui/material';
import {EditorState} from 'lexical';
// import {ToolbarPlugin} from './Toolbar';
import {KeywordsPlugin} from './Plugins/KeywordsPlugin';
import {BlockPlugin} from './Plugins/BlockPlugin';
import {DraggableBlockPlugin} from './Plugins/DraggableBlockPlugin';
import {ImagesPlugin} from './Plugins/ImagesPlugin';
import {ComponentPickerPlugin} from './Plugins/ComponentPickerPlugin';
import {QuotePlugin} from './Plugins/QuotePlugin';
import {FilesPlugin} from './Plugins/FilesPlugin';
import {LegendPlugin} from './Plugins/LegendPlugin';
import {VideoPlugin} from './Plugins/VideoPlugin';
import {ContentPlugin} from './Plugins/ContentPlugin';

import './styles.css';
import {nodes} from './nodes';
import {theme} from './theme';
import {LexicalComposerProps} from './types';

import {ToolbarPlugin} from './Toolbar';
import {OnChangePlugin} from './Plugins/OnChangePlugin';
import {AutoFocusPlugin} from './Plugins/AutoFocusPlugin';

const isJSONString = (str?: string) => {
  if (!str) return false;
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

function onError(error: any) {
  console.error(error);
}

const DEFAULT_NAMESPACE = 'rootEditor';

export const LexicalComposer: FC<LexicalComposerProps> = ({initialValue, onValueChange, label}) => {
  const onChange = (state: EditorState) => onValueChange(JSON.stringify(state.toJSON() || {}));
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);
  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const initialConfig = {
    nodes,
    theme,
    onError,
    namespace: DEFAULT_NAMESPACE,
    editorState: isJSONString(initialValue) ? initialValue : undefined,
  };

  return (
    <Box
      sx={{borderWidth: 1, borderColor: 'grey.300', borderStyle: 'solid', '&:hover': {borderColor: 'grey.900'}}}
      borderRadius={1}
      maxWidth="100%"
      width="100%"
      marginX="auto"
      position="relative"
    >
      {label && (
        <Box position="absolute" top={-14} left={8} bgcolor="white" px={0.75}>
          <Typography variant="caption" color="grey.500" fontSize="12px">
            {label}
          </Typography>
        </Box>
      )}
      <LexicalOriginComposer initialConfig={initialConfig}>
        <Box sx={{borderBottomWidth: 1, borderBottomColor: 'grey.300', borderBottomStyle: 'solid'}}>
          <ToolbarPlugin />
        </Box>
        <RichTextPlugin
          contentEditable={
            <Box
              sx={{
                minHeight: 300,
                border: 0,
                display: 'flex',
                position: 'relative',
                outline: 0,
                zIndex: 0,
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{
                  flex: 'auto',
                  resize: 'vertical',
                  position: 'relative',
                }}
                ref={onRef}
              >
                <ContentEditable className="content-editable__root" />
              </Box>
            </Box>
          }
          placeholder={<div />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <ImagesPlugin />
        <ListPlugin />
        <BlockPlugin anchorElem={floatingAnchorElem} />
        <TabIndentationPlugin />
        <HistoryPlugin />
        <QuotePlugin />
        <LegendPlugin />
        <DraggableBlockPlugin />
        <AutoFocusPlugin />
        <KeywordsPlugin />
        <VideoPlugin />
        <FilesPlugin />
        <ContentPlugin />
        <ComponentPickerPlugin />
        <OnChangePlugin onChange={onChange} />
        {/* <>{floatingAnchorElem && <FloatingToolbarPlugin anchorElem={floatingAnchorElem} />}</> */}
      </LexicalOriginComposer>
    </Box>
  );
};
