/* eslint-disable react-hooks/exhaustive-deps */
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {LinearProgress} from '@mui/material';

import {adminApi} from 'api';
import {Form} from 'components/Form';

import {fields, validation} from './fields';
import {useCreateContentCategory} from './hook';

export type ContentCategoryFormProps = {
  entryId?: string;
  onClose: () => void;
};

export const ContentCategoryForm = ({entryId, onClose}: ContentCategoryFormProps) => {
  const {data, isFetching} = adminApi.endpoints.contentsCategoryIndex.useQuery(
    entryId ? {categoryId: entryId} : skipToken
  );
  const {onSubmit, initials} = useCreateContentCategory(onClose, data);

  if (isFetching && !data) return <LinearProgress />;
  return <Form fields={fields} initials={initials} validation={validation} onSubmit={onSubmit} onReset={onClose} />;
};
