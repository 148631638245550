import {atom, useAtom} from 'jotai';
import {useUpdateAtom} from 'jotai/utils';
import {skipToken} from '@reduxjs/toolkit/query';

import LoadingScreen from '__theme/components/LoadingScreen';
import {Stack} from '@mui/material';

import {usersApi} from 'api';
import {CommentsIndexApiArg} from 'api/generated/users-api';

import useDialog from 'components/Dialog';
import {DataTable} from 'components/DataTable/DataTable';

import {CommentsTableRow} from './CommentsTableRow';

const commentsModalAtom = atom<{referenceName: CommentsIndexApiArg['referenceName']; referenceId: string} | null>(null);

export const useOpenComments = (referenceName: CommentsIndexApiArg['referenceName']) => {
  const update = useUpdateAtom(commentsModalAtom, Symbol.for('commentsModal'));
  return (referenceId: string) => update({referenceName, referenceId});
};

export const CommentsModal = () => {
  const [Dialog] = useDialog();
  const [item, setItem] = useAtom(commentsModalAtom, Symbol.for('commentsModal'));
  const {data, isLoading} = usersApi.endpoints.commentsIndex.useQuery(
    item ? {...item, sort: 'created_asc'} : skipToken
  );

  const comments = data?.data;
  return (
    <Dialog title="Комментарии" open={Boolean(item)} onClose={() => setItem(null)} contentSx={{p: 0}} maxWidth="lg">
      <Stack p={2}>
        {isLoading && <LoadingScreen />}
        {comments && (
          <DataTable
            columns={[
              {id: 'content', label: 'Название'},
              {id: 'createdAt', label: 'Дата создания'},
              {id: 'user.name', label: 'Автор'},
              {id: ''},
            ]}
            data={comments}
            Row={CommentsTableRow}
            paginationOptions={{
              rowsPerPageDefault: 5,
            }}
          />
        )}
      </Stack>
    </Dialog>
  );
};
