import {Button, Stack, Typography} from '@mui/material';
import {Comments} from 'api/generated/users-api';
import {UserItem} from 'components/UserItem';

export const CommentModal = ({
  comment,
  onClose,
  onDelete,
  onRestore,
}: {
  comment: Comments;
  onClose: () => void;
  onDelete: () => void;
  onRestore: () => void;
}) => (
  <Stack mt={3} spacing={3}>
    {comment.user && <UserItem user={comment.user} />}
    <Typography sx={{wordBreak: 'break-all'}}>{comment.content}</Typography>
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
      {comment.deletedAt ? (
        <Button variant="contained" color="secondary" onClick={onRestore}>
          Восстановить
        </Button>
      ) : (
        <Button variant="contained" color="error" onClick={onDelete}>
          Удалить
        </Button>
      )}

      <Button variant="outlined" onClick={onClose}>
        Закрыть
      </Button>
    </Stack>
  </Stack>
);
