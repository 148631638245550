import _ from 'lodash';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import Player from 'video.js/dist/types/player';

import Box from '@mui/material/Box';
import {FC, memo, useEffect, useRef} from 'react';

import {filesApi} from 'api';
import {VideoTimecodes} from 'modules/files/containers';

const mimeTypes: Record<string, string> = {
  mp4: 'video/mp4',
  webm: 'video/webm',
  ogg: 'video/ogg',
  ogv: 'video/ogg',
  mov: 'video/quicktime',
  m4v: 'video/x-m4v',
  avi: 'video/x-msvideo',
  wmv: 'video/x-ms-wmv',
  flv: 'video/x-flv',
  m3u8: 'application/x-mpegURL',
};

type VideoPlayerProps = {
  fileId: string;
};

export const VideoPlayer: FC<VideoPlayerProps> = memo(({fileId}) => {
  const videoRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<Player | null>(null);
  const {data} = filesApi.endpoints.fileInfo.useQuery(
    {fileId},
    {
      selectFromResult: ({data}) => {
        if (!data) return {data: undefined};
        const isVideo = _.startsWith(data.type, 'video');
        const extension = (data.location || '').split('.').pop()?.toLowerCase();

        return {
          data: {
            isVideo,
            sources:
              extension && extension in mimeTypes && isVideo
                ? [{src: data.location, type: mimeTypes[extension]}]
                : undefined,
          },
        };
      },
    }
  );

  useEffect(() => {
    if (data?.isVideo && data?.sources) {
      if (!playerRef.current) {
        const videoElement = document.createElement('video-js');
        videoElement.classList.add('vjs-big-play-centered');
        videoRef.current?.appendChild(videoElement);

        playerRef.current = videojs(
          videoElement,
          {
            fluid: true,
            responsive: true,
            controls: true,
            autoplay: false,
            preload: 'auto',
            sources: data.sources,
          },
          () => {}
        );
      }
    }
  }, [data]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <>
      <Box data-vjs-player width={1} mb={2}>
        <div ref={videoRef} />
      </Box>
      {/* <CreatePreview fileId={fileId} /> */}
      <VideoTimecodes fileId={fileId} videoPlayerRef={playerRef} />
    </>
  );
});
