import React, {useEffect, useState} from 'react';
import {useMount} from 'react-use';
import {useForm} from 'react-hook-form';
import {useAppDispatch} from 'store';
import {useSnackbar} from 'notistack';

import {Button, Stack, TextField, Typography} from '@mui/material';

import {authApi} from 'api';
import {startSesstion} from '../../store/actions';

export const ConfirmCode = ({login, request}: {login: string; request: (login: string) => any}) => {
  const [confirm] = authApi.useLoginRouteConfirmCodeMutation();

  const {enqueueSnackbar} = useSnackbar();
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState(false);

  useMount(() => {
    setDisabled(true);
  });
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (disabled) timer = setTimeout(() => setDisabled(false), 5000);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [disabled]);

  const {register, handleSubmit} = useForm<{code: string}>();
  const onSubmit = handleSubmit(async data => {
    const result = await confirm({body: {...data, login}});
    if ('data' in result) {
      dispatch(startSesstion(result.data.accessToken));
    } else if ('error' in result) {
      enqueueSnackbar((result.error as any)?.data?.message || 'Ошибка подтверждения', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={1}>
        <Typography>Введите код подтверждения</Typography>
        <TextField
          {...register('code')}
          type="number"
          label="Код подтверждения"
          variant="outlined"
          autoComplete="off"
        />
        <Button type="submit" variant="contained" size="medium" sx={{display: 'block'}}>
          Подтвердить
        </Button>
        <Button
          type="submit"
          variant="text"
          size="medium"
          sx={{display: 'block'}}
          disabled={disabled}
          onClick={() => {
            request(login);
            setDisabled(true);
          }}
        >
          Выслать код повторно
        </Button>
      </Stack>
    </form>
  );
};
