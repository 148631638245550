import * as React from 'react';
import {
  $applyNodeReplacement,
  DecoratorNode,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical';

const Component = React.lazy(
  // @ts-ignore
  () => import('./VideoComponent')
);

const VIDEO_TYPE = 'video';

export interface VideoPayload {
  key?: NodeKey;
  url?: string;
  fileId?: string;
}

export type SerializedVideoNode = Spread<
  {
    url?: string;
    fileId?: string;
  },
  SerializedLexicalNode
>;

export class VideoNode extends DecoratorNode<JSX.Element> {
  __url?: string;

  __fileId?: string;

  static getType(): string {
    return VIDEO_TYPE;
  }

  static clone(node: VideoNode): VideoNode {
    return new VideoNode(node.__url, node.__fileId, node.__key);
  }

  static importJSON(serializedNode: SerializedVideoNode): VideoNode {
    const {url, fileId} = serializedNode;
    return $createVideoNode({url, fileId});
  }

  constructor(url?: string, fileId?: string, key?: NodeKey) {
    super(key);
    this.__url = url;
    this.__fileId = fileId;
  }

  exportJSON(): SerializedVideoNode {
    return {
      type: VIDEO_TYPE,
      url: this.__url,
      fileId: this.__fileId,
      version: 1,
    };
  }

  setUrl(url: string): void {
    const writable = this.getWritable();
    writable.__url = url;
    writable.__fileId = undefined;
  }

  setFileId(fileId: string): void {
    const writable = this.getWritable();
    writable.__fileId = fileId;
    writable.__url = undefined;
  }

  createDOM(config: EditorConfig): HTMLElement {
    const div = document.createElement('div');
    const theme = config.theme;
    const className = theme.image;
    if (className !== undefined) div.className = className;
    return div;
  }

  updateDOM(): false {
    return false;
  }

  decorate(): JSX.Element {
    return (
      <React.Suspense fallback={null}>
        <Component url={this.__url} fileId={this.__fileId} nodeKey={this.getKey()} />
      </React.Suspense>
    );
  }
}

export const $createVideoNode = ({url, fileId, key}: VideoPayload): VideoNode =>
  $applyNodeReplacement(new VideoNode(url, fileId, key));

export const $isVideoNode = (node: LexicalNode | null | undefined): node is VideoNode => node instanceof VideoNode;
