/* eslint-disable @typescript-eslint/no-shadow */
import {useState} from 'react';
import {Icon} from '@iconify/react';
import {NavLink as RouterLink, matchPath, useLocation} from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import {alpha, useTheme, styled} from '@mui/material/styles';
import {
  Box,
  List,
  BoxProps,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  ListItemButton,
  ListItemButtonProps,
  ListSubheaderProps,
} from '@mui/material';
import {AccessGuard} from 'modules/auth/containers';
import {adminApi} from 'api';

// ----------------------------------------------------------------------

const ListSubheaderStyle = (props: ListSubheaderProps) => (
  <ListSubheader disableSticky disableGutters sx={{mt: 3, mb: 2, pl: 5, color: 'text.primary'}} {...props} />
);

interface ListItemStyleProps extends ListItemButtonProps {
  component?: React.ElementType;
  to?: string;
}

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(({theme}) => ({
  ...(theme.typography.body2 as any),
  height: 'auto',
  position: 'relative',
  textTransform: 'capitalize',
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

type NavItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  info?: JSX.Element;
  children?: {
    title: string;
    path: string;
    access?: keyof typeof adminApi.endpoints;
  }[];
};

function NavItem({item, isShow}: {item: NavItemProps; isShow?: boolean | undefined}) {
  const theme = useTheme();
  const {pathname} = useLocation();
  const {title, path, icon, info, children} = item;
  const isActiveRoot = path ? !!matchPath({path, end: false}, pathname) : false;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen(!open);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': {display: 'block'},
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>

          {isShow && (
            <>
              <ListItemText disableTypography primary={title} />
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{width: 16, height: 16, ml: 1}}
              />
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map(item => {
                const {title, path} = item;
                const isActiveSub = path ? !!matchPath({path, end: false}, pathname) : false;
                const Content = (
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeSubStyle),
                    }}
                  >
                    <ListItemIconStyle>
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: 'flex',
                          borderRadius: '50%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: 'text.disabled',
                          transition: theme => theme.transitions.create('transform'),
                          ...(isActiveSub && {
                            transform: 'scale(2)',
                            bgcolor: 'primary.main',
                          }),
                        }}
                      />
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={title} />
                  </ListItemStyle>
                );

                if (!item.access) return Content;
                return (
                  <AccessGuard key={title} disableBlockScreen endpoint={item.access}>
                    {Content}
                  </AccessGuard>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

interface NavSectionProps extends BoxProps {
  isShow?: boolean | undefined;
  navConfig: {
    subheader: string;
    items: NavItemProps[];
  }[];
}

export default function NavSection({navConfig, isShow = true, ...other}: NavSectionProps) {
  return (
    <Box {...other}>
      {navConfig.map(list => {
        const {subheader, items} = list;
        return (
          <List key={subheader} disablePadding>
            {isShow && (
              <ListSubheaderStyle>
                <>{subheader}</>
              </ListSubheaderStyle>
            )}
            {items.map((item: NavItemProps) => (
              <NavItem key={item.title} item={item} isShow={isShow} />
            ))}
          </List>
        );
      })}
    </Box>
  );
}
