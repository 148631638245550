import {Stages} from 'api/generated/users-api';
import {DataTable} from 'components/DataTable/DataTable';
import {UserQuestsTableRow} from './UserQuestsTableRow';

export const UserQuestsTable = ({data}: {data: Stages[]}) => {
  return (
    <DataTable
      data={data}
      columns={[
        {id: 'quest.name', label: 'Название'},
        {id: 'createdAt', label: 'Взят в работу'},
        {id: 'questType', label: 'Тип'},
        {id: 'status', label: 'Статус'},
        {id: ''},
      ]}
      Row={UserQuestsTableRow}
      paginationOptions={{
        rowsPerPageDefault: 5,
      }}
    />
  );
};
