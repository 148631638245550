import {Stack, Typography} from '@mui/material';
import {Publications} from 'api/generated/users-api';

export const PublicationContent = ({post}: {post: Publications}) => {
  return (
    <Stack p={3} spacing={1.5} boxShadow={theme => theme.customShadows.z16} borderRadius={2}>
      <Typography sx={{wordBreak: 'break-word'}}>{post.content}</Typography>
    </Stack>
  );
};
