import {FC} from 'react';
import {Stack, Box, Typography, CircularProgress} from '@mui/material';

import {adminApi} from 'api';

import {QuestPointIdProp} from 'modules/events/types';

export const QuestPointPreview: FC<QuestPointIdProp> = ({pointId}) => {
  const {data: questPoint, isLoading} = adminApi.endpoints.questPointIndex.useQuery(
    {pointId},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  if (!isLoading && !questPoint) return null;
  return (
    <Box>
      {questPoint && (
        <Stack alignItems="top" spacing={2} p={2} borderRadius={2} border="1px solid" borderColor="grey.300">
          <Typography variant="body2" fontWeight={600}>
            {questPoint.name}
          </Typography>
          <Typography variant="caption">{questPoint.type}</Typography>
        </Stack>
      )}
      {isLoading && <CircularProgress />}
    </Box>
  );
};
