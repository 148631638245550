import {format, parseISO} from 'date-fns';
import {ru} from 'date-fns/locale';
import * as _ from 'lodash/fp';
import {stringify} from 'query-string';

import {adminApi} from 'api';
import {FileCropApiArg} from 'api/generated/files-api';

export * from './redux';
export * from './columns';
export * from './hooks';

export const getRowId = (row: any) => row._id;

export const filePath = (fileId: string, method?: 'stream' | 'download' | 'preview') =>
  `${process.env.REACT_APP_FILE_API_PATH}/file_${fileId}/${method || 'download'}`;

export const croppedImagePath = (fileId: string, params: Pick<FileCropApiArg, 'ratio' | 'maxWidth'>) => {
  return `${process.env.REACT_APP_FILE_API_PATH}/file_${fileId}/crop?${stringify(params)}`;
};

export const getRatioMultiplier = (ratio: FileCropApiArg['ratio']) =>
  _.compose((item: [number, number]) => _.divide(...item), _.map(_.parseInt(10)), _.split(':'))(ratio);

export const formatDate = (date: string) => format(parseISO(date), 'PP', {locale: ru});

export const useMyId = () => {
  const {me} = adminApi.endpoints.profileIndex.useQueryState(undefined, {selectFromResult: ({data}) => ({me: data})});
  return me?._id;
};

export const isSuccessResult = <T>(result: any): result is Record<'data', T> => Boolean(result?.data?._id);
