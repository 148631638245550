import * as AWS from 'aws-sdk';
import {useController} from 'react-hook-form';
import Box from '@mui/system/Box';

import {FileUploadProps} from './types';
import {CommonFieldProps} from '../types';
import {filesApi} from 'api';
import {useCallback, useRef, useState} from 'react';
import {format} from 'date-fns';
import {useDropzone} from 'react-dropzone';
import {Button, LinearProgress, Stack, Typography} from '@mui/material';
import {VideoPlayer} from 'components/VideoPlayer';

const s3client = new AWS.S3({
  endpoint: 'https://storage.yandexcloud.net',
  accessKeyId: 'YCAJEi02J8-sEjQ7MsiTfbEwM',
  secretAccessKey: 'YCPHRdn_k6sDUZvvEgQT0_qXd7KQxaFX31sYceVX',
  region: 'ru-central1',
  httpOptions: {
    timeout: 5 * 60 * 60 * 1000,
  },
});

const uploadDefaultState = {isUploading: false, progress: 0, isUploadFinished: false, uploadError: null};

export const useFileUpload = ({name}: CommonFieldProps & FileUploadProps) => {
  // const [upload] = filesApi.useRootUploadMutation();
  const uploadRef = useRef<AWS.S3.ManagedUpload | undefined>();
  const [uploadState, setState] = useState<{
    progress: number;
    isUploading: boolean;
    isUploadFinished: boolean;
    uploadError: null | AWS.AWSError;
  }>(uploadDefaultState);

  const cancelUpload = () => {
    uploadRef.current?.abort();
    setState(uploadDefaultState);
  };

  const [createDummy] = filesApi.endpoints.rootCreateDummy.useMutation();
  const {
    field: {onChange},
  } = useController({name});

  const uploadFile = useCallback(
    (fileId: string, file: File) => {
      onChange(fileId);
      uploadRef.current = new AWS.S3.ManagedUpload({
        params: {
          Bucket: 'demo-raw-videos',
          Key: `${format(new Date(), 'yyyy/MM/dd')}/${Date.now()}-${file.name}`,
          Body: file,
          ContentType: file.type,
          Metadata: {
            RelationId: fileId,
          },
        },
        partSize: 10 * 1024 * 1024,
        queueSize: 10,
        service: s3client,
      });

      setState({...uploadDefaultState, isUploading: true});

      uploadRef.current.on('httpUploadProgress', event => {
        setState(state => ({...state, progress: Math.round((event.loaded / event.total) * 100)}));
      });

      uploadRef.current.send(err => {
        if (err) {
          setState(state => ({...state, isUploading: false, isUploadFinished: false, uploadError: err}));
        } else {
          setState(state => ({...state, isUploading: false, isUploadFinished: true}));
        }
      });
    },
    [onChange]
  );

  const onDrop = useCallback(
    async (files: File[]) => {
      const [file] = files;
      if (!file) return;
      // Создадим объект-пустышку для видео
      const dummy = await createDummy({
        createDummyDto: {
          s3: true,
          type: file.type,
          name: file.name,
          width: 1000,
          height: 600,
        },
      });

      if ('data' in dummy) {
        const fileId = dummy.data._id!;
        // загрузим видео
        uploadFile(fileId, file);
      }
    },
    [uploadFile, createDummy]
  );

  return {onDrop, cancelUpload, ...uploadState};
};

export const VideoUpload = (props: CommonFieldProps & FileUploadProps) => {
  const {label, name} = props;
  const {onDrop, progress, cancelUpload, isUploading} = useFileUpload(props);
  const {
    field: {value},
    fieldState: {error},
  } = useController({name});
  const {getRootProps, getInputProps, isDragActive, isDragReject} = useDropzone({
    multiple: false,
    onDrop,
    accept: '.mov, .mp4, .mkv',
  });

  return (
    <Box>
      <Box sx={{typography: 'body1', mb: 2}}>{label}</Box>
      {value && !isUploading && <VideoPlayer fileId={value} />}
      <Box
        {...getRootProps()}
        p={3}
        sx={theme => ({
          borderRadius: 1,
          padding: theme.spacing(5, 3),
          backgroundColor: theme.palette.background.neutral,
          border: `1px dashed ${theme.palette.grey[500_32]}`,
          ...(isDragActive && {opacity: 0.72}),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
        })}
      >
        <input {...getInputProps()} />
        <video id="videoPreview" width="320" height="240" controls style={{display: 'none'}}></video>
        <canvas id="thumbnailCanvas" style={{display: 'none'}}></canvas>
        <img id="thumbnail" alt="Video Thumbnail" style={{display: 'none'}}></img>

        <Stack spacing={1} position="relative">
          <Typography gutterBottom variant="h5">
            {isUploading ? 'Идет загрузка видео...' : 'Перетащите или выберите файл'}
          </Typography>

          {isUploading && <LinearProgress value={progress} variant="determinate" />}
          {isUploading && (
            <Box>
              <Button color="error" size="small" onClick={cancelUpload}>
                Отменить загрузку
              </Button>
            </Box>
          )}
          {!isUploading && (
            <Typography variant="body2" sx={{color: 'text.secondary'}}>
              Перетащите файлы сюда или&nbsp;
              <Typography variant="body2" component="span" sx={{color: 'primary.main', textDecoration: 'underline'}}>
                выберите
              </Typography>
              &nbsp;на своем устройстве
            </Typography>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default VideoUpload;
