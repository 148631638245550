import {get} from 'lodash';

type Anonymous = Record<string | number, string>;

function descendingComparator(objA: Anonymous, objB: Anonymous, orderBy: string) {
  const defaultNumber = 0;
  const defaultString = '�';

  const a = get(objA, orderBy);
  const b = get(objB, orderBy);
  const defaultValue = typeof b === 'number' || a === 'number' ? defaultNumber : defaultString;

  const valueA = typeof a === 'string' ? a.toLowerCase() : !!a ? a : defaultValue;
  const valueB = typeof b === 'string' ? b.toLowerCase() : !!b ? b : defaultValue;

  if (valueB < valueA) return -1;
  if (valueB > valueA) return 1;
  return 0;
}

export function getComparator(order: string, orderBy: string) {
  return order === 'desc'
    ? (a: Anonymous, b: Anonymous) => descendingComparator(a, b, orderBy)
    : (a: Anonymous, b: Anonymous) => -descendingComparator(a, b, orderBy);
}

export function applySortFilter<T>(array: T[], comparator: (a: any, b: any) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as const);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}
