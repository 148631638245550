import {HeadingNode} from '@lexical/rich-text';
import {ListNode, ListItemNode} from '@lexical/list';

import {FileNode} from './Plugins/FilesPlugin';
import {BlockNode} from './Plugins/BlockPlugin';
import {VideoNode} from './Plugins/VideoPlugin';
import {ContentNode} from './Plugins/ContentPlugin';
import {KeywordNode} from './Plugins/KeywordsPlugin';
import {ImageNode, ImageContainerNode} from './Plugins/ImagesPlugin';
import {QuoteContainerNode, QuoteAuthorNode, QuoteContentNode} from './Plugins/QuotePlugin';
import {LegendContainerNode, LegendTitleNode, LegendContentNode} from './Plugins/LegendPlugin';

export const nodes = [
  HeadingNode,
  QuoteContainerNode,
  QuoteAuthorNode,
  QuoteContentNode,
  ListNode,
  FileNode,
  ListItemNode,
  KeywordNode,
  BlockNode,
  ImageNode,
  ImageContainerNode,
  LegendContainerNode,
  LegendTitleNode,
  LegendContentNode,
  VideoNode,
  ContentNode,
];
