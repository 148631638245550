import {PropsWithChildren, useEffect} from 'react';
import {Outlet} from 'react-router';

import {BreadcrumbItem} from './types';
import {useBreadcrumbsSetter} from './context';

export const BreadcrumbsSet = ({children, name, href}: PropsWithChildren<BreadcrumbItem>) => {
  const addItem = useBreadcrumbsSetter();

  useEffect(() => {
    const item = {name, href};
    if (addItem) addItem(item);
    return () => {
      if (addItem) addItem(item, true);
    };
  }, [name, href, addItem]);

  return children ? <>{children}</> : <Outlet />;
};
