import {pick} from 'lodash';
import {skipToken} from '@reduxjs/toolkit/query';

import {adminApi} from 'api';
import {Goals} from 'api/generated/users-api';

import {useGoalActions} from 'modules/users/components/UserCardGoalTable/hooks';

import {getInitials} from './fields';
import {GoalFormType} from './types';

export const useGoalForm = (goal: Goals) => {
  const {data: goalType} = adminApi.endpoints.goalsTypeIndex.useQuery(
    goal.goalTypeId && !goal.rewardValue ? {typeId: goal.goalTypeId} : skipToken
  );
  const {deleteAction, restoreAction, updateAction} = useGoalActions(goal);

  const onSubmit = async (data: GoalFormType) => {
    const rewardValue = goal.rewardValue
      ? {...goal.rewardValue, value: data.rewardValue.value}
      : goalType?.rewardId
      ? {rewardId: goalType.rewardId, value: data.rewardValue.value}
      : undefined;
    await updateAction({
      ...pick(data, ['name', 'description', 'goalTypeId', 'planFinishDate', 'description', 'personalQualityTagsId']),
      privacy: data.privacy || undefined,
      rewardValue,
    });
  };

  return {
    initials: getInitials(goal),
    onSubmit,
    onDelete: deleteAction,
    onRestore: restoreAction,
  };
};
