import {useParams} from 'react-router-dom';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {adminApi} from 'api';

const useGetReactionsBlock = () => {
  const {blockId} = useParams<'blockId'>();
  return adminApi.endpoints.reactionsBlockIndex.useQuery(blockId ? {blockId} : skipToken);
};

export default useGetReactionsBlock;
