import {Users} from 'api/generated/admin-api';
import {enchanceColumns} from 'utils';

export const usersColumns = enchanceColumns<Users>(
  [
    {field: '_id', headerName: 'ID', width: 90, hide: true},
    {
      field: 'name',
      headerName: 'Имя',
      flex: 0.3,
    },
    {
      field: 'surname',
      headerName: 'Фамилия',
      flex: 0.3,
    },
    {
      field: 'username',
      headerName: 'Username',
      type: 'string',
      flex: 0.4,
      valueGetter: ({row}: {row: Users}) => row.logins?.find(login => login.type === 'plain')?.value || '',
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'string',
      flex: 0.4,
      valueGetter: ({row}: {row: Users}) => row.logins?.find(login => login.type === 'email')?.value || '',
    },
    {
      field: 'phone',
      headerName: 'Phone',
      type: 'string',
      flex: 0.4,
      valueGetter: ({row}: {row: Users}) => row.logins?.find(login => login.type === 'phone')?.value || '',
    },
  ],
  {hideDates: true, hideEnabled: true}
);
