import * as React from 'react';
import {Suspense} from 'react';
import {$applyNodeReplacement, DecoratorNode} from 'lexical';
import {EditorConfig, LexicalNode, NodeKey, SerializedLexicalNode, Spread} from 'lexical';

const ImageComponent = React.lazy(
  // @ts-ignore
  () => import('./ImageComponent')
);

export type ImageNodeType = 'block' | 'round';
export interface ImagePayload {
  key?: NodeKey;
  mode: ImageNodeType;
  fileId?: string;
  width?: number;
  height?: number;
  maxWidth?: number;
  caption?: string;
  captionsEnabled?: boolean;
}

export type SerializedImageNode = Spread<
  {
    mode: ImageNodeType;
    fileId?: string;
    width?: number;
    height?: number;
    maxWidth?: number;
    captionsEnabled?: boolean;
    caption?: string;
  },
  SerializedLexicalNode
>;

export class ImageNode extends DecoratorNode<JSX.Element> {
  __mode: ImageNodeType;

  __fileId?: string;

  __width?: number;

  __height?: number;

  __maxWidth?: number;

  __captionsEnabled: boolean;

  __caption?: string;

  static getType(): string {
    return 'image';
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(
      node.__mode,
      node.__fileId,
      node.__maxWidth,
      node.__width,
      node.__height,
      node.__caption,
      node.__captionsEnabled,
      node.__key
    );
  }

  static importJSON(serializedNode: SerializedImageNode): ImageNode {
    const {height, width, maxWidth, caption, fileId, mode, captionsEnabled} = serializedNode;
    return $createImageNode({
      mode,
      height,
      maxWidth,
      caption,
      captionsEnabled,
      fileId,
      width,
    });
  }

  constructor(
    mode: ImageNodeType,
    fileId?: string,
    maxWidth?: number,
    width?: number,
    height?: number,
    caption?: string,
    captionsEnabled?: boolean,
    key?: NodeKey
  ) {
    super(key);

    this.__mode = mode;
    this.__fileId = fileId;
    this.__width = width;
    this.__height = height;
    this.__maxWidth = maxWidth;
    this.__caption = caption;
    this.__captionsEnabled = !!captionsEnabled;
  }

  exportJSON(): SerializedImageNode {
    return {
      type: 'image',
      mode: this.__mode,
      fileId: this.__fileId,
      width: this.__width,
      height: this.__height,
      maxWidth: this.__maxWidth,
      caption: this.__caption,
      captionsEnabled: this.__captionsEnabled,
      version: 1,
    };
  }

  setWidthAndHeight(width: number, height: number): void {
    const writable = this.getWritable();
    writable.__width = width;
    writable.__height = height;
  }

  setFileId(fileId: string): void {
    const writable = this.getWritable();
    writable.__fileId = fileId;
  }

  setCaption(caption: string): void {
    const writable = this.getWritable();
    writable.__caption = caption;
  }

  createDOM(config: EditorConfig): HTMLElement {
    const span = document.createElement('span');
    const theme = config.theme;
    const className = theme.image;
    if (className !== undefined) span.className = className;
    return span;
  }

  updateDOM(): false {
    return false;
  }

  decorate(): JSX.Element {
    return (
      <Suspense fallback={null}>
        <ImageComponent
          caption={this.__caption}
          captionsEnabled={this.__captionsEnabled}
          mode={this.__mode}
          fileId={this.__fileId}
          width={this.__width}
          height={this.__height}
          maxWidth={this.__maxWidth}
          nodeKey={this.getKey()}
        />
      </Suspense>
    );
  }
}

export function $createImageNode({
  mode,
  height,
  fileId,
  maxWidth,
  width,
  caption,
  captionsEnabled,
  key,
}: ImagePayload): ImageNode {
  return $applyNodeReplacement(new ImageNode(mode, fileId, maxWidth, width, height, caption, captionsEnabled, key));
}

export function $isImageNode(node: LexicalNode | null | undefined): node is ImageNode {
  return node instanceof ImageNode;
}
