import React from 'react';
import {questPointPluginHooks} from './plugin';
import {Typography} from '@mui/material';
import {ButtonWithTooltip} from '@mdxeditor/editor';

import {QuestIdProp} from 'modules/events/types';
import useDialogSelector from 'components/DialogSelector';
import {questPointColumns} from 'modules/events/containers/QuestPoint';

export const InsertQuestPoint = React.forwardRef<HTMLButtonElement, QuestIdProp>(({questId}, forwardedRef) => {
  const insertQuestPoint = questPointPluginHooks.usePublisher('insertQuestPoint');
  const [Selector, openSelector] = useDialogSelector();

  return (
    <>
      <ButtonWithTooltip ref={forwardedRef} title="Insert quest Point" onClick={openSelector}>
        <Typography>Вставить квест-поинт</Typography>
      </ButtonWithTooltip>
      <Selector
        single
        title="Выбор точки действия"
        initialSelection={[]}
        params={{questId}}
        onConfirm={([entryId]) => entryId && insertQuestPoint(entryId)}
        columns={questPointColumns()}
        queryName="questPointsIndex"
      />
    </>
  );
});
