import Label from '__theme/components/Label';

type InfoType = 'checking' | 'wait_payment';
type SuccessType = 'ready' | 'completed' | 'paid';
type WarningType = 'new' | 'in_progress' | 'edit';
type DefaultType = 'archived' | 'draft' | 'returned';
type ErrorType = 'error' | 'declined' | 'deadline' | 'failed' | 'rejected';
type StatusType = ErrorType | WarningType | InfoType | SuccessType | DefaultType | null | undefined;

export const StatusLabel = ({status, deletedAt}: {status: StatusType; deletedAt?: string}) => {
  const text = deletedAt ? 'deleted' : status === 'new' ? 'in progress' : status;

  return (
    <Label
      color={
        deletedAt || ['error', 'declined', 'deadline', 'failed', 'rejected'].includes(status || '')
          ? 'error'
          : ['ready', 'completed', 'paid'].includes(status || '')
          ? 'success'
          : ['new', 'in_progress', 'edit'].includes(status || '')
          ? 'warning'
          : ['checking', 'wait_payment'].includes(status || '')
          ? 'info'
          : 'default'
      }
    >
      {text}
    </Label>
  );
};
