import {Publications} from 'api/generated/users-api';

import {usePublicationActions} from 'modules/users/components/UserCardPublicationsTable/hooks';

import {getInitials} from './fields';
import {PublicationFormType} from './types';

export const usePublicationForm = (post: Publications) => {
  const {restoreAction, deleteAction, updateAction} = usePublicationActions(post);
  const initials = getInitials(post);

  const onSubmit = async (data: PublicationFormType) =>
    updateAction({
      publication: {
        privacy: data.privacy || undefined,
      },
    });

  return {
    initials,
    onSubmit,
    onDelete: deleteAction,
    onRestore: restoreAction,
  };
};
