import {Outlet} from 'react-router';
import {ReactNode} from 'react';
import {QueryDefinition} from '@reduxjs/toolkit/dist/query';
import {UseQueryStateResult} from '@reduxjs/toolkit/dist/query/react/buildHooks';
import isFunction from 'lodash/isFunction';
import isArray from 'lodash/isArray';

type UseFetchType<Result> = () => UseQueryStateResult<QueryDefinition<any, any, any, Result>, any>;
type UseExtraFetchReturnType<Result> = ReturnType<UseFetchType<Result>>;

type IsExistsContainerProps<Result extends object> = {
  useFetch: UseFetchType<Result>;
  useExtraFetch?: () => UseExtraFetchReturnType<Result> | UseExtraFetchReturnType<Result>[];
  context?: React.Context<any>;
  debug?: true;
  ErrorComponent?: ReactNode;
  LoadingComponent?: ReactNode;
  children?: ReactNode | ((data: Result) => JSX.Element);
  passUninitialized?: boolean;
};
export function IsExistsContainer<Result extends object>({
  useFetch,
  useExtraFetch,
  debug,
  context: Context,
  ErrorComponent,
  LoadingComponent,
  children,
  passUninitialized = false,
}: IsExistsContainerProps<Result>) {
  const {isFetching, isError, isUninitialized, isLoading, data} = useFetch();

  const results = useExtraFetch?.();
  const isExtraLoading =
    Boolean(useExtraFetch) && isArray(results) ? Boolean(results.findIndex(i => i.isLoading) + 1) : results?.isLoading;
  // const isExtraError =
  //   Boolean(useExtraFetch) && isArray(results)
  //     ? Boolean(results.findIndex(i => !i.isFetching && i.isError) + 1)
  //     : !results.isFetching && results.isError;

  // eslint-disable-next-line no-console
  if (debug && process.env.NODE_ENV === 'development') console.log({data, isFetching});

  if ((isFetching && !data) || isLoading || isExtraLoading) return LoadingComponent ? <>{LoadingComponent}</> : <div />;
  // if ((isFetching && !data) || isLoading) return LoadingComponent ? <>{LoadingComponent}</> : <div />;

  if (passUninitialized && isUninitialized)
    return children ? <>{isFunction(children) ? children(data) : children}</> : <Outlet />;

  if ((!isFetching && isError) || !data) return ErrorComponent ? <>{ErrorComponent}</> : <div />;
  // if ((!isFetching && isError) || isExtraError || !data) return ErrorComponent ? <>{ErrorComponent}</> : <div />;

  const Child = children ? <>{isFunction(children) ? children(data) : children}</> : <Outlet />;
  if (!Context) return Child;
  return <Context.Provider value={data}>{Child}</Context.Provider>;
}

export default IsExistsContainer;
