import {Box, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';
import {visuallyHidden} from '@mui/utils';

type UserCardGoalTableHeadProps = {
  orderBy: string;
  order: 'desc' | 'asc';
  onRequestSort: (arg: string) => void;
  columns: {
    id: string;
    label?: string;
    alignRight?: boolean;
  }[];
};
export const DataTableHead = ({orderBy, order, onRequestSort, columns}: UserCardGoalTableHeadProps) => {
  return (
    <TableHead>
      <TableRow>
        {columns.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{whiteSpace: 'nowrap'}}
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() => onRequestSort(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box sx={{...visuallyHidden}}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
