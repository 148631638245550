import {FC} from 'react';
import {Box, Stack} from '@mui/material';

import {adminApi} from 'api';
import {FileIdProp, VideoPlayerProp} from 'modules/files/types';

import {Timecode} from './Timecode';
import {AddTimecode} from './AddTimecode';

export const VideoTimecodes: FC<FileIdProp & VideoPlayerProp> = ({fileId, videoPlayerRef}) => {
  const {data} = adminApi.endpoints.timecodesIndex.useQuery(
    {enabled: true, fileId, sort: 'ASC'},
    {
      selectFromResult: ({data}) => ({
        data: data?.data || [],
      }),
    }
  );

  return (
    <Stack spacing={2} bgcolor="rgba(33, 2, 123, 0.07)" p={1.5} borderRadius={1}>
      <Box pb={data.length ? 1 : 0}>
        <AddTimecode fileId={fileId} videoPlayerRef={videoPlayerRef} />
      </Box>
      {data.map(tc => (
        <Timecode key={tc._id!} timecodeId={tc._id!} videoPlayerRef={videoPlayerRef} />
      ))}
    </Stack>
  );
};
