import {createApi} from '@reduxjs/toolkit/query/react';
import {default as customBaseQuery} from '../queries/admin';
export const api = createApi({
  reducerPath: 'admin',
  baseQuery: customBaseQuery,
  tagTypes: [],
  endpoints: build => ({
    rootIndex: build.query<RootIndexApiResponse, RootIndexApiArg>({
      query: () => ({url: `/`}),
    }),
    rootJsonDocs: build.query<RootJsonDocsApiResponse, RootJsonDocsApiArg>({
      query: () => ({url: `/docs.json`}),
    }),
    rootDocs: build.query<RootDocsApiResponse, RootDocsApiArg>({
      query: () => ({url: `/docs.html`}),
    }),
    rootSwagger: build.query<RootSwaggerApiResponse, RootSwaggerApiArg>({
      query: () => ({url: `/swagger.html`}),
    }),
    appIndex: build.query<AppIndexApiResponse, AppIndexApiArg>({
      query: () => ({url: `/api`}),
    }),
    appRoutes: build.query<AppRoutesApiResponse, AppRoutesApiArg>({
      query: () => ({url: `/api/routes`}),
    }),
    widgetsFormulasIndex: build.query<WidgetsFormulasIndexApiResponse, WidgetsFormulasIndexApiArg>({
      query: queryArg => ({
        url: `/api/widgets/formulas`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          populates: queryArg.populates,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          widgetId: queryArg.widgetId,
          enabled: queryArg.enabled,
        },
      }),
    }),
    widgetsFormulasAdd: build.mutation<WidgetsFormulasAddApiResponse, WidgetsFormulasAddApiArg>({
      query: queryArg => ({
        url: `/api/widgets/formulas`,
        method: 'POST',
        body: queryArg.widgetFormulaDto,
      }),
    }),
    widgetFormulaIndex: build.query<WidgetFormulaIndexApiResponse, WidgetFormulaIndexApiArg>({
      query: queryArg => ({
        url: `/api/widgets/formulas/formula_${queryArg.widgetFormulaId}`,
      }),
    }),
    widgetFormulaUpdate: build.mutation<WidgetFormulaUpdateApiResponse, WidgetFormulaUpdateApiArg>({
      query: queryArg => ({
        url: `/api/widgets/formulas/formula_${queryArg.widgetFormulaId}`,
        method: 'PUT',
        body: queryArg.widgetFormulaDto,
      }),
    }),
    widgetFormulaPatch: build.mutation<WidgetFormulaPatchApiResponse, WidgetFormulaPatchApiArg>({
      query: queryArg => ({
        url: `/api/widgets/formulas/formula_${queryArg.widgetFormulaId}`,
        method: 'PATCH',
        body: queryArg.widgetFormulaPartialDto,
      }),
    }),
    widgetFormulaValues: build.query<WidgetFormulaValuesApiResponse, WidgetFormulaValuesApiArg>({
      query: queryArg => ({
        url: `/api/widgets/formulas/formula_${queryArg.widgetFormulaId}/values`,
        params: {groupBy: queryArg.groupBy},
      }),
    }),
    widgetsIndex: build.query<WidgetsIndexApiResponse, WidgetsIndexApiArg>({
      query: queryArg => ({
        url: `/api/widgets`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    widgetsAdd: build.mutation<WidgetsAddApiResponse, WidgetsAddApiArg>({
      query: queryArg => ({
        url: `/api/widgets`,
        method: 'POST',
        body: queryArg.widgetDto,
      }),
    }),
    widgetIndex: build.query<WidgetIndexApiResponse, WidgetIndexApiArg>({
      query: queryArg => ({
        url: `/api/widgets/widget_${queryArg.widgetId}`,
      }),
    }),
    widgetUpdate: build.mutation<WidgetUpdateApiResponse, WidgetUpdateApiArg>({
      query: queryArg => ({
        url: `/api/widgets/widget_${queryArg.widgetId}`,
        method: 'PUT',
        body: queryArg.widgetDto,
      }),
    }),
    widgetPatch: build.mutation<WidgetPatchApiResponse, WidgetPatchApiArg>({
      query: queryArg => ({
        url: `/api/widgets/widget_${queryArg.widgetId}`,
        method: 'PATCH',
        body: queryArg.widgetPartialDto,
      }),
    }),
    widgetFillData: build.mutation<WidgetFillDataApiResponse, WidgetFillDataApiArg>({
      query: queryArg => ({
        url: `/api/widgets/widget_${queryArg.widgetId}/fillData`,
        method: 'POST',
      }),
    }),
    widgetRulesIndex: build.query<WidgetRulesIndexApiResponse, WidgetRulesIndexApiArg>({
      query: queryArg => ({
        url: `/api/widgets/widget_${queryArg.widgetId}/rules`,
        params: {
          isDeleted: queryArg.isDeleted,
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    widgetRulesAdd: build.mutation<WidgetRulesAddApiResponse, WidgetRulesAddApiArg>({
      query: queryArg => ({
        url: `/api/widgets/widget_${queryArg.widgetId}/rules`,
        method: 'POST',
        body: queryArg.widgetRuleDto,
      }),
    }),
    widgetRuleIndex: build.query<WidgetRuleIndexApiResponse, WidgetRuleIndexApiArg>({
      query: queryArg => ({
        url: `/api/widgets/widget_${queryArg.widgetId}/rules/rule_${queryArg.ruleId}`,
      }),
    }),
    widgetRuleUpdate: build.mutation<WidgetRuleUpdateApiResponse, WidgetRuleUpdateApiArg>({
      query: queryArg => ({
        url: `/api/widgets/widget_${queryArg.widgetId}/rules/rule_${queryArg.ruleId}`,
        method: 'PUT',
        body: queryArg.widgetRuleDto,
      }),
    }),
    widgetRulePatch: build.mutation<WidgetRulePatchApiResponse, WidgetRulePatchApiArg>({
      query: queryArg => ({
        url: `/api/widgets/widget_${queryArg.widgetId}/rules/rule_${queryArg.ruleId}`,
        method: 'PATCH',
        body: queryArg.widgetRulePartialDto,
      }),
    }),
    widgetRuleDelete: build.mutation<WidgetRuleDeleteApiResponse, WidgetRuleDeleteApiArg>({
      query: queryArg => ({
        url: `/api/widgets/widget_${queryArg.widgetId}/rules/rule_${queryArg.ruleId}`,
        method: 'DELETE',
      }),
    }),
    widgetAttrsIndex: build.query<WidgetAttrsIndexApiResponse, WidgetAttrsIndexApiArg>({
      query: queryArg => ({
        url: `/api/widgets/widget_${queryArg.widgetId}/attrs`,
        params: {
          isDeleted: queryArg.isDeleted,
          offset: queryArg.offset,
          limit: queryArg.limit,
          populates: queryArg.populates,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    widgetAttrsAdd: build.mutation<WidgetAttrsAddApiResponse, WidgetAttrsAddApiArg>({
      query: queryArg => ({
        url: `/api/widgets/widget_${queryArg.widgetId}/attrs`,
        method: 'POST',
        body: queryArg.widgetAttrDto,
      }),
    }),
    widgetAttrsChangePositions: build.mutation<WidgetAttrsChangePositionsApiResponse, WidgetAttrsChangePositionsApiArg>(
      {
        query: queryArg => ({
          url: `/api/widgets/widget_${queryArg.widgetId}/attrs/changePositions`,
          method: 'POST',
          body: queryArg.widgetAttrsPositions,
        }),
      }
    ),
    widgetAttrIndex: build.query<WidgetAttrIndexApiResponse, WidgetAttrIndexApiArg>({
      query: queryArg => ({
        url: `/api/widgets/widget_${queryArg.widgetId}/attrs/attr_${queryArg.attrId}`,
      }),
    }),
    widgetAttrUpdate: build.mutation<WidgetAttrUpdateApiResponse, WidgetAttrUpdateApiArg>({
      query: queryArg => ({
        url: `/api/widgets/widget_${queryArg.widgetId}/attrs/attr_${queryArg.attrId}`,
        method: 'PUT',
        body: queryArg.widgetAttrDto,
      }),
    }),
    widgetAttrPatch: build.mutation<WidgetAttrPatchApiResponse, WidgetAttrPatchApiArg>({
      query: queryArg => ({
        url: `/api/widgets/widget_${queryArg.widgetId}/attrs/attr_${queryArg.attrId}`,
        method: 'PATCH',
        body: queryArg.widgetAttrPartialDto,
      }),
    }),
    widgetAttrDelete: build.mutation<WidgetAttrDeleteApiResponse, WidgetAttrDeleteApiArg>({
      query: queryArg => ({
        url: `/api/widgets/widget_${queryArg.widgetId}/attrs/attr_${queryArg.attrId}`,
        method: 'DELETE',
      }),
    }),
    widgetsVisualsIndex: build.query<WidgetsVisualsIndexApiResponse, WidgetsVisualsIndexApiArg>({
      query: queryArg => ({
        url: `/api/widgets/visuals`,
        params: {
          isDeleted: queryArg.isDeleted,
          offset: queryArg.offset,
          limit: queryArg.limit,
          populates: queryArg.populates,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    widgetsVisualsAdd: build.mutation<WidgetsVisualsAddApiResponse, WidgetsVisualsAddApiArg>({
      query: queryArg => ({
        url: `/api/widgets/visuals`,
        method: 'POST',
        body: queryArg.widgetVisualDto,
      }),
    }),
    widgetsVisualIndex: build.query<WidgetsVisualIndexApiResponse, WidgetsVisualIndexApiArg>({
      query: queryArg => ({
        url: `/api/widgets/visuals/visual_${queryArg.visualId}`,
      }),
    }),
    widgetsVisualUpdate: build.mutation<WidgetsVisualUpdateApiResponse, WidgetsVisualUpdateApiArg>({
      query: queryArg => ({
        url: `/api/widgets/visuals/visual_${queryArg.visualId}`,
        method: 'PUT',
        body: queryArg.widgetVisualDto,
      }),
    }),
    widgetsVisualPatch: build.mutation<WidgetsVisualPatchApiResponse, WidgetsVisualPatchApiArg>({
      query: queryArg => ({
        url: `/api/widgets/visuals/visual_${queryArg.visualId}`,
        method: 'PATCH',
        body: queryArg.widgetVisualPartialDto,
      }),
    }),
    widgetsVisualDelete: build.mutation<WidgetsVisualDeleteApiResponse, WidgetsVisualDeleteApiArg>({
      query: queryArg => ({
        url: `/api/widgets/visuals/visual_${queryArg.visualId}`,
        method: 'DELETE',
      }),
    }),
    widgetsVisualDesignsIndex: build.query<WidgetsVisualDesignsIndexApiResponse, WidgetsVisualDesignsIndexApiArg>({
      query: queryArg => ({
        url: `/api/widgets/visuals/visual_${queryArg.visualId}/designs`,
        params: {
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          isDeleted: queryArg.isDeleted,
        },
      }),
    }),
    widgetsVisualDesignsAdd: build.mutation<WidgetsVisualDesignsAddApiResponse, WidgetsVisualDesignsAddApiArg>({
      query: queryArg => ({
        url: `/api/widgets/visuals/visual_${queryArg.visualId}/designs`,
        method: 'POST',
        body: queryArg.widgetVisualDesignDto,
      }),
    }),
    widgetsVisualDesignsChangePositions: build.mutation<
      WidgetsVisualDesignsChangePositionsApiResponse,
      WidgetsVisualDesignsChangePositionsApiArg
    >({
      query: queryArg => ({
        url: `/api/widgets/visuals/visual_${queryArg.visualId}/designs/changePositions`,
        method: 'POST',
        body: queryArg.iDs,
      }),
    }),
    widgetsVisualDesignIndex: build.query<WidgetsVisualDesignIndexApiResponse, WidgetsVisualDesignIndexApiArg>({
      query: queryArg => ({
        url: `/api/widgets/visuals/visual_${queryArg.visualId}/designs/elem_${queryArg.designId}`,
      }),
    }),
    widgetsVisualDesignUpdate: build.mutation<WidgetsVisualDesignUpdateApiResponse, WidgetsVisualDesignUpdateApiArg>({
      query: queryArg => ({
        url: `/api/widgets/visuals/visual_${queryArg.visualId}/designs/elem_${queryArg.designId}`,
        method: 'PUT',
        body: queryArg.widgetVisualDesignDto,
      }),
    }),
    widgetsVisualDesignPatch: build.mutation<WidgetsVisualDesignPatchApiResponse, WidgetsVisualDesignPatchApiArg>({
      query: queryArg => ({
        url: `/api/widgets/visuals/visual_${queryArg.visualId}/designs/elem_${queryArg.designId}`,
        method: 'PATCH',
        body: queryArg.widgetVisualDesignPartialDto,
      }),
    }),
    widgetsVisualDesignDelete: build.mutation<WidgetsVisualDesignDeleteApiResponse, WidgetsVisualDesignDeleteApiArg>({
      query: queryArg => ({
        url: `/api/widgets/visuals/visual_${queryArg.visualId}/designs/elem_${queryArg.designId}`,
        method: 'DELETE',
      }),
    }),
    widgetsVisualPropsIndex: build.query<WidgetsVisualPropsIndexApiResponse, WidgetsVisualPropsIndexApiArg>({
      query: queryArg => ({
        url: `/api/widgets/visuals/visual_${queryArg.visualId}/props`,
        params: {
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          isDeleted: queryArg.isDeleted,
          populates: queryArg.populates,
        },
      }),
    }),
    widgetsVisualPropsAdd: build.mutation<WidgetsVisualPropsAddApiResponse, WidgetsVisualPropsAddApiArg>({
      query: queryArg => ({
        url: `/api/widgets/visuals/visual_${queryArg.visualId}/props`,
        method: 'POST',
        body: queryArg.widgetVisualPropDto,
      }),
    }),
    widgetsVisualPropsChangePositions: build.mutation<
      WidgetsVisualPropsChangePositionsApiResponse,
      WidgetsVisualPropsChangePositionsApiArg
    >({
      query: queryArg => ({
        url: `/api/widgets/visuals/visual_${queryArg.visualId}/props/changePositions`,
        method: 'POST',
        body: queryArg.widgetVisualPropsPositions,
      }),
    }),
    widgetsVisualPropIndex: build.query<WidgetsVisualPropIndexApiResponse, WidgetsVisualPropIndexApiArg>({
      query: queryArg => ({
        url: `/api/widgets/visuals/visual_${queryArg.visualId}/props/prop_${queryArg.propId}`,
      }),
    }),
    widgetsVisualPropUpdate: build.mutation<WidgetsVisualPropUpdateApiResponse, WidgetsVisualPropUpdateApiArg>({
      query: queryArg => ({
        url: `/api/widgets/visuals/visual_${queryArg.visualId}/props/prop_${queryArg.propId}`,
        method: 'PUT',
        body: queryArg.widgetVisualPropDto,
      }),
    }),
    widgetsVisualPropPatch: build.mutation<WidgetsVisualPropPatchApiResponse, WidgetsVisualPropPatchApiArg>({
      query: queryArg => ({
        url: `/api/widgets/visuals/visual_${queryArg.visualId}/props/prop_${queryArg.propId}`,
        method: 'PATCH',
        body: queryArg.widgetVisualPropPartialDto,
      }),
    }),
    widgetsVisualPropDelete: build.mutation<WidgetsVisualPropDeleteApiResponse, WidgetsVisualPropDeleteApiArg>({
      query: queryArg => ({
        url: `/api/widgets/visuals/visual_${queryArg.visualId}/props/prop_${queryArg.propId}`,
        method: 'DELETE',
      }),
    }),
    receiptsIndex: build.query<ReceiptsIndexApiResponse, ReceiptsIndexApiArg>({
      query: queryArg => ({
        url: `/api/receipts`,
        params: {type: queryArg['type'], userId: queryArg.userId},
      }),
    }),
    receiptIndex: build.query<ReceiptIndexApiResponse, ReceiptIndexApiArg>({
      query: queryArg => ({
        url: `/api/receipts/receipt_${queryArg.receiptId}`,
      }),
    }),
    receiptsServicesIndex: build.query<ReceiptsServicesIndexApiResponse, ReceiptsServicesIndexApiArg>({
      query: queryArg => ({
        url: `/api/receipts/services`,
        params: {type: queryArg['type']},
      }),
    }),
    receiptsServicesAdd: build.mutation<ReceiptsServicesAddApiResponse, ReceiptsServicesAddApiArg>({
      query: queryArg => ({
        url: `/api/receipts/services`,
        method: 'POST',
        body: queryArg.receiptsServiceDto,
      }),
    }),
    receiptsServiceIndex: build.query<ReceiptsServiceIndexApiResponse, ReceiptsServiceIndexApiArg>({
      query: queryArg => ({
        url: `/api/receipts/services/service_${queryArg.serviceId}`,
      }),
    }),
    receiptsServiceUpdate: build.mutation<ReceiptsServiceUpdateApiResponse, ReceiptsServiceUpdateApiArg>({
      query: queryArg => ({
        url: `/api/receipts/services/service_${queryArg.serviceId}`,
        method: 'PUT',
        body: queryArg.receiptsServiceDto,
      }),
    }),
    receiptsServicePatch: build.mutation<ReceiptsServicePatchApiResponse, ReceiptsServicePatchApiArg>({
      query: queryArg => ({
        url: `/api/receipts/services/service_${queryArg.serviceId}`,
        method: 'PATCH',
        body: queryArg.receiptsServicePartialDto,
      }),
    }),
    receiptsServiceDelete: build.mutation<ReceiptsServiceDeleteApiResponse, ReceiptsServiceDeleteApiArg>({
      query: queryArg => ({
        url: `/api/receipts/services/service_${queryArg.serviceId}`,
        method: 'DELETE',
      }),
    }),
    paymentsIndex: build.query<PaymentsIndexApiResponse, PaymentsIndexApiArg>({
      query: queryArg => ({
        url: `/api/payments`,
        params: {type: queryArg['type'], userId: queryArg.userId},
      }),
    }),
    paymentsStatsPartnersDynamic: build.query<
      PaymentsStatsPartnersDynamicApiResponse,
      PaymentsStatsPartnersDynamicApiArg
    >({
      query: queryArg => ({
        url: `/api/payments/stats/dynamic`,
        params: {
          scale: queryArg.scale,
          startDate: queryArg.startDate,
          finishDate: queryArg.finishDate,
        },
      }),
    }),
    paymentsStatsStatusSummary: build.query<PaymentsStatsStatusSummaryApiResponse, PaymentsStatsStatusSummaryApiArg>({
      query: () => ({url: `/api/payments/stats/status_summary`}),
    }),
    paymentIndex: build.query<PaymentIndexApiResponse, PaymentIndexApiArg>({
      query: queryArg => ({
        url: `/api/payments/payment_${queryArg.paymentId}`,
      }),
    }),
    paymentRefresh: build.query<PaymentRefreshApiResponse, PaymentRefreshApiArg>({
      query: queryArg => ({
        url: `/api/payments/payment_${queryArg.paymentId}/refresh`,
      }),
    }),
    paymentsServicesIndex: build.query<PaymentsServicesIndexApiResponse, PaymentsServicesIndexApiArg>({
      query: queryArg => ({
        url: `/api/payments/services`,
        params: {type: queryArg['type']},
      }),
    }),
    paymentsServicesAdd: build.mutation<PaymentsServicesAddApiResponse, PaymentsServicesAddApiArg>({
      query: queryArg => ({
        url: `/api/payments/services`,
        method: 'POST',
        body: queryArg.paymentsServiceDto,
      }),
    }),
    paymentsServiceIndex: build.query<PaymentsServiceIndexApiResponse, PaymentsServiceIndexApiArg>({
      query: queryArg => ({
        url: `/api/payments/services/service_${queryArg.serviceId}`,
      }),
    }),
    paymentsServiceUpdate: build.mutation<PaymentsServiceUpdateApiResponse, PaymentsServiceUpdateApiArg>({
      query: queryArg => ({
        url: `/api/payments/services/service_${queryArg.serviceId}`,
        method: 'PUT',
        body: queryArg.paymentsServiceDto,
      }),
    }),
    paymentsServicePatch: build.mutation<PaymentsServicePatchApiResponse, PaymentsServicePatchApiArg>({
      query: queryArg => ({
        url: `/api/payments/services/service_${queryArg.serviceId}`,
        method: 'PATCH',
        body: queryArg.paymentsServicePartialDto,
      }),
    }),
    paymentsServiceDelete: build.mutation<PaymentsServiceDeleteApiResponse, PaymentsServiceDeleteApiArg>({
      query: queryArg => ({
        url: `/api/payments/services/service_${queryArg.serviceId}`,
        method: 'DELETE',
      }),
    }),
    reactionsBlocksIndex: build.query<ReactionsBlocksIndexApiResponse, ReactionsBlocksIndexApiArg>({
      query: queryArg => ({
        url: `/api/reactions/blocks`,
        params: {referenceName: queryArg.referenceName},
      }),
    }),
    reactionsBlocksAdd: build.mutation<ReactionsBlocksAddApiResponse, ReactionsBlocksAddApiArg>({
      query: queryArg => ({
        url: `/api/reactions/blocks`,
        method: 'POST',
        body: queryArg.reactionsBlockDto,
      }),
    }),
    reactionsBlockIndex: build.query<ReactionsBlockIndexApiResponse, ReactionsBlockIndexApiArg>({
      query: queryArg => ({
        url: `/api/reactions/blocks/block_${queryArg.blockId}`,
      }),
    }),
    reactionsBlockUpdate: build.mutation<ReactionsBlockUpdateApiResponse, ReactionsBlockUpdateApiArg>({
      query: queryArg => ({
        url: `/api/reactions/blocks/block_${queryArg.blockId}`,
        method: 'PUT',
        body: queryArg.reactionsBlockDto,
      }),
    }),
    reactionsBlockPatch: build.mutation<ReactionsBlockPatchApiResponse, ReactionsBlockPatchApiArg>({
      query: queryArg => ({
        url: `/api/reactions/blocks/block_${queryArg.blockId}`,
        method: 'PATCH',
        body: queryArg.reactionsBlockPartialDto,
      }),
    }),
    reactionsBlockDelete: build.mutation<ReactionsBlockDeleteApiResponse, ReactionsBlockDeleteApiArg>({
      query: queryArg => ({
        url: `/api/reactions/blocks/block_${queryArg.blockId}`,
        method: 'DELETE',
      }),
    }),
    blockersIndex: build.query<BlockersIndexApiResponse, BlockersIndexApiArg>({
      query: queryArg => ({
        url: `/api/blockers`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          name: queryArg.name,
        },
      }),
    }),
    blockersAdd: build.mutation<BlockersAddApiResponse, BlockersAddApiArg>({
      query: queryArg => ({
        url: `/api/blockers`,
        method: 'POST',
        body: queryArg.blockerDto,
      }),
    }),
    blockerIndex: build.query<BlockerIndexApiResponse, BlockerIndexApiArg>({
      query: queryArg => ({
        url: `/api/blockers/blocker_${queryArg.blockerId}`,
      }),
    }),
    blockerUpdate: build.mutation<BlockerUpdateApiResponse, BlockerUpdateApiArg>({
      query: queryArg => ({
        url: `/api/blockers/blocker_${queryArg.blockerId}`,
        method: 'PUT',
        body: queryArg.blockerDto,
      }),
    }),
    blockerPatch: build.mutation<BlockerPatchApiResponse, BlockerPatchApiArg>({
      query: queryArg => ({
        url: `/api/blockers/blocker_${queryArg.blockerId}`,
        method: 'PATCH',
        body: queryArg.blockerPartialDto,
      }),
    }),
    blockerDelete: build.mutation<BlockerDeleteApiResponse, BlockerDeleteApiArg>({
      query: queryArg => ({
        url: `/api/blockers/blocker_${queryArg.blockerId}`,
        method: 'DELETE',
      }),
    }),
    stagePointIndex: build.query<StagePointIndexApiResponse, StagePointIndexApiArg>({
      query: queryArg => ({
        url: `/api/stages/points/point_${queryArg.pointId}`,
      }),
    }),
    stagePointPatch: build.mutation<StagePointPatchApiResponse, StagePointPatchApiArg>({
      query: queryArg => ({
        url: `/api/stages/points/point_${queryArg.pointId}`,
        method: 'PATCH',
        body: queryArg.stagePointPartialDto,
      }),
    }),
    stagePointRetrigger: build.mutation<StagePointRetriggerApiResponse, StagePointRetriggerApiArg>({
      query: queryArg => ({
        url: `/api/stages/points/point_${queryArg.pointId}/retrigger`,
        method: 'POST',
      }),
    }),
    questsIndex: build.query<QuestsIndexApiResponse, QuestsIndexApiArg>({
      query: queryArg => ({
        url: `/api/quests`,
        params: {
          parentQuestId: queryArg.parentQuestId,
          status: queryArg.status,
          type: queryArg['type'],
          isPattern: queryArg.isPattern,
          offset: queryArg.offset,
          limit: queryArg.limit,
          populates: queryArg.populates,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    questsAdd: build.mutation<QuestsAddApiResponse, QuestsAddApiArg>({
      query: queryArg => ({
        url: `/api/quests`,
        method: 'POST',
        body: queryArg.questDto,
      }),
    }),
    questsStatsDynamic: build.query<QuestsStatsDynamicApiResponse, QuestsStatsDynamicApiArg>({
      query: queryArg => ({
        url: `/api/quests/stats/dynamic`,
        params: {
          questId: queryArg.questId,
          timelineId: queryArg.timelineId,
          userId: queryArg.userId,
          streamId: queryArg.streamId,
          groupId: queryArg.groupId,
          scale: queryArg.scale,
          startDate: queryArg.startDate,
          finishDate: queryArg.finishDate,
        },
      }),
    }),
    questsStatsStatusSummary: build.query<QuestsStatsStatusSummaryApiResponse, QuestsStatsStatusSummaryApiArg>({
      query: queryArg => ({
        url: `/api/quests/stats/status_summary`,
        params: {
          questId: queryArg.questId,
          timelineId: queryArg.timelineId,
          userId: queryArg.userId,
          streamId: queryArg.streamId,
          groupId: queryArg.groupId,
        },
      }),
    }),
    questsStatsStatusUsers: build.query<QuestsStatsStatusUsersApiResponse, QuestsStatsStatusUsersApiArg>({
      query: queryArg => ({
        url: `/api/quests/stats/status_users`,
        params: {
          questId: queryArg.questId,
          timelineId: queryArg.timelineId,
          userId: queryArg.userId,
          streamId: queryArg.streamId,
          groupId: queryArg.groupId,
        },
      }),
    }),
    questPointsIndex: build.query<QuestPointsIndexApiResponse, QuestPointsIndexApiArg>({
      query: queryArg => ({
        url: `/api/quests/points`,
        params: {
          questId: queryArg.questId,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    questPointsAdd: build.mutation<QuestPointsAddApiResponse, QuestPointsAddApiArg>({
      query: queryArg => ({
        url: `/api/quests/points`,
        method: 'POST',
        body: queryArg.questPointDto,
        params: {questId: queryArg.questId},
      }),
    }),
    questPointsChangePositions: build.mutation<QuestPointsChangePositionsApiResponse, QuestPointsChangePositionsApiArg>(
      {
        query: queryArg => ({
          url: `/api/quests/points/changePositions`,
          method: 'POST',
          body: queryArg.body,
          params: {questId: queryArg.questId},
        }),
      }
    ),
    questPointIndex: build.query<QuestPointIndexApiResponse, QuestPointIndexApiArg>({
      query: queryArg => ({
        url: `/api/quests/points/point_${queryArg.pointId}`,
      }),
    }),
    questPointUpdate: build.mutation<QuestPointUpdateApiResponse, QuestPointUpdateApiArg>({
      query: queryArg => ({
        url: `/api/quests/points/point_${queryArg.pointId}`,
        method: 'PUT',
        body: queryArg.questPointDto,
      }),
    }),
    questPointPatch: build.mutation<QuestPointPatchApiResponse, QuestPointPatchApiArg>({
      query: queryArg => ({
        url: `/api/quests/points/point_${queryArg.pointId}`,
        method: 'PATCH',
        body: queryArg.questPointPartialDto,
      }),
    }),
    questPointDelete: build.mutation<QuestPointDeleteApiResponse, QuestPointDeleteApiArg>({
      query: queryArg => ({
        url: `/api/quests/points/point_${queryArg.pointId}`,
        method: 'DELETE',
      }),
    }),
    questPointFillWidgets: build.query<QuestPointFillWidgetsApiResponse, QuestPointFillWidgetsApiArg>({
      query: queryArg => ({
        url: `/api/quests/points/point_${queryArg.pointId}/fillWidgets`,
      }),
    }),
    questPointsRewardsIndex: build.query<QuestPointsRewardsIndexApiResponse, QuestPointsRewardsIndexApiArg>({
      query: queryArg => ({
        url: `/api/quests/points/point_${queryArg.pointId}/rewards`,
        params: {enabled: queryArg.enabled},
      }),
    }),
    questPointsRewardsAdd: build.mutation<QuestPointsRewardsAddApiResponse, QuestPointsRewardsAddApiArg>({
      query: queryArg => ({
        url: `/api/quests/points/point_${queryArg.pointId}/rewards`,
        method: 'POST',
        body: queryArg.questPointRewardDto,
      }),
    }),
    questPointRewardIndex: build.query<QuestPointRewardIndexApiResponse, QuestPointRewardIndexApiArg>({
      query: queryArg => ({
        url: `/api/quests/points/point_${queryArg.pointId}/rewards/reward_${queryArg.rewardId}`,
      }),
    }),
    questPointRewardUpdate: build.mutation<QuestPointRewardUpdateApiResponse, QuestPointRewardUpdateApiArg>({
      query: queryArg => ({
        url: `/api/quests/points/point_${queryArg.pointId}/rewards/reward_${queryArg.rewardId}`,
        method: 'PUT',
        body: queryArg.questPointRewardDto,
      }),
    }),
    questPointRewardPatch: build.mutation<QuestPointRewardPatchApiResponse, QuestPointRewardPatchApiArg>({
      query: queryArg => ({
        url: `/api/quests/points/point_${queryArg.pointId}/rewards/reward_${queryArg.rewardId}`,
        method: 'PATCH',
        body: queryArg.questPointRewardPartialDto,
      }),
    }),
    questPointsTimelinesIndex: build.query<QuestPointsTimelinesIndexApiResponse, QuestPointsTimelinesIndexApiArg>({
      query: queryArg => ({
        url: `/api/quests/points/point_${queryArg.pointId}/timelines`,
      }),
    }),
    questPointsTimelinesAdd: build.mutation<QuestPointsTimelinesAddApiResponse, QuestPointsTimelinesAddApiArg>({
      query: queryArg => ({
        url: `/api/quests/points/point_${queryArg.pointId}/timelines`,
        method: 'POST',
        body: queryArg.questPointTimelineDto,
      }),
    }),
    questPointTimelineElemIndex: build.query<QuestPointTimelineElemIndexApiResponse, QuestPointTimelineElemIndexApiArg>(
      {
        query: queryArg => ({
          url: `/api/quests/points/point_${queryArg.pointId}/timelines/timeline_${queryArg.timelineId}`,
        }),
      }
    ),
    questPointTimelineElemUpdate: build.mutation<
      QuestPointTimelineElemUpdateApiResponse,
      QuestPointTimelineElemUpdateApiArg
    >({
      query: queryArg => ({
        url: `/api/quests/points/point_${queryArg.pointId}/timelines/timeline_${queryArg.timelineId}`,
        method: 'PUT',
        body: queryArg.questPointTimelineDto,
      }),
    }),
    questPointTimelineElemPatch: build.mutation<
      QuestPointTimelineElemPatchApiResponse,
      QuestPointTimelineElemPatchApiArg
    >({
      query: queryArg => ({
        url: `/api/quests/points/point_${queryArg.pointId}/timelines/timeline_${queryArg.timelineId}`,
        method: 'PATCH',
        body: queryArg.questPointTimelinePartialDto,
      }),
    }),
    questPointTimelineElemDelete: build.mutation<
      QuestPointTimelineElemDeleteApiResponse,
      QuestPointTimelineElemDeleteApiArg
    >({
      query: queryArg => ({
        url: `/api/quests/points/point_${queryArg.pointId}/timelines/timeline_${queryArg.timelineId}`,
        method: 'DELETE',
      }),
    }),
    questPointTimelineElemRecalculateRewards: build.mutation<
      QuestPointTimelineElemRecalculateRewardsApiResponse,
      QuestPointTimelineElemRecalculateRewardsApiArg
    >({
      query: queryArg => ({
        url: `/api/quests/points/point_${queryArg.pointId}/timelines/timeline_${queryArg.timelineId}/recalculateRewards`,
        method: 'POST',
      }),
    }),
    questIndex: build.query<QuestIndexApiResponse, QuestIndexApiArg>({
      query: queryArg => ({url: `/api/quests/quest_${queryArg.questId}`}),
    }),
    questUpdate: build.mutation<QuestUpdateApiResponse, QuestUpdateApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}`,
        method: 'PUT',
        body: queryArg.questDto,
      }),
    }),
    questPatch: build.mutation<QuestPatchApiResponse, QuestPatchApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}`,
        method: 'PATCH',
        body: queryArg.questPartialDto,
      }),
    }),
    questDeleteForUsers: build.mutation<QuestDeleteForUsersApiResponse, QuestDeleteForUsersApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/deleteForUsers`,
        method: 'POST',
        body: queryArg.questDeleteDataDto,
      }),
    }),
    questDestructForUsers: build.mutation<QuestDestructForUsersApiResponse, QuestDestructForUsersApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/destructForUsers`,
        method: 'POST',
        body: queryArg.questDeleteDataDto,
      }),
    }),
    questTagsIndex: build.query<QuestTagsIndexApiResponse, QuestTagsIndexApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/tags`,
      }),
    }),
    questTagsSet: build.mutation<QuestTagsSetApiResponse, QuestTagsSetApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/tags`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    questTimelinesIndex: build.query<QuestTimelinesIndexApiResponse, QuestTimelinesIndexApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/timelines`,
      }),
    }),
    questTimelinesAdd: build.mutation<QuestTimelinesAddApiResponse, QuestTimelinesAddApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/timelines`,
        method: 'POST',
        body: queryArg.questTimelineDto,
      }),
    }),
    questTimelineElemIndex: build.query<QuestTimelineElemIndexApiResponse, QuestTimelineElemIndexApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/timelines/timeline_${queryArg.timelineId}`,
      }),
    }),
    questTimelineElemUpdate: build.mutation<QuestTimelineElemUpdateApiResponse, QuestTimelineElemUpdateApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/timelines/timeline_${queryArg.timelineId}`,
        method: 'PUT',
        body: queryArg.questTimelineDto,
      }),
    }),
    questTimelineElemPatch: build.mutation<QuestTimelineElemPatchApiResponse, QuestTimelineElemPatchApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/timelines/timeline_${queryArg.timelineId}`,
        method: 'PATCH',
        body: queryArg.questTimelinePartialDto,
      }),
    }),
    questTimelineElemDelete: build.mutation<QuestTimelineElemDeleteApiResponse, QuestTimelineElemDeleteApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/timelines/timeline_${queryArg.timelineId}`,
        method: 'DELETE',
      }),
    }),
    questTimelineElemUsersId: build.query<QuestTimelineElemUsersIdApiResponse, QuestTimelineElemUsersIdApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/timelines/timeline_${queryArg.timelineId}/usersId`,
      }),
    }),
    questTimelineElemStart: build.mutation<QuestTimelineElemStartApiResponse, QuestTimelineElemStartApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/timelines/timeline_${queryArg.timelineId}/start`,
        method: 'POST',
      }),
    }),
    questTimelineElemStop: build.mutation<QuestTimelineElemStopApiResponse, QuestTimelineElemStopApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/timelines/timeline_${queryArg.timelineId}/stop`,
        method: 'POST',
      }),
    }),
    questTimelineElemReset: build.mutation<QuestTimelineElemResetApiResponse, QuestTimelineElemResetApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/timelines/timeline_${queryArg.timelineId}/reset`,
        method: 'POST',
      }),
    }),
    questTimelineElemRebuild: build.mutation<QuestTimelineElemRebuildApiResponse, QuestTimelineElemRebuildApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/timelines/timeline_${queryArg.timelineId}/rebuild`,
        method: 'POST',
      }),
    }),
    questTimelineElemRecalculateRewards: build.mutation<
      QuestTimelineElemRecalculateRewardsApiResponse,
      QuestTimelineElemRecalculateRewardsApiArg
    >({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/timelines/timeline_${queryArg.timelineId}/recalculateRewards`,
        method: 'POST',
      }),
    }),
    questTimelineElemEmit: build.mutation<QuestTimelineElemEmitApiResponse, QuestTimelineElemEmitApiArg>({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/timelines/timeline_${queryArg.timelineId}/emit`,
        method: 'POST',
      }),
    }),
    questTimelineElemEmitSpecific: build.mutation<
      QuestTimelineElemEmitSpecificApiResponse,
      QuestTimelineElemEmitSpecificApiArg
    >({
      query: queryArg => ({
        url: `/api/quests/quest_${queryArg.questId}/timelines/timeline_${queryArg.timelineId}/emit_specific`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    statisticsSummary: build.query<StatisticsSummaryApiResponse, StatisticsSummaryApiArg>({
      query: () => ({url: `/api/statistics/summary`}),
    }),
    claimsDataIndex: build.query<ClaimsDataIndexApiResponse, ClaimsDataIndexApiArg>({
      query: queryArg => ({
        url: `/api/claims`,
        params: {
          referenceName: queryArg.referenceName,
          status: queryArg.status,
        },
      }),
    }),
    claimsDataDetails: build.query<ClaimsDataDetailsApiResponse, ClaimsDataDetailsApiArg>({
      query: queryArg => ({
        url: `/api/claims/details`,
        params: {
          status: queryArg.status,
          referenceName: queryArg.referenceName,
          referenceId: queryArg.referenceId,
          offset: queryArg.offset,
          limit: queryArg.limit,
          populates: queryArg.populates,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    claimsDataSetClaimed: build.mutation<ClaimsDataSetClaimedApiResponse, ClaimsDataSetClaimedApiArg>({
      query: queryArg => ({
        url: `/api/claims/setClaimed`,
        method: 'POST',
        params: {
          referenceName: queryArg.referenceName,
          referenceId: queryArg.referenceId,
        },
      }),
    }),
    claimsDataRejectClaims: build.mutation<ClaimsDataRejectClaimsApiResponse, ClaimsDataRejectClaimsApiArg>({
      query: queryArg => ({
        url: `/api/claims/rejectClaims`,
        method: 'POST',
        params: {
          referenceName: queryArg.referenceName,
          referenceId: queryArg.referenceId,
        },
      }),
    }),
    claimIndex: build.query<ClaimIndexApiResponse, ClaimIndexApiArg>({
      query: queryArg => ({url: `/api/claims/claim_${queryArg.claimId}`}),
    }),
    claimUpdate: build.mutation<ClaimUpdateApiResponse, ClaimUpdateApiArg>({
      query: queryArg => ({
        url: `/api/claims/claim_${queryArg.claimId}`,
        method: 'PUT',
        body: queryArg.claimDto,
      }),
    }),
    claimPatch: build.mutation<ClaimPatchApiResponse, ClaimPatchApiArg>({
      query: queryArg => ({
        url: `/api/claims/claim_${queryArg.claimId}`,
        method: 'PATCH',
        body: queryArg.claimPartialDto,
      }),
    }),
    claimDelete: build.mutation<ClaimDeleteApiResponse, ClaimDeleteApiArg>({
      query: queryArg => ({
        url: `/api/claims/claim_${queryArg.claimId}`,
        method: 'DELETE',
      }),
    }),
    mailingsIndex: build.query<MailingsIndexApiResponse, MailingsIndexApiArg>({
      query: queryArg => ({
        url: `/api/mailings`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          populates: queryArg.populates,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    mailingsAdd: build.mutation<MailingsAddApiResponse, MailingsAddApiArg>({
      query: queryArg => ({
        url: `/api/mailings`,
        method: 'POST',
        body: queryArg.mailingDto,
      }),
    }),
    mailingIndex: build.query<MailingIndexApiResponse, MailingIndexApiArg>({
      query: queryArg => ({
        url: `/api/mailings/mailing_${queryArg.mailingId}`,
      }),
    }),
    mailingUpdate: build.mutation<MailingUpdateApiResponse, MailingUpdateApiArg>({
      query: queryArg => ({
        url: `/api/mailings/mailing_${queryArg.mailingId}`,
        method: 'PUT',
        body: queryArg.mailingDto,
      }),
    }),
    mailingPatch: build.mutation<MailingPatchApiResponse, MailingPatchApiArg>({
      query: queryArg => ({
        url: `/api/mailings/mailing_${queryArg.mailingId}`,
        method: 'PATCH',
        body: queryArg.mailingPartialDto,
      }),
    }),
    mailingReset: build.mutation<MailingResetApiResponse, MailingResetApiArg>({
      query: queryArg => ({
        url: `/api/mailings/mailing_${queryArg.mailingId}/reset`,
        method: 'POST',
      }),
    }),
    mailingActivate: build.mutation<MailingActivateApiResponse, MailingActivateApiArg>({
      query: queryArg => ({
        url: `/api/mailings/mailing_${queryArg.mailingId}/activate`,
        method: 'POST',
      }),
    }),
    mailingDisactivate: build.mutation<MailingDisactivateApiResponse, MailingDisactivateApiArg>({
      query: queryArg => ({
        url: `/api/mailings/mailing_${queryArg.mailingId}/disactivate`,
        method: 'POST',
      }),
    }),
    mailingsReportsIndex: build.query<MailingsReportsIndexApiResponse, MailingsReportsIndexApiArg>({
      query: queryArg => ({
        url: `/api/mailings/mailing_${queryArg.mailingId}/reports`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          populates: queryArg.populates,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    collectorsIndex: build.query<CollectorsIndexApiResponse, CollectorsIndexApiArg>({
      query: queryArg => ({
        url: `/api/collectors`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    collectorsAdd: build.mutation<CollectorsAddApiResponse, CollectorsAddApiArg>({
      query: queryArg => ({
        url: `/api/collectors`,
        method: 'POST',
        body: queryArg.collectorDto,
      }),
    }),
    collectorsChangePositions: build.mutation<CollectorsChangePositionsApiResponse, CollectorsChangePositionsApiArg>({
      query: queryArg => ({
        url: `/api/collectors/changePositions`,
        method: 'POST',
        body: queryArg.collectorsPositions,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    collectorIndex: build.query<CollectorIndexApiResponse, CollectorIndexApiArg>({
      query: queryArg => ({
        url: `/api/collectors/collector_${queryArg.collectorId}`,
      }),
    }),
    collectorUpdate: build.mutation<CollectorUpdateApiResponse, CollectorUpdateApiArg>({
      query: queryArg => ({
        url: `/api/collectors/collector_${queryArg.collectorId}`,
        method: 'PUT',
        body: queryArg.collectorDto,
      }),
    }),
    collectorKeysIndex: build.query<CollectorKeysIndexApiResponse, CollectorKeysIndexApiArg>({
      query: queryArg => ({
        url: `/api/collectors/collector_${queryArg.collectorId}/keys`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    collectorKeysAdd: build.mutation<CollectorKeysAddApiResponse, CollectorKeysAddApiArg>({
      query: queryArg => ({
        url: `/api/collectors/collector_${queryArg.collectorId}/keys`,
        method: 'POST',
        body: queryArg.collectorKeyDto,
      }),
    }),
    collectorKeysChangePositions: build.mutation<
      CollectorKeysChangePositionsApiResponse,
      CollectorKeysChangePositionsApiArg
    >({
      query: queryArg => ({
        url: `/api/collectors/collector_${queryArg.collectorId}/keys/changePositions`,
        method: 'POST',
        body: queryArg.collectorKeysPositions,
      }),
    }),
    collectorKeyIndex: build.query<CollectorKeyIndexApiResponse, CollectorKeyIndexApiArg>({
      query: queryArg => ({
        url: `/api/collectors/collector_${queryArg.collectorId}/keys/key_${queryArg.keyId}`,
      }),
    }),
    collectorKeyUpdate: build.mutation<CollectorKeyUpdateApiResponse, CollectorKeyUpdateApiArg>({
      query: queryArg => ({
        url: `/api/collectors/collector_${queryArg.collectorId}/keys/key_${queryArg.keyId}`,
        method: 'PUT',
        body: queryArg.collectorKeyDto,
      }),
    }),
    goalsIndex: build.query<GoalsIndexApiResponse, GoalsIndexApiArg>({
      query: queryArg => ({
        url: `/api/goals`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          populates: queryArg.populates,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    goalIndex: build.query<GoalIndexApiResponse, GoalIndexApiArg>({
      query: queryArg => ({url: `/api/goals/goal_${queryArg.goalId}`}),
    }),
    goalReports: build.query<GoalReportsApiResponse, GoalReportsApiArg>({
      query: queryArg => ({
        url: `/api/goals/goal_${queryArg.goalId}/reports`,
      }),
    }),
    goalsTypesIndex: build.query<GoalsTypesIndexApiResponse, GoalsTypesIndexApiArg>({
      query: queryArg => ({
        url: `/api/goals/types`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          populates: queryArg.populates,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    goalsTypesAdd: build.mutation<GoalsTypesAddApiResponse, GoalsTypesAddApiArg>({
      query: queryArg => ({
        url: `/api/goals/types`,
        method: 'POST',
        body: queryArg.goalTypeDto,
      }),
    }),
    goalsTypeIndex: build.query<GoalsTypeIndexApiResponse, GoalsTypeIndexApiArg>({
      query: queryArg => ({
        url: `/api/goals/types/type_${queryArg.typeId}`,
      }),
    }),
    goalsTypeUpdate: build.mutation<GoalsTypeUpdateApiResponse, GoalsTypeUpdateApiArg>({
      query: queryArg => ({
        url: `/api/goals/types/type_${queryArg.typeId}`,
        method: 'PUT',
        body: queryArg.goalTypeDto,
      }),
    }),
    goalsTypePatch: build.mutation<GoalsTypePatchApiResponse, GoalsTypePatchApiArg>({
      query: queryArg => ({
        url: `/api/goals/types/type_${queryArg.typeId}`,
        method: 'PATCH',
        body: queryArg.goalTypePartialDto,
      }),
    }),
    blocksIndex: build.query<BlocksIndexApiResponse, BlocksIndexApiArg>({
      query: queryArg => ({
        url: `/api/blocks`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    blocksAdd: build.mutation<BlocksAddApiResponse, BlocksAddApiArg>({
      query: queryArg => ({
        url: `/api/blocks`,
        method: 'POST',
        body: queryArg.blockDto,
      }),
    }),
    blockIndex: build.query<BlockIndexApiResponse, BlockIndexApiArg>({
      query: queryArg => ({url: `/api/blocks/block_${queryArg.blockId}`}),
    }),
    blockUpdate: build.mutation<BlockUpdateApiResponse, BlockUpdateApiArg>({
      query: queryArg => ({
        url: `/api/blocks/block_${queryArg.blockId}`,
        method: 'PUT',
        body: queryArg.blockDto,
      }),
    }),
    blockPatch: build.mutation<BlockPatchApiResponse, BlockPatchApiArg>({
      query: queryArg => ({
        url: `/api/blocks/block_${queryArg.blockId}`,
        method: 'PATCH',
        body: queryArg.blockPartialDto,
      }),
    }),
    blockTasksIndex: build.query<BlockTasksIndexApiResponse, BlockTasksIndexApiArg>({
      query: queryArg => ({
        url: `/api/blocks/block_${queryArg.blockId}/tasks`,
      }),
    }),
    blockTasksTasksOrder: build.mutation<BlockTasksTasksOrderApiResponse, BlockTasksTasksOrderApiArg>({
      query: queryArg => ({
        url: `/api/blocks/block_${queryArg.blockId}/tasks/order`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    blockTaskInfo: build.query<BlockTaskInfoApiResponse, BlockTaskInfoApiArg>({
      query: queryArg => ({
        url: `/api/blocks/block_${queryArg.blockId}/tasks/task_${queryArg.taskId}`,
      }),
    }),
    blockTaskAdd: build.mutation<BlockTaskAddApiResponse, BlockTaskAddApiArg>({
      query: queryArg => ({
        url: `/api/blocks/block_${queryArg.blockId}/tasks/task_${queryArg.taskId}`,
        method: 'POST',
        body: queryArg.blockTaskDto,
      }),
    }),
    blockTaskDeleteTask: build.mutation<BlockTaskDeleteTaskApiResponse, BlockTaskDeleteTaskApiArg>({
      query: queryArg => ({
        url: `/api/blocks/block_${queryArg.blockId}/tasks/task_${queryArg.taskId}`,
        method: 'DELETE',
      }),
    }),
    tasksIndex: build.query<TasksIndexApiResponse, TasksIndexApiArg>({
      query: queryArg => ({
        url: `/api/tasks`,
        params: {
          categoryId: queryArg.categoryId,
          enabled: queryArg.enabled,
          offset: queryArg.offset,
          limit: queryArg.limit,
          populates: queryArg.populates,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    tasksAdd: build.mutation<TasksAddApiResponse, TasksAddApiArg>({
      query: queryArg => ({
        url: `/api/tasks`,
        method: 'POST',
        body: queryArg.taskDto,
      }),
    }),
    tasksStatsDynamic: build.query<TasksStatsDynamicApiResponse, TasksStatsDynamicApiArg>({
      query: queryArg => ({
        url: `/api/tasks/stats/dynamic`,
        params: {
          taskId: queryArg.taskId,
          timelineId: queryArg.timelineId,
          userId: queryArg.userId,
          streamId: queryArg.streamId,
          groupId: queryArg.groupId,
          scale: queryArg.scale,
          startDate: queryArg.startDate,
          finishDate: queryArg.finishDate,
        },
      }),
    }),
    tasksStatsStatusSummary: build.query<TasksStatsStatusSummaryApiResponse, TasksStatsStatusSummaryApiArg>({
      query: queryArg => ({
        url: `/api/tasks/stats/status_summary`,
        params: {
          taskId: queryArg.taskId,
          timelineId: queryArg.timelineId,
          userId: queryArg.userId,
          streamId: queryArg.streamId,
          groupId: queryArg.groupId,
        },
      }),
    }),
    tasksStatsStatusUsers: build.query<TasksStatsStatusUsersApiResponse, TasksStatsStatusUsersApiArg>({
      query: queryArg => ({
        url: `/api/tasks/stats/status_users`,
        params: {
          taskId: queryArg.taskId,
          timelineId: queryArg.timelineId,
          userId: queryArg.userId,
          streamId: queryArg.streamId,
          groupId: queryArg.groupId,
        },
      }),
    }),
    tasksCategoriesIndex: build.query<TasksCategoriesIndexApiResponse, TasksCategoriesIndexApiArg>({
      query: queryArg => ({
        url: `/api/tasks/categories`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          enabled: queryArg.enabled,
        },
      }),
    }),
    tasksCategoriesAdd: build.mutation<TasksCategoriesAddApiResponse, TasksCategoriesAddApiArg>({
      query: queryArg => ({
        url: `/api/tasks/categories`,
        method: 'POST',
        body: queryArg.taskCategoryDto,
      }),
    }),
    tasksCategoriesChangePositions: build.mutation<
      TasksCategoriesChangePositionsApiResponse,
      TasksCategoriesChangePositionsApiArg
    >({
      query: queryArg => ({
        url: `/api/tasks/categories/changePositions`,
        method: 'POST',
        body: queryArg.taskCategoriesPositions,
      }),
    }),
    tasksCategoryIndex: build.query<TasksCategoryIndexApiResponse, TasksCategoryIndexApiArg>({
      query: queryArg => ({
        url: `/api/tasks/categories/category_${queryArg.categoryId}`,
      }),
    }),
    tasksCategoryUpdate: build.mutation<TasksCategoryUpdateApiResponse, TasksCategoryUpdateApiArg>({
      query: queryArg => ({
        url: `/api/tasks/categories/category_${queryArg.categoryId}`,
        method: 'PUT',
        body: queryArg.taskCategoryDto,
      }),
    }),
    tasksCategoryPatch: build.mutation<TasksCategoryPatchApiResponse, TasksCategoryPatchApiArg>({
      query: queryArg => ({
        url: `/api/tasks/categories/category_${queryArg.categoryId}`,
        method: 'PATCH',
        body: queryArg.taskCategoryPartialDto,
      }),
    }),
    taskIndex: build.query<TaskIndexApiResponse, TaskIndexApiArg>({
      query: queryArg => ({url: `/api/tasks/task_${queryArg.taskId}`}),
    }),
    taskUpdate: build.mutation<TaskUpdateApiResponse, TaskUpdateApiArg>({
      query: queryArg => ({
        url: `/api/tasks/task_${queryArg.taskId}`,
        method: 'PUT',
        body: queryArg.taskDto,
      }),
    }),
    taskPatch: build.mutation<TaskPatchApiResponse, TaskPatchApiArg>({
      query: queryArg => ({
        url: `/api/tasks/task_${queryArg.taskId}`,
        method: 'PATCH',
        body: queryArg.taskPartialDto,
      }),
    }),
    taskTimelinesIndex: build.query<TaskTimelinesIndexApiResponse, TaskTimelinesIndexApiArg>({
      query: queryArg => ({
        url: `/api/tasks/task_${queryArg.taskId}/timelines`,
      }),
    }),
    taskTimelinesAdd: build.mutation<TaskTimelinesAddApiResponse, TaskTimelinesAddApiArg>({
      query: queryArg => ({
        url: `/api/tasks/task_${queryArg.taskId}/timelines`,
        method: 'POST',
        body: queryArg.taskTimelineDto,
      }),
    }),
    taskTimelineElemIndex: build.query<TaskTimelineElemIndexApiResponse, TaskTimelineElemIndexApiArg>({
      query: queryArg => ({
        url: `/api/tasks/task_${queryArg.taskId}/timelines/timeline_${queryArg.timelineId}`,
      }),
    }),
    taskTimelineElemUpdate: build.mutation<TaskTimelineElemUpdateApiResponse, TaskTimelineElemUpdateApiArg>({
      query: queryArg => ({
        url: `/api/tasks/task_${queryArg.taskId}/timelines/timeline_${queryArg.timelineId}`,
        method: 'PUT',
        body: queryArg.taskTimelineDto,
      }),
    }),
    taskTimelineElemPatch: build.mutation<TaskTimelineElemPatchApiResponse, TaskTimelineElemPatchApiArg>({
      query: queryArg => ({
        url: `/api/tasks/task_${queryArg.taskId}/timelines/timeline_${queryArg.timelineId}`,
        method: 'PATCH',
        body: queryArg.taskTimelinePartialDto,
      }),
    }),
    taskTimelineElemDelete: build.mutation<TaskTimelineElemDeleteApiResponse, TaskTimelineElemDeleteApiArg>({
      query: queryArg => ({
        url: `/api/tasks/task_${queryArg.taskId}/timelines/timeline_${queryArg.timelineId}`,
        method: 'DELETE',
      }),
    }),
    scriptsIndex: build.query<ScriptsIndexApiResponse, ScriptsIndexApiArg>({
      query: queryArg => ({
        url: `/api/scripts`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          populates: queryArg.populates,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    scriptsAdd: build.mutation<ScriptsAddApiResponse, ScriptsAddApiArg>({
      query: queryArg => ({
        url: `/api/scripts`,
        method: 'POST',
        body: queryArg.scriptDto,
      }),
    }),
    scriptIndex: build.query<ScriptIndexApiResponse, ScriptIndexApiArg>({
      query: queryArg => ({
        url: `/api/scripts/script_${queryArg.scriptId}`,
      }),
    }),
    scriptUpdate: build.mutation<ScriptUpdateApiResponse, ScriptUpdateApiArg>({
      query: queryArg => ({
        url: `/api/scripts/script_${queryArg.scriptId}`,
        method: 'PUT',
        body: queryArg.scriptDto,
      }),
    }),
    scriptPatch: build.mutation<ScriptPatchApiResponse, ScriptPatchApiArg>({
      query: queryArg => ({
        url: `/api/scripts/script_${queryArg.scriptId}`,
        method: 'PATCH',
        body: queryArg.scriptPartialDto,
      }),
    }),
    scriptLinks: build.query<ScriptLinksApiResponse, ScriptLinksApiArg>({
      query: queryArg => ({
        url: `/api/scripts/script_${queryArg.scriptId}/links`,
      }),
    }),
    scriptGroupsIndex: build.query<ScriptGroupsIndexApiResponse, ScriptGroupsIndexApiArg>({
      query: queryArg => ({
        url: `/api/scripts/script_${queryArg.scriptId}/groups`,
      }),
    }),
    scriptGroupsAdd: build.mutation<ScriptGroupsAddApiResponse, ScriptGroupsAddApiArg>({
      query: queryArg => ({
        url: `/api/scripts/script_${queryArg.scriptId}/groups`,
        method: 'POST',
        body: queryArg.scriptGroupDto,
      }),
    }),
    scriptGroupIndex: build.query<ScriptGroupIndexApiResponse, ScriptGroupIndexApiArg>({
      query: queryArg => ({
        url: `/api/scripts/script_${queryArg.scriptId}/groups/group_${queryArg.groupId}`,
      }),
    }),
    scriptGroupUpdate: build.mutation<ScriptGroupUpdateApiResponse, ScriptGroupUpdateApiArg>({
      query: queryArg => ({
        url: `/api/scripts/script_${queryArg.scriptId}/groups/group_${queryArg.groupId}`,
        method: 'PUT',
        body: queryArg.scriptGroupDto,
      }),
    }),
    scriptGroupPatch: build.mutation<ScriptGroupPatchApiResponse, ScriptGroupPatchApiArg>({
      query: queryArg => ({
        url: `/api/scripts/script_${queryArg.scriptId}/groups/group_${queryArg.groupId}`,
        method: 'PATCH',
        body: queryArg.scriptGroupPartialDto,
      }),
    }),
    scriptActionsIndex: build.query<ScriptActionsIndexApiResponse, ScriptActionsIndexApiArg>({
      query: queryArg => ({
        url: `/api/scripts/script_${queryArg.scriptId}/groups/group_${queryArg.groupId}/actions`,
        params: {populates: queryArg.populates},
      }),
    }),
    scriptActionsAdd: build.mutation<ScriptActionsAddApiResponse, ScriptActionsAddApiArg>({
      query: queryArg => ({
        url: `/api/scripts/script_${queryArg.scriptId}/groups/group_${queryArg.groupId}/actions`,
        method: 'POST',
        body: queryArg.scriptActionDto,
      }),
    }),
    scriptActionsChangePositions: build.mutation<
      ScriptActionsChangePositionsApiResponse,
      ScriptActionsChangePositionsApiArg
    >({
      query: queryArg => ({
        url: `/api/scripts/script_${queryArg.scriptId}/groups/group_${queryArg.groupId}/actions/changePositions`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    scriptActionIndex: build.query<ScriptActionIndexApiResponse, ScriptActionIndexApiArg>({
      query: queryArg => ({
        url: `/api/scripts/script_${queryArg.scriptId}/groups/group_${queryArg.groupId}/actions/action_${queryArg.actionId}`,
      }),
    }),
    scriptActionUpdate: build.mutation<ScriptActionUpdateApiResponse, ScriptActionUpdateApiArg>({
      query: queryArg => ({
        url: `/api/scripts/script_${queryArg.scriptId}/groups/group_${queryArg.groupId}/actions/action_${queryArg.actionId}`,
        method: 'PUT',
        body: queryArg.scriptActionDto,
      }),
    }),
    scriptActionPatch: build.mutation<ScriptActionPatchApiResponse, ScriptActionPatchApiArg>({
      query: queryArg => ({
        url: `/api/scripts/script_${queryArg.scriptId}/groups/group_${queryArg.groupId}/actions/action_${queryArg.actionId}`,
        method: 'PATCH',
        body: queryArg.scriptActionPartialDto,
      }),
    }),
    scriptLinksIndex: build.query<ScriptLinksIndexApiResponse, ScriptLinksIndexApiArg>({
      query: queryArg => ({
        url: `/api/scripts/script_${queryArg.scriptId}/groups/group_${queryArg.groupId}/actions/action_${queryArg.actionId}/links`,
      }),
    }),
    scriptLinksAdd: build.mutation<ScriptLinksAddApiResponse, ScriptLinksAddApiArg>({
      query: queryArg => ({
        url: `/api/scripts/script_${queryArg.scriptId}/groups/group_${queryArg.groupId}/actions/action_${queryArg.actionId}/links`,
        method: 'POST',
        body: queryArg.scriptLinkDto,
      }),
    }),
    scriptLinkIndex: build.query<ScriptLinkIndexApiResponse, ScriptLinkIndexApiArg>({
      query: queryArg => ({
        url: `/api/scripts/script_${queryArg.scriptId}/groups/group_${queryArg.groupId}/actions/action_${queryArg.actionId}/links/link_${queryArg.linkId}`,
      }),
    }),
    scriptLinkUpdate: build.mutation<ScriptLinkUpdateApiResponse, ScriptLinkUpdateApiArg>({
      query: queryArg => ({
        url: `/api/scripts/script_${queryArg.scriptId}/groups/group_${queryArg.groupId}/actions/action_${queryArg.actionId}/links/link_${queryArg.linkId}`,
        method: 'PUT',
        body: queryArg.scriptLinkDto,
      }),
    }),
    scriptLinkPatch: build.mutation<ScriptLinkPatchApiResponse, ScriptLinkPatchApiArg>({
      query: queryArg => ({
        url: `/api/scripts/script_${queryArg.scriptId}/groups/group_${queryArg.groupId}/actions/action_${queryArg.actionId}/links/link_${queryArg.linkId}`,
        method: 'PATCH',
        body: queryArg.scriptLinkPartialDto,
      }),
    }),
    scriptLinkDelete: build.mutation<ScriptLinkDeleteApiResponse, ScriptLinkDeleteApiArg>({
      query: queryArg => ({
        url: `/api/scripts/script_${queryArg.scriptId}/groups/group_${queryArg.groupId}/actions/action_${queryArg.actionId}/links/link_${queryArg.linkId}`,
        method: 'DELETE',
      }),
    }),
    timecodesIndex: build.query<TimecodesIndexApiResponse, TimecodesIndexApiArg>({
      query: queryArg => ({
        url: `/api/timecodes`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          sort: queryArg.sort,
          enabled: queryArg.enabled,
          fileId: queryArg.fileId,
        },
      }),
    }),
    timecodesAdd: build.mutation<TimecodesAddApiResponse, TimecodesAddApiArg>({
      query: queryArg => ({
        url: `/api/timecodes`,
        method: 'POST',
        body: queryArg.timecodeDto,
      }),
    }),
    timecodeIndex: build.query<TimecodeIndexApiResponse, TimecodeIndexApiArg>({
      query: queryArg => ({
        url: `/api/timecodes/timecode_${queryArg.timecodeId}`,
      }),
    }),
    timecodeUpdate: build.mutation<TimecodeUpdateApiResponse, TimecodeUpdateApiArg>({
      query: queryArg => ({
        url: `/api/timecodes/timecode_${queryArg.timecodeId}`,
        method: 'PUT',
        body: queryArg.timecodeDto,
      }),
    }),
    timecodePatch: build.mutation<TimecodePatchApiResponse, TimecodePatchApiArg>({
      query: queryArg => ({
        url: `/api/timecodes/timecode_${queryArg.timecodeId}`,
        method: 'PATCH',
        body: queryArg.timecodePartialDto,
      }),
    }),
    templatesIndex: build.query<TemplatesIndexApiResponse, TemplatesIndexApiArg>({
      query: queryArg => ({
        url: `/api/templates`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    templatesAdd: build.mutation<TemplatesAddApiResponse, TemplatesAddApiArg>({
      query: queryArg => ({
        url: `/api/templates`,
        method: 'POST',
        body: queryArg.templateDto,
      }),
    }),
    templateIndex: build.query<TemplateIndexApiResponse, TemplateIndexApiArg>({
      query: queryArg => ({
        url: `/api/templates/template_${queryArg.templateId}`,
      }),
    }),
    templateUpdate: build.mutation<TemplateUpdateApiResponse, TemplateUpdateApiArg>({
      query: queryArg => ({
        url: `/api/templates/template_${queryArg.templateId}`,
        method: 'PUT',
        body: queryArg.templateDto,
      }),
    }),
    templatePatch: build.mutation<TemplatePatchApiResponse, TemplatePatchApiArg>({
      query: queryArg => ({
        url: `/api/templates/template_${queryArg.templateId}`,
        method: 'PATCH',
        body: queryArg.templatePartialDto,
      }),
    }),
    templateOptionsIndex: build.query<TemplateOptionsIndexApiResponse, TemplateOptionsIndexApiArg>({
      query: queryArg => ({
        url: `/api/templates/template_${queryArg.templateId}/options`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    templateOptionsAdd: build.mutation<TemplateOptionsAddApiResponse, TemplateOptionsAddApiArg>({
      query: queryArg => ({
        url: `/api/templates/template_${queryArg.templateId}/options`,
        method: 'POST',
        body: queryArg.templateOptionDto,
      }),
    }),
    templateOptionsChangePositions: build.mutation<
      TemplateOptionsChangePositionsApiResponse,
      TemplateOptionsChangePositionsApiArg
    >({
      query: queryArg => ({
        url: `/api/templates/template_${queryArg.templateId}/options/changePositions`,
        method: 'POST',
        body: queryArg.templateOptionsPositions,
      }),
    }),
    templateOptionIndex: build.query<TemplateOptionIndexApiResponse, TemplateOptionIndexApiArg>({
      query: queryArg => ({
        url: `/api/templates/template_${queryArg.templateId}/options/option_${queryArg.optionId}`,
      }),
    }),
    templateOptionUpdate: build.mutation<TemplateOptionUpdateApiResponse, TemplateOptionUpdateApiArg>({
      query: queryArg => ({
        url: `/api/templates/template_${queryArg.templateId}/options/option_${queryArg.optionId}`,
        method: 'PUT',
        body: queryArg.templateOptionDto,
      }),
    }),
    templateOptionPatch: build.mutation<TemplateOptionPatchApiResponse, TemplateOptionPatchApiArg>({
      query: queryArg => ({
        url: `/api/templates/template_${queryArg.templateId}/options/option_${queryArg.optionId}`,
        method: 'PATCH',
        body: queryArg.templateOptionPartialDto,
      }),
    }),
    contentsIndex: build.query<ContentsIndexApiResponse, ContentsIndexApiArg>({
      query: queryArg => ({
        url: `/api/contents`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          populates: queryArg.populates,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          tagsId: queryArg.tagsId,
          enabled: queryArg.enabled,
          categoryId: queryArg.categoryId,
          isSystem: queryArg.isSystem,
        },
      }),
    }),
    contentsAdd: build.mutation<ContentsAddApiResponse, ContentsAddApiArg>({
      query: queryArg => ({
        url: `/api/contents`,
        method: 'POST',
        body: queryArg.contentDto,
      }),
    }),
    contentsCategoriesIndex: build.query<ContentsCategoriesIndexApiResponse, ContentsCategoriesIndexApiArg>({
      query: queryArg => ({
        url: `/api/contents/categories`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          populates: queryArg.populates,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          enabled: queryArg.enabled,
        },
      }),
    }),
    contentsCategoriesAdd: build.mutation<ContentsCategoriesAddApiResponse, ContentsCategoriesAddApiArg>({
      query: queryArg => ({
        url: `/api/contents/categories`,
        method: 'POST',
        body: queryArg.contentCategoryDto,
      }),
    }),
    contentsCategoriesChangePositions: build.mutation<
      ContentsCategoriesChangePositionsApiResponse,
      ContentsCategoriesChangePositionsApiArg
    >({
      query: queryArg => ({
        url: `/api/contents/categories/changePositions`,
        method: 'POST',
        body: queryArg.contentCategoriesPositions,
      }),
    }),
    contentsCategoryIndex: build.query<ContentsCategoryIndexApiResponse, ContentsCategoryIndexApiArg>({
      query: queryArg => ({
        url: `/api/contents/categories/category_${queryArg.categoryId}`,
      }),
    }),
    contentsCategoryUpdate: build.mutation<ContentsCategoryUpdateApiResponse, ContentsCategoryUpdateApiArg>({
      query: queryArg => ({
        url: `/api/contents/categories/category_${queryArg.categoryId}`,
        method: 'PUT',
        body: queryArg.contentCategoryDto,
      }),
    }),
    contentsCategoryPatch: build.mutation<ContentsCategoryPatchApiResponse, ContentsCategoryPatchApiArg>({
      query: queryArg => ({
        url: `/api/contents/categories/category_${queryArg.categoryId}`,
        method: 'PATCH',
        body: queryArg.contentCategoryPartialDto,
      }),
    }),
    notionPageIndex: build.query<NotionPageIndexApiResponse, NotionPageIndexApiArg>({
      query: queryArg => ({
        url: `/api/contents/notion/page_${queryArg.pageId}`,
      }),
    }),
    contentIndex: build.query<ContentIndexApiResponse, ContentIndexApiArg>({
      query: queryArg => ({
        url: `/api/contents/content_${queryArg.contentId}`,
      }),
    }),
    contentNotifyUser: build.mutation<ContentNotifyUserApiResponse, ContentNotifyUserApiArg>({
      query: queryArg => ({
        url: `/api/contents/content_${queryArg.contentId}`,
        method: 'POST',
      }),
    }),
    contentUpdate: build.mutation<ContentUpdateApiResponse, ContentUpdateApiArg>({
      query: queryArg => ({
        url: `/api/contents/content_${queryArg.contentId}`,
        method: 'PUT',
        body: queryArg.contentDto,
      }),
    }),
    contentPatch: build.mutation<ContentPatchApiResponse, ContentPatchApiArg>({
      query: queryArg => ({
        url: `/api/contents/content_${queryArg.contentId}`,
        method: 'PATCH',
        body: queryArg.contentPartialDto,
      }),
    }),
    contentTagsIndex: build.query<ContentTagsIndexApiResponse, ContentTagsIndexApiArg>({
      query: queryArg => ({
        url: `/api/contents/content_${queryArg.contentId}/tags`,
      }),
    }),
    contentTagsSet: build.mutation<ContentTagsSetApiResponse, ContentTagsSetApiArg>({
      query: queryArg => ({
        url: `/api/contents/content_${queryArg.contentId}/tags`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    tagsIndex: build.query<TagsIndexApiResponse, TagsIndexApiArg>({
      query: queryArg => ({
        url: `/api/tags`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          populates: queryArg.populates,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          name: queryArg.name,
          type: queryArg['type'],
        },
      }),
    }),
    tagsAdd: build.mutation<TagsAddApiResponse, TagsAddApiArg>({
      query: queryArg => ({
        url: `/api/tags`,
        method: 'POST',
        body: queryArg.tagDto,
      }),
    }),
    tagIndex: build.query<TagIndexApiResponse, TagIndexApiArg>({
      query: queryArg => ({url: `/api/tags/tag_${queryArg.tagId}`}),
    }),
    tagUpdate: build.mutation<TagUpdateApiResponse, TagUpdateApiArg>({
      query: queryArg => ({
        url: `/api/tags/tag_${queryArg.tagId}`,
        method: 'PUT',
        body: queryArg.tagDto,
      }),
    }),
    tagPatch: build.mutation<TagPatchApiResponse, TagPatchApiArg>({
      query: queryArg => ({
        url: `/api/tags/tag_${queryArg.tagId}`,
        method: 'PATCH',
        body: queryArg.tagPartialDto,
      }),
    }),
    tagSets: build.query<TagSetsApiResponse, TagSetsApiArg>({
      query: queryArg => ({url: `/api/tags/tag_${queryArg.tagId}/sets`}),
    }),
    publicationSafeDelete1: build.mutation<PublicationSafeDelete1ApiResponse, PublicationSafeDelete1ApiArg>({
      query: queryArg => ({
        url: `/api/publications/publication_${queryArg.postId}`,
        method: 'DELETE',
      }),
    }),
    publicationSafeRestore1: build.mutation<PublicationSafeRestore1ApiResponse, PublicationSafeRestore1ApiArg>({
      query: queryArg => ({
        url: `/api/publications/publication_${queryArg.postId}/restore`,
        method: 'POST',
      }),
    }),
    commentSafeDelete: build.mutation<CommentSafeDeleteApiResponse, CommentSafeDeleteApiArg>({
      query: queryArg => ({
        url: `/api/comments/comment_${queryArg.commentId}`,
        method: 'DELETE',
      }),
    }),
    commentSafeRestore: build.mutation<CommentSafeRestoreApiResponse, CommentSafeRestoreApiArg>({
      query: queryArg => ({
        url: `/api/comments/comment_${queryArg.commentId}/restore`,
        method: 'POST',
      }),
    }),
    rewardsIndex: build.query<RewardsIndexApiResponse, RewardsIndexApiArg>({
      query: queryArg => ({
        url: `/api/rewards`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          type: queryArg['type'],
          enabled: queryArg.enabled,
        },
      }),
    }),
    rewardsAdd: build.mutation<RewardsAddApiResponse, RewardsAddApiArg>({
      query: queryArg => ({
        url: `/api/rewards`,
        method: 'POST',
        body: queryArg.rewardDto,
      }),
    }),
    rewardIndex: build.query<RewardIndexApiResponse, RewardIndexApiArg>({
      query: queryArg => ({
        url: `/api/rewards/reward_${queryArg.rewardId}`,
      }),
    }),
    rewardUpdate: build.mutation<RewardUpdateApiResponse, RewardUpdateApiArg>({
      query: queryArg => ({
        url: `/api/rewards/reward_${queryArg.rewardId}`,
        method: 'PUT',
        body: queryArg.rewardDto,
      }),
    }),
    rewardPatch: build.mutation<RewardPatchApiResponse, RewardPatchApiArg>({
      query: queryArg => ({
        url: `/api/rewards/reward_${queryArg.rewardId}`,
        method: 'PATCH',
        body: queryArg.rewardPartialDto,
      }),
    }),
    usersIndex: build.query<UsersIndexApiResponse, UsersIndexApiArg>({
      query: queryArg => ({
        url: `/api/users`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          populates: queryArg.populates,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          streamId: queryArg.streamId,
          groupId: queryArg.groupId,
          username: queryArg.username,
          phone: queryArg.phone,
          email: queryArg.email,
          incompleteTaskId: queryArg.incompleteTaskId,
          inactiveTaskId: queryArg.inactiveTaskId,
          timelineId: queryArg.timelineId,
          noneOfTasks: queryArg.noneOfTasks,
          noneOfCompleteTasks: queryArg.noneOfCompleteTasks,
          unauthorized: queryArg.unauthorized,
        },
      }),
    }),
    usersAdd: build.mutation<UsersAddApiResponse, UsersAddApiArg>({
      query: queryArg => ({
        url: `/api/users`,
        method: 'POST',
        body: queryArg.userDto,
      }),
    }),
    usersCheckLogins: build.mutation<UsersCheckLoginsApiResponse, UsersCheckLoginsApiArg>({
      query: queryArg => ({
        url: `/api/users/check-logins`,
        method: 'POST',
        body: queryArg.userLoginCheckDto,
      }),
    }),
    userIndex: build.query<UserIndexApiResponse, UserIndexApiArg>({
      query: queryArg => ({url: `/api/users/user_${queryArg.userId}`}),
    }),
    userUpdate: build.mutation<UserUpdateApiResponse, UserUpdateApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}`,
        method: 'PUT',
        body: queryArg.userDto,
      }),
    }),
    userPatch: build.mutation<UserPatchApiResponse, UserPatchApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}`,
        method: 'PATCH',
        body: queryArg.userPartialDto,
      }),
    }),
    userDelete: build.mutation<UserDeleteApiResponse, UserDeleteApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}/delete`,
        method: 'DELETE',
      }),
    }),
    userRestore: build.mutation<UserRestoreApiResponse, UserRestoreApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}/restore`,
        method: 'POST',
      }),
    }),
    userTagsIndex: build.query<UserTagsIndexApiResponse, UserTagsIndexApiArg>({
      query: queryArg => ({url: `/api/users/user_${queryArg.userId}/tags`}),
    }),
    userTagsSet: build.mutation<UserTagsSetApiResponse, UserTagsSetApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}/tags`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    userPhotosIndex: build.query<UserPhotosIndexApiResponse, UserPhotosIndexApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}/photos`,
        params: {
          populates: queryArg.populates,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    userPhotosAdd: build.mutation<UserPhotosAddApiResponse, UserPhotosAddApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}/photos`,
        method: 'POST',
        body: queryArg.userPhotoDto,
      }),
    }),
    userPhotoIndex: build.query<UserPhotoIndexApiResponse, UserPhotoIndexApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}/photos/photo_${queryArg.photoId}`,
      }),
    }),
    userPhotoUpdate: build.mutation<UserPhotoUpdateApiResponse, UserPhotoUpdateApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}/photos/photo_${queryArg.photoId}`,
        method: 'PATCH',
        body: queryArg.userPhotoPatchDto,
      }),
    }),
    userPhotoDelete: build.mutation<UserPhotoDeleteApiResponse, UserPhotoDeleteApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}/photos/photo_${queryArg.photoId}`,
        method: 'DELETE',
      }),
    }),
    userPermissionsIndex: build.query<UserPermissionsIndexApiResponse, UserPermissionsIndexApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}/permissions`,
      }),
    }),
    userPermissionsUpdate: build.mutation<UserPermissionsUpdateApiResponse, UserPermissionsUpdateApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}/permissions`,
        method: 'PUT',
        body: queryArg.userPermissionDto,
      }),
    }),
    userLoginsIndex: build.query<UserLoginsIndexApiResponse, UserLoginsIndexApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}/logins`,
      }),
    }),
    userLoginsAdd: build.mutation<UserLoginsAddApiResponse, UserLoginsAddApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}/logins`,
        method: 'POST',
        body: queryArg.usersLogins,
      }),
    }),
    userLoginInvite: build.mutation<UserLoginInviteApiResponse, UserLoginInviteApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}/logins/login_${queryArg.loginId}/invite`,
        method: 'POST',
        body: queryArg.userInviteDto,
      }),
    }),
    userLoginIndex: build.query<UserLoginIndexApiResponse, UserLoginIndexApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}/logins/login_${queryArg.loginId}`,
      }),
    }),
    userLoginUpdate: build.mutation<UserLoginUpdateApiResponse, UserLoginUpdateApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}/logins/login_${queryArg.loginId}`,
        method: 'PUT',
        body: queryArg.usersLogins,
      }),
    }),
    userLoginDelete: build.mutation<UserLoginDeleteApiResponse, UserLoginDeleteApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}/logins/login_${queryArg.loginId}`,
        method: 'DELETE',
      }),
    }),
    userLoginPassword: build.mutation<UserLoginPasswordApiResponse, UserLoginPasswordApiArg>({
      query: queryArg => ({
        url: `/api/users/user_${queryArg.userId}/logins/login_${queryArg.loginId}/password`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    groupsIndex: build.query<GroupsIndexApiResponse, GroupsIndexApiArg>({
      query: queryArg => ({
        url: `/api/groups`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          populates: queryArg.populates,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    groupsAdd: build.mutation<GroupsAddApiResponse, GroupsAddApiArg>({
      query: queryArg => ({
        url: `/api/groups`,
        method: 'POST',
        body: queryArg.groupDto,
      }),
    }),
    groupsAccessIndex: build.query<GroupsAccessIndexApiResponse, GroupsAccessIndexApiArg>({
      query: queryArg => ({
        url: `/api/groups/access`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    groupsAccessAdd: build.mutation<GroupsAccessAddApiResponse, GroupsAccessAddApiArg>({
      query: queryArg => ({
        url: `/api/groups/access`,
        method: 'POST',
        body: queryArg.groupAccessDto,
      }),
    }),
    groupAccessElemIndex: build.query<GroupAccessElemIndexApiResponse, GroupAccessElemIndexApiArg>({
      query: queryArg => ({
        url: `/api/groups/access/id_${queryArg.accessId}`,
      }),
    }),
    groupAccessElemUpdate: build.mutation<GroupAccessElemUpdateApiResponse, GroupAccessElemUpdateApiArg>({
      query: queryArg => ({
        url: `/api/groups/access/id_${queryArg.accessId}`,
        method: 'PUT',
        body: queryArg.groupAccessDto,
      }),
    }),
    groupAccessElemPatch: build.mutation<GroupAccessElemPatchApiResponse, GroupAccessElemPatchApiArg>({
      query: queryArg => ({
        url: `/api/groups/access/id_${queryArg.accessId}`,
        method: 'PATCH',
        body: queryArg.groupAccessPartialDto,
      }),
    }),
    groupIndex: build.query<GroupIndexApiResponse, GroupIndexApiArg>({
      query: queryArg => ({url: `/api/groups/group_${queryArg.groupId}`}),
    }),
    groupUpdate: build.mutation<GroupUpdateApiResponse, GroupUpdateApiArg>({
      query: queryArg => ({
        url: `/api/groups/group_${queryArg.groupId}`,
        method: 'PUT',
        body: queryArg.groupDto,
      }),
    }),
    groupPatch: build.mutation<GroupPatchApiResponse, GroupPatchApiArg>({
      query: queryArg => ({
        url: `/api/groups/group_${queryArg.groupId}`,
        method: 'PATCH',
        body: queryArg.groupPartialDto,
      }),
    }),
    groupUsers: build.query<GroupUsersApiResponse, GroupUsersApiArg>({
      query: queryArg => ({
        url: `/api/groups/group_${queryArg.groupId}/users`,
      }),
    }),
    groupAddUsers: build.mutation<GroupAddUsersApiResponse, GroupAddUsersApiArg>({
      query: queryArg => ({
        url: `/api/groups/group_${queryArg.groupId}/users`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    groupUpdateUser: build.mutation<GroupUpdateUserApiResponse, GroupUpdateUserApiArg>({
      query: queryArg => ({
        url: `/api/groups/group_${queryArg.groupId}/user_${queryArg.userId}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    groupDeleteUser: build.mutation<GroupDeleteUserApiResponse, GroupDeleteUserApiArg>({
      query: queryArg => ({
        url: `/api/groups/group_${queryArg.groupId}/user_${queryArg.userId}`,
        method: 'DELETE',
      }),
    }),
    groupSetLeader: build.mutation<GroupSetLeaderApiResponse, GroupSetLeaderApiArg>({
      query: queryArg => ({
        url: `/api/groups/group_${queryArg.groupId}/user_${queryArg.userId}/setLeader`,
        method: 'POST',
      }),
    }),
    groupSetLimit: build.mutation<GroupSetLimitApiResponse, GroupSetLimitApiArg>({
      query: queryArg => ({
        url: `/api/groups/group_${queryArg.groupId}/setLimit`,
        method: 'POST',
        body: queryArg.setLimitDto,
      }),
    }),
    streamsIndex: build.query<StreamsIndexApiResponse, StreamsIndexApiArg>({
      query: queryArg => ({
        url: `/api/streams`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    streamsAdd: build.mutation<StreamsAddApiResponse, StreamsAddApiArg>({
      query: queryArg => ({
        url: `/api/streams`,
        method: 'POST',
        body: queryArg.streamDto,
      }),
    }),
    streamsAccessIndex: build.query<StreamsAccessIndexApiResponse, StreamsAccessIndexApiArg>({
      query: queryArg => ({
        url: `/api/streams/access`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    streamsAccessAdd: build.mutation<StreamsAccessAddApiResponse, StreamsAccessAddApiArg>({
      query: queryArg => ({
        url: `/api/streams/access`,
        method: 'POST',
        body: queryArg.streamAccessDto,
      }),
    }),
    streamAccessElemIndex: build.query<StreamAccessElemIndexApiResponse, StreamAccessElemIndexApiArg>({
      query: queryArg => ({
        url: `/api/streams/access/id_${queryArg.accessId}`,
      }),
    }),
    streamAccessElemPatch: build.mutation<StreamAccessElemPatchApiResponse, StreamAccessElemPatchApiArg>({
      query: queryArg => ({
        url: `/api/streams/access/id_${queryArg.accessId}`,
        method: 'PUT',
        body: queryArg.streamAccessPartialDto,
      }),
    }),
    streamIndex: build.query<StreamIndexApiResponse, StreamIndexApiArg>({
      query: queryArg => ({
        url: `/api/streams/stream_${queryArg.streamId}`,
      }),
    }),
    streamUpdate: build.mutation<StreamUpdateApiResponse, StreamUpdateApiArg>({
      query: queryArg => ({
        url: `/api/streams/stream_${queryArg.streamId}`,
        method: 'PUT',
        body: queryArg.streamDto,
      }),
    }),
    streamPatch: build.mutation<StreamPatchApiResponse, StreamPatchApiArg>({
      query: queryArg => ({
        url: `/api/streams/stream_${queryArg.streamId}`,
        method: 'PATCH',
        body: queryArg.streamPartialDto,
      }),
    }),
    streamUsers: build.query<StreamUsersApiResponse, StreamUsersApiArg>({
      query: queryArg => ({
        url: `/api/streams/stream_${queryArg.streamId}/users`,
      }),
    }),
    streamAddUsers: build.mutation<StreamAddUsersApiResponse, StreamAddUsersApiArg>({
      query: queryArg => ({
        url: `/api/streams/stream_${queryArg.streamId}/users`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    streamUpdateUser: build.mutation<StreamUpdateUserApiResponse, StreamUpdateUserApiArg>({
      query: queryArg => ({
        url: `/api/streams/stream_${queryArg.streamId}/user_${queryArg.userId}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    streamDeleteUser: build.mutation<StreamDeleteUserApiResponse, StreamDeleteUserApiArg>({
      query: queryArg => ({
        url: `/api/streams/stream_${queryArg.streamId}/user_${queryArg.userId}`,
        method: 'DELETE',
      }),
    }),
    streamImportsIndex: build.query<StreamImportsIndexApiResponse, StreamImportsIndexApiArg>({
      query: queryArg => ({
        url: `/api/streams/stream_${queryArg.streamId}/imports`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    streamImportsUpload: build.mutation<StreamImportsUploadApiResponse, StreamImportsUploadApiArg>({
      query: queryArg => ({
        url: `/api/streams/stream_${queryArg.streamId}/imports/upload`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    streamImportIndex: build.query<StreamImportIndexApiResponse, StreamImportIndexApiArg>({
      query: queryArg => ({
        url: `/api/streams/stream_${queryArg.streamId}/imports/elem_${queryArg.importId}`,
      }),
    }),
    streamImportRunImport: build.mutation<StreamImportRunImportApiResponse, StreamImportRunImportApiArg>({
      query: queryArg => ({
        url: `/api/streams/stream_${queryArg.streamId}/imports/elem_${queryArg.importId}`,
        method: 'POST',
      }),
    }),
    streamImportDelete: build.mutation<StreamImportDeleteApiResponse, StreamImportDeleteApiArg>({
      query: queryArg => ({
        url: `/api/streams/stream_${queryArg.streamId}/imports/elem_${queryArg.importId}`,
        method: 'DELETE',
      }),
    }),
    settingsIndex: build.query<SettingsIndexApiResponse, SettingsIndexApiArg>({
      query: () => ({url: `/api/settings`}),
    }),
    settingsUpdate: build.mutation<SettingsUpdateApiResponse, SettingsUpdateApiArg>({
      query: queryArg => ({
        url: `/api/settings`,
        method: 'POST',
        body: queryArg.settingsDto,
      }),
    }),
    settingsPatch: build.mutation<SettingsPatchApiResponse, SettingsPatchApiArg>({
      query: queryArg => ({
        url: `/api/settings`,
        method: 'PATCH',
        body: queryArg.settingsPartialDto,
      }),
    }),
    profileIndex: build.query<ProfileIndexApiResponse, ProfileIndexApiArg>({
      query: () => ({url: `/api/profile`}),
    }),
    profileUpdate: build.mutation<ProfileUpdateApiResponse, ProfileUpdateApiArg>({
      query: queryArg => ({
        url: `/api/profile`,
        method: 'PUT',
        body: queryArg.userDto,
      }),
    }),
    profileLogins: build.query<ProfileLoginsApiResponse, ProfileLoginsApiArg>({
      query: () => ({url: `/api/profile/logins`}),
    }),
    profileChangePassword: build.mutation<ProfileChangePasswordApiResponse, ProfileChangePasswordApiArg>({
      query: queryArg => ({
        url: `/api/profile/change-password/${queryArg.loginId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    achievementsAdd: build.mutation<AchievementsAddApiResponse, AchievementsAddApiArg>({
      query: queryArg => ({
        url: `/api/achievements`,
        method: 'POST',
        body: queryArg.achievementsDto,
      }),
    }),
    accessIndex: build.query<AccessIndexApiResponse, AccessIndexApiArg>({
      query: queryArg => ({
        url: `/api/access`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    accessAdd: build.mutation<AccessAddApiResponse, AccessAddApiArg>({
      query: queryArg => ({
        url: `/api/access`,
        method: 'POST',
        body: queryArg.accessDto,
      }),
    }),
    accessElemIndex: build.query<AccessElemIndexApiResponse, AccessElemIndexApiArg>({
      query: queryArg => ({url: `/api/access/id_${queryArg.accessId}`}),
    }),
    accessElemUpdate: build.mutation<AccessElemUpdateApiResponse, AccessElemUpdateApiArg>({
      query: queryArg => ({
        url: `/api/access/id_${queryArg.accessId}`,
        method: 'PUT',
        body: queryArg.accessDto,
      }),
    }),
    accessElemPatch: build.mutation<AccessElemPatchApiResponse, AccessElemPatchApiArg>({
      query: queryArg => ({
        url: `/api/access/id_${queryArg.accessId}`,
        method: 'PATCH',
        body: queryArg.accessPartialDto,
      }),
    }),
    envEnv: build.query<EnvEnvApiResponse, EnvEnvApiArg>({
      query: () => ({url: `/env`}),
    }),
  }),
});
export type RootIndexApiResponse = /** status 200 Список маршрутов */ RouteElement[];
export type RootIndexApiArg = void;
export type RootJsonDocsApiResponse = /** status 200 Документация OpenAPI */ string;
export type RootJsonDocsApiArg = void;
export type RootDocsApiResponse = /** status 200 Интерфейс для работы с документацией */ string;
export type RootDocsApiArg = void;
export type RootSwaggerApiResponse = /** status 200 Интерфейс для работы с документацией */ string;
export type RootSwaggerApiArg = void;
export type AppIndexApiResponse = /** status 200 Информация о пользователе */ {
  account?: Users;
};
export type AppIndexApiArg = void;
export type AppRoutesApiResponse = /** status 200 Список маршрутов */ RouteElement[];
export type AppRoutesApiArg = void;
export type WidgetsFormulasIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: WidgetsFormulas[];
};
export type WidgetsFormulasIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  populates?: 'widget'[];
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  /** id виджета */
  widgetId?: string;
  /** Статус активности */
  enabled?: boolean;
};
export type WidgetsFormulasAddApiResponse = /** status 200 Новая виджет формула */ WidgetsFormulas;
export type WidgetsFormulasAddApiArg = {
  widgetFormulaDto: WidgetFormulaDto;
};
export type WidgetFormulaIndexApiResponse = /** status 200 Виджет */ WidgetsFormulas;
export type WidgetFormulaIndexApiArg = {
  /** Идентификатор */
  widgetFormulaId: string;
};
export type WidgetFormulaUpdateApiResponse = /** status 200 Виджет */ WidgetsFormulas;
export type WidgetFormulaUpdateApiArg = {
  /** Идентификатор */
  widgetFormulaId: string;
  widgetFormulaDto: WidgetFormulaDto;
};
export type WidgetFormulaPatchApiResponse = /** status 200 Виджет */ WidgetsFormulas;
export type WidgetFormulaPatchApiArg = {
  /** Идентификатор */
  widgetFormulaId: string;
  widgetFormulaPartialDto: WidgetFormulaPartialDto;
};
export type WidgetFormulaValuesApiResponse = /** status 200 Виджет */ WidgetsFormulas;
export type WidgetFormulaValuesApiArg = {
  /** Идентификатор */
  widgetFormulaId: string;
  /** группировка ответов */
  groupBy?: 'userId';
};
export type WidgetsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Widgets[];
};
export type WidgetsIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type WidgetsAddApiResponse = /** status 200 Новый виджет */ Widgets;
export type WidgetsAddApiArg = {
  widgetDto: WidgetDto;
};
export type WidgetIndexApiResponse = /** status 200 Виджет */ Widgets;
export type WidgetIndexApiArg = {
  /** Идентификатор */
  widgetId: string;
};
export type WidgetUpdateApiResponse = /** status 200 Виджет */ Widgets;
export type WidgetUpdateApiArg = {
  /** Идентификатор */
  widgetId: string;
  widgetDto: WidgetDto;
};
export type WidgetPatchApiResponse = /** status 200 Виджет */ Widgets;
export type WidgetPatchApiArg = {
  /** Идентификатор */
  widgetId: string;
  widgetPartialDto: WidgetPartialDto;
};
export type WidgetFillDataApiResponse = unknown;
export type WidgetFillDataApiArg = {
  /** Идентификатор */
  widgetId: string;
};
export type WidgetRulesIndexApiResponse = /** status 200 Список правил */ WidgetsRules[];
export type WidgetRulesIndexApiArg = {
  /** Идентификатор */
  widgetId: string;
  /** Признак удаленного */
  isDeleted?: boolean;
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type WidgetRulesAddApiResponse = /** status 200 Список правил */ WidgetsRules[];
export type WidgetRulesAddApiArg = {
  /** Идентификатор */
  widgetId: string;
  widgetRuleDto: WidgetRuleDto;
};
export type WidgetRuleIndexApiResponse = /** status 200 Правило виджета */ WidgetsRules;
export type WidgetRuleIndexApiArg = {
  /** Идентификатор */
  widgetId: string;
  /** Идентификатор */
  ruleId: string;
};
export type WidgetRuleUpdateApiResponse = /** status 200 Правило виджета */ WidgetsRules;
export type WidgetRuleUpdateApiArg = {
  /** Идентификатор */
  widgetId: string;
  /** Идентификатор */
  ruleId: string;
  widgetRuleDto: WidgetRuleDto;
};
export type WidgetRulePatchApiResponse = /** status 200 Правило виджета */ WidgetsRules;
export type WidgetRulePatchApiArg = {
  /** Идентификатор */
  widgetId: string;
  /** Идентификатор */
  ruleId: string;
  widgetRulePartialDto: WidgetRulePartialDto;
};
export type WidgetRuleDeleteApiResponse = /** status 200 Правило виджета */ WidgetsRules;
export type WidgetRuleDeleteApiArg = {
  /** Идентификатор */
  widgetId: string;
  /** Идентификатор */
  ruleId: string;
};
export type WidgetAttrsIndexApiResponse = /** status 200 Список вариантов */ WidgetsAttrs[];
export type WidgetAttrsIndexApiArg = {
  /** Идентификатор */
  widgetId: string;
  /** Признак удаленного */
  isDeleted?: boolean;
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  populates?: ('templateOption' | 'template')[];
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type WidgetAttrsAddApiResponse = /** status 200 Список вариантов */ WidgetsAttrs[];
export type WidgetAttrsAddApiArg = {
  /** Идентификатор */
  widgetId: string;
  widgetAttrDto: WidgetAttrDto;
};
export type WidgetAttrsChangePositionsApiResponse = /** status 200 Список вариантов */ WidgetsAttrs[];
export type WidgetAttrsChangePositionsApiArg = {
  /** Идентификатор */
  widgetId: string;
  widgetAttrsPositions: WidgetAttrsPositions;
};
export type WidgetAttrIndexApiResponse = /** status 200 Атрибут виджета */ WidgetsAttrs;
export type WidgetAttrIndexApiArg = {
  /** Идентификатор */
  widgetId: string;
  /** Идентификатор */
  attrId: string;
};
export type WidgetAttrUpdateApiResponse = /** status 200 Атрибут виджета */ WidgetsAttrs;
export type WidgetAttrUpdateApiArg = {
  /** Идентификатор */
  widgetId: string;
  /** Идентификатор */
  attrId: string;
  widgetAttrDto: WidgetAttrDto;
};
export type WidgetAttrPatchApiResponse = /** status 200 Атрибут виджета */ WidgetsAttrs;
export type WidgetAttrPatchApiArg = {
  /** Идентификатор */
  widgetId: string;
  /** Идентификатор */
  attrId: string;
  widgetAttrPartialDto: WidgetAttrPartialDto;
};
export type WidgetAttrDeleteApiResponse = /** status 200 Атрибут виджета */ WidgetsAttrs;
export type WidgetAttrDeleteApiArg = {
  /** Идентификатор */
  widgetId: string;
  /** Идентификатор */
  attrId: string;
};
export type WidgetsVisualsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: WidgetsVisuals[];
};
export type WidgetsVisualsIndexApiArg = {
  /** Признак удаленного */
  isDeleted?: boolean;
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  populates?: ('designs' | 'props')[];
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type WidgetsVisualsAddApiResponse = /** status 200 Визуал на виджете */ WidgetsVisuals;
export type WidgetsVisualsAddApiArg = {
  widgetVisualDto: WidgetVisualDto;
};
export type WidgetsVisualIndexApiResponse = /** status 200 Визуал на виджете */ WidgetsVisuals;
export type WidgetsVisualIndexApiArg = {
  /** Идентификатор */
  visualId: string;
};
export type WidgetsVisualUpdateApiResponse = /** status 200 Визуал на виджете */ WidgetsVisuals;
export type WidgetsVisualUpdateApiArg = {
  /** Идентификатор */
  visualId: string;
  widgetVisualDto: WidgetVisualDto;
};
export type WidgetsVisualPatchApiResponse = /** status 200 Визуал на виджете */ WidgetsVisuals;
export type WidgetsVisualPatchApiArg = {
  /** Идентификатор */
  visualId: string;
  widgetVisualPartialDto: WidgetVisualPartialDto;
};
export type WidgetsVisualDeleteApiResponse = /** status 200 Визуал на виджете */ WidgetsVisuals;
export type WidgetsVisualDeleteApiArg = {
  /** Идентификатор */
  visualId: string;
};
export type WidgetsVisualDesignsIndexApiResponse = /** status 200 Список свойств */ WidgetsVisualsDesigns[];
export type WidgetsVisualDesignsIndexApiArg = {
  /** Идентификатор */
  visualId: string;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  /** Признак удаленного */
  isDeleted?: boolean;
};
export type WidgetsVisualDesignsAddApiResponse = /** status 200 Свойство визуала */ WidgetsVisualsDesigns;
export type WidgetsVisualDesignsAddApiArg = {
  /** Идентификатор */
  visualId: string;
  widgetVisualDesignDto: WidgetVisualDesignDto;
};
export type WidgetsVisualDesignsChangePositionsApiResponse = /** status 200 Список свойств */ WidgetsVisualsDesigns[];
export type WidgetsVisualDesignsChangePositionsApiArg = {
  /** Идентификатор */
  visualId: string;
  iDs: IDs;
};
export type WidgetsVisualDesignIndexApiResponse = /** status 200 Свойство визуала */ WidgetsVisualsDesigns;
export type WidgetsVisualDesignIndexApiArg = {
  /** Идентификатор */
  visualId: string;
  /** Идентификатор */
  designId: string;
};
export type WidgetsVisualDesignUpdateApiResponse = /** status 200 Свойство визуала */ WidgetsVisualsDesigns;
export type WidgetsVisualDesignUpdateApiArg = {
  /** Идентификатор */
  visualId: string;
  /** Идентификатор */
  designId: string;
  widgetVisualDesignDto: WidgetVisualDesignDto;
};
export type WidgetsVisualDesignPatchApiResponse = /** status 200 Свойство визуала */ WidgetsVisualsDesigns;
export type WidgetsVisualDesignPatchApiArg = {
  /** Идентификатор */
  visualId: string;
  /** Идентификатор */
  designId: string;
  widgetVisualDesignPartialDto: WidgetVisualDesignPartialDto;
};
export type WidgetsVisualDesignDeleteApiResponse = /** status 200 Свойство визуала */ WidgetsVisualsDesigns;
export type WidgetsVisualDesignDeleteApiArg = {
  /** Идентификатор */
  visualId: string;
  /** Идентификатор */
  designId: string;
};
export type WidgetsVisualPropsIndexApiResponse = /** status 200 Список свойств */ WidgetsVisualsProps[];
export type WidgetsVisualPropsIndexApiArg = {
  /** Идентификатор */
  visualId: string;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  /** Признак удаленного */
  isDeleted?: boolean;
  populates?: ('widget' | 'widgetAttr')[];
};
export type WidgetsVisualPropsAddApiResponse = /** status 200 Свойство визуала */ WidgetsVisualsProps;
export type WidgetsVisualPropsAddApiArg = {
  /** Идентификатор */
  visualId: string;
  widgetVisualPropDto: WidgetVisualPropDto;
};
export type WidgetsVisualPropsChangePositionsApiResponse = /** status 200 Список свойств */ WidgetsVisualsProps[];
export type WidgetsVisualPropsChangePositionsApiArg = {
  /** Идентификатор */
  visualId: string;
  widgetVisualPropsPositions: WidgetVisualPropsPositions;
};
export type WidgetsVisualPropIndexApiResponse = /** status 200 Свойство визуала */ WidgetsVisualsProps;
export type WidgetsVisualPropIndexApiArg = {
  /** Идентификатор */
  visualId: string;
  /** Идентификатор */
  propId: string;
};
export type WidgetsVisualPropUpdateApiResponse = /** status 200 Свойство визуала */ WidgetsVisualsProps;
export type WidgetsVisualPropUpdateApiArg = {
  /** Идентификатор */
  visualId: string;
  /** Идентификатор */
  propId: string;
  widgetVisualPropDto: WidgetVisualPropDto;
};
export type WidgetsVisualPropPatchApiResponse = /** status 200 Свойство визуала */ WidgetsVisualsProps;
export type WidgetsVisualPropPatchApiArg = {
  /** Идентификатор */
  visualId: string;
  /** Идентификатор */
  propId: string;
  widgetVisualPropPartialDto: WidgetVisualPropPartialDto;
};
export type WidgetsVisualPropDeleteApiResponse = /** status 200 Свойство визуала */ WidgetsVisualsProps;
export type WidgetsVisualPropDeleteApiArg = {
  /** Идентификатор */
  visualId: string;
  /** Идентификатор */
  propId: string;
};
export type ReceiptsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Receipts[];
};
export type ReceiptsIndexApiArg = {
  /** Тип */
  type?: ('kit_online' | 'cloud_kassir')[];
  userId?: string[];
};
export type ReceiptIndexApiResponse = /** status 200 Информация о чеке */ Receipts;
export type ReceiptIndexApiArg = {
  /** Идентификатор */
  receiptId: string;
};
export type ReceiptsServicesIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: ReceiptsServices[];
};
export type ReceiptsServicesIndexApiArg = {
  /** Тип */
  type?: ('kit_online' | 'cloud_kassir')[];
};
export type ReceiptsServicesAddApiResponse = /** status 200 Информация о сервисе */ ReceiptsServices;
export type ReceiptsServicesAddApiArg = {
  receiptsServiceDto: ReceiptsServiceDto;
};
export type ReceiptsServiceIndexApiResponse = /** status 200 Информация о сервисе */ ReceiptsServices;
export type ReceiptsServiceIndexApiArg = {
  /** Идентификатор */
  serviceId: string;
};
export type ReceiptsServiceUpdateApiResponse = /** status 200 Информация о сервисе */ ReceiptsServices;
export type ReceiptsServiceUpdateApiArg = {
  /** Идентификатор */
  serviceId: string;
  receiptsServiceDto: ReceiptsServiceDto;
};
export type ReceiptsServicePatchApiResponse = /** status 200 Информация о сервисе */ ReceiptsServices;
export type ReceiptsServicePatchApiArg = {
  /** Идентификатор */
  serviceId: string;
  receiptsServicePartialDto: ReceiptsServicePartialDto;
};
export type ReceiptsServiceDeleteApiResponse = /** status 200 Информация о сервисе */ ReceiptsServices;
export type ReceiptsServiceDeleteApiArg = {
  /** Идентификатор */
  serviceId: string;
};
export type PaymentsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Payments[];
};
export type PaymentsIndexApiArg = {
  /** Тип */
  type?: ('yoo_kassa' | 'tinkoff_kassa' | 'paypal' | 'stripe' | 'bluesnap' | 'crypto' | 'manual')[];
  userId?: string[];
};
export type PaymentsStatsPartnersDynamicApiResponse =
  /** status 200 Статистика по динамике платежей */ PaymentsStatsDto;
export type PaymentsStatsPartnersDynamicApiArg = {
  /** Масштаб */
  scale: 'day' | 'week' | 'month' | 'year';
  /** Начало периода */
  startDate?: string | string;
  /** Окончание периода */
  finishDate?: string | string;
};
export type PaymentsStatsStatusSummaryApiResponse =
  /** status 200 Сводка по статусам платежей */ PaymentsStatusSummary[];
export type PaymentsStatsStatusSummaryApiArg = void;
export type PaymentIndexApiResponse = /** status 200 Информация о платеже */ Payments;
export type PaymentIndexApiArg = {
  /** Идентификатор */
  paymentId: string;
};
export type PaymentRefreshApiResponse = /** status 200 Информация о платеже */ Payments;
export type PaymentRefreshApiArg = {
  /** Идентификатор */
  paymentId: string;
};
export type PaymentsServicesIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: PaymentsServices[];
};
export type PaymentsServicesIndexApiArg = {
  /** Тип */
  type?: ('yoo_kassa' | 'tinkoff_kassa' | 'paypal' | 'stripe' | 'bluesnap' | 'crypto' | 'manual')[];
};
export type PaymentsServicesAddApiResponse = /** status 200 Информация о сервисе */ PaymentsServices;
export type PaymentsServicesAddApiArg = {
  paymentsServiceDto: PaymentsServiceDto;
};
export type PaymentsServiceIndexApiResponse = /** status 200 Информация о сервисе */ PaymentsServices;
export type PaymentsServiceIndexApiArg = {
  /** Идентификатор */
  serviceId: string;
};
export type PaymentsServiceUpdateApiResponse = /** status 200 Информация о сервисе */ PaymentsServices;
export type PaymentsServiceUpdateApiArg = {
  /** Идентификатор */
  serviceId: string;
  paymentsServiceDto: PaymentsServiceDto;
};
export type PaymentsServicePatchApiResponse = /** status 200 Информация о сервисе */ PaymentsServices;
export type PaymentsServicePatchApiArg = {
  /** Идентификатор */
  serviceId: string;
  paymentsServicePartialDto: PaymentsServicePartialDto;
};
export type PaymentsServiceDeleteApiResponse = /** status 200 Информация о сервисе */ PaymentsServices;
export type PaymentsServiceDeleteApiArg = {
  /** Идентификатор */
  serviceId: string;
};
export type ReactionsBlocksIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: ReactionsBlocks[];
};
export type ReactionsBlocksIndexApiArg = {
  /** Сущность */
  referenceName?: 'Comments' | 'StagesPoints' | 'Publications' | 'Tasks' | 'Contents' | 'Goals' | 'Messages';
};
export type ReactionsBlocksAddApiResponse = /** status 200 Информация о блоке реакций */ ReactionsBlocks;
export type ReactionsBlocksAddApiArg = {
  reactionsBlockDto: ReactionsBlockDto;
};
export type ReactionsBlockIndexApiResponse = /** status 200 Блок реакций */ ReactionsBlocks;
export type ReactionsBlockIndexApiArg = {
  /** Идентификатор */
  blockId: string;
};
export type ReactionsBlockUpdateApiResponse = /** status 200 Блок реакций */ ReactionsBlocks;
export type ReactionsBlockUpdateApiArg = {
  /** Идентификатор */
  blockId: string;
  reactionsBlockDto: ReactionsBlockDto;
};
export type ReactionsBlockPatchApiResponse = /** status 200 Блок реакций */ ReactionsBlocks;
export type ReactionsBlockPatchApiArg = {
  /** Идентификатор */
  blockId: string;
  reactionsBlockPartialDto: ReactionsBlockPartialDto;
};
export type ReactionsBlockDeleteApiResponse = /** status 200 Блок реакций */ ReactionsBlocks;
export type ReactionsBlockDeleteApiArg = {
  /** Идентификатор */
  blockId: string;
};
export type BlockersIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Blockers[];
};
export type BlockersIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  /** Назване блокировки */
  name?: string;
};
export type BlockersAddApiResponse = /** status 200 Информация о блокировке */ Blockers;
export type BlockersAddApiArg = {
  blockerDto: BlockerDto;
};
export type BlockerIndexApiResponse = /** status 200 Информация о блокировке */ Blockers;
export type BlockerIndexApiArg = {
  /** Идентификатор */
  blockerId: string;
};
export type BlockerUpdateApiResponse = /** status 200 Информация о блокировке */ Blockers;
export type BlockerUpdateApiArg = {
  /** Идентификатор */
  blockerId: string;
  blockerDto: BlockerDto;
};
export type BlockerPatchApiResponse = /** status 200 Информация о блокировке */ Blockers;
export type BlockerPatchApiArg = {
  /** Идентификатор */
  blockerId: string;
  blockerPartialDto: BlockerPartialDto;
};
export type BlockerDeleteApiResponse = /** status 200  */ MessageResponse;
export type BlockerDeleteApiArg = {
  /** Идентификатор */
  blockerId: string;
};
export type StagePointIndexApiResponse = /** status 200 Информация о точке этапа */ StagesPoints;
export type StagePointIndexApiArg = {
  /** Идентификатор */
  pointId: string;
};
export type StagePointPatchApiResponse = /** status 200 Информация о точке этапа */ StagesPoints;
export type StagePointPatchApiArg = {
  /** Идентификатор */
  pointId: string;
  stagePointPartialDto: StagePointPartialDto;
};
export type StagePointRetriggerApiResponse = /** status 200 Информация о точке этапа */ StagesPoints;
export type StagePointRetriggerApiArg = {
  /** Идентификатор */
  pointId: string;
};
export type QuestsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Quests[];
};
export type QuestsIndexApiArg = {
  /** Родительский квест */
  parentQuestId?: string;
  /** Статус квеста */
  status?: ('draft' | 'active' | 'archived')[];
  /** Типы квестов */
  type?: (
    | 'default'
    | 'season'
    | 'meeting'
    | 'event'
    | 'point'
    | 'block'
    | 'adventure'
    | 'challenge'
    | 'lesson'
    | 'goal'
    | 'goal_report'
  )[];
  /** Отбор только паттернов */
  isPattern?: boolean;
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  populates?: ('isViewed' | 'tagsSet' | 'timelines')[];
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type QuestsAddApiResponse = /** status 200 Информация о квесте */ Quests;
export type QuestsAddApiArg = {
  questDto: QuestDto;
};
export type QuestsStatsDynamicApiResponse = /** status 200 Статистика по динамике квеста */ StagesStatsDto;
export type QuestsStatsDynamicApiArg = {
  /** ID квестов */
  questId: string[];
  /** ID таймлайнов */
  timelineId?: string[];
  /** ID пользователей */
  userId?: string[];
  /** ID потока */
  streamId?: string[];
  /** ID группы */
  groupId?: string[];
  /** Масштаб */
  scale: 'day' | 'week' | 'month' | 'year';
  /** Начало периода */
  startDate?: string | string;
  /** Окончание периода */
  finishDate?: string | string;
};
export type QuestsStatsStatusSummaryApiResponse = /** status 200 Сводка по статусам квестов */ StagesStatusSummary[];
export type QuestsStatsStatusSummaryApiArg = {
  /** ID квестов */
  questId: string[];
  /** ID таймлайнов */
  timelineId?: string[];
  /** ID пользователей */
  userId?: string[];
  /** ID потока */
  streamId?: string[];
  /** ID группы */
  groupId?: string[];
};
export type QuestsStatsStatusUsersApiResponse = /** status 200 Стейджи по пользователям */ StagesUsersStatusSummary[];
export type QuestsStatsStatusUsersApiArg = {
  /** ID квестов */
  questId: string[];
  /** ID таймлайнов */
  timelineId?: string[];
  /** ID пользователей */
  userId?: string[];
  /** ID потока */
  streamId?: string[];
  /** ID группы */
  groupId?: string[];
};
export type QuestPointsIndexApiResponse = /** status 200 Список точек квеста */ QuestsPoints[];
export type QuestPointsIndexApiArg = {
  /** ID квеста */
  questId: string;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type QuestPointsAddApiResponse = /** status 200 Информация о точке квеста */ QuestsPoints;
export type QuestPointsAddApiArg = {
  /** ID квеста */
  questId: string;
  questPointDto: QuestPointDto;
};
export type QuestPointsChangePositionsApiResponse = /** status 200 Список точек квеста */ QuestsPoints[];
export type QuestPointsChangePositionsApiArg = {
  /** ID квеста */
  questId: string;
  body: {
    pointsId: string[];
  };
};
export type QuestPointIndexApiResponse = /** status 200 Информация о точке квеста */ QuestsPoints;
export type QuestPointIndexApiArg = {
  /** Идентификатор */
  pointId: string;
};
export type QuestPointUpdateApiResponse = /** status 200 Информация о точке квеста */ QuestsPoints;
export type QuestPointUpdateApiArg = {
  /** Идентификатор */
  pointId: string;
  questPointDto: QuestPointDto;
};
export type QuestPointPatchApiResponse = /** status 200 Информация о точке квеста */ QuestsPoints;
export type QuestPointPatchApiArg = {
  /** Идентификатор */
  pointId: string;
  questPointPartialDto: QuestPointPartialDto;
};
export type QuestPointDeleteApiResponse = /** status 200 Информация о точке квеста */ QuestsPoints;
export type QuestPointDeleteApiArg = {
  /** Идентификатор */
  pointId: string;
};
export type QuestPointFillWidgetsApiResponse = /** status 200 Информация о точке квеста */ QuestsPoints;
export type QuestPointFillWidgetsApiArg = {
  /** Идентификатор */
  pointId: string;
};
export type QuestPointsRewardsIndexApiResponse = /** status 200 Список вознаграждений */ QuestsPointsRewards[];
export type QuestPointsRewardsIndexApiArg = {
  /** Идентификатор */
  pointId: string;
  /** Фильтр по активности значения */
  enabled?: boolean;
};
export type QuestPointsRewardsAddApiResponse = /** status 200 Информация о награде */ QuestsPointsRewards;
export type QuestPointsRewardsAddApiArg = {
  /** Идентификатор */
  pointId: string;
  questPointRewardDto: QuestPointRewardDto;
};
export type QuestPointRewardIndexApiResponse = /** status 200 Информация о награде */ QuestsPointsRewards;
export type QuestPointRewardIndexApiArg = {
  /** Идентификатор */
  pointId: string;
  /** Идентификатор */
  rewardId: string;
};
export type QuestPointRewardUpdateApiResponse = /** status 200 Информация о награде */ QuestsPointsRewards;
export type QuestPointRewardUpdateApiArg = {
  /** Идентификатор */
  pointId: string;
  /** Идентификатор */
  rewardId: string;
  questPointRewardDto: QuestPointRewardDto;
};
export type QuestPointRewardPatchApiResponse = /** status 200 Информация о награде */ QuestsPointsRewards;
export type QuestPointRewardPatchApiArg = {
  /** Идентификатор */
  pointId: string;
  /** Идентификатор */
  rewardId: string;
  questPointRewardPartialDto: QuestPointRewardPartialDto;
};
export type QuestPointsTimelinesIndexApiResponse = /** status 200 Список таймлайнов */ Timelines[];
export type QuestPointsTimelinesIndexApiArg = {
  /** Идентификатор */
  pointId: string;
};
export type QuestPointsTimelinesAddApiResponse = /** status 200 Информация о таймлайне */ Timelines;
export type QuestPointsTimelinesAddApiArg = {
  /** Идентификатор */
  pointId: string;
  questPointTimelineDto: QuestPointTimelineDto;
};
export type QuestPointTimelineElemIndexApiResponse = /** status 200 Информация о таймлайне */ Timelines;
export type QuestPointTimelineElemIndexApiArg = {
  /** Идентификатор */
  pointId: string;
  /** Идентификатор */
  timelineId: string;
};
export type QuestPointTimelineElemUpdateApiResponse = /** status 200 Информация о таймлайне */ Timelines;
export type QuestPointTimelineElemUpdateApiArg = {
  /** Идентификатор */
  pointId: string;
  /** Идентификатор */
  timelineId: string;
  questPointTimelineDto: QuestPointTimelineDto;
};
export type QuestPointTimelineElemPatchApiResponse = /** status 200 Информация о таймлайне */ Timelines;
export type QuestPointTimelineElemPatchApiArg = {
  /** Идентификатор */
  pointId: string;
  /** Идентификатор */
  timelineId: string;
  questPointTimelinePartialDto: QuestPointTimelinePartialDto;
};
export type QuestPointTimelineElemDeleteApiResponse = /** status 200  */ MessageResponse;
export type QuestPointTimelineElemDeleteApiArg = {
  /** Идентификатор */
  pointId: string;
  /** Идентификатор */
  timelineId: string;
};
export type QuestPointTimelineElemRecalculateRewardsApiResponse = /** status 200  */ MessageResponse;
export type QuestPointTimelineElemRecalculateRewardsApiArg = {
  /** Идентификатор */
  pointId: string;
  /** Идентификатор */
  timelineId: string;
};
export type QuestIndexApiResponse = /** status 200 Информация о квесте */ Quests;
export type QuestIndexApiArg = {
  /** Идентификатор */
  questId: string;
};
export type QuestUpdateApiResponse = /** status 200 Информация о квесте */ Quests;
export type QuestUpdateApiArg = {
  /** Идентификатор */
  questId: string;
  questDto: QuestDto;
};
export type QuestPatchApiResponse = /** status 200 Информация о квесте */ Quests;
export type QuestPatchApiArg = {
  /** Идентификатор */
  questId: string;
  questPartialDto: QuestPartialDto;
};
export type QuestDeleteForUsersApiResponse = /** status 200  */ MessageResponse;
export type QuestDeleteForUsersApiArg = {
  /** Идентификатор */
  questId: string;
  questDeleteDataDto: QuestDeleteDataDto;
};
export type QuestDestructForUsersApiResponse = /** status 200  */ MessageResponse;
export type QuestDestructForUsersApiArg = {
  /** Идентификатор */
  questId: string;
  questDeleteDataDto: QuestDeleteDataDto;
};
export type QuestTagsIndexApiResponse = /** status 200 Список тегов */ Tags[];
export type QuestTagsIndexApiArg = {
  /** Идентификатор */
  questId: string;
};
export type QuestTagsSetApiResponse = /** status 200 Список тегов */ Tags[];
export type QuestTagsSetApiArg = {
  /** Идентификатор */
  questId: string;
  body: {
    tagsId: string[];
  };
};
export type QuestTimelinesIndexApiResponse = /** status 200 Список таймлайнов */ Timelines[];
export type QuestTimelinesIndexApiArg = {
  /** Идентификатор */
  questId: string;
};
export type QuestTimelinesAddApiResponse = /** status 200 Информация о таймлайне */ Timelines;
export type QuestTimelinesAddApiArg = {
  /** Идентификатор */
  questId: string;
  questTimelineDto: QuestTimelineDto;
};
export type QuestTimelineElemIndexApiResponse = /** status 200 Информация о таймлайне */ Timelines;
export type QuestTimelineElemIndexApiArg = {
  /** Идентификатор */
  questId: string;
  /** Идентификатор */
  timelineId: string;
};
export type QuestTimelineElemUpdateApiResponse = /** status 200 Информация о таймлайне */ Timelines;
export type QuestTimelineElemUpdateApiArg = {
  /** Идентификатор */
  questId: string;
  /** Идентификатор */
  timelineId: string;
  questTimelineDto: QuestTimelineDto;
};
export type QuestTimelineElemPatchApiResponse = /** status 200 Информация о таймлайне */ Timelines;
export type QuestTimelineElemPatchApiArg = {
  /** Идентификатор */
  questId: string;
  /** Идентификатор */
  timelineId: string;
  questTimelinePartialDto: QuestTimelinePartialDto;
};
export type QuestTimelineElemDeleteApiResponse = /** status 200  */ MessageResponse;
export type QuestTimelineElemDeleteApiArg = {
  /** Идентификатор */
  questId: string;
  /** Идентификатор */
  timelineId: string;
};
export type QuestTimelineElemUsersIdApiResponse = unknown;
export type QuestTimelineElemUsersIdApiArg = {
  /** Идентификатор */
  questId: string;
  /** Идентификатор */
  timelineId: string;
};
export type QuestTimelineElemStartApiResponse = /** status 200 Информация о таймлайне */ Timelines;
export type QuestTimelineElemStartApiArg = {
  /** Идентификатор */
  questId: string;
  /** Идентификатор */
  timelineId: string;
};
export type QuestTimelineElemStopApiResponse = /** status 200 Информация о таймлайне */ Timelines;
export type QuestTimelineElemStopApiArg = {
  /** Идентификатор */
  questId: string;
  /** Идентификатор */
  timelineId: string;
};
export type QuestTimelineElemResetApiResponse = unknown;
export type QuestTimelineElemResetApiArg = {
  /** Идентификатор */
  questId: string;
  /** Идентификатор */
  timelineId: string;
};
export type QuestTimelineElemRebuildApiResponse = /** status 200 Информация о таймлайне */ Timelines;
export type QuestTimelineElemRebuildApiArg = {
  /** Идентификатор */
  questId: string;
  /** Идентификатор */
  timelineId: string;
};
export type QuestTimelineElemRecalculateRewardsApiResponse =
  /** status 200 Сообщение об запуске процедуры */ MessageResponse;
export type QuestTimelineElemRecalculateRewardsApiArg = {
  /** Идентификатор */
  questId: string;
  /** Идентификатор */
  timelineId: string;
};
export type QuestTimelineElemEmitApiResponse = /** status 200 Информация о таймлайне */ Timelines;
export type QuestTimelineElemEmitApiArg = {
  /** Идентификатор */
  questId: string;
  /** Идентификатор */
  timelineId: string;
};
export type QuestTimelineElemEmitSpecificApiResponse = /** status 200 Информация о таймлайне */ Timelines;
export type QuestTimelineElemEmitSpecificApiArg = {
  /** Идентификатор */
  questId: string;
  /** Идентификатор */
  timelineId: string;
  /** Список пользователей */
  body: {
    usersId: string[];
  };
};
export type StatisticsSummaryApiResponse = /** status 200 Сводка */ {
  activeUsersCount: number;
  goalsCount: number;
  goalsReportsCount: number;
  goalsCompleteCount: number;
  publicationsCount: number;
  scoresCount: number;
  commentsCount: number;
  likesRecievedCount: number;
  jobsCount: number;
  jobsCompleteCount: number;
};
export type StatisticsSummaryApiArg = void;
export type ClaimsDataIndexApiResponse = /** status 200 Список сгруппированных жалоб */ ClaimsDataDto[];
export type ClaimsDataIndexApiArg = {
  /** Сущность */
  referenceName?: 'Users' | 'Goals' | 'GoalsReports' | 'Contents' | 'Tasks' | 'Publications' | 'Jobs' | 'Comments';
  /** Статус */
  status: 'new' | 'approved' | 'rejected';
};
export type ClaimsDataDetailsApiResponse = /** status 200 Структура со сводной информацией */ {
  referenceData: object;
  message?: string;
  data?: Claims[];
  total?: number;
};
export type ClaimsDataDetailsApiArg = {
  /** Статус */
  status?: 'new' | 'approved' | 'rejected';
  /** Сущность */
  referenceName: 'Users' | 'Goals' | 'GoalsReports' | 'Contents' | 'Tasks' | 'Publications' | 'Jobs' | 'Comments';
  /** ID значения */
  referenceId: string;
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  populates?: 'user'[];
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type ClaimsDataSetClaimedApiResponse = /** status 200  */ MessageResponse;
export type ClaimsDataSetClaimedApiArg = {
  /** Сущность */
  referenceName: 'Users' | 'Goals' | 'GoalsReports' | 'Contents' | 'Tasks' | 'Publications' | 'Jobs' | 'Comments';
  /** ID значения */
  referenceId: string;
};
export type ClaimsDataRejectClaimsApiResponse = /** status 200  */ MessageResponse;
export type ClaimsDataRejectClaimsApiArg = {
  /** Сущность */
  referenceName: 'Users' | 'Goals' | 'GoalsReports' | 'Contents' | 'Tasks' | 'Publications' | 'Jobs' | 'Comments';
  /** ID значения */
  referenceId: string;
};
export type ClaimIndexApiResponse = /** status 200 Жалоба */ Claims;
export type ClaimIndexApiArg = {
  /** Идентификатор */
  claimId: string;
};
export type ClaimUpdateApiResponse = /** status 200 Жалоба */ Claims;
export type ClaimUpdateApiArg = {
  /** Идентификатор */
  claimId: string;
  claimDto: ClaimDto;
};
export type ClaimPatchApiResponse = /** status 200 Жалоба */ Claims;
export type ClaimPatchApiArg = {
  /** Идентификатор */
  claimId: string;
  claimPartialDto: ClaimPartialDto;
};
export type ClaimDeleteApiResponse = /** status 200 Жалоба */ Claims;
export type ClaimDeleteApiArg = {
  /** Идентификатор */
  claimId: string;
};
export type MailingsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Mailings[];
};
export type MailingsIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  populates?: 'reportsCount'[];
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type MailingsAddApiResponse = /** status 200 Информация о рассылке */ Mailings;
export type MailingsAddApiArg = {
  mailingDto: MailingDto;
};
export type MailingIndexApiResponse = /** status 200 Информация о рассылке */ Mailings;
export type MailingIndexApiArg = {
  /** Идентификатор */
  mailingId: string;
};
export type MailingUpdateApiResponse = /** status 200 Информация о рассылке */ Mailings;
export type MailingUpdateApiArg = {
  /** Идентификатор */
  mailingId: string;
  mailingDto: MailingDto;
};
export type MailingPatchApiResponse = /** status 200 Информация о рассылке */ Mailings;
export type MailingPatchApiArg = {
  /** Идентификатор */
  mailingId: string;
  mailingPartialDto: MailingPartialDto;
};
export type MailingResetApiResponse = /** status 200 Информация о рассылке */ Mailings;
export type MailingResetApiArg = {
  /** Идентификатор */
  mailingId: string;
};
export type MailingActivateApiResponse = /** status 200 Информация о рассылке */ Mailings;
export type MailingActivateApiArg = {
  /** Идентификатор */
  mailingId: string;
};
export type MailingDisactivateApiResponse = /** status 200 Информация о рассылке */ Mailings;
export type MailingDisactivateApiArg = {
  /** Идентификатор */
  mailingId: string;
};
export type MailingsReportsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: MailingsReports[];
};
export type MailingsReportsIndexApiArg = {
  /** Идентификатор */
  mailingId: string;
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  populates?: ('user' | 'userLogin')[];
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type CollectorsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Collectors[];
};
export type CollectorsIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type CollectorsAddApiResponse = /** status 200 Информация о коллекторе */ Collectors;
export type CollectorsAddApiArg = {
  collectorDto: CollectorDto;
};
export type CollectorsChangePositionsApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Collectors[];
};
export type CollectorsChangePositionsApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  collectorsPositions: CollectorsPositions;
};
export type CollectorIndexApiResponse = /** status 200 Информация о коллекторе */ Collectors;
export type CollectorIndexApiArg = {
  /** Идентификатор */
  collectorId: string;
};
export type CollectorUpdateApiResponse = /** status 200 Информация о коллекторе */ Collectors;
export type CollectorUpdateApiArg = {
  /** Идентификатор */
  collectorId: string;
  collectorDto: CollectorDto;
};
export type CollectorKeysIndexApiResponse = /** status 200 Список ключей */ CollectorsKeys[];
export type CollectorKeysIndexApiArg = {
  /** Идентификатор */
  collectorId: string;
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type CollectorKeysAddApiResponse = /** status 200 Список ключей */ CollectorsKeys[];
export type CollectorKeysAddApiArg = {
  /** Идентификатор */
  collectorId: string;
  collectorKeyDto: CollectorKeyDto;
};
export type CollectorKeysChangePositionsApiResponse = /** status 200 Список ключей */ CollectorsKeys[];
export type CollectorKeysChangePositionsApiArg = {
  /** Идентификатор */
  collectorId: string;
  collectorKeysPositions: CollectorKeysPositions;
};
export type CollectorKeyIndexApiResponse = /** status 200 Информация о варианте */ CollectorsKeys;
export type CollectorKeyIndexApiArg = {
  /** Идентификатор */
  collectorId: string;
  /** Идентификатор */
  keyId: string;
};
export type CollectorKeyUpdateApiResponse = /** status 200 Информация о варианте */ CollectorsKeys;
export type CollectorKeyUpdateApiArg = {
  /** Идентификатор */
  collectorId: string;
  /** Идентификатор */
  keyId: string;
  collectorKeyDto: CollectorKeyDto;
};
export type GoalsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Goals[];
};
export type GoalsIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  populates?: ('user' | 'files' | 'goalType' | 'reward' | 'tagsSet')[];
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type GoalIndexApiResponse = /** status 200 Информация по цели */ Goals;
export type GoalIndexApiArg = {
  /** Идентификатор */
  goalId: string;
};
export type GoalReportsApiResponse = /** status 200 Список отчетов */ GoalsReports[];
export type GoalReportsApiArg = {
  /** Идентификатор */
  goalId: string;
};
export type GoalsTypesIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: GoalsTypes[];
};
export type GoalsTypesIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  populates?: 'reward'[];
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type GoalsTypesAddApiResponse = /** status 200 Информация о типе цели */ GoalsTypes;
export type GoalsTypesAddApiArg = {
  goalTypeDto: GoalTypeDto;
};
export type GoalsTypeIndexApiResponse = /** status 200 Информация о типе цели */ GoalsTypes;
export type GoalsTypeIndexApiArg = {
  /** Идентификатор */
  typeId: string;
};
export type GoalsTypeUpdateApiResponse = /** status 200 Информация о типе цели */ GoalsTypes;
export type GoalsTypeUpdateApiArg = {
  /** Идентификатор */
  typeId: string;
  goalTypeDto: GoalTypeDto;
};
export type GoalsTypePatchApiResponse = /** status 200 Информация о типе цели */ GoalsTypes;
export type GoalsTypePatchApiArg = {
  /** Идентификатор */
  typeId: string;
  goalTypePartialDto: GoalTypePartialDto;
};
export type BlocksIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Blocks[];
};
export type BlocksIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type BlocksAddApiResponse = /** status 200 Информация о блоке */ Blocks;
export type BlocksAddApiArg = {
  blockDto: BlockDto;
};
export type BlockIndexApiResponse = /** status 200 Информация о блоке */ Blocks;
export type BlockIndexApiArg = {
  /** Идентификатор */
  blockId: string;
};
export type BlockUpdateApiResponse = /** status 200 Информация о блоке */ Blocks;
export type BlockUpdateApiArg = {
  /** Идентификатор */
  blockId: string;
  blockDto: BlockDto;
};
export type BlockPatchApiResponse = /** status 200 Информация о блоке */ Blocks;
export type BlockPatchApiArg = {
  /** Идентификатор */
  blockId: string;
  blockPartialDto: BlockPartialDto;
};
export type BlockTasksIndexApiResponse = /** status 200 Список задач в блоке */ BlocksTasks[];
export type BlockTasksIndexApiArg = {
  /** Идентификатор */
  blockId: string;
};
export type BlockTasksTasksOrderApiResponse = /** status 200 Список задач в блоке */ BlocksTasks[];
export type BlockTasksTasksOrderApiArg = {
  /** Идентификатор */
  blockId: string;
  body: {
    tasksId: string[];
  };
};
export type BlockTaskInfoApiResponse = /** status 200 Информация о задаче в блоке */ BlocksTasks;
export type BlockTaskInfoApiArg = {
  /** Идентификатор */
  blockId: string;
  /** Идентификатор */
  taskId: string;
};
export type BlockTaskAddApiResponse = /** status 200 Информация о задаче в блоке */ BlocksTasks;
export type BlockTaskAddApiArg = {
  /** Идентификатор */
  blockId: string;
  /** Идентификатор */
  taskId: string;
  blockTaskDto: BlockTaskDto;
};
export type BlockTaskDeleteTaskApiResponse = /** status 200 Список задач в блоке */ BlocksTasks[];
export type BlockTaskDeleteTaskApiArg = {
  /** Идентификатор */
  blockId: string;
  /** Идентификатор */
  taskId: string;
};
export type TasksIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Tasks[];
};
export type TasksIndexApiArg = {
  /** Принадлежность категории */
  categoryId?: string;
  /** Признак активности */
  enabled?: boolean;
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  populates?: ('category' | 'isViewed' | 'timelines')[];
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type TasksAddApiResponse = /** status 200 Информация о задаче */ Tasks;
export type TasksAddApiArg = {
  taskDto: TaskDto;
};
export type TasksStatsDynamicApiResponse = /** status 200 Статистика по динамике задачи */ JobsStatsDto;
export type TasksStatsDynamicApiArg = {
  /** ID задач */
  taskId: string[];
  /** ID таймлайнов */
  timelineId?: string[];
  /** ID пользователей */
  userId?: string[];
  /** ID потока */
  streamId?: string[];
  /** ID группы */
  groupId?: string[];
  /** Масштаб */
  scale: 'day' | 'week' | 'month' | 'year';
  /** Начало периода */
  startDate?: string | string;
  /** Окончание периода */
  finishDate?: string | string;
};
export type TasksStatsStatusSummaryApiResponse = /** status 200 Сводка по статусам задач */ JobsStatusSummary[];
export type TasksStatsStatusSummaryApiArg = {
  /** ID задач */
  taskId: string[];
  /** ID таймлайнов */
  timelineId?: string[];
  /** ID пользователей */
  userId?: string[];
  /** ID потока */
  streamId?: string[];
  /** ID группы */
  groupId?: string[];
};
export type TasksStatsStatusUsersApiResponse = /** status 200 Задачи по пользователям */ JobsUsersStatusSummary[];
export type TasksStatsStatusUsersApiArg = {
  /** ID задач */
  taskId: string[];
  /** ID таймлайнов */
  timelineId?: string[];
  /** ID пользователей */
  userId?: string[];
  /** ID потока */
  streamId?: string[];
  /** ID группы */
  groupId?: string[];
};
export type TasksCategoriesIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: TasksCategories[];
};
export type TasksCategoriesIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  /** Признак активности */
  enabled?: boolean;
};
export type TasksCategoriesAddApiResponse = /** status 200 Информация о категории */ TasksCategories;
export type TasksCategoriesAddApiArg = {
  taskCategoryDto: TaskCategoryDto;
};
export type TasksCategoriesChangePositionsApiResponse = /** status 200  */ MessageResponse;
export type TasksCategoriesChangePositionsApiArg = {
  taskCategoriesPositions: TaskCategoriesPositions;
};
export type TasksCategoryIndexApiResponse = /** status 200 Информация о категории */ TasksCategories;
export type TasksCategoryIndexApiArg = {
  /** Идентификатор */
  categoryId: string;
};
export type TasksCategoryUpdateApiResponse = /** status 200 Информация о категории */ TasksCategories;
export type TasksCategoryUpdateApiArg = {
  /** Идентификатор */
  categoryId: string;
  taskCategoryDto: TaskCategoryDto;
};
export type TasksCategoryPatchApiResponse = /** status 200 Информация о категории */ TasksCategories;
export type TasksCategoryPatchApiArg = {
  /** Идентификатор */
  categoryId: string;
  taskCategoryPartialDto: TaskCategoryPartialDto;
};
export type TaskIndexApiResponse = /** status 200 Задача */ Tasks;
export type TaskIndexApiArg = {
  /** Идентификатор */
  taskId: string;
};
export type TaskUpdateApiResponse = /** status 200 Задача */ Tasks;
export type TaskUpdateApiArg = {
  /** Идентификатор */
  taskId: string;
  taskDto: TaskDto;
};
export type TaskPatchApiResponse = /** status 200 Задача */ Tasks;
export type TaskPatchApiArg = {
  /** Идентификатор */
  taskId: string;
  taskPartialDto: TaskPartialDto;
};
export type TaskTimelinesIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Timelines[];
};
export type TaskTimelinesIndexApiArg = {
  /** Идентификатор */
  taskId: string;
};
export type TaskTimelinesAddApiResponse = /** status 200 Информация о параметре доступа */ Timelines;
export type TaskTimelinesAddApiArg = {
  /** Идентификатор */
  taskId: string;
  taskTimelineDto: TaskTimelineDto;
};
export type TaskTimelineElemIndexApiResponse = /** status 200 Информация о параметре доступа */ Timelines;
export type TaskTimelineElemIndexApiArg = {
  /** Идентификатор */
  taskId: string;
  /** Идентификатор */
  timelineId: string;
};
export type TaskTimelineElemUpdateApiResponse = /** status 200 Информация о параметре доступа */ Timelines;
export type TaskTimelineElemUpdateApiArg = {
  /** Идентификатор */
  taskId: string;
  /** Идентификатор */
  timelineId: string;
  taskTimelineDto: TaskTimelineDto;
};
export type TaskTimelineElemPatchApiResponse = /** status 200 Информация о параметре доступа */ Timelines;
export type TaskTimelineElemPatchApiArg = {
  /** Идентификатор */
  taskId: string;
  /** Идентификатор */
  timelineId: string;
  taskTimelinePartialDto: TaskTimelinePartialDto;
};
export type TaskTimelineElemDeleteApiResponse = /** status 200 Сообщение об успешном удалении */ MessageResponse;
export type TaskTimelineElemDeleteApiArg = {
  /** Идентификатор */
  taskId: string;
  /** Идентификатор */
  timelineId: string;
};
export type ScriptsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Scripts[];
};
export type ScriptsIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  populates?: ('content' | 'stream')[];
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type ScriptsAddApiResponse = /** status 200 Информация о сценарии */ Scripts;
export type ScriptsAddApiArg = {
  scriptDto: ScriptDto;
};
export type ScriptIndexApiResponse = /** status 200 Информация о сценарии */ Scripts;
export type ScriptIndexApiArg = {
  /** Идентификатор */
  scriptId: string;
};
export type ScriptUpdateApiResponse = /** status 200 Информация о сценарии */ Scripts;
export type ScriptUpdateApiArg = {
  /** Идентификатор */
  scriptId: string;
  scriptDto: ScriptDto;
};
export type ScriptPatchApiResponse = /** status 200 Информация о сценарии */ Scripts;
export type ScriptPatchApiArg = {
  /** Идентификатор */
  scriptId: string;
  scriptPartialDto: ScriptPartialDto;
};
export type ScriptLinksApiResponse = /** status 200 Список связей */ ScriptsLinks[];
export type ScriptLinksApiArg = {
  /** Идентификатор */
  scriptId: string;
};
export type ScriptGroupsIndexApiResponse = /** status 200 Список групп */ ScriptsGroups[];
export type ScriptGroupsIndexApiArg = {
  /** Идентификатор */
  scriptId: string;
};
export type ScriptGroupsAddApiResponse = /** status 200 Информация о группе */ ScriptsGroups;
export type ScriptGroupsAddApiArg = {
  /** Идентификатор */
  scriptId: string;
  scriptGroupDto: ScriptGroupDto;
};
export type ScriptGroupIndexApiResponse = /** status 200 Информация о группе */ ScriptsGroups;
export type ScriptGroupIndexApiArg = {
  /** Идентификатор */
  scriptId: string;
  /** Идентификатор */
  groupId: string;
};
export type ScriptGroupUpdateApiResponse = /** status 200 Информация о группе */ ScriptsGroups;
export type ScriptGroupUpdateApiArg = {
  /** Идентификатор */
  scriptId: string;
  /** Идентификатор */
  groupId: string;
  scriptGroupDto: ScriptGroupDto;
};
export type ScriptGroupPatchApiResponse = /** status 200 Информация о группе */ ScriptsGroups;
export type ScriptGroupPatchApiArg = {
  /** Идентификатор */
  scriptId: string;
  /** Идентификатор */
  groupId: string;
  scriptGroupPartialDto: ScriptGroupPartialDto;
};
export type ScriptActionsIndexApiResponse = /** status 200 Список действий */ ScriptsActions[];
export type ScriptActionsIndexApiArg = {
  /** Идентификатор */
  scriptId: string;
  /** Идентификатор */
  groupId: string;
  populates?: ('scriptGroup' | 'template')[];
};
export type ScriptActionsAddApiResponse = /** status 200 Список действий */ ScriptsActions[];
export type ScriptActionsAddApiArg = {
  /** Идентификатор */
  scriptId: string;
  /** Идентификатор */
  groupId: string;
  scriptActionDto: ScriptActionDto;
};
export type ScriptActionsChangePositionsApiResponse = /** status 200 Список действий */ ScriptsActions[];
export type ScriptActionsChangePositionsApiArg = {
  /** Идентификатор */
  scriptId: string;
  /** Идентификатор */
  groupId: string;
  body: {
    actionsId: string[];
  };
};
export type ScriptActionIndexApiResponse = /** status 200 Информация о действии */ ScriptsActions;
export type ScriptActionIndexApiArg = {
  /** Идентификатор */
  scriptId: string;
  /** Идентификатор */
  groupId: string;
  /** Идентификатор */
  actionId: string;
};
export type ScriptActionUpdateApiResponse = /** status 200 Информация о действии */ ScriptsActions;
export type ScriptActionUpdateApiArg = {
  /** Идентификатор */
  scriptId: string;
  /** Идентификатор */
  groupId: string;
  /** Идентификатор */
  actionId: string;
  scriptActionDto: ScriptActionDto;
};
export type ScriptActionPatchApiResponse = /** status 200 Информация о действии */ ScriptsActions;
export type ScriptActionPatchApiArg = {
  /** Идентификатор */
  scriptId: string;
  /** Идентификатор */
  groupId: string;
  /** Идентификатор */
  actionId: string;
  scriptActionPartialDto: ScriptActionPartialDto;
};
export type ScriptLinksIndexApiResponse = /** status 200 Список связей */ ScriptsLinks[];
export type ScriptLinksIndexApiArg = {
  /** Идентификатор */
  scriptId: string;
  /** Идентификатор */
  groupId: string;
  /** Идентификатор */
  actionId: string;
};
export type ScriptLinksAddApiResponse = /** status 200 Список связей */ ScriptsLinks[];
export type ScriptLinksAddApiArg = {
  /** Идентификатор */
  scriptId: string;
  /** Идентификатор */
  groupId: string;
  /** Идентификатор */
  actionId: string;
  scriptLinkDto: ScriptLinkDto;
};
export type ScriptLinkIndexApiResponse = /** status 200 Информация о действии */ ScriptsLinks;
export type ScriptLinkIndexApiArg = {
  /** Идентификатор */
  scriptId: string;
  /** Идентификатор */
  groupId: string;
  /** Идентификатор */
  actionId: string;
  /** Идентификатор */
  linkId: string;
};
export type ScriptLinkUpdateApiResponse = /** status 200 Информация о действии */ ScriptsLinks;
export type ScriptLinkUpdateApiArg = {
  /** Идентификатор */
  scriptId: string;
  /** Идентификатор */
  groupId: string;
  /** Идентификатор */
  actionId: string;
  /** Идентификатор */
  linkId: string;
  scriptLinkDto: ScriptLinkDto;
};
export type ScriptLinkPatchApiResponse = /** status 200 Информация о действии */ ScriptsLinks;
export type ScriptLinkPatchApiArg = {
  /** Идентификатор */
  scriptId: string;
  /** Идентификатор */
  groupId: string;
  /** Идентификатор */
  actionId: string;
  /** Идентификатор */
  linkId: string;
  scriptLinkPartialDto: ScriptLinkPartialDto;
};
export type ScriptLinkDeleteApiResponse = /** status 200  */ MessageResponse;
export type ScriptLinkDeleteApiArg = {
  /** Идентификатор */
  scriptId: string;
  /** Идентификатор */
  groupId: string;
  /** Идентификатор */
  actionId: string;
  /** Идентификатор */
  linkId: string;
};
export type TimecodesIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Timecodes[];
};
export type TimecodesIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  /** Сортировка */
  sort: 'ASC' | 'DESC';
  /** Статус активности */
  enabled?: boolean;
  /** _id файла */
  fileId?: string;
};
export type TimecodesAddApiResponse = /** status 200 Информация о таймкоде */ Timecodes;
export type TimecodesAddApiArg = {
  timecodeDto: TimecodeDto;
};
export type TimecodeIndexApiResponse = /** status 200 Информация о таймкоде */ Timecodes;
export type TimecodeIndexApiArg = {
  /** Идентификатор */
  timecodeId: string;
};
export type TimecodeUpdateApiResponse = /** status 200 Информация о таймкоде */ Timecodes;
export type TimecodeUpdateApiArg = {
  /** Идентификатор */
  timecodeId: string;
  timecodeDto: TimecodeDto;
};
export type TimecodePatchApiResponse = /** status 200 Информация о таймкоде */ Timecodes;
export type TimecodePatchApiArg = {
  /** Идентификатор */
  timecodeId: string;
  timecodePartialDto: TimecodePartialDto;
};
export type TemplatesIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Templates[];
};
export type TemplatesIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type TemplatesAddApiResponse = /** status 200 Информация о шаблоне */ Templates;
export type TemplatesAddApiArg = {
  templateDto: TemplateDto;
};
export type TemplateIndexApiResponse = /** status 200 Информация о шаблоне */ Templates;
export type TemplateIndexApiArg = {
  /** Идентификатор */
  templateId: string;
};
export type TemplateUpdateApiResponse = /** status 200 Информация о шаблоне */ Templates;
export type TemplateUpdateApiArg = {
  /** Идентификатор */
  templateId: string;
  templateDto: TemplateDto;
};
export type TemplatePatchApiResponse = /** status 200 Информация о шаблоне */ Templates;
export type TemplatePatchApiArg = {
  /** Идентификатор */
  templateId: string;
  templatePartialDto: TemplatePartialDto;
};
export type TemplateOptionsIndexApiResponse = /** status 200 Список вариантов */ TemplatesOptions[];
export type TemplateOptionsIndexApiArg = {
  /** Идентификатор */
  templateId: string;
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type TemplateOptionsAddApiResponse = /** status 200 Список вариантов */ TemplatesOptions[];
export type TemplateOptionsAddApiArg = {
  /** Идентификатор */
  templateId: string;
  templateOptionDto: TemplateOptionDto;
};
export type TemplateOptionsChangePositionsApiResponse = /** status 200 Список вариантов */ TemplatesOptions[];
export type TemplateOptionsChangePositionsApiArg = {
  /** Идентификатор */
  templateId: string;
  templateOptionsPositions: TemplateOptionsPositions;
};
export type TemplateOptionIndexApiResponse = /** status 200 Информация о варианте */ TemplatesOptions;
export type TemplateOptionIndexApiArg = {
  /** Идентификатор */
  templateId: string;
  /** Идентификатор */
  optionId: string;
};
export type TemplateOptionUpdateApiResponse = /** status 200 Информация о варианте */ TemplatesOptions;
export type TemplateOptionUpdateApiArg = {
  /** Идентификатор */
  templateId: string;
  /** Идентификатор */
  optionId: string;
  templateOptionDto: TemplateOptionDto;
};
export type TemplateOptionPatchApiResponse = /** status 200 Информация о варианте */ TemplatesOptions;
export type TemplateOptionPatchApiArg = {
  /** Идентификатор */
  templateId: string;
  /** Идентификатор */
  optionId: string;
  templateOptionPartialDto: TemplateOptionPartialDto;
};
export type ContentsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Contents[];
};
export type ContentsIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  populates?: ('category' | 'file' | 'tagsSet' | 'isViewed')[];
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  /** ID тегов */
  tagsId?: string[];
  /** Признак активности */
  enabled?: boolean;
  /** Id категории */
  categoryId?: string;
  /** Системный материал */
  isSystem?: boolean;
};
export type ContentsAddApiResponse = /** status 200 Информация о материале */ Contents;
export type ContentsAddApiArg = {
  contentDto: ContentDto;
};
export type ContentsCategoriesIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: ContentsCategories[];
};
export type ContentsCategoriesIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  populates?: 'file'[];
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  /** Признак активности */
  enabled?: boolean;
};
export type ContentsCategoriesAddApiResponse = /** status 200 Информация о варианте */ ContentsCategories;
export type ContentsCategoriesAddApiArg = {
  contentCategoryDto: ContentCategoryDto;
};
export type ContentsCategoriesChangePositionsApiResponse = /** status 200  */ MessageResponse;
export type ContentsCategoriesChangePositionsApiArg = {
  contentCategoriesPositions: ContentCategoriesPositions;
};
export type ContentsCategoryIndexApiResponse = /** status 200 Информация о варианте */ ContentsCategories;
export type ContentsCategoryIndexApiArg = {
  /** Идентификатор */
  categoryId: string;
};
export type ContentsCategoryUpdateApiResponse = /** status 200 Информация о варианте */ ContentsCategories;
export type ContentsCategoryUpdateApiArg = {
  /** Идентификатор */
  categoryId: string;
  contentCategoryDto: ContentCategoryDto;
};
export type ContentsCategoryPatchApiResponse = /** status 200 Информация о варианте */ ContentsCategories;
export type ContentsCategoryPatchApiArg = {
  /** Идентификатор */
  categoryId: string;
  contentCategoryPartialDto: ContentCategoryPartialDto;
};
export type NotionPageIndexApiResponse = /** status 200 Результат */ object;
export type NotionPageIndexApiArg = {
  /** ID страницы */
  pageId: string;
};
export type ContentIndexApiResponse = /** status 200 Информация о материале */ Contents;
export type ContentIndexApiArg = {
  /** Идентификатор */
  contentId: string;
};
export type ContentNotifyUserApiResponse = /** status 200 Информация о материале */ Contents;
export type ContentNotifyUserApiArg = {
  /** Идентификатор */
  contentId: string;
};
export type ContentUpdateApiResponse = /** status 200 Информация о материале */ Contents;
export type ContentUpdateApiArg = {
  /** Идентификатор */
  contentId: string;
  contentDto: ContentDto;
};
export type ContentPatchApiResponse = /** status 200 Информация о материале */ Contents;
export type ContentPatchApiArg = {
  /** Идентификатор */
  contentId: string;
  contentPartialDto: ContentPartialDto;
};
export type ContentTagsIndexApiResponse = /** status 200 Список тегов */ Tags[];
export type ContentTagsIndexApiArg = {
  /** Идентификатор */
  contentId: string;
};
export type ContentTagsSetApiResponse = /** status 200 Список тегов */ Tags[];
export type ContentTagsSetApiArg = {
  /** Идентификатор */
  contentId: string;
  body: {
    tagsId: string[];
  };
};
export type TagsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Tags[];
};
export type TagsIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  populates?: 'taggedCount'[];
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  /** Название тега */
  name?: string;
  /** Тип тега */
  type?: 'default' | 'personal_quality' | 'hashtag' | 'quest' | 'publication';
};
export type TagsAddApiResponse = /** status 200 Информация о теге */ Tags;
export type TagsAddApiArg = {
  tagDto: TagDto;
};
export type TagIndexApiResponse = /** status 200 Информация о теге */ Tags;
export type TagIndexApiArg = {
  /** Идентификатор */
  tagId: string;
};
export type TagUpdateApiResponse = /** status 200 Информация о теге */ Tags;
export type TagUpdateApiArg = {
  /** Идентификатор */
  tagId: string;
  tagDto: TagDto;
};
export type TagPatchApiResponse = /** status 200 Информация о теге */ Tags;
export type TagPatchApiArg = {
  /** Идентификатор */
  tagId: string;
  tagPartialDto: TagPartialDto;
};
export type TagSetsApiResponse = unknown;
export type TagSetsApiArg = {
  /** Идентификатор */
  tagId: string;
};
export type PublicationSafeDelete1ApiResponse = unknown;
export type PublicationSafeDelete1ApiArg = {
  /** Идентификатор */
  postId: string;
};
export type PublicationSafeRestore1ApiResponse = unknown;
export type PublicationSafeRestore1ApiArg = {
  /** Идентификатор */
  postId: string;
};
export type CommentSafeDeleteApiResponse = unknown;
export type CommentSafeDeleteApiArg = {
  /** Идентификатор */
  commentId: string;
};
export type CommentSafeRestoreApiResponse = unknown;
export type CommentSafeRestoreApiArg = {
  /** Идентификатор */
  commentId: string;
};
export type RewardsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Rewards[];
};
export type RewardsIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  /** Тип награды */
  type?: 'default' | 'currency' | 'experience' | 'quality' | 'ability' | 'achivement' | 'reward' | 'prize';
  /** Статус активности */
  enabled?: boolean;
};
export type RewardsAddApiResponse = /** status 200 Информация о вознаграждении */ Rewards;
export type RewardsAddApiArg = {
  rewardDto: RewardDto;
};
export type RewardIndexApiResponse = /** status 200 Информация о вознаграждении */ Rewards;
export type RewardIndexApiArg = {
  /** Идентификатор */
  rewardId: string;
};
export type RewardUpdateApiResponse = /** status 200 Информация о вознаграждении */ Rewards;
export type RewardUpdateApiArg = {
  /** Идентификатор */
  rewardId: string;
  rewardDto: RewardDto;
};
export type RewardPatchApiResponse = /** status 200 Информация о вознаграждении */ Rewards;
export type RewardPatchApiArg = {
  /** Идентификатор */
  rewardId: string;
  rewardPartialDto: RewardPartialDto;
};
export type UsersIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Users[];
};
export type UsersIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  populates?: ('userLogin' | 'logins' | 'groups' | 'meIsSubscriber' | 'isMySubscriber' | 'file')[];
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  /** ID потоков */
  streamId?: string[];
  /** ID групп */
  groupId?: string[];
  /** Логин или имя пользователя */
  username?: string;
  /** Телефон */
  phone?: string;
  /** Email */
  email?: string;
  /** ID невыполненных заданий */
  incompleteTaskId?: string[];
  /** ID непринятых заданий */
  inactiveTaskId?: string[];
  /** Доступных в таймлайне */
  timelineId?: string;
  /** Не сделали ни одной задачи */
  noneOfTasks?: boolean;
  /** Не завершили ни одной задачи */
  noneOfCompleteTasks?: boolean;
  /** Ни разу не авторизовывались */
  unauthorized?: boolean;
};
export type UsersAddApiResponse = /** status 200 Информация о пользователе */ Users;
export type UsersAddApiArg = {
  userDto: UserDto;
};
export type UsersCheckLoginsApiResponse = /** status 200 Логин корректен */ MessageResponse;
export type UsersCheckLoginsApiArg = {
  /** Данные логина */
  userLoginCheckDto: UserLoginCheckDto;
};
export type UserIndexApiResponse = /** status 200 Информация о пользователе */ Users;
export type UserIndexApiArg = {
  /** Идентификатор */
  userId: string;
};
export type UserUpdateApiResponse = /** status 200 Информация о пользователе */ Users;
export type UserUpdateApiArg = {
  /** Идентификатор */
  userId: string;
  /** Данные пользователя */
  userDto: UserDto;
};
export type UserPatchApiResponse = /** status 200 Информация о пользователе */ Users;
export type UserPatchApiArg = {
  /** Идентификатор */
  userId: string;
  /** Данные пользователя */
  userPartialDto: UserPartialDto;
};
export type UserDeleteApiResponse = /** status 200  */ MessageResponse;
export type UserDeleteApiArg = {
  /** Идентификатор */
  userId: string;
};
export type UserRestoreApiResponse = /** status 200  */ MessageResponse;
export type UserRestoreApiArg = {
  /** Идентификатор */
  userId: string;
};
export type UserTagsIndexApiResponse = /** status 200 Список тегов */ Tags[];
export type UserTagsIndexApiArg = {
  /** Идентификатор */
  userId: string;
};
export type UserTagsSetApiResponse = /** status 200 Список тегов */ Tags[];
export type UserTagsSetApiArg = {
  /** Идентификатор */
  userId: string;
  body: {
    tagsId: string[];
  };
};
export type UserPhotosIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: UsersPhotos[];
};
export type UserPhotosIndexApiArg = {
  /** Идентификатор */
  userId: string;
  populates?: ('user' | 'file' | 'croppedFile')[];
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type UserPhotosAddApiResponse = /** status 200 Фотография */ UsersPhotos;
export type UserPhotosAddApiArg = {
  /** Идентификатор */
  userId: string;
  userPhotoDto: UserPhotoDto;
};
export type UserPhotoIndexApiResponse = /** status 200 Информация о фото */ UsersPhotos;
export type UserPhotoIndexApiArg = {
  /** Идентификатор */
  userId: string;
  /** Идентификатор */
  photoId: string;
};
export type UserPhotoUpdateApiResponse = /** status 200 Информация о фото */ UsersPhotos;
export type UserPhotoUpdateApiArg = {
  /** Идентификатор */
  userId: string;
  /** Идентификатор */
  photoId: string;
  userPhotoPatchDto: UserPhotoPatchDto;
};
export type UserPhotoDeleteApiResponse = /** status 200  */ MessageResponse;
export type UserPhotoDeleteApiArg = {
  /** Идентификатор */
  userId: string;
  /** Идентификатор */
  photoId: string;
};
export type UserPermissionsIndexApiResponse = /** status 200 Полномочия пользователя */ UsersPermissions;
export type UserPermissionsIndexApiArg = {
  /** Идентификатор */
  userId: string;
};
export type UserPermissionsUpdateApiResponse = /** status 200 Полномочия пользователя */ UsersPermissions;
export type UserPermissionsUpdateApiArg = {
  /** Идентификатор */
  userId: string;
  userPermissionDto: UserPermissionDto;
};
export type UserLoginsIndexApiResponse = /** status 200 Список логинов */ {
  total?: number;
  message?: string;
  data?: UsersLogins;
}[];
export type UserLoginsIndexApiArg = {
  /** Идентификатор */
  userId: string;
};
export type UserLoginsAddApiResponse = /** status 200 Информация о логине */ UsersLogins;
export type UserLoginsAddApiArg = {
  /** Идентификатор */
  userId: string;
  /** Логин */
  usersLogins: UsersLogins;
};
export type UserLoginInviteApiResponse = /** status 200 Успешная отправка */ {
  data?: object;
  message?: string;
};
export type UserLoginInviteApiArg = {
  /** Идентификатор */
  userId: string;
  loginId: string;
  userInviteDto: UserInviteDto;
};
export type UserLoginIndexApiResponse = /** status 200 Логин */ UsersLogins;
export type UserLoginIndexApiArg = {
  /** Идентификатор */
  userId: string;
  loginId: string;
};
export type UserLoginUpdateApiResponse = /** status 200 Логин */ UsersLogins;
export type UserLoginUpdateApiArg = {
  /** Идентификатор */
  userId: string;
  loginId: string;
  /** Логин */
  usersLogins: UsersLogins;
};
export type UserLoginDeleteApiResponse = /** status 200 Логин удален */ MessageResponse;
export type UserLoginDeleteApiArg = {
  /** Идентификатор */
  userId: string;
  loginId: string;
};
export type UserLoginPasswordApiResponse = /** status 200 Пароль установлен */ MessageResponse;
export type UserLoginPasswordApiArg = {
  /** Идентификатор */
  userId: string;
  loginId: string;
  /** Пароль и подтверждение */
  body: {
    password: string;
    passwordConfirm: string;
  };
};
export type GroupsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Groups[];
};
export type GroupsIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  populates?: ('file' | 'subGroups' | 'stream' | 'membersCount')[];
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type GroupsAddApiResponse = /** status 200 Информация о группе */ Groups;
export type GroupsAddApiArg = {
  groupDto: GroupDto;
};
export type GroupsAccessIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: GroupsAccess[];
};
export type GroupsAccessIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type GroupsAccessAddApiResponse = /** status 200 Информация о роли */ GroupsAccess;
export type GroupsAccessAddApiArg = {
  groupAccessDto: GroupAccessDto;
};
export type GroupAccessElemIndexApiResponse = /** status 200 Информация о роли */ GroupsAccess;
export type GroupAccessElemIndexApiArg = {
  /** Идентификатор */
  accessId: string;
};
export type GroupAccessElemUpdateApiResponse = /** status 200 Информация о роли */ GroupsAccess;
export type GroupAccessElemUpdateApiArg = {
  /** Идентификатор */
  accessId: string;
  groupAccessDto: GroupAccessDto;
};
export type GroupAccessElemPatchApiResponse = /** status 200 Информация о роли */ GroupsAccess;
export type GroupAccessElemPatchApiArg = {
  /** Идентификатор */
  accessId: string;
  groupAccessPartialDto: GroupAccessPartialDto;
};
export type GroupIndexApiResponse = /** status 200 Информация о группе */ Groups;
export type GroupIndexApiArg = {
  /** Идентификатор */
  groupId: string;
};
export type GroupUpdateApiResponse = /** status 200 Информация о группе */ Groups;
export type GroupUpdateApiArg = {
  /** Идентификатор */
  groupId: string;
  groupDto: GroupDto;
};
export type GroupPatchApiResponse = /** status 200 Информация о группе */ Groups;
export type GroupPatchApiArg = {
  /** Идентификатор */
  groupId: string;
  groupPartialDto: GroupPartialDto;
};
export type GroupUsersApiResponse = /** status 200 Пользователи в группе */ GroupsUsers[];
export type GroupUsersApiArg = {
  /** Идентификатор */
  groupId: string;
};
export type GroupAddUsersApiResponse = /** status 200 Пользователи в группе */ GroupsUsers[];
export type GroupAddUsersApiArg = {
  /** Идентификатор */
  groupId: string;
  body: {
    usersId: string[];
    accessId?: string[];
  };
};
export type GroupUpdateUserApiResponse = /** status 200 Пользователи в группе */ GroupsUsers[];
export type GroupUpdateUserApiArg = {
  /** Идентификатор */
  groupId: string;
  /** Идентификатор */
  userId: string;
  body: {
    accessId?: string[];
  };
};
export type GroupDeleteUserApiResponse = /** status 200 Пользователи в группе */ GroupsUsers[];
export type GroupDeleteUserApiArg = {
  /** Идентификатор */
  groupId: string;
  /** Идентификатор */
  userId: string;
};
export type GroupSetLeaderApiResponse = /** status 200 Пользователи в группе */ GroupsUsers[];
export type GroupSetLeaderApiArg = {
  /** Идентификатор */
  groupId: string;
  /** Идентификатор */
  userId: string;
};
export type GroupSetLimitApiResponse = /** status 200 Информация о группе */ Groups;
export type GroupSetLimitApiArg = {
  /** Идентификатор */
  groupId: string;
  setLimitDto: SetLimitDto;
};
export type StreamsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Streams[];
};
export type StreamsIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type StreamsAddApiResponse = /** status 200 Информация о потоке */ Streams;
export type StreamsAddApiArg = {
  streamDto: StreamDto;
};
export type StreamsAccessIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: StreamsAccess[];
};
export type StreamsAccessIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type StreamsAccessAddApiResponse = /** status 200 Информация о роли */ StreamsAccess;
export type StreamsAccessAddApiArg = {
  streamAccessDto: StreamAccessDto;
};
export type StreamAccessElemIndexApiResponse = /** status 200 Информация о роли */ StreamsAccess;
export type StreamAccessElemIndexApiArg = {
  /** Идентификатор */
  accessId: string;
};
export type StreamAccessElemPatchApiResponse = /** status 200 Информация о роли */ StreamsAccess;
export type StreamAccessElemPatchApiArg = {
  /** Идентификатор */
  accessId: string;
  streamAccessPartialDto: StreamAccessPartialDto;
};
export type StreamIndexApiResponse = /** status 200 Информация о потоке */ Streams;
export type StreamIndexApiArg = {
  /** Идентификатор */
  streamId: string;
};
export type StreamUpdateApiResponse = /** status 200 Информация о потоке */ Streams;
export type StreamUpdateApiArg = {
  /** Идентификатор */
  streamId: string;
  streamDto: StreamDto;
};
export type StreamPatchApiResponse = /** status 200 Информация о потоке */ Streams;
export type StreamPatchApiArg = {
  /** Идентификатор */
  streamId: string;
  streamPartialDto: StreamPartialDto;
};
export type StreamUsersApiResponse = /** status 200 Пользователи в потоке */ StreamsUsers[];
export type StreamUsersApiArg = {
  /** Идентификатор */
  streamId: string;
};
export type StreamAddUsersApiResponse = /** status 200 Пользователи в потоке */ StreamsUsers[];
export type StreamAddUsersApiArg = {
  /** Идентификатор */
  streamId: string;
  body: {
    usersId: string[];
    accessId?: string[];
  };
};
export type StreamUpdateUserApiResponse = /** status 200 Пользователи в потоке */ StreamsUsers[];
export type StreamUpdateUserApiArg = {
  /** Идентификатор */
  streamId: string;
  /** Идентификатор */
  userId: string;
  body: {
    accessId?: string[];
  };
};
export type StreamDeleteUserApiResponse = /** status 200 Пользователи в потоке */ StreamsUsers[];
export type StreamDeleteUserApiArg = {
  /** Идентификатор */
  streamId: string;
  /** Идентификатор */
  userId: string;
};
export type StreamImportsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: StreamsImports[];
};
export type StreamImportsIndexApiArg = {
  /** Идентификатор */
  streamId: string;
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type StreamImportsUploadApiResponse = /** status 200 Информация об импорте */ StreamsImports;
export type StreamImportsUploadApiArg = {
  /** Идентификатор */
  streamId: string;
  /** xlsx file */
  body: {
    file?: Blob;
  };
};
export type StreamImportIndexApiResponse = /** status 200 Информация об импорте */ StreamsImports;
export type StreamImportIndexApiArg = {
  /** Идентификатор */
  streamId: string;
  /** Идентификатор */
  importId: string;
};
export type StreamImportRunImportApiResponse = /** status 200 Результат обработки */ {
  success: StreamsImportsElem[];
  skipped: StreamsImportsElem[];
  failed: StreamsImportsElem[];
};
export type StreamImportRunImportApiArg = {
  /** Идентификатор */
  streamId: string;
  /** Идентификатор */
  importId: string;
};
export type StreamImportDeleteApiResponse = /** status 200  */ MessageResponse;
export type StreamImportDeleteApiArg = {
  /** Идентификатор */
  streamId: string;
  /** Идентификатор */
  importId: string;
};
export type SettingsIndexApiResponse = /** status 200 Настройки */ Settings;
export type SettingsIndexApiArg = void;
export type SettingsUpdateApiResponse = /** status 200 Настройки */ Settings;
export type SettingsUpdateApiArg = {
  settingsDto: SettingsDto;
};
export type SettingsPatchApiResponse = /** status 200 Настройки */ Settings;
export type SettingsPatchApiArg = {
  settingsPartialDto: SettingsPartialDto;
};
export type ProfileIndexApiResponse = /** status 200 Информация о пользователе */ Users;
export type ProfileIndexApiArg = void;
export type ProfileUpdateApiResponse = /** status 200 Информация о пользователе */ Users;
export type ProfileUpdateApiArg = {
  /** Данные пользователя */
  userDto: UserDto;
};
export type ProfileLoginsApiResponse = unknown;
export type ProfileLoginsApiArg = void;
export type ProfileChangePasswordApiResponse = /** status 200 Пароль установлен */ MessageResponse;
export type ProfileChangePasswordApiArg = {
  /** id логина */
  loginId: string;
  /** Пароль и подтверждение */
  body: {
    password: string;
    passwordConfirm: string;
  };
};
export type AchievementsAddApiResponse = /** status 200 Информация о достижении */ AchievementsData;
export type AchievementsAddApiArg = {
  achievementsDto: AchievementsDto;
};
export type AccessIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  message?: string;
  data?: Access[];
};
export type AccessIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type AccessAddApiResponse = /** status 200 Информация о роли */ Access;
export type AccessAddApiArg = {
  accessDto: AccessDto;
};
export type AccessElemIndexApiResponse = /** status 200 Информация о роли */ Access;
export type AccessElemIndexApiArg = {
  /** Идентификатор */
  accessId: string;
};
export type AccessElemUpdateApiResponse = /** status 200 Информация о роли */ Access;
export type AccessElemUpdateApiArg = {
  /** Идентификатор */
  accessId: string;
  accessDto: AccessDto;
};
export type AccessElemPatchApiResponse = /** status 200 Информация о роли */ Access;
export type AccessElemPatchApiArg = {
  /** Идентификатор */
  accessId: string;
  accessPartialDto: AccessPartialDto;
};
export type EnvEnvApiResponse = unknown;
export type EnvEnvApiArg = void;
export type AccessPoint = {
  method?: string;
  path?: string;
  prefix?: string;
};
export type RouteElement = {
  AccessAdminControl?: AccessPoint[];
  method: string;
  path: string;
  operationId: string;
};
export type UsersMetaInfo = {
  onboardingCompleted?: boolean;
  onboardingStepsCompleted?: number | null;
  metaRequest?: string;
  metaInsight?: string;
};
export type UsersLogins = {
  userId?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
  type: 'plain' | 'phone' | 'email';
  value: string;
};
export type Files = {
  name: string;
  path: string;
  type: string;
  size: number;
  location?: string;
  s3?: boolean;
  blurhash?: string;
  s3Status?: 'falied' | 'received' | 'converting' | 'converted';
  height?: number;
  width?: number;
  relationType?: 'preview' | 'jpg' | 'crop';
  relationParams?: string;
  relationId?: string;
  userId?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type Streams = {
  name: string;
  description?: string;
  defaultQuestId?: string;
  defaultAdventureId?: string;
  hidden?: boolean;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type GroupsStats = {
  subscribersCount: number;
  teammatesCount: number;
  goalsCount: number;
  goalsCompletedCount: number;
  publicationsCount: number;
  scoresCount: number;
  scoresPercent: number;
  likesRecievedCount: number;
  likesPercent: number;
  NPS: number;
  NPSRating: number;
  totalRating: number;
  updatedDate: string | string;
};
export type GroupsRatingPosition = {
  byNPS: number;
  totalPlaces: number;
  byTotal: number;
  bySummary: number;
  byMoney: number;
  byActivity: number;
  byGoals: number;
  updatedDate: string | string;
};
export type Groups = {
  ident?: number;
  name: string;
  short_description?: string;
  description?: string;
  externalLink?: string;
  fileId?: string;
  file?: Files;
  streamId?: string;
  subGroupsId?: string[];
  subGroups?: Groups[];
  stream?: Streams;
  requestsIsActive: boolean;
  membersLimit?: number;
  isDismissed?: boolean;
  dismissReason?: string;
  membersCount?: number;
  stats?: GroupsStats;
  ratingPosition?: GroupsRatingPosition;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type GroupsUsers = {
  title?: string;
  userId: string;
  user?: Users;
  groupId: string;
  group?: Groups;
  isLeader?: boolean;
  joinDate: string | string;
  exitDate: string | string;
  exitReason?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type UsersStatus = {
  isOnline: number;
  lastSeen: number;
  updatedDate: string | string;
};
export type UsersStats = {
  subscribersCount: number;
  subscribesCount: number;
  teammatesCount: number;
  goalsCount: number;
  goalsCompletedCount: number;
  commentsSentCount: number;
  commentsRecievedCount: number;
  tasksCount: number;
  jobsCount: number;
  jobsCompletedCount: number;
  publicationsCount: number;
  groupsId: string[];
  groups: Groups[];
  streamsId: string[];
  streams: Groups[];
  scoresCount: number;
  scoresPercent: number;
  likesRecievedCount: number;
  likesSentCount: number;
  reactionsSentCount: number;
  reactionsRecievedCount: number;
  likesPercent: number;
  NPS: number;
  NPSRating: number;
  totalRating: number;
  experience: number;
  experiencePower: number;
  updatedDate: string | string;
};
export type NotificationRestrict = {
  restrict: (
    | 'like'
    | 'score'
    | 'comment'
    | 'reaction'
    | 'score_reply'
    | 'comment_reply'
    | 'new_subscription'
    | 'new_task'
    | 'new_content'
    | 'new_quest'
    | 'new_achievement'
    | 'group_new_leader'
    | 'group_new_member'
    | 'group_leave_member'
    | 'group_new_request'
    | 'group_new_invite'
    | 'active_module'
    | 'active_module_last_call'
    | 'active_module_deadline'
    | 'active_task'
    | 'active_task_last_call'
    | 'active_task_deadline'
  )[];
};
export type UsersRatingPosition = {
  byNPS: number;
  byExperience: number;
  totalPlaces: number;
  byTotal: number;
  byMoney: number;
  byMoneyMonth?: number;
  byMoneyQuarter?: number;
  financialResult?: number;
  financialResultMonth?: number;
  financialResultQuarter?: number;
  financialGoal?: number;
  financialGoalMonth?: number;
  financialGoalQuarter?: number;
  byActivity?: number;
  byActivityMonth?: number;
  byActivityQuarter?: number;
  byGoals?: number;
  byGoalsMonth?: number;
  byGoalsQuarter?: number;
  totalGoals?: number;
  totalGoalsMonth?: number;
  totalGoalsQuarter?: number;
  updatedDate: string | string;
};
export type ClaimsStats = {
  count?: number;
  updatedDate?: string | string;
  isClaimed?: boolean;
  claimedDate?: string | string;
};
export type Users = {
  ident?: number;
  name: string;
  surname?: string;
  middlename?: string;
  disclaimer?: string;
  birthDate?: string | string;
  description?: string;
  gender?: 'male' | 'female' | 'not_sure';
  relationStatus?:
    | 'single'
    | 'in_relation'
    | 'engaged'
    | 'married'
    | 'civil_union'
    | 'in_love'
    | 'complicated'
    | 'in_search';
  city?: string;
  externalLink?: string;
  fileId?: string;
  backgroundFileId?: string | null;
  metaInfo?: UsersMetaInfo | null;
  userLogin?: UsersLogins;
  logins?: UsersLogins[];
  groups?: GroupsUsers[];
  meIsSubscriber?: number;
  isMySubscriber?: number;
  file?: Files;
  status?: UsersStatus;
  stats?: UsersStats;
  notificationsSettings?: NotificationRestrict;
  ratingPosition?: UsersRatingPosition;
  claims?: ClaimsStats;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type ErrorMessage = {
  status: number;
  message: string;
  data?: object;
};
export type Widgets = {
  name: string;
  type: 'table' | 'chart' | 'diagram' | 'radar' | 'pie_chart' | 'slider' | 'progress_bar';
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type WidgetsFormulas = {
  name: string;
  widgetId: string;
  widget: Widgets;
  formula: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type ValidationErrorSchema = {
  target?: object;
  property: string;
  value?: any;
  children?: object[];
  constraints: object;
};
export type WidgetFormulaDto = {
  name: string;
  widgetId: string;
  formula: string;
  deletedAt?: string;
  enabled: boolean;
};
export type WidgetFormulaPartialDto = {
  name?: string;
  widgetId?: string;
  widget?: Widgets;
  formula?: string;
  deletedAt?: string;
  enabled?: boolean;
};
export type WidgetDto = {
  name: string;
  type: 'table' | 'chart' | 'diagram' | 'radar' | 'pie_chart' | 'slider' | 'progress_bar';
  deletedAt?: string;
  enabled: boolean;
};
export type WidgetPartialDto = {
  name?: string;
  type?: 'table' | 'chart' | 'diagram' | 'radar' | 'pie_chart' | 'slider' | 'progress_bar';
  deletedAt?: string;
  enabled?: boolean;
};
export type WidgetsRules = {
  name?: string;
  widgetId?: string;
  sourceAttrId: string;
  targetAttrId: string;
  rule: 'eq' | 'lte' | 'gte';
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type WidgetRuleDto = {
  name?: string;
  widgetId?: string;
  sourceAttrId: string;
  targetAttrId: string;
  rule: 'eq' | 'lte' | 'gte';
  deletedAt?: string;
};
export type WidgetRulePartialDto = {
  name?: string;
  widgetId?: string;
  sourceAttrId?: string;
  targetAttrId?: string;
  rule?: 'eq' | 'lte' | 'gte';
  deletedAt?: string;
};
export type RewardsValues = {
  rewardId: string;
  value: number;
};
export type TemplatesOptionsSettings = {
  isRequired?: boolean;
  minValue?: string;
  maxValue?: string;
  mediaMaxLength?: number;
  iconSymbol?: string;
  iconFileId?: string;
  questId?: string;
  questLookupType?: 'random' | 'currentUser';
  questVisualContainerName?: string;
  scoreGrades?: string;
  rewardsTypes?: ('default' | 'currency' | 'experience' | 'quality' | 'ability' | 'achivement' | 'reward' | 'prize')[];
  rewardId?: string;
};
export type TemplatesOptions = {
  name: string;
  description?: string;
  templateId?: string;
  positionNumber?: number;
  type?:
    | 'string'
    | 'text'
    | 'number'
    | 'score'
    | 'date'
    | 'checkbox'
    | 'radio'
    | 'select'
    | 'multi_select'
    | 'file'
    | 'photo'
    | 'video'
    | 'audio'
    | 'goal'
    | 'custom'
    | 'user'
    | 'reward'
    | 'stage';
  rewardValues?: RewardsValues[];
  settings?: TemplatesOptionsSettings;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type Templates = {
  name: string;
  description?: string;
  type:
    | 'string'
    | 'text'
    | 'number'
    | 'score'
    | 'date'
    | 'checkbox'
    | 'radio'
    | 'select'
    | 'multi_select'
    | 'file'
    | 'photo'
    | 'video'
    | 'audio'
    | 'goal'
    | 'custom'
    | 'user'
    | 'reward'
    | 'stage';
  scriptId?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type WidgetsAttrs = {
  name: string;
  widgetId?: string;
  templateId: string;
  templateOptionId?: string;
  placeholder?: string;
  templateOption?: TemplatesOptions;
  template?: Templates;
  formula: 'first_value' | 'last_value' | 'list' | 'sum' | 'avg' | 'min' | 'max' | 'increment' | 'delta';
  positionNumber?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type WidgetAttrDto = {
  name: string;
  widgetId?: string;
  templateId: string;
  templateOptionId?: string;
  placeholder?: string;
  templateOption?: TemplatesOptions;
  template?: Templates;
  formula: 'first_value' | 'last_value' | 'list' | 'sum' | 'avg' | 'min' | 'max' | 'increment' | 'delta';
  positionNumber?: number;
  deletedAt?: string;
};
export type WidgetAttrsPositions = {
  attrsId: string[];
};
export type WidgetAttrPartialDto = {
  name?: string;
  widgetId?: string;
  templateId?: string;
  templateOptionId?: string;
  placeholder?: string;
  templateOption?: TemplatesOptions;
  template?: Templates;
  formula?: 'first_value' | 'last_value' | 'list' | 'sum' | 'avg' | 'min' | 'max' | 'increment' | 'delta';
  positionNumber?: number;
  deletedAt?: string;
};
export type WidgetsVisualsDesigns = {
  visualId?: string;
  visualPropId?: string;
  name?: string;
  customJSONString?: string;
  positionNumber?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type WidgetVisualsOptionsSlider = {
  aspectRatio?: '1:1' | '16:9' | '4:5' | '4:3';
};
export type WidgetVisualsOptionsChart = any;
export type WidgetsVisualsOptions = {
  slider?: WidgetVisualsOptionsSlider;
  chart?: WidgetVisualsOptionsChart;
};
export type WidgetsVisualsProps = {
  widgetId: string;
  widgetAttrId: string;
  widget?: Widgets;
  widgetAttr?: WidgetsAttrs;
  options?: WidgetsVisualsOptions;
  custom?: object;
  positionNumber?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type WidgetsVisuals = {
  widgetsId: string[];
  name: string;
  containerName: string;
  customJSONString?: string;
  designs?: WidgetsVisualsDesigns[];
  props?: WidgetsVisualsProps[];
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type WidgetVisualDto = {
  widgetsId: string[];
  name: string;
  containerName: string;
  customJSONString?: string;
  designs?: WidgetsVisualsDesigns[];
  props?: WidgetsVisualsProps[];
  deletedAt?: string;
};
export type WidgetVisualPartialDto = {
  widgetsId?: string[];
  name?: string;
  containerName?: string;
  customJSONString?: string;
  designs?: WidgetsVisualsDesigns[];
  props?: WidgetsVisualsProps[];
  deletedAt?: string;
};
export type WidgetVisualDesignDto = {
  visualId?: string;
  visualPropId?: string;
  name?: string;
  customJSONString?: string;
  positionNumber?: number;
  deletedAt?: string;
};
export type IDs = {
  _id: string[];
};
export type WidgetVisualDesignPartialDto = {
  visualId?: string;
  visualPropId?: string;
  name?: string;
  customJSONString?: string;
  positionNumber?: number;
  deletedAt?: string;
};
export type WidgetVisualPropDto = {
  widgetId: string;
  widgetAttrId: string;
  widget?: Widgets;
  widgetAttr?: WidgetsAttrs;
  options?: WidgetsVisualsOptions;
  custom?: object;
  positionNumber?: number;
  deletedAt?: string;
};
export type WidgetVisualPropsPositions = {
  propsId: string[];
};
export type WidgetVisualPropPartialDto = {
  widgetId?: string;
  widgetAttrId?: string;
  widget?: Widgets;
  widgetAttr?: WidgetsAttrs;
  options?: WidgetsVisualsOptions;
  custom?: object;
  positionNumber?: number;
  deletedAt?: string;
};
export type Receipts = {
  userId: string;
  paymentId: string;
  receiptServiceId: string;
  value: number;
  url?: string;
  type?: 'buy_reward';
  status: 'new' | 'ready' | 'process' | 'success' | 'error';
  serviceType: 'kit_online' | 'cloud_kassir';
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type ReceiptsServices = {
  name: string;
  description?: string;
  type: 'kit_online' | 'cloud_kassir';
  settings?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type ReceiptsServiceDto = {
  name: string;
  description?: string;
  type: 'kit_online' | 'cloud_kassir';
  settings?: string;
  enabled: boolean;
};
export type ReceiptsServicePartialDto = {
  name?: string;
  description?: string;
  type?: 'kit_online' | 'cloud_kassir';
  settings?: string;
  enabled?: boolean;
};
export type PaymentsProcessing = {
  params?: object;
  request?: object;
  hook?: object;
  error?: object;
  result?: object;
};
export type PaymentsResult = {
  paymentUrl?: string;
  error?: string;
};
export type Payments = {
  userId: string;
  paymentServiceId: string;
  paymentTokenId?: string;
  stagePointId: string;
  description: string;
  value: number;
  currencyName: string;
  currencyCode: string;
  processing: PaymentsProcessing;
  result: PaymentsResult;
  status: 'new' | 'wait_payment' | 'paid' | 'declined' | 'returned' | 'error';
  isSubscription: boolean;
  receipts?: Receipts[] | null;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type PaymentsServices = {
  name: string;
  description?: string;
  fileId?: string | null;
  receiptServiceId?: string | null;
  type: 'yoo_kassa' | 'tinkoff_kassa' | 'paypal' | 'stripe' | 'bluesnap' | 'crypto' | 'manual';
  settings?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type PaymentsDynamicDto = {
  partnerServiceId: string;
  dataAttr: string | string;
  currencyName: string;
  count: number;
  value: number;
};
export type PaymentsStatsDto = {
  services: PaymentsServices[];
  dynamic: PaymentsDynamicDto[];
};
export type PaymentsStatusSummary = {
  status: 'new' | 'wait_payment' | 'paid' | 'declined' | 'returned' | 'error';
  currencyName: string;
  count: number;
  value: number;
};
export type PaymentsServiceDto = {
  name: string;
  description?: string;
  fileId?: string | null;
  receiptServiceId?: string | null;
  type: 'yoo_kassa' | 'tinkoff_kassa' | 'paypal' | 'stripe' | 'bluesnap' | 'crypto' | 'manual';
  settings?: string;
  enabled: boolean;
};
export type PaymentsServicePartialDto = {
  name?: string;
  description?: string;
  fileId?: string | null;
  receiptServiceId?: string | null;
  type?: 'yoo_kassa' | 'tinkoff_kassa' | 'paypal' | 'stripe' | 'bluesnap' | 'crypto' | 'manual';
  settings?: string;
  enabled?: boolean;
};
export type RewardsCurrency = {
  code: string | null;
  isoNumber: number | null;
};
export type Rewards = {
  name: string;
  currency?: RewardsCurrency | null;
  description?: string;
  symbol?: string;
  backgroundColor?: string | null;
  fileId?: string | null;
  inactiveFileId?: string | null;
  type: 'default' | 'currency' | 'experience' | 'quality' | 'ability' | 'achivement' | 'reward' | 'prize';
  expKoef?: number;
  isSystem: boolean;
  isPublic: boolean;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type ReactionsBlocks = {
  name: string;
  allowedReferences: ('Comments' | 'StagesPoints' | 'Publications' | 'Tasks' | 'Contents' | 'Goals' | 'Messages')[];
  rewardValues: RewardsValues[];
  rewards: Rewards[];
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type ReactionsBlockDto = {
  name: string;
  allowedReferences: ('Comments' | 'StagesPoints' | 'Publications' | 'Tasks' | 'Contents' | 'Goals' | 'Messages')[];
  rewardValues: RewardsValues[];
  enabled: boolean;
};
export type ReactionsBlockPartialDto = {
  name?: string;
  allowedReferences?: ('Comments' | 'StagesPoints' | 'Publications' | 'Tasks' | 'Contents' | 'Goals' | 'Messages')[];
  rewardValues?: RewardsValues[];
  enabled?: boolean;
};
export type Blockers = {
  name: string;
  message: string;
  streamsId?: string[];
  groupsId?: string[];
  usersId?: string[];
  rewardsId?: string[];
  isNotIn?: boolean;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type BlockerDto = {
  name: string;
  message: string;
  streamsId?: string[];
  groupsId?: string[];
  usersId?: string[];
  rewardsId?: string[];
  isNotIn?: boolean;
  deletedAt?: string;
};
export type BlockerPartialDto = {
  name?: string;
  message?: string;
  streamsId?: string[];
  groupsId?: string[];
  usersId?: string[];
  rewardsId?: string[];
  isNotIn?: boolean;
  deletedAt?: string;
};
export type MessageResponse = {
  message?: string;
};
export type ReactionsStats = {
  reactionsId: string[];
  rewardValues: RewardsValues[];
};
export type StagesPoints = {
  stageId: string;
  userId: string;
  user?: Users;
  questPointId: string;
  timelineId: string;
  referenceName?:
    | 'Jobs'
    | 'Goals'
    | 'Users'
    | 'Stages'
    | 'Publications'
    | 'Timelines'
    | 'Contents'
    | 'Payments'
    | 'WidgetsValues';
  referenceId?: string;
  status: 'new' | 'in_progress' | 'checking' | 'ready' | 'completed' | 'deadline' | 'failed' | 'rejected';
  reactions?: ReactionsStats;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type StagePointPartialDto = {
  status: 'new' | 'in_progress' | 'checking' | 'ready' | 'completed' | 'deadline' | 'failed' | 'rejected';
};
export type Links = {
  title: string;
  url: string;
  isRecommended?: boolean;
};
export type QuestDesign = {
  header?: string;
  hint?: string;
  rejectTitle?: string;
  acceptTitle?: string;
  confirmTitle?: string;
  fileId?: string | null;
  backgroundFileId?: string | null;
  successImageFileId?: string | null;
  backgroundColor?: string | null;
  notionPageId?: string;
  completedText?: string;
  failedText?: string;
  estimatedText?: string;
  deadlineText?: string;
};
export type Tags = {
  name: string;
  type: 'default' | 'personal_quality' | 'hashtag' | 'quest' | 'publication';
  taggedCount?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type TagsSets = {
  tagId: string;
  tag?: Tags;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type AccessBase = {
  isActive: boolean;
  usersId?: string[];
  usersNotIn?: boolean;
  groupsId?: string[];
  groupsNotIn?: boolean;
  streamsId?: string[];
  streamsNotIn?: boolean;
  rewardsId?: string[];
  rewardsNotIn?: boolean;
};
export type Timelines = {
  referenceName: 'Tasks' | 'Quests' | 'QuestsPoints';
  referenceId: string;
  dateStart?: (string | string) | null;
  dateFinish?: (string | string) | null;
  streamsId?: string[];
  groupsId?: string[];
  usersId?: string[];
  questsId?: string[];
  questsPointsId?: string[];
  rewardsId?: string[];
  isNotIn?: boolean;
  access: AccessBase;
  isAllowedAfterFinishDate?: boolean;
  changeStatusOnProgressReadyOrComplete?: boolean;
  totalMatch?: boolean;
  userId?: string;
  status?: 'active' | 'building' | 'checking' | 'closed';
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type ScoresStats = {
  average?: number;
  total?: number;
  count?: number;
  NPS?: number;
  usersId?: string[];
  topUsersId?: string[];
  topUsers?: Users[];
  scoresId?: string[];
  values?: number[];
  updatedDate?: string | string;
};
export type Scores = {
  value: number;
  userId?: string;
  user?: Users;
  toUserId?: string;
  score?: ScoresStats;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type LikesStats = {
  count?: number;
  usersId?: string[];
  topUsersId?: string[];
  topUsers?: Users[];
  updatedDate?: string | string;
};
export type Comments = {
  content: string;
  userId?: string;
  user?: Users;
  commentId?: string;
  branchCommentId?: string;
  scoreId?: string;
  scoreInfo?: Scores;
  referenceName: 'Contents' | 'Users' | 'Goals' | 'Publications' | 'Jobs' | 'Tasks' | 'Quests';
  referenceId: string;
  subCommentsCount?: number;
  branchCommentsCount?: number;
  score?: ScoresStats;
  likes?: LikesStats;
  claims?: ClaimsStats;
  reactions?: ReactionsStats;
  isViewed?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type CommentsStats = {
  count?: number;
  lastCommentsId?: string[];
  lastComments?: Comments[];
  topCommentsId?: string[];
  topComments?: Comments[];
  updatedDate?: string | string;
};
export type Quests = {
  name: string;
  description?: string;
  content?: string;
  contentsId?: string[];
  links?: Links[];
  design?: QuestDesign;
  type:
    | 'default'
    | 'season'
    | 'meeting'
    | 'event'
    | 'point'
    | 'block'
    | 'adventure'
    | 'challenge'
    | 'lesson'
    | 'goal'
    | 'goal_report';
  status: 'draft' | 'active' | 'archived';
  parentQuestId?: string;
  isPattern?: boolean;
  allowReject?: boolean;
  userId?: string;
  isViewed?: number;
  tagsSet?: TagsSets[];
  timelines?: Timelines[];
  score?: ScoresStats;
  comments?: CommentsStats;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type QuestDto = {
  name: string;
  description?: string;
  content?: string;
  contentsId?: string[];
  design?: QuestDesign;
  type:
    | 'default'
    | 'season'
    | 'meeting'
    | 'event'
    | 'point'
    | 'block'
    | 'adventure'
    | 'challenge'
    | 'lesson'
    | 'goal'
    | 'goal_report';
  status: 'draft' | 'active' | 'archived';
  parentQuestId?: string;
  isPattern?: boolean;
  allowReject?: boolean;
  enabled: boolean;
};
export type StagesDynamicDto = {
  status: 'new' | 'in_progress' | 'checking' | 'ready' | 'completed' | 'deadline' | 'failed' | 'rejected';
  questId?: string;
  userId?: string;
  date: string | string;
  count: number;
};
export type StagesStatsDto = {
  dynamic: StagesDynamicDto[];
};
export type StagesStatusSummary = {
  status: 'new' | 'in_progress' | 'checking' | 'ready' | 'completed' | 'deadline' | 'failed' | 'rejected';
  questId: string;
  count: number;
};
export type StagesUsersStatusSummary = {
  userId: string;
  user: Users;
  status: 'new' | 'in_progress' | 'checking' | 'ready' | 'completed' | 'deadline' | 'failed' | 'rejected';
  questId: string;
  count: number;
};
export type QuestsPointOptionsDesign = {
  title?: string | null;
  description?: string | null;
  fileId?: string | null;
  bgFileId?: string | null;
  color?: string | null;
  borderColor?: string | null;
  containerName?: string | null;
  customPropsJSONString?: string | null;
};
export type QuestsPointOptionsTriggers = {
  isDeadline: boolean;
  isLocal: boolean;
  requiredStagePointStatus?:
    | 'new'
    | 'in_progress'
    | 'checking'
    | 'ready'
    | 'completed'
    | 'deadline'
    | 'failed'
    | 'rejected';
  setStageStatus?: 'new' | 'in_progress' | 'checking' | 'ready' | 'completed' | 'deadline' | 'failed' | 'rejected';
};
export type QuestsPointOptionsButtons = {
  title: string | null;
  confirmText?: string | null;
  fileId?: string | null;
  bgFileId?: string | null;
  color?: string | null;
  variant?: string | null;
  alwaysOnTop?: boolean | null;
};
export type QuestsPointOptionsGoals = {
  goalTypeId?: string | null;
  completeRequired: boolean;
};
export type QuestsPointOptionsPayments = {
  rewardId: string;
  paymentServicesTypes: ('yoo_kassa' | 'tinkoff_kassa' | 'paypal' | 'stripe' | 'bluesnap' | 'crypto' | 'manual')[];
  savePaymentData: boolean;
  successMessage?: string | null;
  errorMessage?: string | null;
};
export type QuestsPointOptionsQuests = {
  questId?: string;
  questsId?: string[];
};
export type QuestsPointOptionsTimelines = {
  access: AccessBase;
  privacy?: ('private' | 'groups' | 'subscribers' | 'groups_subscribers' | 'public') | null;
  isRequiredDeadline?: boolean;
  isBroadcast?: boolean;
  isNewQuest?: boolean;
  autoSuccess?: boolean;
  completeLimit?: number | null;
  successOffset?: number | null;
  allowRestart?: boolean;
  activationText?: string;
  сloseTimelineOnFinish?: boolean;
};
export type QuestsPointOptionsSubscriptions = {
  isActive?: boolean;
  autoStart?: boolean;
  emitPeriod: number;
  emitInterval: 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year';
  strictDate?: boolean;
  emitTime?: any;
  emitLock?: boolean;
  deadlinePeriod: number;
  deadlineInterval: 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year';
  completeLimit?: number | null;
  successOffset?: number | null;
  questId?: string;
  questsId?: string[];
};
export type QuestsPointOptionsTasks = {
  taskId?: string;
};
export type QuestsPointOptionsPublications = {
  videoRequired?: boolean;
  tagsId?: string[];
  contentRequired?: boolean;
};
export type QuestsPointOptionsContents = {
  contentId?: string;
};
export type QuestsPointOptionsWidgets = {
  widgetId?: string;
  widgetsId?: string[];
  visualsId?: string[];
};
export type QuestsPointOptions = {
  isRequired: boolean;
  autoAccept: boolean;
  notInProgress?: boolean;
  showPositionNumber?: boolean;
  design?: QuestsPointOptionsDesign | null;
  triggers?: QuestsPointOptionsTriggers | null;
  buttons?: QuestsPointOptionsButtons | null;
  goals?: QuestsPointOptionsGoals | null;
  payments?: QuestsPointOptionsPayments | null;
  quests?: QuestsPointOptionsQuests | null;
  timelines?: QuestsPointOptionsTimelines | null;
  subscriptions?: QuestsPointOptionsSubscriptions | null;
  tasks?: QuestsPointOptionsTasks | null;
  publications?: QuestsPointOptionsPublications | null;
  contents?: QuestsPointOptionsContents | null;
  widgets?: QuestsPointOptionsWidgets | null;
};
export type QuestsPoints = {
  questId?: string;
  name?: string;
  type:
    | 'quest'
    | 'timeline'
    | 'task'
    | 'goal'
    | 'publication'
    | 'content'
    | 'button'
    | 'shortcut'
    | 'payment'
    | 'widget';
  positionNumber?: number;
  options: QuestsPointOptions;
  timelines?: Timelines[];
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type QuestPointDto = {
  name?: string;
  type:
    | 'quest'
    | 'timeline'
    | 'task'
    | 'goal'
    | 'publication'
    | 'content'
    | 'button'
    | 'shortcut'
    | 'payment'
    | 'widget';
  options: QuestsPointOptions;
  enabled: boolean;
};
export type QuestPointPartialDto = {
  name?: string;
  type?:
    | 'quest'
    | 'timeline'
    | 'task'
    | 'goal'
    | 'publication'
    | 'content'
    | 'button'
    | 'shortcut'
    | 'payment'
    | 'widget';
  options?: QuestsPointOptions;
  enabled?: boolean;
};
export type QuestsPointsRewards = {
  questPointId: string;
  rewardValue: RewardsValues;
  reward?: Rewards;
  paymentCost?: number;
  limits?: number;
  description?: string;
  stagePointStatus?: 'new' | 'in_progress' | 'checking' | 'ready' | 'completed' | 'deadline' | 'failed' | 'rejected';
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type QuestPointRewardDto = {
  rewardValue: RewardsValues;
  reward?: Rewards;
  paymentCost?: number;
  limits?: number;
  description?: string;
  stagePointStatus?: 'new' | 'in_progress' | 'checking' | 'ready' | 'completed' | 'deadline' | 'failed' | 'rejected';
  deletedAt?: string;
  enabled: boolean;
};
export type QuestPointRewardPartialDto = {
  rewardValue?: RewardsValues;
  reward?: Rewards;
  paymentCost?: number;
  limits?: number;
  description?: string;
  stagePointStatus?: 'new' | 'in_progress' | 'checking' | 'ready' | 'completed' | 'deadline' | 'failed' | 'rejected';
  deletedAt?: string;
  enabled?: boolean;
};
export type QuestPointTimelineDto = {
  dateStart?: (string | string) | null;
  dateFinish?: (string | string) | null;
  streamsId?: string[];
  groupsId?: string[];
  usersId?: string[];
  questsId?: string[];
  questsPointsId?: string[];
  rewardsId?: string[];
  isNotIn?: boolean;
  access: AccessBase;
  isAllowedAfterFinishDate?: boolean;
  changeStatusOnProgressReadyOrComplete?: boolean;
  totalMatch?: boolean;
  userId?: string;
  status?: 'active' | 'building' | 'checking' | 'closed';
  deletedAt?: string;
};
export type QuestPointTimelinePartialDto = {
  dateStart?: (string | string) | null;
  dateFinish?: (string | string) | null;
  streamsId?: string[];
  groupsId?: string[];
  usersId?: string[];
  questsId?: string[];
  questsPointsId?: string[];
  rewardsId?: string[];
  isNotIn?: boolean;
  access?: AccessBase;
  isAllowedAfterFinishDate?: boolean;
  changeStatusOnProgressReadyOrComplete?: boolean;
  totalMatch?: boolean;
  userId?: string;
  status?: 'active' | 'building' | 'checking' | 'closed';
  deletedAt?: string;
};
export type QuestPartialDto = {
  name?: string;
  description?: string;
  content?: string;
  contentsId?: string[];
  design?: QuestDesign;
  type?:
    | 'default'
    | 'season'
    | 'meeting'
    | 'event'
    | 'point'
    | 'block'
    | 'adventure'
    | 'challenge'
    | 'lesson'
    | 'goal'
    | 'goal_report';
  status?: 'draft' | 'active' | 'archived';
  parentQuestId?: string;
  isPattern?: boolean;
  allowReject?: boolean;
  enabled?: boolean;
};
export type QuestDeleteDataDto = {
  usersId?: string[] | null;
  timelineId?: string | null;
  groupsId?: string[] | null;
  streamsId?: string[] | null;
};
export type QuestTimelineDto = {
  dateStart?: (string | string) | null;
  dateFinish?: (string | string) | null;
  streamsId?: string[];
  groupsId?: string[];
  usersId?: string[];
  questsId?: string[];
  questsPointsId?: string[];
  rewardsId?: string[];
  isNotIn?: boolean;
  access: AccessBase;
  isAllowedAfterFinishDate?: boolean;
  changeStatusOnProgressReadyOrComplete?: boolean;
  totalMatch?: boolean;
  userId?: string;
  status?: 'active' | 'building' | 'checking' | 'closed';
  deletedAt?: string;
};
export type QuestTimelinePartialDto = {
  dateStart?: (string | string) | null;
  dateFinish?: (string | string) | null;
  streamsId?: string[];
  groupsId?: string[];
  usersId?: string[];
  questsId?: string[];
  questsPointsId?: string[];
  rewardsId?: string[];
  isNotIn?: boolean;
  access?: AccessBase;
  isAllowedAfterFinishDate?: boolean;
  changeStatusOnProgressReadyOrComplete?: boolean;
  totalMatch?: boolean;
  userId?: string;
  status?: 'active' | 'building' | 'checking' | 'closed';
  deletedAt?: string;
};
export type ClaimsDataDto = {
  referenceName: 'Users' | 'Goals' | 'GoalsReports' | 'Contents' | 'Tasks' | 'Publications' | 'Jobs' | 'Comments';
  referenceId: string;
  count: number;
  lastDate: string | string;
};
export type Claims = {
  description: string;
  filesId?: string[];
  result?: string;
  status?: 'new' | 'approved' | 'rejected';
  userId?: string;
  user?: Users;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type ClaimDto = {
  result?: string;
  status?: 'new' | 'approved' | 'rejected';
};
export type ClaimPartialDto = {
  result?: string;
  status?: 'new' | 'approved' | 'rejected';
};
export type MailingsParams = {
  streamsId?: string[];
  groupsId?: string[];
  usersId?: string[];
  loginType: 'plain' | 'phone' | 'email';
};
export type Mailings = {
  name: string;
  message: string;
  params: MailingsParams;
  status: 'draft' | 'active' | 'in_progress' | 'completed';
  type: 'default' | 'UsersInvites';
  startDate?: (string | string) | null;
  executionDate?: string | string;
  finishDate?: string | string;
  reportsCount?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type MailingDto = {
  name: string;
  message: string;
  params: MailingsParams;
  type: 'default' | 'UsersInvites';
  startDate?: (string | string) | null;
};
export type MailingPartialDto = {
  name?: string;
  message?: string;
  params?: MailingsParams;
  type?: 'default' | 'UsersInvites';
  startDate?: (string | string) | null;
};
export type MailingsReports = {
  mailingId: string;
  userId: string;
  user?: Users;
  userLoginId: string;
  userLogin?: UsersLogins;
  message: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type Collectors = {
  code: string;
  name: string;
  type: 'index' | 'profile' | 'widget';
  positionNumber?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type CollectorDto = {
  code: string;
  name: string;
  type: 'index' | 'profile' | 'widget';
  positionNumber?: number;
  deletedAt?: string;
  enabled: boolean;
};
export type CollectorsPositions = {
  collectorsId: string[];
};
export type CollectorsKeys = {
  collectorId?: string;
  templateOptionId: string;
  name?: string;
  positionNumber?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type CollectorKeyDto = {
  collectorId?: string;
  templateOptionId: string;
  name?: string;
  positionNumber?: number;
  deletedAt?: string;
  enabled: boolean;
};
export type CollectorKeysPositions = {
  collectorKeysId: string[];
};
export type GoalsTypes = {
  name: string;
  description?: string;
  rewardId?: string;
  rewardAsAnalog?: boolean;
  reward?: Rewards;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type GoalsSchedules = {
  isEnabled: boolean;
  actionsCount?: number;
  actionsPeriods?: 'total' | 'per_week' | 'per_month' | 'per_year';
};
export type GoalsProgress = {
  total: number;
  count: number;
  percentage: number;
};
export type Goals = {
  name: string;
  description?: string;
  originGoalId?: string;
  userId?: string;
  user?: Users;
  filesId: string[];
  filesAspectRatio: '1:1' | '16:9' | '4:5' | '4:3';
  files?: Files[];
  goalTypeId: string;
  goalType?: GoalsTypes;
  rewardValue?: RewardsValues;
  schedule?: GoalsSchedules;
  reward?: Rewards;
  planFinishDate: (string | string) | null;
  realStartDate?: (string | string) | null;
  realFinishDate?: (string | string) | null;
  status?: ('draft' | 'new' | 'completed' | 'archived' | 'failed') | null;
  privacy: 'private' | 'groups' | 'subscribers' | 'groups_subscribers' | 'public';
  progress?: GoalsProgress;
  jobId?: string;
  tagsSet?: TagsSets[];
  score?: ScoresStats;
  likes?: LikesStats;
  comments?: CommentsStats;
  claims?: ClaimsStats;
  reactions?: ReactionsStats;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type PublicationsAttachments = {
  referenceName: 'GoalsReports' | 'Jobs' | 'Stages' | 'Achievements';
  referenceId: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type Publications = {
  title?: string;
  content?: string;
  videoLink?: string;
  filesId?: string[];
  files?: Files[];
  filesAspectRatio: '1:1' | '16:9' | '4:5' | '4:3';
  userId?: string;
  user?: Users;
  privacy: 'private' | 'groups' | 'subscribers' | 'groups_subscribers' | 'public';
  type: 'default' | 'question' | 'report' | 'content' | 'goal' | 'achievement';
  qaStatus?: ('new' | 'answered' | 'closed' | 'declined') | null;
  jobId?: string;
  tagsSet?: TagsSets[];
  attachments?: PublicationsAttachments[];
  score?: ScoresStats;
  likes?: LikesStats;
  comments?: CommentsStats;
  claims?: ClaimsStats;
  reactions?: ReactionsStats;
  viewsCount?: number;
  isViewed?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type GoalsReports = {
  goalId?: string;
  goal?: Goals;
  userId?: string;
  user?: Users;
  filesId?: string[];
  filesAspectRatio?: '1:1' | '16:9' | '4:5' | '4:3';
  files?: Files[];
  description: string;
  rewardValue?: RewardsValues;
  isSystem?: boolean;
  goalClosed?: boolean;
  publicationId?: string;
  publication?: Publications;
  changelog?: object[];
  likes?: LikesStats;
  localProgress?: GoalsProgress;
  claims?: ClaimsStats;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type GoalTypeDto = {
  name: string;
  description?: string;
  rewardId?: string;
  rewardAsAnalog?: boolean;
  deletedAt?: string;
  enabled: boolean;
};
export type GoalTypePartialDto = {
  name?: string;
  description?: string;
  rewardId?: string;
  rewardAsAnalog?: boolean;
  deletedAt?: string;
  enabled?: boolean;
};
export type Blocks = {
  name: string;
  description?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type BlockDto = {
  name: string;
  description?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type BlockPartialDto = {
  name?: string;
  description?: string;
  deletedAt?: string;
  enabled?: boolean;
};
export type TasksCategories = {
  name: string;
  positionNumber?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type TasksLinks = {
  title: string;
  url: string;
  isRecommended?: boolean;
};
export type Tasks = {
  name: string;
  description?: string;
  short_description?: string;
  last_description?: string;
  estimatedTime?: number;
  status: 'draft' | 'checking' | 'active' | 'closed' | 'archived';
  categoryId?: string;
  category?: TasksCategories;
  scriptsId?: string[];
  contentsId?: string[];
  filesId: string[];
  filesAspectRatio: '1:1' | '16:9' | '4:5' | '4:3';
  publicationRequired: boolean;
  lastStepNotionPageId?: string;
  publicationPlaceholder?: string;
  userId?: string;
  links?: TasksLinks[];
  widgetType?: 'user_profile';
  widgetName?: string;
  isViewed?: number;
  score?: ScoresStats;
  comments?: CommentsStats;
  claims?: ClaimsStats;
  reactions?: ReactionsStats;
  timelines?: Timelines[];
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type BlocksTasks = {
  blockId: string;
  block?: Blocks;
  taskId: string;
  task?: Tasks;
  positionNumber?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type BlockTaskDto = {
  positionNumber?: number;
};
export type TaskDto = {
  name: string;
  description?: string;
  short_description?: string;
  last_description?: string;
  estimatedTime?: number;
  status: 'draft' | 'checking' | 'active' | 'closed' | 'archived';
  categoryId?: string;
  scriptsId?: string[];
  contentsId?: string[];
  filesId: string[];
  filesAspectRatio: '1:1' | '16:9' | '4:5' | '4:3';
  publicationRequired: boolean;
  lastStepNotionPageId?: string;
  publicationPlaceholder?: string;
  userId?: string;
  links?: TasksLinks[];
  widgetType?: 'user_profile';
  widgetName?: string;
  isViewed?: number;
  claims?: ClaimsStats;
  reactions?: ReactionsStats;
  timelines?: Timelines[];
  deletedAt?: string;
  enabled: boolean;
};
export type JobsDynamicDto = {
  status: 'new' | 'edit' | 'completed';
  taskId?: string;
  userId?: string;
  date: string | string;
  count: number;
};
export type JobsStatsDto = {
  dynamic: JobsDynamicDto[];
};
export type JobsStatusSummary = {
  status: 'new' | 'edit' | 'completed';
  taskId: string;
  count: number;
};
export type JobsUsersStatusSummary = {
  userId: string;
  user: Users;
  status: 'new' | 'edit' | 'completed';
  taskId: string;
  count: number;
};
export type TaskCategoryDto = {
  name: string;
  positionNumber?: number;
  enabled: boolean;
};
export type TaskCategoriesPositions = {
  taskCategoriesId: string[];
};
export type TaskCategoryPartialDto = {
  name?: string;
  positionNumber?: number;
  enabled?: boolean;
};
export type TaskPartialDto = {
  name?: string;
  description?: string;
  short_description?: string;
  last_description?: string;
  estimatedTime?: number;
  status?: 'draft' | 'checking' | 'active' | 'closed' | 'archived';
  categoryId?: string;
  scriptsId?: string[];
  contentsId?: string[];
  filesId?: string[];
  filesAspectRatio?: '1:1' | '16:9' | '4:5' | '4:3';
  publicationRequired?: boolean;
  lastStepNotionPageId?: string;
  publicationPlaceholder?: string;
  userId?: string;
  links?: TasksLinks[];
  widgetType?: 'user_profile';
  widgetName?: string;
  isViewed?: number;
  claims?: ClaimsStats;
  reactions?: ReactionsStats;
  timelines?: Timelines[];
  deletedAt?: string;
  enabled?: boolean;
};
export type TaskTimelineDto = {
  dateStart?: (string | string) | null;
  dateFinish?: (string | string) | null;
  streamsId?: string[];
  groupsId?: string[];
  usersId?: string[];
  questsId?: string[];
  questsPointsId?: string[];
  rewardsId?: string[];
  isNotIn?: boolean;
  access: AccessBase;
  isAllowedAfterFinishDate?: boolean;
  changeStatusOnProgressReadyOrComplete?: boolean;
  totalMatch?: boolean;
  userId?: string;
  status?: 'active' | 'building' | 'checking' | 'closed';
  deletedAt?: string;
};
export type TaskTimelinePartialDto = {
  dateStart?: (string | string) | null;
  dateFinish?: (string | string) | null;
  streamsId?: string[];
  groupsId?: string[];
  usersId?: string[];
  questsId?: string[];
  questsPointsId?: string[];
  rewardsId?: string[];
  isNotIn?: boolean;
  access?: AccessBase;
  isAllowedAfterFinishDate?: boolean;
  changeStatusOnProgressReadyOrComplete?: boolean;
  totalMatch?: boolean;
  userId?: string;
  status?: 'active' | 'building' | 'checking' | 'closed';
  deletedAt?: string;
};
export type ContentsCategories = {
  name: string;
  positionNumber?: number;
  backgroundColor?: string;
  fileId?: string;
  file?: Files;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type Contents = {
  name: string;
  description?: string;
  content?: string;
  structure?: object[];
  userId?: string;
  categoryId?: string;
  category?: ContentsCategories;
  fileId?: string;
  isSystem: boolean;
  withVideo?: boolean;
  access: AccessBase;
  file?: Files;
  tagsSet?: TagsSets[];
  score?: ScoresStats;
  comments?: CommentsStats;
  claims?: ClaimsStats;
  reactions?: ReactionsStats;
  isViewed?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type Scripts = {
  name: string;
  description?: string;
  contentId?: string | null;
  content?: Contents;
  streamId?: string;
  stream?: Streams;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type ScriptDto = {
  name: string;
  description?: string;
  contentId?: string | null;
  content?: Contents;
  streamId?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type ScriptPartialDto = {
  name?: string;
  description?: string;
  contentId?: string | null;
  content?: Contents;
  streamId?: string;
  deletedAt?: string;
  enabled?: boolean;
};
export type ScriptsLinks = {
  scriptActionId?: string;
  templateOptionId: string;
  gotoScriptGroupId?: string;
  extraRewardValues?: RewardsValues[];
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type ScriptsGroups = {
  name?: string;
  scriptId?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type ScriptGroupDto = {
  name?: string;
  scriptId?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type ScriptGroupPartialDto = {
  name?: string;
  scriptId?: string;
  deletedAt?: string;
  enabled?: boolean;
};
export type ScriptsActionsSettings = {
  isRequired?: boolean;
  isCycled?: boolean;
  minCycleIteration?: number;
  maxCycleIteration?: number;
};
export type ScriptsActions = {
  name?: string;
  scriptGroupId: string;
  scriptGroup?: ScriptsGroups;
  templateId: string;
  template?: Templates;
  positionNumber?: number;
  settings?: ScriptsActionsSettings;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type ScriptActionDto = {
  name?: string;
  templateId: string;
  positionNumber?: number;
  settings?: ScriptsActionsSettings;
  deletedAt?: string;
  enabled: boolean;
};
export type ScriptActionPartialDto = {
  name?: string;
  templateId?: string;
  positionNumber?: number;
  settings?: ScriptsActionsSettings;
  deletedAt?: string;
  enabled?: boolean;
};
export type ScriptLinkDto = {
  scriptActionId?: string;
  templateOptionId: string;
  gotoScriptGroupId?: string;
  extraRewardValues?: RewardsValues[];
  deletedAt?: string;
};
export type ScriptLinkPartialDto = {
  scriptActionId?: string;
  templateOptionId?: string;
  gotoScriptGroupId?: string;
  extraRewardValues?: RewardsValues[];
  deletedAt?: string;
};
export type Timecodes = {
  description?: string;
  time: number;
  fileId: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type TimecodeDto = {
  description?: string;
  time: number;
  fileId: string;
  deletedAt?: string;
  enabled: boolean;
};
export type TimecodePartialDto = {
  description?: string;
  time?: number;
  fileId?: string;
  deletedAt?: string;
  enabled?: boolean;
};
export type TemplateDto = {
  name: string;
  description?: string;
  type:
    | 'string'
    | 'text'
    | 'number'
    | 'score'
    | 'date'
    | 'checkbox'
    | 'radio'
    | 'select'
    | 'multi_select'
    | 'file'
    | 'photo'
    | 'video'
    | 'audio'
    | 'goal'
    | 'custom'
    | 'user'
    | 'reward'
    | 'stage';
  scriptId?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type TemplatePartialDto = {
  name?: string;
  description?: string;
  type?:
    | 'string'
    | 'text'
    | 'number'
    | 'score'
    | 'date'
    | 'checkbox'
    | 'radio'
    | 'select'
    | 'multi_select'
    | 'file'
    | 'photo'
    | 'video'
    | 'audio'
    | 'goal'
    | 'custom'
    | 'user'
    | 'reward'
    | 'stage';
  scriptId?: string;
  deletedAt?: string;
  enabled?: boolean;
};
export type TemplateOptionDto = {
  name: string;
  description?: string;
  templateId?: string;
  positionNumber?: number;
  type?:
    | 'string'
    | 'text'
    | 'number'
    | 'score'
    | 'date'
    | 'checkbox'
    | 'radio'
    | 'select'
    | 'multi_select'
    | 'file'
    | 'photo'
    | 'video'
    | 'audio'
    | 'goal'
    | 'custom'
    | 'user'
    | 'reward'
    | 'stage';
  rewardValues?: RewardsValues[];
  settings?: TemplatesOptionsSettings;
  deletedAt?: string;
  enabled: boolean;
};
export type TemplateOptionsPositions = {
  optionsId: string[];
};
export type TemplateOptionPartialDto = {
  name?: string;
  description?: string;
  templateId?: string;
  positionNumber?: number;
  type?:
    | 'string'
    | 'text'
    | 'number'
    | 'score'
    | 'date'
    | 'checkbox'
    | 'radio'
    | 'select'
    | 'multi_select'
    | 'file'
    | 'photo'
    | 'video'
    | 'audio'
    | 'goal'
    | 'custom'
    | 'user'
    | 'reward'
    | 'stage';
  rewardValues?: RewardsValues[];
  settings?: TemplatesOptionsSettings;
  deletedAt?: string;
  enabled?: boolean;
};
export type ContentDto = {
  name: string;
  description?: string;
  content?: string;
  structure?: object[];
  categoryId?: string;
  fileId?: string;
  isSystem: boolean;
  withVideo?: boolean;
  access: AccessBase;
  enabled: boolean;
};
export type ContentCategoryDto = {
  name: string;
  positionNumber?: number;
  backgroundColor?: string;
  fileId?: string;
  enabled: boolean;
};
export type ContentCategoriesPositions = {
  contentCategoriesId: string[];
};
export type ContentCategoryPartialDto = {
  name?: string;
  positionNumber?: number;
  backgroundColor?: string;
  fileId?: string;
  enabled?: boolean;
};
export type ContentPartialDto = {
  name?: string;
  description?: string;
  content?: string;
  structure?: object[];
  categoryId?: string;
  fileId?: string;
  isSystem?: boolean;
  withVideo?: boolean;
  access?: AccessBase;
  enabled?: boolean;
};
export type TagDto = {
  name: string;
  type: 'default' | 'personal_quality' | 'hashtag' | 'quest' | 'publication';
  taggedCount?: number;
  deletedAt?: string;
  enabled: boolean;
};
export type TagPartialDto = {
  name?: string;
  type?: 'default' | 'personal_quality' | 'hashtag' | 'quest' | 'publication';
  taggedCount?: number;
  deletedAt?: string;
  enabled?: boolean;
};
export type RewardDto = {
  name: string;
  currency?: RewardsCurrency | null;
  description?: string;
  symbol?: string;
  backgroundColor?: string | null;
  fileId?: string | null;
  inactiveFileId?: string | null;
  type: 'default' | 'currency' | 'experience' | 'quality' | 'ability' | 'achivement' | 'reward' | 'prize';
  expKoef?: number;
  isSystem: boolean;
  isPublic: boolean;
  deletedAt?: string;
  enabled: boolean;
};
export type RewardPartialDto = {
  name?: string;
  currency?: RewardsCurrency | null;
  description?: string;
  symbol?: string;
  backgroundColor?: string | null;
  fileId?: string | null;
  inactiveFileId?: string | null;
  type?: 'default' | 'currency' | 'experience' | 'quality' | 'ability' | 'achivement' | 'reward' | 'prize';
  expKoef?: number;
  isSystem?: boolean;
  isPublic?: boolean;
  deletedAt?: string;
  enabled?: boolean;
};
export type UserDto = {
  name: string;
  surname?: string;
  birthDate?: string | string;
  gender?: 'male' | 'female' | 'not_sure';
  enabled: boolean;
};
export type UserLoginCheckDto = {
  userId?: string;
  deletedAt?: string;
  type: 'plain' | 'phone' | 'email';
  value: string;
};
export type UserPartialDto = {
  name?: string;
  surname?: string;
  birthDate?: string | string;
  gender?: 'male' | 'female' | 'not_sure';
  enabled?: boolean;
};
export type UsersPhotos = {
  description?: string;
  isProfilePhoto?: boolean;
  userId?: string;
  user?: Users;
  fileId: string;
  file?: Files;
  croppedFileId: string;
  croppedFile?: Files;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type UserPhotoDto = {
  description?: string;
  fileId: string;
  croppedFileId: string;
};
export type UserPhotoPatchDto = {
  description?: string;
  croppedFileId: string;
};
export type UsersPermissions = {
  userId?: string;
  isSuperAdmin?: boolean;
  isApplicationUser?: boolean;
  privacyConfirmedAt?: string | string;
  policyConfirmedAt?: string | string;
  accessId?: string[];
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type UserPermissionDto = {
  userId?: string;
  isSuperAdmin?: boolean;
  isApplicationUser?: boolean;
  privacyConfirmedAt?: string | string;
  policyConfirmedAt?: string | string;
  accessId?: string[];
  deletedAt?: string;
};
export type UserInviteDto = {
  message: string;
};
export type GroupDto = {
  name: string;
  short_description?: string;
  description?: string;
  externalLink?: string;
  fileId?: string;
  streamId?: string;
  subGroupsId?: string[];
  requestsIsActive: boolean;
  membersLimit?: number;
  enabled: boolean;
};
export type GroupsAccess = {
  name: string;
  points?: AccessPoint[];
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type GroupAccessDto = {
  name: string;
  points?: AccessPoint[];
  deletedAt?: string;
  enabled: boolean;
};
export type GroupAccessPartialDto = {
  name?: string;
  points?: AccessPoint[];
  deletedAt?: string;
  enabled?: boolean;
};
export type GroupPartialDto = {
  name?: string;
  short_description?: string;
  description?: string;
  externalLink?: string;
  fileId?: string;
  streamId?: string;
  subGroupsId?: string[];
  requestsIsActive?: boolean;
  membersLimit?: number;
  enabled?: boolean;
};
export type SetLimitDto = {
  membersLimit: number;
};
export type StreamDto = {
  name: string;
  description?: string;
  defaultQuestId?: string;
  defaultAdventureId?: string;
  hidden?: boolean;
  deletedAt?: string;
  enabled: boolean;
};
export type StreamsAccess = {
  name: string;
  points?: AccessPoint[];
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type StreamAccessDto = {
  name: string;
  points?: AccessPoint[];
  deletedAt?: string;
  enabled: boolean;
};
export type StreamAccessPartialDto = {
  name?: string;
  points?: AccessPoint[];
  deletedAt?: string;
  enabled?: boolean;
};
export type StreamPartialDto = {
  name?: string;
  description?: string;
  defaultQuestId?: string;
  defaultAdventureId?: string;
  hidden?: boolean;
  deletedAt?: string;
  enabled?: boolean;
};
export type StreamsUsers = {
  userId: string;
  user?: Users;
  streamId: string;
  stream?: Streams;
  accessId?: string[];
  access?: StreamsAccess[];
  joinDate: string | string;
  exitDate: string | string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type StreamsImportsElem = {
  index: number;
  name: string;
  surname: string;
  middlename: string;
  phone: string;
  email: string;
  groupName: string;
  groupLeader: boolean;
  warnings?: string[];
  failures?: string[];
};
export type StreamsImports = {
  streamId: string;
  filename: string;
  validRows: StreamsImportsElem[];
  warningRows: StreamsImportsElem[];
  failedRows: StreamsImportsElem[];
  existsRows: StreamsImportsElem[];
  conflictExistsRows: StreamsImportsElem[];
  totalValues: number;
  isCompleted: boolean;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type GoalsSettings = {
  privateGoalsLimit?: number;
};
export type MainFeedSettings = {
  tagsId?: string[];
};
export type PublicationsSettings = {
  publicationFormText: string;
};
export type CommentsSettings = {
  restoreTimeout?: number;
  formDisclaimer: string;
};
export type AppVideoConversionSettings = {
  allowConverting?: boolean;
  crf?: number;
  videoBitrate?: number;
  resolution?: 'hd480' | 'hd720' | 'hd1080';
  preset?: 'ultrafast' | 'superfast' | 'veryfast' | 'faster' | 'fast' | 'medium' | 'slow' | 'slower' | 'veryslow';
};
export type GroupsSettings = {
  allowInvites?: boolean;
  allowRequests?: boolean;
  allowKickout?: boolean;
  allowLeave?: boolean;
  allowChangeLeader?: boolean;
  allowDestroy?: boolean;
};
export type Settings = {
  agreement: string;
  policy: string;
  goals: GoalsSettings;
  mainFeed: MainFeedSettings;
  publications: PublicationsSettings;
  comments: CommentsSettings;
  appVideoConversion: AppVideoConversionSettings;
  groups: GroupsSettings;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type SettingsDto = {
  agreement: string;
  policy: string;
  goals: GoalsSettings;
  mainFeed: MainFeedSettings;
  publications: PublicationsSettings;
  comments: CommentsSettings;
  appVideoConversion: AppVideoConversionSettings;
  groups: GroupsSettings;
};
export type SettingsPartialDto = {
  agreement?: string;
  policy?: string;
  goals?: GoalsSettings;
  mainFeed?: MainFeedSettings;
  publications?: PublicationsSettings;
  comments?: CommentsSettings;
  appVideoConversion?: AppVideoConversionSettings;
  groups?: GroupsSettings;
};
export type AchievementsData = {
  rewardId: string;
  reward: Rewards;
  referenceName: 'Reactions' | 'StagesPoints' | 'Stages' | 'Jobs';
  count: number;
  value: number;
};
export type AchievementsDto = {
  rewardId: string;
  description?: string;
  title?: string;
  toUserId?: string;
};
export type Access = {
  name: string;
  points?: AccessPoint[];
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type AccessDto = {
  name: string;
  points?: AccessPoint[];
  deletedAt?: string;
  enabled: boolean;
};
export type AccessPartialDto = {
  name?: string;
  points?: AccessPoint[];
  deletedAt?: string;
  enabled?: boolean;
};
export const {
  useRootIndexQuery,
  useRootJsonDocsQuery,
  useRootDocsQuery,
  useRootSwaggerQuery,
  useAppIndexQuery,
  useAppRoutesQuery,
  useWidgetsFormulasIndexQuery,
  useWidgetsFormulasAddMutation,
  useWidgetFormulaIndexQuery,
  useWidgetFormulaUpdateMutation,
  useWidgetFormulaPatchMutation,
  useWidgetFormulaValuesQuery,
  useWidgetsIndexQuery,
  useWidgetsAddMutation,
  useWidgetIndexQuery,
  useWidgetUpdateMutation,
  useWidgetPatchMutation,
  useWidgetFillDataMutation,
  useWidgetRulesIndexQuery,
  useWidgetRulesAddMutation,
  useWidgetRuleIndexQuery,
  useWidgetRuleUpdateMutation,
  useWidgetRulePatchMutation,
  useWidgetRuleDeleteMutation,
  useWidgetAttrsIndexQuery,
  useWidgetAttrsAddMutation,
  useWidgetAttrsChangePositionsMutation,
  useWidgetAttrIndexQuery,
  useWidgetAttrUpdateMutation,
  useWidgetAttrPatchMutation,
  useWidgetAttrDeleteMutation,
  useWidgetsVisualsIndexQuery,
  useWidgetsVisualsAddMutation,
  useWidgetsVisualIndexQuery,
  useWidgetsVisualUpdateMutation,
  useWidgetsVisualPatchMutation,
  useWidgetsVisualDeleteMutation,
  useWidgetsVisualDesignsIndexQuery,
  useWidgetsVisualDesignsAddMutation,
  useWidgetsVisualDesignsChangePositionsMutation,
  useWidgetsVisualDesignIndexQuery,
  useWidgetsVisualDesignUpdateMutation,
  useWidgetsVisualDesignPatchMutation,
  useWidgetsVisualDesignDeleteMutation,
  useWidgetsVisualPropsIndexQuery,
  useWidgetsVisualPropsAddMutation,
  useWidgetsVisualPropsChangePositionsMutation,
  useWidgetsVisualPropIndexQuery,
  useWidgetsVisualPropUpdateMutation,
  useWidgetsVisualPropPatchMutation,
  useWidgetsVisualPropDeleteMutation,
  useReceiptsIndexQuery,
  useReceiptIndexQuery,
  useReceiptsServicesIndexQuery,
  useReceiptsServicesAddMutation,
  useReceiptsServiceIndexQuery,
  useReceiptsServiceUpdateMutation,
  useReceiptsServicePatchMutation,
  useReceiptsServiceDeleteMutation,
  usePaymentsIndexQuery,
  usePaymentsStatsPartnersDynamicQuery,
  usePaymentsStatsStatusSummaryQuery,
  usePaymentIndexQuery,
  usePaymentRefreshQuery,
  usePaymentsServicesIndexQuery,
  usePaymentsServicesAddMutation,
  usePaymentsServiceIndexQuery,
  usePaymentsServiceUpdateMutation,
  usePaymentsServicePatchMutation,
  usePaymentsServiceDeleteMutation,
  useReactionsBlocksIndexQuery,
  useReactionsBlocksAddMutation,
  useReactionsBlockIndexQuery,
  useReactionsBlockUpdateMutation,
  useReactionsBlockPatchMutation,
  useReactionsBlockDeleteMutation,
  useBlockersIndexQuery,
  useBlockersAddMutation,
  useBlockerIndexQuery,
  useBlockerUpdateMutation,
  useBlockerPatchMutation,
  useBlockerDeleteMutation,
  useStagePointIndexQuery,
  useStagePointPatchMutation,
  useStagePointRetriggerMutation,
  useQuestsIndexQuery,
  useQuestsAddMutation,
  useQuestsStatsDynamicQuery,
  useQuestsStatsStatusSummaryQuery,
  useQuestsStatsStatusUsersQuery,
  useQuestPointsIndexQuery,
  useQuestPointsAddMutation,
  useQuestPointsChangePositionsMutation,
  useQuestPointIndexQuery,
  useQuestPointUpdateMutation,
  useQuestPointPatchMutation,
  useQuestPointDeleteMutation,
  useQuestPointFillWidgetsQuery,
  useQuestPointsRewardsIndexQuery,
  useQuestPointsRewardsAddMutation,
  useQuestPointRewardIndexQuery,
  useQuestPointRewardUpdateMutation,
  useQuestPointRewardPatchMutation,
  useQuestPointsTimelinesIndexQuery,
  useQuestPointsTimelinesAddMutation,
  useQuestPointTimelineElemIndexQuery,
  useQuestPointTimelineElemUpdateMutation,
  useQuestPointTimelineElemPatchMutation,
  useQuestPointTimelineElemDeleteMutation,
  useQuestPointTimelineElemRecalculateRewardsMutation,
  useQuestIndexQuery,
  useQuestUpdateMutation,
  useQuestPatchMutation,
  useQuestDeleteForUsersMutation,
  useQuestDestructForUsersMutation,
  useQuestTagsIndexQuery,
  useQuestTagsSetMutation,
  useQuestTimelinesIndexQuery,
  useQuestTimelinesAddMutation,
  useQuestTimelineElemIndexQuery,
  useQuestTimelineElemUpdateMutation,
  useQuestTimelineElemPatchMutation,
  useQuestTimelineElemDeleteMutation,
  useQuestTimelineElemUsersIdQuery,
  useQuestTimelineElemStartMutation,
  useQuestTimelineElemStopMutation,
  useQuestTimelineElemResetMutation,
  useQuestTimelineElemRebuildMutation,
  useQuestTimelineElemRecalculateRewardsMutation,
  useQuestTimelineElemEmitMutation,
  useQuestTimelineElemEmitSpecificMutation,
  useStatisticsSummaryQuery,
  useClaimsDataIndexQuery,
  useClaimsDataDetailsQuery,
  useClaimsDataSetClaimedMutation,
  useClaimsDataRejectClaimsMutation,
  useClaimIndexQuery,
  useClaimUpdateMutation,
  useClaimPatchMutation,
  useClaimDeleteMutation,
  useMailingsIndexQuery,
  useMailingsAddMutation,
  useMailingIndexQuery,
  useMailingUpdateMutation,
  useMailingPatchMutation,
  useMailingResetMutation,
  useMailingActivateMutation,
  useMailingDisactivateMutation,
  useMailingsReportsIndexQuery,
  useCollectorsIndexQuery,
  useCollectorsAddMutation,
  useCollectorsChangePositionsMutation,
  useCollectorIndexQuery,
  useCollectorUpdateMutation,
  useCollectorKeysIndexQuery,
  useCollectorKeysAddMutation,
  useCollectorKeysChangePositionsMutation,
  useCollectorKeyIndexQuery,
  useCollectorKeyUpdateMutation,
  useGoalsIndexQuery,
  useGoalIndexQuery,
  useGoalReportsQuery,
  useGoalsTypesIndexQuery,
  useGoalsTypesAddMutation,
  useGoalsTypeIndexQuery,
  useGoalsTypeUpdateMutation,
  useGoalsTypePatchMutation,
  useBlocksIndexQuery,
  useBlocksAddMutation,
  useBlockIndexQuery,
  useBlockUpdateMutation,
  useBlockPatchMutation,
  useBlockTasksIndexQuery,
  useBlockTasksTasksOrderMutation,
  useBlockTaskInfoQuery,
  useBlockTaskAddMutation,
  useBlockTaskDeleteTaskMutation,
  useTasksIndexQuery,
  useTasksAddMutation,
  useTasksStatsDynamicQuery,
  useTasksStatsStatusSummaryQuery,
  useTasksStatsStatusUsersQuery,
  useTasksCategoriesIndexQuery,
  useTasksCategoriesAddMutation,
  useTasksCategoriesChangePositionsMutation,
  useTasksCategoryIndexQuery,
  useTasksCategoryUpdateMutation,
  useTasksCategoryPatchMutation,
  useTaskIndexQuery,
  useTaskUpdateMutation,
  useTaskPatchMutation,
  useTaskTimelinesIndexQuery,
  useTaskTimelinesAddMutation,
  useTaskTimelineElemIndexQuery,
  useTaskTimelineElemUpdateMutation,
  useTaskTimelineElemPatchMutation,
  useTaskTimelineElemDeleteMutation,
  useScriptsIndexQuery,
  useScriptsAddMutation,
  useScriptIndexQuery,
  useScriptUpdateMutation,
  useScriptPatchMutation,
  useScriptLinksQuery,
  useScriptGroupsIndexQuery,
  useScriptGroupsAddMutation,
  useScriptGroupIndexQuery,
  useScriptGroupUpdateMutation,
  useScriptGroupPatchMutation,
  useScriptActionsIndexQuery,
  useScriptActionsAddMutation,
  useScriptActionsChangePositionsMutation,
  useScriptActionIndexQuery,
  useScriptActionUpdateMutation,
  useScriptActionPatchMutation,
  useScriptLinksIndexQuery,
  useScriptLinksAddMutation,
  useScriptLinkIndexQuery,
  useScriptLinkUpdateMutation,
  useScriptLinkPatchMutation,
  useScriptLinkDeleteMutation,
  useTimecodesIndexQuery,
  useTimecodesAddMutation,
  useTimecodeIndexQuery,
  useTimecodeUpdateMutation,
  useTimecodePatchMutation,
  useTemplatesIndexQuery,
  useTemplatesAddMutation,
  useTemplateIndexQuery,
  useTemplateUpdateMutation,
  useTemplatePatchMutation,
  useTemplateOptionsIndexQuery,
  useTemplateOptionsAddMutation,
  useTemplateOptionsChangePositionsMutation,
  useTemplateOptionIndexQuery,
  useTemplateOptionUpdateMutation,
  useTemplateOptionPatchMutation,
  useContentsIndexQuery,
  useContentsAddMutation,
  useContentsCategoriesIndexQuery,
  useContentsCategoriesAddMutation,
  useContentsCategoriesChangePositionsMutation,
  useContentsCategoryIndexQuery,
  useContentsCategoryUpdateMutation,
  useContentsCategoryPatchMutation,
  useNotionPageIndexQuery,
  useContentIndexQuery,
  useContentNotifyUserMutation,
  useContentUpdateMutation,
  useContentPatchMutation,
  useContentTagsIndexQuery,
  useContentTagsSetMutation,
  useTagsIndexQuery,
  useTagsAddMutation,
  useTagIndexQuery,
  useTagUpdateMutation,
  useTagPatchMutation,
  useTagSetsQuery,
  usePublicationSafeDelete1Mutation,
  usePublicationSafeRestore1Mutation,
  useCommentSafeDeleteMutation,
  useCommentSafeRestoreMutation,
  useRewardsIndexQuery,
  useRewardsAddMutation,
  useRewardIndexQuery,
  useRewardUpdateMutation,
  useRewardPatchMutation,
  useUsersIndexQuery,
  useUsersAddMutation,
  useUsersCheckLoginsMutation,
  useUserIndexQuery,
  useUserUpdateMutation,
  useUserPatchMutation,
  useUserDeleteMutation,
  useUserRestoreMutation,
  useUserTagsIndexQuery,
  useUserTagsSetMutation,
  useUserPhotosIndexQuery,
  useUserPhotosAddMutation,
  useUserPhotoIndexQuery,
  useUserPhotoUpdateMutation,
  useUserPhotoDeleteMutation,
  useUserPermissionsIndexQuery,
  useUserPermissionsUpdateMutation,
  useUserLoginsIndexQuery,
  useUserLoginsAddMutation,
  useUserLoginInviteMutation,
  useUserLoginIndexQuery,
  useUserLoginUpdateMutation,
  useUserLoginDeleteMutation,
  useUserLoginPasswordMutation,
  useGroupsIndexQuery,
  useGroupsAddMutation,
  useGroupsAccessIndexQuery,
  useGroupsAccessAddMutation,
  useGroupAccessElemIndexQuery,
  useGroupAccessElemUpdateMutation,
  useGroupAccessElemPatchMutation,
  useGroupIndexQuery,
  useGroupUpdateMutation,
  useGroupPatchMutation,
  useGroupUsersQuery,
  useGroupAddUsersMutation,
  useGroupUpdateUserMutation,
  useGroupDeleteUserMutation,
  useGroupSetLeaderMutation,
  useGroupSetLimitMutation,
  useStreamsIndexQuery,
  useStreamsAddMutation,
  useStreamsAccessIndexQuery,
  useStreamsAccessAddMutation,
  useStreamAccessElemIndexQuery,
  useStreamAccessElemPatchMutation,
  useStreamIndexQuery,
  useStreamUpdateMutation,
  useStreamPatchMutation,
  useStreamUsersQuery,
  useStreamAddUsersMutation,
  useStreamUpdateUserMutation,
  useStreamDeleteUserMutation,
  useStreamImportsIndexQuery,
  useStreamImportsUploadMutation,
  useStreamImportIndexQuery,
  useStreamImportRunImportMutation,
  useStreamImportDeleteMutation,
  useSettingsIndexQuery,
  useSettingsUpdateMutation,
  useSettingsPatchMutation,
  useProfileIndexQuery,
  useProfileUpdateMutation,
  useProfileLoginsQuery,
  useProfileChangePasswordMutation,
  useAchievementsAddMutation,
  useAccessIndexQuery,
  useAccessAddMutation,
  useAccessElemIndexQuery,
  useAccessElemUpdateMutation,
  useAccessElemPatchMutation,
  useEnvEnvQuery,
} = api;
