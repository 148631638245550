import {FC, PropsWithChildren, useCallback, useEffect, useRef, useState} from 'react';

import {Box, useTheme} from '@mui/material';
import {mergeRegister} from '@lexical/utils';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$getSelection, $isRangeSelection, COMMAND_PRIORITY_LOW, LexicalNode, SELECTION_CHANGE_COMMAND} from 'lexical';

import {getDOMRangeRect, setFloatingElemPosition} from './utils';

type FloatingToolbarProps = {
  anchorElem: HTMLElement;
  getParentNode?: (selectedNode: LexicalNode) => LexicalNode | null;
};

type SetToolbarStateProp = {setToolbarState: (setState: (value: boolean) => void) => void};

export const FloatingToolbar: FC<PropsWithChildren<FloatingToolbarProps & SetToolbarStateProp>> = ({
  setToolbarState,
  ...props
}) => {
  const [editor] = useLexicalComposerContext();
  const [isShown, setIsShown] = useState(false);
  const updatePopup = useCallback(() => {
    editor.getEditorState().read(() => {
      if (editor.isComposing()) return;
      setToolbarState(setIsShown);
    });
  }, [setToolbarState, editor]);

  useEffect(() => {
    document.addEventListener('selectionchange', updatePopup);
    return () => {
      document.removeEventListener('selectionchange', updatePopup);
    };
  }, [updatePopup]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(() => {
        updatePopup();
      }),
      editor.registerRootListener(() => {
        if (editor.getRootElement() === null) {
          setIsShown(false);
        }
      })
    );
  }, [editor, updatePopup]);

  if (!isShown) return null;
  return <FloatingToolbarWrap {...props} />;
};
const FloatingToolbarWrap: FC<PropsWithChildren<FloatingToolbarProps>> = ({anchorElem, children, getParentNode}) => {
  const theme = useTheme();
  const popupCharStylesEditorRef = useRef<HTMLDivElement | null>(null);
  const [editor] = useLexicalComposerContext();

  function mouseMoveListener(e: MouseEvent) {
    if (popupCharStylesEditorRef?.current && (e.buttons === 1 || e.buttons === 3)) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== 'none') {
        const x = e.clientX;
        const y = e.clientY;
        const elementUnderMouse = document.elementFromPoint(x, y);

        if (!popupCharStylesEditorRef.current.contains(elementUnderMouse)) {
          // Mouse is not over the target element => not a normal click, but probably a drag
          popupCharStylesEditorRef.current.style.pointerEvents = 'none';
        }
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function mouseUpListener(e: MouseEvent) {
    if (popupCharStylesEditorRef?.current) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== 'auto') {
        popupCharStylesEditorRef.current.style.pointerEvents = 'auto';
      }
    }
  }

  useEffect(() => {
    if (popupCharStylesEditorRef?.current) {
      document.addEventListener('mousemove', mouseMoveListener);
      document.addEventListener('mouseup', mouseUpListener);
      return () => {
        document.removeEventListener('mousemove', mouseMoveListener);
        document.removeEventListener('mouseup', mouseUpListener);
      };
    }
  }, [popupCharStylesEditorRef]);

  const updateTextFormatFloatingToolbar = useCallback(() => {
    const selection = $getSelection();

    const popupCharStylesEditorElem = popupCharStylesEditorRef.current;
    const nativeSelection = window.getSelection();

    if (popupCharStylesEditorElem === null) return;

    const rootElement = editor.getRootElement();
    if (getParentNode) {
      if ($isRangeSelection(selection)) {
        const parentNode = getParentNode(selection.anchor.getNode());
        if (parentNode) {
          const rect = editor.getElementByKey(parentNode.getKey())?.getBoundingClientRect();
          if (rect) setFloatingElemPosition(rect, popupCharStylesEditorElem, anchorElem, false, 0, 0);
        }
      }
    } else if (
      selection !== null &&
      nativeSelection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      setFloatingElemPosition(getDOMRangeRect(nativeSelection, rootElement), popupCharStylesEditorElem, anchorElem);
    }
  }, [editor, anchorElem, getParentNode]);

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement;

    const update = () => {
      editor.getEditorState().read(() => {
        updateTextFormatFloatingToolbar();
      });
    };

    window.addEventListener('resize', update);
    if (scrollerElem) {
      scrollerElem.addEventListener('scroll', update);
    }

    return () => {
      window.removeEventListener('resize', update);
      if (scrollerElem) {
        scrollerElem.removeEventListener('scroll', update);
      }
    };
  }, [editor, updateTextFormatFloatingToolbar, anchorElem]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateTextFormatFloatingToolbar();
    });
    return mergeRegister(
      editor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {
          updateTextFormatFloatingToolbar();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateTextFormatFloatingToolbar();
          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor, updateTextFormatFloatingToolbar]);

  return (
    <Box
      bgcolor="white"
      position="absolute"
      borderRadius={1}
      boxShadow={theme.shadows[1]}
      ref={popupCharStylesEditorRef}
      sx={{top: 0, left: 0, zIndex: 10}}
    >
      {children}
    </Box>
  );
};
