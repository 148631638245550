import {Stack, Typography} from '@mui/material';

import {Goals} from 'api/generated/users-api';
import {Form} from 'components/Form';

import {fields, validation} from './fields';
import {useGoalForm} from './hook';
import {useConfirmDialog} from 'components/Dialog';

export const GoalForm = ({goal}: {goal: Goals}) => {
  const {initials, onSubmit, onDelete, onRestore} = useGoalForm(goal);
  const [ConfirmDelete, openConfirmDelete] = useConfirmDialog({
    onConfirm: onDelete,
    title: 'Подтвердить удаление?',
  });

  return (
    <>
      <ConfirmDelete />
      <Stack p={3} spacing={1.5} boxShadow={theme => theme.customShadows.z16} borderRadius={2}>
        <Typography variant="h4">Цель</Typography>
        <Form
          fields={fields}
          initials={initials}
          validation={validation}
          onSubmit={onSubmit}
          buttonsStructure={[
            goal.deletedAt
              ? {
                  text: 'Восстановить',
                  color: 'secondary',
                  onClick: onRestore,
                }
              : {
                  text: 'Удалить',
                  color: 'error',
                  onClick: openConfirmDelete,
                },
            {
              text: 'Сохранить',
              type: 'submit',
            },
          ]}
        />
      </Stack>
    </>
  );
};
