import * as yup from 'yup';
import {FC, useEffect, useState} from 'react';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import plusIcon from '@iconify/icons-eva/plus-outline';

import {adminApi} from 'api';

import {Form} from 'components/Form';
import useDialogSelector from 'components/DialogSelector';
import {FormResetFnProp} from 'components/Form/types';

import {TemplateOptionProp} from 'modules/tasks/types';
import {templateListColumns} from 'modules/tasks/utils';
import {WidgetAttrFormDto, WidgetIdProp} from 'modules/widgets/types';

import {attributeFormulaSelect} from '../WidgetAttributeForm/fields';

const WidgetAttributehortForm: FC<TemplateOptionProp & WidgetIdProp> = ({templateOption, widgetId}) => {
  const [add, {isLoading}] = adminApi.endpoints.widgetAttrsAdd.useMutation();
  const onSubmit = async (data: Pick<WidgetAttrFormDto, 'formula'>) => {
    if (templateOption.templateId && templateOption._id) {
      await add({
        widgetId,
        widgetAttrDto: {
          ...data,
          widgetId,
          name: templateOption.name,
          templateId: templateOption.templateId,
          templateOptionId: templateOption._id,
        },
      });
    }
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <Box>
        <Typography variant="body2">{templateOption.name}</Typography>
        <Typography variant="subtitle2" color="grey.500">
          {templateOption.type}
        </Typography>
      </Box>
      <Form
        buttonsPosition="right"
        fields={[...attributeFormulaSelect]}
        initials={{formula: 'first_value'}}
        validation={yup.object().shape({
          formula: yup.string().required(),
        })}
        onSubmit={onSubmit}
        buttonsStructure={[{type: 'submit', text: 'Сохранить', display: 'icon', icon: plusIcon, disabled: isLoading}]}
      />
    </Stack>
  );
};

export const WidgetAttributeCreateForTemplate: FC<WidgetIdProp & FormResetFnProp> = ({widgetId, onReset}) => {
  const [Selector, openSelector] = useDialogSelector();
  const [templateId, setTemplateId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!templateId) openSelector();
  }, [templateId, openSelector]);

  const {data: template} = adminApi.endpoints.templateIndex.useQuery(templateId ? {templateId} : skipToken);
  const {data: templateOptions} = adminApi.endpoints.templateOptionsIndex.useQuery(
    templateId ? {templateId} : skipToken
  );

  return (
    <>
      <Selector
        single
        params={{}}
        title="Выбор шаблона"
        queryName="templatesIndex"
        columns={templateListColumns()}
        onConfirm={item => setTemplateId(item[0] || undefined)}
      />
      {templateId && !!template && (
        <Box>
          <Box mb={2}>
            <Typography variant="body1">{template.name}</Typography>
            <Typography variant="subtitle1" color="grey.500">
              {template.type}
            </Typography>
          </Box>
          <Stack spacing={2} my={2}>
            {templateOptions?.map(item => (
              <WidgetAttributehortForm key={item._id} templateOption={item} widgetId={widgetId} />
            ))}
          </Stack>
        </Box>
      )}
      <Stack spacing={1} direction="row-reverse">
        {/* {templateId && (
          <Button
            onClick={() =>
              add({widgetId, widgetAttrDto: {name: template?.name || templateId, templateId, formula: 'list'}})
            }
            fullWidth
            variant="contained"
          >
            Добавить весь шаблон
          </Button>
        )} */}
        <Button onClick={templateId ? () => setTemplateId(undefined) : openSelector} fullWidth variant="contained">
          {templateId ? 'Сменить шаблон' : 'Выбрать шаблон'}
        </Button>
        <Button onClick={onReset} fullWidth variant="text">
          Закрыть окно
        </Button>
      </Stack>
    </>
  );
};
