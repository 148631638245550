import {useNavigate} from 'react-router';
import {format, parseISO} from 'date-fns';

import {TableCell, Typography} from '@mui/material';
import Label from '__theme/components/Label';

import {Stages} from 'api/generated/users-api';

import {getStagePath} from 'modules/users/utils';
import {DataTableRow} from 'components/DataTable/DataTableRow';
import {MenuButton} from 'components/MenuButton';
import {StatusLabel} from 'components/StatusLabel';

export const UserQuestsTableRow = ({item: stage}: {item: Stages}) => {
  const navigate = useNavigate();

  return (
    <DataTableRow onClick={() => navigate(getStagePath(stage.userId, stage._id))}>
      <TableCell>
        <Typography variant="body2" color="text.secondary">
          {stage.quest?.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="subtitle2" color="text.primary">
          {stage.createdAt ? format(parseISO(stage.createdAt), 'MMM d y') : ''}
        </Typography>
      </TableCell>
      <TableCell>
        <Label>{stage.questType}</Label>
      </TableCell>
      <TableCell>
        <StatusLabel status={stage.status} />
      </TableCell>
      <TableCell>
        <MenuButton buttons={[{label: 'Информация', onClick: () => null}]} />
      </TableCell>
    </DataTableRow>
  );
};
