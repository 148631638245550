import {useRef, MouseEvent} from 'react';
import {useToggle} from 'react-use';

import {Icon, IconifyIcon} from '@iconify/react';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';

type MenuButtonProps = {
  buttons: ({label: string; icon?: IconifyIcon; onClick: () => void} | null)[];
};

export const MenuButton = ({buttons}: MenuButtonProps) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useToggle(false);

  const toggleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen();
  };
  return (
    <>
      <IconButton ref={ref} onClick={toggleOpen}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={toggleOpen}
        MenuListProps={{sx: {p: 0, width: 1}}}
        PaperProps={{
          sx: {maxWidth: '100%', minWidth: 200},
        }}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
      >
        {buttons.filter(Boolean).map(i => (
          <MenuItem
            key={i?.label}
            onClick={e => {
              e.stopPropagation();
              i?.onClick();
              setIsOpen(false);
            }}
            sx={{color: 'text.primary', borderRadius: 1}}
          >
            {i?.icon && (
              <ListItemIcon>
                <Icon icon={i?.icon} width={24} height={24} />
              </ListItemIcon>
            )}
            <ListItemText primaryTypographyProps={{variant: 'body2', color: 'text.primary', fontWeight: 600}}>
              {i?.label}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
