import {adminApi} from 'api';
import {useSelector} from 'react-redux';

import {MODULE_NAME} from '../constants';

export const useIsAuthentificated = () => {
  const isLogged = useSelector((state: any) => state[MODULE_NAME].isLogged);
  return isLogged;
};

export const useGetCurrentUser = () => {
  const {data: user} = adminApi.endpoints.profileIndex.useQueryState(undefined);
  return user;
};

export default useIsAuthentificated;
