import {useEffect, useRef, useState} from 'react';
import Slider from 'react-slick';
import {findIndex} from 'lodash';

import {Box, BoxProps} from '@mui/material';
import {alpha, styled} from '@mui/material/styles';
import LightboxModal from '__theme/components/LightboxModal';
import {CarouselControlsArrowsIndex} from '__theme/components/carousel/controls';

import {LazyImage} from 'components/ImagesGallery/LazyImage';
import {croppedImagePath} from 'utils';

const THUMB_SIZE = 64;

type LargeItemProps = {
  item: string;
  onOpenLightbox: (value: string) => void;
};
function LargeItem({item, onOpenLightbox}: LargeItemProps) {
  return (
    <Box onClick={() => onOpenLightbox(item)} sx={{cursor: 'zoom-in', paddingTop: '100%', position: 'relative'}}>
      <LazyImage fileId={item} ratio="1:1" alt="" sx={{position: 'absolute', width: '100%', height: '100%', top: 0}} />
    </Box>
  );
}

const ThumbWrapperStyle = styled('div')(({theme}) => ({
  cursor: 'pointer',
  width: THUMB_SIZE,
  overflow: 'hidden',
  height: THUMB_SIZE,
  position: 'relative',
  margin: theme.spacing(0, 1),
  borderRadius: theme.shape.borderRadiusSm,
  '&:hover': {
    opacity: 0.72,
    transition: theme.transitions.create('opacity'),
  },
  '& .isActive': {
    top: 0,
    zIndex: 9,
    opacity: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    borderRadius: theme.shape.borderRadiusSm,
    border: `solid 3px ${theme.palette.primary.main}`,
    backgroundColor: alpha(theme.palette.grey[900], 0.48),
  },
}));
function ThumbnailItem({item}: {item: string}) {
  return (
    <ThumbWrapperStyle>
      <Box className="isActive" />
      <LazyImage fileId={item} ratio="1:1" alt="" />
    </ThumbWrapperStyle>
  );
}
type ImagesGalleryProps = {
  filesId: string[];
  wrapperProps?: BoxProps;
};
export const ImagesGallery = ({filesId, wrapperProps}: ImagesGalleryProps) => {
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState<number>(0);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [nav1, setNav1] = useState<Slider>();
  const [nav2, setNav2] = useState<Slider>();
  const slider1 = useRef<Slider | null>(null);
  const slider2 = useRef<Slider | null>(null);
  const imagesLightbox = filesId.map(_image => croppedImagePath(_image, {ratio: '1:1'}));

  const handleOpenLightbox = (id: string) => {
    const selectedImage = findIndex(imagesLightbox, index => index.includes(id));
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  const settings1 = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    adaptiveHeight: true,
    beforeChange: (current: number, next: number) => setCurrentIndex(next),
  };

  const settings2 = {
    dots: false,
    arrows: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    variableWidth: true,
    centerPadding: '0px',
    slidesToShow: filesId.length > 3 ? 3 : filesId.length,
  };

  useEffect(() => {
    setNav1(slider1.current || undefined);
    setNav2(slider2.current || undefined);
  }, [currentIndex]);

  const handlePrevious = () => {
    slider2.current?.slickPrev();
  };

  const handleNext = () => {
    slider2?.current?.slickNext();
  };

  return (
    <Box boxShadow={theme => theme.customShadows.z16} borderRadius={2} {...wrapperProps}>
      <Box sx={{p: 1}}>
        <Box
          sx={{
            zIndex: 0,
            borderRadius: 2,
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          {/* @ts-ignore */}
          <Slider {...settings1} asNavFor={nav2} ref={slider1}>
            {filesId.map(item => (
              <LargeItem key={item} item={item} onOpenLightbox={handleOpenLightbox} />
            ))}
          </Slider>
          <CarouselControlsArrowsIndex
            index={currentIndex}
            total={filesId.length}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        </Box>
      </Box>

      <Box
        sx={{
          mt: 0.5,
          mb: 1.5,
          mx: 'auto',
          '& .slick-current .isActive': {opacity: 1},
          ...(filesId.length === 1 && {maxWidth: THUMB_SIZE * 1 + 16}),
          ...(filesId.length === 2 && {maxWidth: THUMB_SIZE * 2 + 32}),
          ...(filesId.length === 3 && {maxWidth: THUMB_SIZE * 3 + 48}),
          ...(filesId.length === 4 && {maxWidth: THUMB_SIZE * 3 + 48}),
          ...(filesId.length >= 5 && {maxWidth: THUMB_SIZE * 6}),
          ...(filesId.length > 2 && {
            position: 'relative',
          }),
        }}
      >
        {/* @ts-ignore */}
        <Slider {...settings2} asNavFor={nav1} ref={slider2}>
          {filesId.map(item => (
            <ThumbnailItem key={item} item={item} />
          ))}
        </Slider>
      </Box>

      <LightboxModal
        images={imagesLightbox}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onClose={() => setOpenLightbox(false)}
      />
    </Box>
  );
};
