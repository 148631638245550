import {useEffect} from 'react';

import {$insertNodeToNearestRoot, mergeRegister} from '@lexical/utils';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$getNodeByKey, COMMAND_PRIORITY_LOW, NodeKey, createCommand} from 'lexical';

import {$createContentNode, $isContentNode, ContentNode, ContentPayload} from './ContentNode';

export const INSERT_CONTENT_COMMAND = createCommand('INSERT_CONTENT_COMMAND');
export const REMOVE_CONTENT_COMMAND = createCommand<NodeKey>('REMOVE_CONTENT_COMMAND');
export const UPDATE_CONTENT_COMMAND = createCommand<ContentPayload>('UPDATE_CONTENT_COMMAND');

export const ContentPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([ContentNode])) {
      throw new Error('ContentPlugin: ContentNode not registered on editor');
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_CONTENT_COMMAND,
        () => {
          const node = $createContentNode({});
          $insertNodeToNearestRoot(node);
          return true;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        UPDATE_CONTENT_COMMAND,
        ({contentId, key}) => {
          if (!key) return false;
          const node = $getNodeByKey(key);
          if (!$isContentNode(node)) return false;
          node.setContentId(contentId);
          return true;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        REMOVE_CONTENT_COMMAND,
        key => {
          const node = $getNodeByKey(key);
          if (!$isContentNode(node)) return false;
          node.remove();
          return true;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor]);

  return null;
};
