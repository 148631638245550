import {useAppDispatch} from 'store';
import {useNavigate} from 'react-router';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {Card, CardContent} from '@mui/material';

import {adminApi} from 'api';
import {PaymentsServices} from 'api/generated/admin-api';
import {isSuccessResult} from 'utils';

import {Form} from 'components/Form';
import {PaymentServiceFormSuccessProp, PaymentServiceIdProp} from 'modules/payments/types';

import {fields, validation, initials} from './fields';

const createService = adminApi.endpoints.paymentsServicesAdd.initiate;
const updateService = adminApi.endpoints.paymentsServicePatch.initiate;

export const PaymentServiceForm: React.VFC<Partial<PaymentServiceIdProp & PaymentServiceFormSuccessProp>> = ({
  onSuccess,
  paymentServiceId: serviceId,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {data: service} = adminApi.endpoints.paymentsServiceIndex.useQuery(serviceId ? {serviceId} : skipToken);
  return (
    <Card>
      <CardContent>
        <Form
          fields={fields}
          validation={validation}
          onSubmit={async data => {
            let result;
            if (serviceId && service)
              result = await dispatch(updateService({serviceId, paymentsServicePartialDto: data}));
            else result = await dispatch(createService({paymentsServiceDto: data}));
            if (isSuccessResult<PaymentsServices>(result) && onSuccess) onSuccess(result.data, navigate);
            return result;
          }}
          initials={initials(service)}
        />
      </CardContent>
    </Card>
  );
};

export default PaymentServiceForm;
