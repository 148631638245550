import {FC, useEffect} from 'react';
import {$getNodeByKey, COMMAND_PRIORITY_EDITOR, COMMAND_PRIORITY_LOW, createCommand, NodeKey} from 'lexical';
import {mergeRegister, $insertNodeToNearestRoot} from '@lexical/utils';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';

import {$createFileNode, $isFileNode, FileNode, FilePayload} from './FileNode';

export const INSERT_FILE_COMMAND = createCommand('INSERT_FILE_COMMAND');
export const UPDATE_FILE_COMMAND = createCommand<FilePayload & {nodeKey: NodeKey}>('UPDATE_FILE_COMMAND');

export const FilesPlugin: FC = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([FileNode])) {
      throw new Error('FilesPlugin: FileNode not registered on editor');
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_FILE_COMMAND,
        () => {
          const node = $createFileNode({});
          $insertNodeToNearestRoot(node);
          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        UPDATE_FILE_COMMAND,
        ({nodeKey, fileId, fileType, size, name}) => {
          const node = $getNodeByKey(nodeKey);
          if (!$isFileNode(node)) return false;
          if (name) node.setName(name);
          if (size) node.setSize(size);
          if (fileId) node.setFile(fileId, fileType);
          return true;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor]);

  return null;
};
