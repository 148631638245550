import {FC, useCallback} from 'react';
import saveIcon from '@iconify/icons-eva/save-outline';
import trashIcon from '@iconify/icons-eva/trash-outline';
import refreshIcon from '@iconify/icons-eva/refresh-outline';

import {adminApi} from 'api';
import {WidgetFormulaDto} from 'api/generated/admin-api';

import {Form} from 'components/Form';
import {yupObject, yupStringRequired} from 'utils/validation';
import {FormResetFnProp} from 'components/Form/types';
import {WidgetFormulaIdProp, WidgetIdProp} from 'modules/widgets/types';
import {skipToken} from '@reduxjs/toolkit/query';

export const WidgetFormulaForm: FC<WidgetIdProp & Partial<WidgetFormulaIdProp & FormResetFnProp>> = ({
  widgetId,
  widgetFormulaId,
  onReset,
}) => {
  const {data: formula} = adminApi.endpoints.widgetFormulaIndex.useQuery(
    widgetFormulaId ? {widgetFormulaId} : skipToken
  );
  const [add] = adminApi.endpoints.widgetsFormulasAdd.useMutation();
  const [patch] = adminApi.endpoints.widgetFormulaPatch.useMutation();

  const disableFormula = () => {
    if (!widgetFormulaId) return;
    patch({widgetFormulaId, widgetFormulaPartialDto: {enabled: false}});
  };

  const onSubmit = useCallback(
    (data: Omit<WidgetFormulaDto, 'widgetId' | 'enabled'>) => {
      if (widgetFormulaId) return patch({widgetFormulaId, widgetFormulaPartialDto: data});
      return add({widgetFormulaDto: {widgetId, enabled: true, ...data}});
    },
    [widgetId, add, patch, widgetFormulaId]
  );

  return (
    <>
      <Form
        fields={[
          {
            direction: 'row',
            fields: [
              {name: 'name', type: 'text', label: 'Название'},
              {name: 'formula', type: 'text', label: 'Формула'},
              {name: '_id', type: 'text', label: 'ID', disabled: true},
            ],
          },
        ]}
        validation={yupObject({
          name: yupStringRequired,
          formula: yupStringRequired,
        })}
        initials={
          {
            _id: formula?._id || '',
            name: formula?.name || '',
            formula: formula?.formula || '',
          } as any
        }
        buttonsStructure={[
          {type: 'submit', text: 'Сохранить', display: 'icon', icon: saveIcon},
          {type: 'reset', text: 'Сбросить', color: 'error', display: 'icon', icon: refreshIcon},
          widgetFormulaId
            ? {
                onClick: disableFormula,
                text: 'Деактивировать',
                display: 'icon',
                icon: trashIcon,
                color: 'error',
              }
            : undefined,
        ]}
        onSubmit={onSubmit}
        onReset={onReset}
      />
    </>
  );
};
