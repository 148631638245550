import {format, milliseconds} from 'date-fns';
import {fill} from 'lodash';

export type ChartScaleType = 'week' | 'month' | 'year';
export type ChartScaleProp = {
  scale: ChartScaleType;
};
export type ChartNoDataProp = {
  noData?: ApexNoData;
};

export const chartScaleOptions: {name: string; value: ChartScaleType}[] = [
  {
    name: 'Неделя',
    value: 'week',
  },
  {
    name: 'Месяц',
    value: 'month',
  },
  {
    name: 'Год',
    value: 'year',
  },
];

export const defaultNoDataChartOption = {
  text: 'Нет данных',
  align: 'center',
  verticalAlign: 'middle',
};

export const getChartCategoriesByScale = (scale: ChartScaleType) => {
  switch (scale) {
    case 'year':
      return fill(Array(13), '')
        .map((_, index) => {
          const date = new Date(Date.now() - milliseconds({months: index}));
          date.setDate(1);
          return format(date, 'Y-M-d');
        })
        .reverse();
    case 'month':
      return fill(Array(31), '')
        .map((_, index) => {
          const date = new Date(Date.now() - milliseconds({days: index}));
          return format(date, 'Y-M-d');
        })
        .reverse();
    case 'week':
      return fill(Array(7), '')
        .map((_, index) => {
          const date = new Date(Date.now() - milliseconds({days: index}));
          return format(date, 'Y-M-d');
        })
        .reverse();
    default:
      return [];
  }
};
