import {Groups} from 'api/generated/admin-api';
import {enchanceColumns, nameColumn} from 'utils';

export const groupsColumns = enchanceColumns<Groups>(
  [
    nameColumn,
    {
      field: 'stream.name',
      headerName: 'Поток',
      valueGetter: ({row}) => row.stream?.name,
      width: 150,
    },
  ],
  {hideDates: true}
);
