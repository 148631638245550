import chroma from 'chroma-js';
import {FC, useEffect} from 'react';
import {$insertNodeToNearestRoot, mergeRegister} from '@lexical/utils';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {
  $createParagraphNode,
  $getNodeByKey,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  LexicalNode,
  NodeKey,
} from 'lexical';

import {$createBlockNode, BlockNode, $isBlockNode} from './BlockNode';
import {useFloatingBlockToolbar} from './FloatingBlockToolbar';
import './style.css';

export const INSERT_BLOCK_COMMAND = createCommand<string | undefined>();
export const UPDATE_BLOCK_COMMAND = createCommand<{color: string; nodeKey: NodeKey}>();

export const BlockPlugin: FC<{anchorElem?: HTMLElement | null}> = ({anchorElem = document.body}) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([BlockNode])) {
      throw new Error('BlockPlugin: BlockNode not registered on editor');
    }
    return mergeRegister(
      editor.registerCommand(
        INSERT_BLOCK_COMMAND,
        (color = chroma('00152F').alpha(0.03).hex('rgba')) => {
          editor.update(() => {
            const paragraph = $createParagraphNode();
            $insertNodeToNearestRoot($createBlockNode(color).append(paragraph));
            paragraph.selectStart();
          });

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        UPDATE_BLOCK_COMMAND,
        ({color, nodeKey}) => {
          editor.update(() => {
            const container = $getNodeByKey<LexicalNode>(nodeKey);
            if ($isBlockNode(container)) {
              container?.setColor(color);
            }
          });

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor]);

  return useFloatingBlockToolbar(anchorElem, editor);
};
