import {Stack} from '@mui/material';

import {PaymentsValueChart} from './PaymentsValueChart';
import {PaymentsStatusChart} from './PaymentsStatusChart';

export const PaymentsStats = () => {
  return (
    <Stack spacing={2}>
      <PaymentsValueChart />
      <PaymentsStatusChart />
    </Stack>
  );
};
