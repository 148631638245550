import {GridCellParams, GridColDef} from '@mui/x-data-grid';
import {PaymentsServices, ReceiptsServices} from 'api/generated/admin-api';
import {nameColumn} from 'utils';

export const paymentServicesTypes = [
  {value: 'yoo_kassa', name: 'Yoo Касса'},
  {value: 'tinkoff_kassa', name: 'Тинькофф Касса'},
  {value: 'paypal', name: 'Платежный товарищ (PayPal)'},
  {value: 'stripe', name: 'Stripe'},
  {value: 'bluesnap', name: 'Bluesnap'},
  {value: 'crypto', name: 'Криптовалюта'},
  {value: 'manual', name: 'Мануально'},
];

export const receiptsServicesTypes = [
  {value: 'kit_online', name: 'Kit online'},
  {value: 'cloud_kassir', name: 'Cloud kassir'},
];

export const paymentsServicesColumns = (
  actionCell?: (props: GridCellParams<PaymentsServices>) => JSX.Element
): GridColDef[] =>
  [
    nameColumn,
    {
      field: 'type',
      headerName: 'Тип',
      flex: 0.4,
    },
    {
      field: 'enabled',
      headerName: 'Активный',
      type: 'boolean',
    },
    actionCell && {
      field: '__action',
      headerAlign: 'right',
      align: 'right',
      headerName: '',
      renderCell: actionCell,
      disableColumnMenu: true,
      flex: 0.2,
    },
  ].filter(Boolean) as GridColDef[];

export const receiptsServicesColumns = (
  actionCell?: (props: GridCellParams<ReceiptsServices>) => JSX.Element
): GridColDef[] =>
  [
    {
      field: 'name',
      headerName: 'Название',
      flex: 0.4,
    },
    {
      field: 'type',
      headerName: 'Тип',
      flex: 0.4,
    },
    {
      field: 'enabled',
      headerName: 'Активный',
      type: 'boolean',
    },
    actionCell && {
      field: '__action',
      headerAlign: 'right',
      align: 'right',
      headerName: '',
      renderCell: actionCell,
      disableColumnMenu: true,
      flex: 0.2,
    },
  ].filter(Boolean) as GridColDef[];
