import {useEffect} from 'react';

import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {mergeRegister, $insertNodeToNearestRoot, $findMatchingParent} from '@lexical/utils';
import {
  $createParagraphNode,
  $createTextNode,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_LOW,
  createCommand,
  ElementNode,
  KEY_ARROW_DOWN_COMMAND,
  KEY_ARROW_LEFT_COMMAND,
  KEY_ARROW_RIGHT_COMMAND,
  KEY_ARROW_UP_COMMAND,
  LexicalNode,
} from 'lexical';
import {$createHeadingNode} from '@lexical/rich-text';

import {$createLegendContentNode, $isLegendContentNode, LegendContentNode} from './LegendContentNode';
import {$createLegendContainerNode, $isLegendContainerNode, LegendContainerNode} from './LegendContainerNode';

import './styles.css';
import {$createImageNode, $isImageNode} from '../ImagesPlugin';
import {$createLegendTitleNode, $isLegendTitleNode, LegendTitleNode} from './LegendTitleNode';

export const INSERT_LEGEND_COMMAND = createCommand<void>('INSERT_LEGEND_COMMAND');

const onEscapeUp = () => {
  const selection = $getSelection();
  if ($isRangeSelection(selection) && selection.isCollapsed() && selection.anchor.offset === 0) {
    const container = $findMatchingParent(selection.anchor.getNode(), $isLegendContainerNode);

    if ($isLegendContainerNode(container)) {
      const parent = container.getParent<ElementNode>();
      if (
        parent !== null &&
        parent.getFirstChild<LexicalNode>() === container &&
        selection.anchor.key === container.getFirstDescendant<LexicalNode>()?.getKey()
      ) {
        container.insertBefore($createParagraphNode());
      }
    }
  }

  return false;
};

const onEscapeDown = () => {
  const selection = $getSelection();
  if ($isRangeSelection(selection) && selection.isCollapsed()) {
    const container = $findMatchingParent(selection.anchor.getNode(), $isLegendContainerNode);

    if ($isLegendContainerNode(container)) {
      const parent = container.getParent<ElementNode>();
      if (parent !== null && parent.getLastChild<LexicalNode>() === container) {
        const lastDescendant = container.getLastDescendant<LexicalNode>();
        if (
          lastDescendant !== null &&
          selection.anchor.key === lastDescendant.getKey() &&
          selection.anchor.offset === lastDescendant.getTextContentSize()
        ) {
          container.insertAfter($createParagraphNode());
        }
      }
    }
  }

  return false;
};

export const LegendPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([LegendContainerNode, LegendContentNode, LegendTitleNode])) {
      throw new Error(
        'LegendPlugin: LegendContainerNode, LegendContentNode or LegendTitleNode not registered on editor'
      );
    }

    return mergeRegister(
      // если у QuoteAuthorNode нет QuoteContainerNode в качестве родительского элемента,
      // то заменяем тип ноды на параграф
      editor.registerNodeTransform(LegendTitleNode, node => {
        const parent = node.getParent<ElementNode>();
        if (!$isLegendContainerNode(parent)) {
          node.replace($createParagraphNode().append(...node.getChildren<LexicalNode>()));
          return;
        }
      }),
      // если у QuoteContentNode нет QuoteContainerNode в качестве родительского элемента,
      // то заменяем переносим ноду на уровень вверх и удаляем текущий QuoteContentNode
      editor.registerNodeTransform(LegendContentNode, node => {
        const parent = node.getParent<ElementNode>();
        // console.log('parent 2', parent, node);
        if (!$isLegendContainerNode(parent)) {
          const children = node.getChildren<LexicalNode>();
          for (const child of children) {
            node.insertBefore(child);
          }
          node.remove();
        }
      }),
      // если внутри QuoteContainerNode есть QuoteContentNode и QuoteAuthorNode, то удаляем QuoteContainerNode
      editor.registerNodeTransform(LegendContainerNode, node => {
        const children = node.getChildren<LexicalNode>();
        if (
          children.length !== 3 ||
          !$isImageNode(children[2]) ||
          !$isLegendContentNode(children[1]) ||
          !$isLegendTitleNode(children[0])
        ) {
          // for (const child of children) {
          //   node.insertBefore(child);
          // }
          node.remove();
        }
      }),
      editor.registerCommand(KEY_ARROW_DOWN_COMMAND, onEscapeDown, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_ARROW_RIGHT_COMMAND, onEscapeDown, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_ARROW_UP_COMMAND, onEscapeUp, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_ARROW_LEFT_COMMAND, onEscapeUp, COMMAND_PRIORITY_LOW),
      editor.registerCommand(
        INSERT_LEGEND_COMMAND,
        () => {
          editor.update(() => {
            const title = $createLegendTitleNode().append(
              $createHeadingNode('h1').append($createTextNode('Введите заголовок').setMode('token'))
            );
            const content = $createLegendContentNode().append($createParagraphNode());
            const body = $createLegendContainerNode().append(title, content, $createImageNode({mode: 'block'}));

            $insertNodeToNearestRoot(body);
            content.select();
          });
          return true;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor]);

  return null;
};
