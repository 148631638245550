import {useCallback, useMemo} from 'react';
import {skipToken} from '@reduxjs/toolkit/query';

import {api as usersApi, GoalPartialDto, Goals} from 'api/generated/users-api';
import {api as adminApi} from 'api/generated/admin-api';

export const useUserGoals = (userId?: string) => {
  const {data: goalsData} = usersApi.endpoints.goalsIndex.useQuery(userId ? {userId, sort: 'created_date'} : skipToken);
  const {data: goalsDataDeleted} = usersApi.endpoints.goalsIndex.useQuery(
    userId ? {userId, sort: 'created_date', isDeleted: true} : skipToken
  );
  const {data: goalsTypes} = adminApi.endpoints.goalsTypesIndex.useQuery({});

  return useMemo(() => {
    if (!goalsData?.data || !goalsDataDeleted?.data || !goalsTypes?.data) return {goals: null, goalsTypes: null};
    return {goals: [...goalsData.data, ...goalsDataDeleted.data], goalsTypes: goalsTypes.data};
  }, [goalsData, goalsDataDeleted, goalsTypes]);
};

export const useGoalActions = (goal: Goals) => {
  const [deleteGoalAction, {isLoading: isDeleteLoading}] = usersApi.endpoints.goalSafeDelete.useMutation();
  const [restoreGoalAction, {isLoading: isRestoreLoading}] = usersApi.endpoints.goalSafeRestore.useMutation();
  const [updateGoalAction, {isLoading: isUpdateLoading}] = usersApi.endpoints.goalPatch.useMutation();
  const isLoading = isDeleteLoading || isRestoreLoading || isUpdateLoading;

  const goalId = `${goal._id}`;

  const updateAction = useCallback(
    async (goalPartialDto: GoalPartialDto) => {
      if (isLoading) return;
      await updateGoalAction({goalId, goalPartialDto});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [goalId, isLoading]
  );

  const deleteAction = useCallback(async () => {
    if (isLoading) return;
    await deleteGoalAction({goalId});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalId, isLoading]);

  const restoreAction = useCallback(async () => {
    if (isLoading) return;
    await restoreGoalAction({goalId});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalId, isLoading]);

  return {deleteAction, restoreAction, updateAction};
};
