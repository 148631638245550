import {NodeKey} from '@mdxeditor/editor';
import {DecoratorNode, LexicalNode, Spread, SerializedLexicalNode} from 'lexical';

import {ContentPreview} from 'modules/contents/components';

/**
 * A serialized representation of an {@link ContentNode}.
 */
export type SerializedContentNode = Spread<
  {
    contentId: string;
    type: 'content';
  },
  SerializedLexicalNode
>;

export class ContentNode extends DecoratorNode<JSX.Element> {
  __contentId: string;

  static getType(): string {
    return 'content';
  }

  static clone(node: ContentNode): ContentNode {
    return new ContentNode(node.__contentId, node.__key);
  }

  static importJSON(serializedNode: SerializedContentNode): ContentNode {
    const {contentId} = serializedNode;
    const node = $createContentNode({contentId});
    return node;
  }

  exportJSON(): SerializedContentNode {
    return {
      contentId: this.__contentId,
      type: 'content',
      version: 1,
    };
  }

  createDOM(): HTMLElement {
    return document.createElement('div');
  }

  updateDOM(): false {
    return false;
  }

  decorate(): any {
    return <ContentPreview contentId={this.__contentId} />;
  }

  constructor(contentId: string, key?: NodeKey) {
    super(key);
    this.__contentId = contentId;
  }
}

/**
 * Creates an {@link ImageNode}.
 * @param options - The payload to create an image. The keys map to the img tag attributes.
 */
export function $createContentNode(options: {contentId: string; key?: NodeKey}): ContentNode {
  const {contentId, key} = options;
  return new ContentNode(contentId, key);
}

export function $isContentNode(node: LexicalNode | null | undefined): node is ContentNode {
  return node instanceof ContentNode;
}
