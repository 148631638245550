import {Stack, Box, Typography, CircularProgress} from '@mui/material';

import {adminApi} from 'api';
import {croppedImagePath} from 'utils';

export const ContentPreview = ({contentId}: {contentId: string}) => {
  const {data: content, isLoading} = adminApi.endpoints.contentIndex.useQuery(
    {contentId},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  if (!isLoading && !content) return null;
  return (
    <Box>
      {content && (
        <Stack
          direction="row"
          alignItems="top"
          spacing={2}
          p={2}
          borderRadius={2}
          border="1px solid"
          borderColor="grey.300"
        >
          {content.fileId && (
            <Box
              flexShrink={1}
              component="img"
              borderRadius={1}
              src={croppedImagePath(content.fileId, {ratio: '16:9', maxWidth: 100})}
            />
          )}
          <Typography variant="body2" fontWeight={600}>
            {content.name}
          </Typography>
        </Stack>
      )}
      {isLoading && <CircularProgress />}
    </Box>
  );
};
