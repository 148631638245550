import Box from '@mui/material/Box';
import {
  BoldItalicUnderlineToggles,
  headingsPlugin,
  MDXEditor,
  BlockTypeSelect,
  MDXEditorMethods,
  InsertImage,
  toolbarPlugin,
  imagePlugin,
  linkPlugin,
  linkDialogPlugin,
  // quotePlugin,
} from '@mdxeditor/editor';

import {FC, useRef} from 'react';
import {useController} from 'react-hook-form';

import {filesApi} from 'api';
import {filePath} from 'utils';

import './style.css';
import {CommonFieldProps} from '../types';
import {MarkdownFieldOptions} from './types';
import {contentBlockPlugin, questPointBlockPlugin, InsertMaterial, InsertQuestPoint} from './plugins';

export const MarkdownField: FC<CommonFieldProps & MarkdownFieldOptions> = ({
  name,
  withImageUploading,
  withTextStyling,
  withContentInsert,
  questId,
}) => {
  const {field} = useController({name});
  const ref = useRef<MDXEditorMethods | null>(null);
  const [upload] = filesApi.useRootUploadMutation();

  return (
    <Box
      sx={{
        borderWidth: 1,
        borderColor: '#eeeeee',
        borderStyle: 'solid',
        borderRadius: 1,
        overflow: 'hidden',
        '&:hover': {
          borderColor: 'ActiveBorder',
        },
      }}
    >
      <MDXEditor
        ref={ref}
        markdown={field.value}
        onChange={field.onChange}
        contentEditableClassName="mdx-styles"
        plugins={[
          contentBlockPlugin(),
          questPointBlockPlugin(),
          headingsPlugin(),
          linkPlugin(),
          linkDialogPlugin(),
          imagePlugin({
            imageUploadHandler: async file => {
              const body = new FormData();
              body.append('file', file);
              const result = await upload({body: body as any});
              if ('data' in result) {
                return Promise.resolve(filePath(result.data[0]._id as string));
              }
              return Promise.reject();
            },
          }),
          toolbarPlugin({
            toolbarContents: () => (
              <>
                <BlockTypeSelect />
                {withTextStyling && <BoldItalicUnderlineToggles />}
                {withImageUploading && <InsertImage />}
                {withContentInsert && <InsertMaterial />}
                {questId && <InsertQuestPoint questId={questId} />}
              </>
            ),
          }),
        ]}
      />
    </Box>
  );
};
