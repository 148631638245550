import {pick} from 'lodash';

import {adminApi} from 'api';
import {Contents} from 'api/generated/admin-api';
import {accessBaseFields, accessFieldsValidation, accessInitials} from 'utils/form/accessBaseFields';
import {yupBooleanRequired, yupString, yupObject} from 'utils/validation';

import {FieldStructure} from 'components/Form/types';
import {ContentFormFieldsType} from './types';

const useGetCategories = () => {
  const {data} = adminApi.endpoints.contentsCategoriesIndex.useQuery({limit: 10000});
  return (data?.data || [])
    .filter(item => !!item.enabled)
    .map(item => ({value: item._id, name: item.name}))
    .concat([{value: undefined, name: 'Без категории'}]);
};

export const fields = (): FieldStructure[] => [
  {
    type: 'select',
    name: 'categoryId',
    label: 'Категория',
    options: useGetCategories,
  },
  {
    type: 'file',
    name: 'fileId',
    label: 'Обложка',
    multiple: false,
    variant: 'image',
  },
  ...accessBaseFields,
  {
    type: 'checkbox',
    name: 'withVideo',
    label: 'Содержит видео',
  },
  {
    type: 'checkbox',
    name: 'isSystem',
    label: 'Системный',
  },
  {
    type: 'checkbox',
    name: 'enabled',
    label: 'Активный',
  },
];

export const validation = yupObject({
  categoryId: yupString,
  fileId: yupString,
  isSystem: yupBooleanRequired,
  withVideo: yupBooleanRequired,
  enabled: yupBooleanRequired,
  access: accessFieldsValidation,
});

const initialValues: ContentFormFieldsType = {
  name: '',
  content: '',
  enabled: true,
  isSystem: false,
  withVideo: false,
  categoryId: undefined,
  fileId: undefined,
  description: '',
  type: '',
  value: '',
  ...accessInitials,
};

export const initials = (data?: Contents): ContentFormFieldsType =>
  data
    ? ({
        ...initialValues,
        ...pick(data, Object.keys(initialValues)),
        type: (data?.structure?.[0] as any)?.type || '',
        value: (data?.structure?.[0] as any)?.value || '',
      } as ContentFormFieldsType)
    : initialValues;
