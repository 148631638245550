import {Icon} from '@iconify/react';
import redoIcon from '@iconify/icons-material-symbols/redo-sharp';
import undoIcon from '@iconify/icons-material-symbols/undo-sharp';
import h3Icon from '@iconify/icons-material-symbols/format-h3-sharp';
import h2Icon from '@iconify/icons-material-symbols/format-h2-sharp';
import h1Icon from '@iconify/icons-material-symbols/format-h1-sharp';
import paragraphIcon from '@iconify/icons-material-symbols/format-paragraph-sharp';
import numberIcon from '@iconify/icons-material-symbols/format-list-numbered-sharp';
import bulletIcon from '@iconify/icons-material-symbols/format-list-bulleted-sharp';

import {useCallback, useEffect, useRef, useState} from 'react';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {IconButton, MenuItem, Select, Stack, ListItemIcon, ListItemText, Box, Typography} from '@mui/material';
import {
  $createParagraphNode,
  $getSelection,
  $isParagraphNode,
  $isRangeSelection,
  $isRootNode,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  LexicalEditor,
  NodeKey,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical';
import {$setBlocksType} from '@lexical/selection';
import {$findMatchingParent, $getNearestNodeOfType, mergeRegister} from '@lexical/utils';
import {$createHeadingNode, $isHeadingNode, HeadingTagType} from '@lexical/rich-text';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';

import {IS_APPLE} from '../utils';
import {BoldButton, ItalicButton} from '../Plugins/ToolbarPlugin';

const blockTypeToBlockName = {
  bullet: 'Список',
  h1: 'Заголовок 1',
  h2: 'Заголовок 2',
  h3: 'Заголовок 3',
  number: 'Нумерованный список',
  paragraph: 'Текст',
  quote: 'Цитата',
  check: '',
};

function BlockFormatDropDown({
  editor,
  blockType,
  disabled = false,
}: {
  blockType: keyof typeof blockTypeToBlockName;
  editor: LexicalEditor;
  disabled?: boolean;
}): JSX.Element {
  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createHeadingNode(headingSize));
        }
      });
    }
  };

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  return (
    <Select
      size="small"
      disabled={disabled}
      value={blockType}
      inputProps={{style: {display: 'flex'}}}
      renderValue={selected => (
        <Box alignItems="center" display="flex">
          <Typography>{blockTypeToBlockName[selected]}</Typography>
        </Box>
      )}
    >
      <MenuItem value="paragraph" onClick={formatParagraph}>
        <ListItemIcon>
          <Icon icon={paragraphIcon} />
        </ListItemIcon>
        <ListItemText primary="Текст" />
      </MenuItem>
      <MenuItem value="h1" onClick={() => formatHeading('h1')}>
        <ListItemIcon>
          <Icon icon={h1Icon} />
        </ListItemIcon>
        <ListItemText>Заголовок 1</ListItemText>
      </MenuItem>
      <MenuItem value="h2" onClick={() => formatHeading('h2')}>
        <ListItemIcon>
          <Icon icon={h2Icon} />
        </ListItemIcon>
        <ListItemText>Заголовок 2</ListItemText>
      </MenuItem>
      <MenuItem value="h3" onClick={() => formatHeading('h3')}>
        <ListItemIcon>
          <Icon icon={h3Icon} />
        </ListItemIcon>
        <ListItemText>Заголовок 3</ListItemText>
      </MenuItem>
      <MenuItem value="bullet" onClick={formatBulletList}>
        <ListItemIcon>
          <Icon icon={bulletIcon} />
        </ListItemIcon>
        <ListItemText>Список</ListItemText>
      </MenuItem>
      <MenuItem value="number" onClick={formatNumberedList}>
        <ListItemIcon>
          <Icon icon={numberIcon} />
        </ListItemIcon>
        <ListItemText>Нумерованный список</ListItemText>
      </MenuItem>
    </Select>
  );
}

export function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);

  const toolbarRef = useRef(null);
  const [blockType, setBlockType] = useState<keyof typeof blockTypeToBlockName>('paragraph');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedElementKey, setSelectedElementKey] = useState<NodeKey | null>(null);

  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [, setCanSetFontSize] = useState(false);

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, e => {
              const parent = e.getParent();
              return parent !== null && $isRootNode(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));

      setCanSetFontSize($isParagraphNode(element));

      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
          const type = parentList ? parentList.getListType() : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName);
          }
        }
      }
    }
  }, [activeEditor]);

  useEffect(() => {
    return mergeRegister(
      activeEditor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      activeEditor.registerCommand<boolean>(
        CAN_UNDO_COMMAND,
        payload => {
          setCanUndo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      activeEditor.registerCommand<boolean>(
        CAN_REDO_COMMAND,
        payload => {
          setCanRedo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [$updateToolbar, activeEditor, editor]);

  // const setFontSize = useCallback(
  //   (option: string) => {
  //     editor.update(() => {
  //       const selection = $getSelection();
  //       if ($isRangeSelection(selection)) {
  //         $patchStyleText(selection, {
  //           // eslint-disable-next-line no-useless-computed-key
  //           ['font-size']: option,
  //         });
  //       }
  //     });
  //   },
  //   [editor]
  // );

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        $updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, $updateToolbar]);

  return (
    <Stack ref={toolbarRef} direction="row" spacing={1} alignItems="center" sx={{px: 2}}>
      <Stack direction="row" alignItems="center" py={1.5}>
        <IconButton
          disabled={!canUndo}
          aria-label="Undo"
          size="small"
          title={IS_APPLE ? 'Undo (⌘Z)' : 'Undo (Ctrl+Z)'}
          onClick={() => activeEditor.dispatchCommand(UNDO_COMMAND, undefined)}
        >
          <Icon icon={undoIcon} />
        </IconButton>
        <IconButton
          disabled={!canRedo}
          aria-label="Redo"
          size="small"
          title={IS_APPLE ? 'Redo (⌘Y)' : 'Redo (Ctrl+Y)'}
          onClick={() => activeEditor.dispatchCommand(REDO_COMMAND, undefined)}
        >
          <Icon icon={redoIcon} />
        </IconButton>
      </Stack>
      <BlockFormatDropDown blockType={blockType} editor={editor} />
      <Stack direction="row" alignItems="center" py={1.5}>
        <BoldButton isActive={isBold} editor={activeEditor} />
        <ItalicButton isActive={isItalic} editor={activeEditor} />
      </Stack>
    </Stack>
  );
}
