/* eslint-disable @typescript-eslint/no-unused-vars */
import {FC, useMemo} from 'react';
import {useParams} from 'react-router';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import Card from '@mui/material/Card';
import Grid from '@mui/material//Grid';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';

import {adminApi} from 'api';
import useDialog from 'components/Dialog';
import {CommonPage} from 'components/ListPage';

import {WidgetVisualForm} from '../WidgetVisualForm';
import {WidgetVisualsDesignForm, WidgetVisualsPropForm, WidgetVisualsPropsAdd} from './WidgetVisualsPropForm';
import {WidgetVisualsDesignList, WidgetVisualsPropsList} from './WidgetVisualsPropsList';

import {WidgetVisualIdProp} from 'modules/widgets/types';
import {Tab, Tabs} from '@mui/material';
import {StringParam, useQueryParam, withDefault} from 'use-query-params';

export const WidgetVisualsStructure: FC<WidgetVisualIdProp> = ({visualId}) => {
  const [Dialog, openDialog] = useDialog();
  const [tab, setTab] = useQueryParam('tab', withDefault(StringParam, 'general'));
  const {data: visual} = adminApi.endpoints.widgetsVisualIndex.useQuery(visualId ? {visualId} : skipToken);

  return (
    <>
      <Tabs value={tab} onChange={(e, newTab) => setTab(newTab)}>
        <Tab value="general" label="Основные" />
        <Tab value="properties" label="Данные" />
        <Tab value="design" label="Дизайн" />
      </Tabs>
      <Grid item xs={12} component={Card}>
        {tab === 'general' && (
          <>
            <CardHeader title="Основные настройки" />
            <CardContent>
              <WidgetVisualForm visualId={visualId} />
            </CardContent>
          </>
        )}
        {tab === 'properties' && (
          <>
            <CardHeader title="Параметры" action={<Button onClick={openDialog}>Добавить</Button>} />
            <CardContent>
              <WidgetVisualsPropsList visualId={visualId} widgetsId={visual?.widgetsId || []} />
            </CardContent>
            <Dialog title="Создание параметра">
              <WidgetVisualsPropsAdd visualId={visualId} widgetsId={visual?.widgetsId || []} />
            </Dialog>
          </>
        )}
        {tab === 'design' && (
          <>
            <CardHeader title="Дизайн" action={<Button onClick={openDialog}>Добавить</Button>} />
            <CardContent>
              <WidgetVisualsDesignList visualId={visualId} />
            </CardContent>
            <Dialog title="Создание параметра дизайна">
              <WidgetVisualsDesignForm visualId={visualId} />
            </Dialog>
          </>
        )}
      </Grid>
    </>
  );
};
