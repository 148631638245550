import * as React from 'react';
import {Suspense} from 'react';
import {$applyNodeReplacement, DecoratorNode} from 'lexical';
import {EditorConfig, LexicalNode, NodeKey, SerializedLexicalNode, Spread} from 'lexical';

const FileComponent = React.lazy(
  // @ts-ignore
  () => import('./FileComponent')
);

const NODE_TYPE = 'file';

export interface FilePayload {
  key?: NodeKey;
  name?: string;
  size?: number;
  fileId?: string;
  fileType?: string;
}

export type SerializedFileNode = Spread<
  {
    fileType?: string;
    name?: string;
    size?: number;
    fileId?: string;
  },
  SerializedLexicalNode
>;

export class FileNode extends DecoratorNode<JSX.Element> {
  __fileId?: string;

  __name?: string;

  __size?: number;

  __fileType?: string;

  static getType(): string {
    return NODE_TYPE;
  }

  static clone(node: FileNode): FileNode {
    return new FileNode(node.__fileId, node.fileType, node.__name, node.__size);
  }

  static importJSON(serializedNode: SerializedFileNode): FileNode {
    const {name, fileId, fileType, size} = serializedNode;
    return $createFileNode({
      name,
      fileId,
      fileType,
      size,
    });
  }

  constructor(fileId?: string, fileType?: string, name?: string, size?: number, key?: NodeKey) {
    super(key);
    this.__size = size;
    this.__name = name;
    this.__fileId = fileId;
    this.__fileType = fileType;
  }

  exportJSON(): SerializedFileNode {
    return {
      type: NODE_TYPE,
      fileId: this.__fileId,
      fileType: this.__fileType,
      name: this.__name,
      size: this.__size,
      version: 1,
    };
  }

  /**
   *
   * @param name
   */
  setName(name: string): void {
    const writable = this.getWritable();
    writable.__name = name;
  }

  /**
   *
   * @param name
   */
  setSize(size: number): void {
    const writable = this.getWritable();
    writable.__size = size;
  }

  /**
   *
   * @param fileId
   * @param fileType
   */
  setFile(fileId: string, fileType?: string): void {
    const writable = this.getWritable();
    writable.__fileId = fileId;
    writable.__fileType = fileType;
  }

  /**
   *
   * @param config
   * @returns
   */
  createDOM(config: EditorConfig): HTMLElement {
    const span = document.createElement('div');
    const theme = config.theme;
    const className = theme.image;
    if (className !== undefined) span.className = className;
    return span;
  }

  updateDOM(): false {
    return false;
  }

  decorate(): JSX.Element {
    return (
      <Suspense fallback={null}>
        <FileComponent
          name={this.__name}
          size={this.__size}
          fileId={this.__fileId}
          fileType={this.__fileType}
          nodeKey={this.getKey()}
        />
      </Suspense>
    );
  }
}

export function $createFileNode({fileId, fileType, name, size, key}: FilePayload): FileNode {
  return $applyNodeReplacement(new FileNode(fileId, fileType, name, size, key));
}

export function $isFileNode(node: LexicalNode | null | undefined): node is FileNode {
  return node instanceof FileNode;
}
