import {Contents} from 'api/generated/admin-api';
import {enchanceColumns, nameColumn} from 'utils';

export const contentColumns = enchanceColumns<Contents>([
  nameColumn,
  {
    field: 'categoryId',
    headerName: 'Категория',
    type: 'string',
    flex: 0.2,
  },
  {
    field: 'isSysytem',
    headerName: 'Системный',
    type: 'boolean',
    flex: 0.2,
  },
]);
