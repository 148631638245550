import {skipToken} from '@reduxjs/toolkit/dist/query';
import {adminApi} from 'api';
import {useParams} from 'react-router';

export const useGetQuestPoint = () => {
  const {pointId} = useParams<'pointId'>();
  return adminApi.endpoints.questPointIndex.useQuery(pointId ? {pointId} : skipToken, {
    refetchOnMountOrArgChange: true,
  });
};
