import React from 'react';
import {contentPluginHooks} from './plugin';
import {Typography} from '@mui/material';
import {ButtonWithTooltip} from '@mdxeditor/editor';

import useDialogSelector from 'components/DialogSelector';
import {contentColumns} from 'modules/contents/containers';

export const InsertMaterial = React.forwardRef<HTMLButtonElement, Record<string, never>>((_, forwardedRef) => {
  const insertContent = contentPluginHooks.usePublisher('insertContent');
  const [Selector, openSelector] = useDialogSelector();

  return (
    <>
      <ButtonWithTooltip ref={forwardedRef} title="Insert image" onClick={openSelector}>
        <Typography>Content</Typography>
      </ButtonWithTooltip>
      <Selector
        single
        title="Выбор материала"
        initialSelection={[]}
        params={{}}
        onConfirm={([entryId]) => entryId && insertContent(entryId)}
        columns={contentColumns()}
        queryName="contentsIndex"
      />
    </>
  );
});
