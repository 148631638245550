import {useNavigate, useParams} from 'react-router';
import {atom, useAtom} from 'jotai';
import {useUpdateAtom} from 'jotai/utils';
import {skipToken} from '@reduxjs/toolkit/query';

import {Button, Stack, Typography} from '@mui/material';
import LoadingScreen from '__theme/components/LoadingScreen';

import {usersApi} from 'api';

import {getGoalPath} from 'modules/users/utils';
import useDialog from 'components/Dialog';
import {ImagesGallery} from 'components/ImagesGallery';

const goalReportIdModalAtom = atom<string | null>(null);

export const useOpenGoalReport = () => useUpdateAtom(goalReportIdModalAtom, Symbol.for('goalReportModal'));

export const GoalReportModal = () => {
  const {goalId} = useParams<'goalId'>();
  const navigate = useNavigate();
  const [Dialog] = useDialog();
  const [reportId, setReportId] = useAtom(goalReportIdModalAtom, Symbol.for('goalReportModal'));
  const {data, isLoading} = usersApi.endpoints.goalsReportData.useQuery(reportId ? {reportId} : skipToken);

  const report = data?.report;
  return (
    <Dialog title="Отчет по цели" open={Boolean(reportId)} onClose={() => setReportId(null)} contentSx={{p: 0}}>
      {isLoading && <LoadingScreen />}
      {report && (
        <Stack p={2}>
          {report?.filesId && <ImagesGallery filesId={report.filesId} wrapperProps={{boxShadow: 'none'}} />}
          {report?.description && <Typography>{report.description}</Typography>}
        </Stack>
      )}
      <Stack
        direction="row"
        justifyContent="flex-end"
        p={2}
        spacing={2}
        sx={{borderTop: '1px solid', borderTopColor: 'background.neutral'}}
      >
        {report && !goalId && (
          <Button
            variant="text"
            onClick={() => {
              setReportId(null);
              navigate(getGoalPath(report.userId, report.goalId));
            }}
          >
            Перейти к цели
          </Button>
        )}
        {report && (
          <Button variant="contained" color="error">
            Удалить*
          </Button>
        )}
        <Button variant="outlined" onClick={() => setReportId(null)}>
          Закрыть
        </Button>
      </Stack>
    </Dialog>
  );
};
