import {NodeKey} from '@mdxeditor/editor';
import {DecoratorNode, LexicalNode, Spread, SerializedLexicalNode} from 'lexical';

import {QuestPointPreview} from 'modules/events/components';

/**
 * A serialized representation of an {@link QuestPointNode}.
 */
export type SerializedQuestPointNode = Spread<
  {
    pointId: string;
    type: 'questPoint';
  },
  SerializedLexicalNode
>;

export class QuestPointNode extends DecoratorNode<JSX.Element> {
  __pointId: string;

  static getType(): string {
    return 'questPoint';
  }

  static clone(node: QuestPointNode): QuestPointNode {
    return new QuestPointNode(node.__pointId, node.__key);
  }

  static importJSON(serializedNode: SerializedQuestPointNode): QuestPointNode {
    const {pointId} = serializedNode;
    const node = $createQuestPointNode({pointId});
    return node;
  }

  exportJSON(): SerializedQuestPointNode {
    return {
      pointId: this.__pointId,
      type: 'questPoint',
      version: 1,
    };
  }

  createDOM(): HTMLElement {
    return document.createElement('div');
  }

  updateDOM(): false {
    return false;
  }

  decorate(): any {
    return <QuestPointPreview pointId={this.__pointId} />;
  }

  constructor(pointId: string, key?: NodeKey) {
    super(key);
    this.__pointId = pointId;
  }
}

/**
 * Creates an {@link ImageNode}.
 * @param options - The payload to create an image. The keys map to the img tag attributes.
 */
export function $createQuestPointNode(options: {pointId: string; key?: NodeKey}): QuestPointNode {
  const {pointId, key} = options;
  return new QuestPointNode(pointId, key);
}

export function $isQuestPointNode(node: LexicalNode | null | undefined): node is QuestPointNode {
  return node instanceof QuestPointNode;
}
