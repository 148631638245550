import {FC} from 'react';
import {Link} from 'react-router-dom';

import {Link as MuiLink} from '@mui/material';
import {GridRow} from '@mui/x-data-grid';
import {GridRowProps} from '@mui/x-data-grid/components/GridRow';

export type LinkRowProps = {
  prefix?: string;
  custom?: (props: Pick<GridRowProps, 'rowId' | 'row'>) => string;
} & GridRowProps;

export const LinkRow: FC<LinkRowProps> = ({custom, prefix = '', ...props}) => (
  <MuiLink component={Link} to={custom?.(props) || `${prefix}${props.rowId}`} color="text.primary">
    <GridRow {...props} />
  </MuiLink>
);
