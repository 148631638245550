import {ReactionsStats} from 'api/generated/users-api';
import {keyBy, sum} from 'lodash';

export const getUserPath = (userId?: string) => (!userId ? '' : `/app/users/list/${userId}`);

export const getGoalPath = (userId?: string, goalId?: string) =>
  !userId || !goalId ? '' : `/app/users/list/${userId}/activity/goal/${goalId}`;

export const getPostPath = (userId?: string, postId?: string) =>
  !userId || !postId ? '' : `/app/users/list/${userId}/activity/post/${postId}`;

export const getJobPath = (userId?: string, jobId?: string) =>
  !userId || !jobId ? '' : `/app/users/list/${userId}/activity/job/${jobId}`;

export const getStagePath = (userId?: string, questId?: string) =>
  !userId || !questId ? '' : `/app/users/list/${userId}/activity/stage/${questId}`;

export const getReactionsCount = (reactions?: ReactionsStats) =>
  reactions ? sum(Object.values(keyBy(reactions?.rewardValues, 'rewardId')).map(item => item.value)) : 0;
