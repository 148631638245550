import * as React from 'react';
import {
  $applyNodeReplacement,
  DecoratorNode,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical';

const Component = React.lazy(
  // @ts-ignore
  () => import('./ContentComponent')
);

const CONTENT_TYPE = 'content';

export interface ContentPayload {
  key?: NodeKey;
  contentId?: string[];
}

export type SerializedContentNode = Spread<
  {
    contentId?: string[];
  },
  SerializedLexicalNode
>;

export class ContentNode extends DecoratorNode<JSX.Element> {
  __contentId?: string[];

  static getType(): string {
    return CONTENT_TYPE;
  }

  static clone(node: ContentNode): ContentNode {
    return new ContentNode(node.__contentId, node.__key);
  }

  static importJSON(serializedNode: SerializedContentNode): ContentNode {
    const {contentId} = serializedNode;
    return $createContentNode({contentId});
  }

  constructor(contentId?: string[], key?: NodeKey) {
    super(key);
    this.__contentId = contentId;
  }

  exportJSON(): SerializedContentNode {
    return {
      type: CONTENT_TYPE,
      contentId: this.__contentId,
      version: 1,
    };
  }

  setContentId(contentId?: string[]): void {
    const writable = this.getWritable();
    writable.__contentId = contentId;
  }

  createDOM(config: EditorConfig): HTMLElement {
    const div = document.createElement('div');
    const theme = config.theme;
    const className = theme.image;
    if (className !== undefined) div.className = className;
    return div;
  }

  updateDOM(): false {
    return false;
  }

  decorate(): JSX.Element {
    return (
      <React.Suspense fallback={null}>
        <Component contentId={this.__contentId} nodeKey={this.getKey()} />
      </React.Suspense>
    );
  }
}

export const $createContentNode = ({contentId, key}: ContentPayload): ContentNode =>
  $applyNodeReplacement(new ContentNode(contentId, key));

export const $isContentNode = (node: LexicalNode | null | undefined): node is ContentNode =>
  node instanceof ContentNode;
