import {DOMConversionMap, ElementNode, LexicalNode, SerializedElementNode} from 'lexical';

export type SerializedLegendContentNode = SerializedElementNode;
export type LegendContentPayload = string;

export class LegendContentNode extends ElementNode {
  static getType(): string {
    return 'legend-content';
  }

  static clone(node: LegendContentNode): LegendContentNode {
    return new LegendContentNode(node.__key);
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('div');
    dom.classList.add('legend__content');
    return dom;
  }

  updateDOM(): boolean {
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return {};
  }

  static importJSON(): LegendContentNode {
    return $createLegendContentNode();
  }

  /**
   * @returns {boolean} Whether or not this node can be empty.
   */
  canBeEmpty(): boolean {
    return false;
  }

  exportJSON(): SerializedLegendContentNode {
    return {
      ...super.exportJSON(),
      type: 'legend-content',
      version: 1,
    };
  }
}

export function $createLegendContentNode(): LegendContentNode {
  return new LegendContentNode();
}

export function $isLegendContentNode(node: LexicalNode | null | undefined): node is LegendContentNode {
  return node instanceof LegendContentNode;
}
