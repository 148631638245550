import {useState} from 'react';
import {Tabs, Tab} from '@mui/material';
import {omit} from 'lodash';

import {adminApi} from 'api';

import {DataList} from 'components/DataList';
import {contentColumns} from '../contentColumns';
import {ContentItemForm} from '../ContentItemForm';
import {ContentFormFieldsType} from '../ContentItemForm/types';

export const ContentSelector = ({onSelect}: {onSelect: (id: string) => void}) => {
  const [add] = adminApi.endpoints.contentsAdd.useMutation();
  const [state, setState] = useState<'new' | 'select'>('select');

  const onSubmit = async (data: ContentFormFieldsType) => {
    const structure = [{type: data?.type, value: data?.value}] as object[];
    const contentDto = {...omit(data, ['type', 'value']), structure};

    const result = await add({contentDto});
    if ('data' in result) {
      onSelect(`${result.data._id}`);
    }
    return result;
  };
  return (
    <>
      <Tabs value={state} onChange={(e, newTab: 'new' | 'select') => setState(newTab)}>
        <Tab label="Выбрать из списка" value="select" />
        <Tab label="Создать новый" value="new" />
      </Tabs>
      {state === 'select' && (
        <DataList
          queryName="contentsIndex"
          sx={{height: '70vh'}}
          params={{limit: 10000}}
          columns={contentColumns()}
          onRowClick={({row}: any) => onSelect(`${row._id}`)}
          checkboxSelection={false}
        />
      )}
      {state === 'new' && <ContentItemForm onSubmit={onSubmit} />}
    </>
  );
};
