import {useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import {fill, merge} from 'lodash';

import {Box, Card, CardHeader, TextField} from '@mui/material';
import {BaseOptionChart} from '__theme/components/charts';

import {adminApi} from 'api';
import {chartScaleOptions, ChartScaleType, getChartCategoriesByScale} from 'utils/chart';

export const PaymentsValueChart = () => {
  const [scale, setScale] = useState<ChartScaleType>('month');
  const {data} = adminApi.endpoints.paymentsStatsPartnersDynamic.useQuery({scale});

  if (!data) return null;

  const categories = getChartCategoriesByScale(scale);

  const result: any = {};
  data.dynamic.forEach(item => {
    if (!result[item.currencyName]) result[item.currencyName] = {};
    if (!result[item.currencyName][item.dataAttr]) {
      result[item.currencyName][item.dataAttr] = {
        count: item.count,
        value: item.value,
      };
    } else {
      result[item.currencyName][item.dataAttr].count += item.count;
      result[item.currencyName][item.dataAttr].value += item.value;
    }
  });
  Object.keys(result).forEach((key, i, arr) => {
    Object.keys(result[key]).forEach(i => {
      arr.forEach(item => {
        if (!result[item][i]) result[item][i] = {count: 0, value: 0};
      });
    });
  });

  const chartData = Object.keys(result).map(key => ({
    name: key,
    data: fill(Array(categories.length), '').map((_, index) => result[key][categories[index]]?.value || 0),
  }));

  const chartOptions = merge(BaseOptionChart(), {
    xaxis: {categories},
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y: number) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)}`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card>
      <CardHeader
        title="Динамика платежей"
        action={
          <TextField
            select
            fullWidth
            value={scale}
            SelectProps={{native: true}}
            onChange={e => setScale(e.target.value as ChartScaleType)}
            sx={{
              '& fieldset': {border: '0 !important'},
              '& select': {pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2'},
              '& .MuiOutlinedInput-root': {borderRadius: 0.75, bgcolor: 'background.neutral'},
              '& .MuiNativeSelect-icon': {top: 4, right: 0, width: 20, height: 20},
            }}
          >
            {chartScaleOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </TextField>
        }
      />
      <Box sx={{p: 3, pb: 1}} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
};
