import {JsonEditor as Editor} from 'jsoneditor-react';

import 'jsoneditor-react/es/editor.min.css';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';

import {useState} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MUITextField from '@mui/material/TextField';
import {useController, Controller} from 'react-hook-form';
import {EditorState, convertToRaw, convertFromRaw} from 'draft-js';
import {draftToMarkdown, markdownToDraft} from 'markdown-draft-js';

import {TextFieldProps} from './types';
import {CommonFieldProps} from '../types';
import {DraftEditor} from '__theme/components/editor';
import {useDispatch} from 'react-redux';

const JSONField = ({label, name}: TextFieldProps & CommonFieldProps) => {
  const {field} = useController({name});
  return (
    <Stack spacing={1}>
      <Box pl={1.5}>
        <Typography variant="caption">{label}</Typography>
      </Box>
      <Editor
        value={field.value ? JSON.parse(field.value) : ''}
        onChange={data => field.onChange(JSON.stringify(data))}
        ace={ace}
        theme="ace/theme/github"
      />
    </Stack>
  );
};

const RichTextField = ({label, name}: TextFieldProps & CommonFieldProps) => {
  const {field} = useController({name});
  const initialState = convertFromRaw(markdownToDraft(field.value));
  const [editorState, setEditorState] = useState(() => EditorState.createWithContent(initialState));

  return (
    <Stack spacing={1}>
      <Box pl={1.5}>
        <Typography variant="caption">{label}</Typography>
      </Box>
      <DraftEditor
        simple
        placeholder={label}
        editorState={editorState}
        onEditorStateChange={value => {
          setEditorState(value);
          field.onChange(draftToMarkdown(convertToRaw(editorState.getCurrentContent())));
        }}
      />
    </Stack>
  );
};

export const TextField = ({
  label,
  name,
  size,
  minRows = 10,
  variant,
  updateFn,
  ...props
}: TextFieldProps & CommonFieldProps) => {
  const dispatch = useDispatch();
  if (variant === 'richText') return <RichTextField {...props} name={name} label={label} variant={variant} />;
  if (variant === 'json') return <JSONField {...props} name={name} label={label} variant={variant} />;
  return (
    <Controller
      name={name}
      render={({fieldState, field: {onChange, ...field}}) => (
        <MUITextField
          {...field}
          onChange={e => {
            if (variant !== 'number' || !e.target.value) {
              updateFn?.(e.target.value, {dispatch});
              onChange(e);
            } else {
              const output = parseInt(e.target.value, 10);
              onChange(isNaN(output) ? 0 : output);
            }
          }}
          label={label}
          fullWidth
          error={fieldState.invalid}
          variant="outlined"
          size={size}
          disabled={props.disabled}
          multiline={variant === 'text'}
          minRows={variant === 'text' ? minRows : undefined}
          type={variant === 'number' ? 'number' : variant === 'password' ? 'password' : 'text'}
        />
      )}
    />
  );
};
