import {Avatar, Stack, Typography} from '@mui/material';
import {croppedImagePath} from 'utils';
import {Users} from 'api/generated/users-api';

export const UserItem = ({user}: {user: Users}) => {
  const name = `${user.name} ${user.surname}`;
  const initials = `${user.name?.[0]}${user.surname?.[0]}`;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Avatar src={user.fileId && croppedImagePath(user.fileId, {ratio: '1:1'})} sx={{width: 32, height: 32}}>
        {initials}
      </Avatar>
      <Typography variant="subtitle2">{name}</Typography>
    </Stack>
  );
};
