import {lazy, Suspense} from 'react';
import {Navigate, RouteObject, useParams} from 'react-router';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {PATH_APP} from 'router/routes';
import {adminApi} from 'api';
import {Mailings, Users} from 'api/generated/admin-api';

import groupRoute from 'modules/groups';
import streamsRoute from 'modules/streams';
import {AccessGuard} from 'modules/auth/containers';
import {UserCardLayout} from 'modules/users/containers';

import IsExistsContainer from 'components/IsExistsContainer';
import {BreadcrumbsProvider} from 'components/Breadcrumbs';
import {BreadcrumbsSet} from 'components/Breadcrumbs/BreadcrumbsSet';
import {useGetMailing} from './pages/MailingPage/hook';

const UserCardActivity = lazy(() => import('./pages/UserCardActivity'));
const UserCardInfo = lazy(() => import('./pages/UserCardInfo'));
const UserCardPermissions = lazy(() => import('./pages/UserCardPermissions'));
const UserCardPayments = lazy(() => import('./pages/UserCardPayments'));
const UsersList = lazy(() => import('./pages/UsersList'));
const MailingPage = lazy(() => import('./pages/MailingPage'));
const MailingsList = lazy(() => import('./pages/MailingsList'));
const GoalPage = lazy(() => import('./pages/GoalPage'));
const PublicationPage = lazy(() => import('./pages/PublicationPage'));
const JobPage = lazy(() => import('./pages/JobPage'));
const StagePage = lazy(() => import('./pages/StagePage'));

const useGetUser = () => {
  const {userId} = useParams<'userId'>();
  return adminApi.useUserIndexQuery(userId ? {userId} : skipToken);
};

const router: RouteObject = {
  path: 'users',
  element: <BreadcrumbsProvider name="Пользователи" href={PATH_APP.user.root} />,
  children: [
    {index: true, element: <Navigate to="./list" replace />},
    streamsRoute,
    groupRoute,
    {
      path: 'list',
      element: (
        <AccessGuard endpoint="usersIndex">
          <BreadcrumbsSet name="Список пользователей" href={PATH_APP.user.list.root} />
        </AccessGuard>
      ),
      children: [
        {index: true, element: <UsersList />},
        {
          path: ':userId',
          element: (
            <AccessGuard endpoint="userIndex">
              <IsExistsContainer<Users> useFetch={useGetUser}>
                {user => (
                  <BreadcrumbsSet name={`${user.name || ''} ${user.surname || ''}`}>
                    <UserCardLayout />
                  </BreadcrumbsSet>
                )}
              </IsExistsContainer>
            </AccessGuard>
          ),
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<></>}>
                  <UserCardInfo />
                </Suspense>
              ),
            },
            {
              path: 'activity',
              children: [
                {
                  index: true,
                  element: (
                    <Suspense fallback={<></>}>
                      <UserCardActivity />
                    </Suspense>
                  ),
                },
                {
                  path: 'goal/:goalId',
                  element: (
                    <Suspense fallback={<></>}>
                      <GoalPage />
                    </Suspense>
                  ),
                },
                {
                  path: 'post/:postId',
                  element: (
                    <Suspense fallback={<></>}>
                      <PublicationPage />
                    </Suspense>
                  ),
                },
                {
                  path: 'job/:jobId',
                  element: (
                    <Suspense fallback={<></>}>
                      <JobPage />
                    </Suspense>
                  ),
                },
                {
                  path: 'stage/:stageId',
                  element: (
                    <Suspense fallback={<></>}>
                      <StagePage />
                    </Suspense>
                  ),
                },
              ],
            },
            {
              path: 'permissions',
              element: (
                <AccessGuard endpoint="userPermissionsIndex">
                  <Suspense fallback={<></>}>
                    <UserCardPermissions />
                  </Suspense>
                </AccessGuard>
              ),
            },
            {
              path: 'payments',
              element: (
                <Suspense fallback={<></>}>
                  <UserCardPayments />
                </Suspense>
              ),
            },
          ],
        },
      ],
    },
    {
      path: 'mailings',
      element: (
        <AccessGuard endpoint="mailingsIndex">
          <BreadcrumbsSet name="Рассылки" href={PATH_APP.user.mailings.root} />
        </AccessGuard>
      ),
      children: [
        {
          index: true,
          element: <MailingsList />,
        },
        {
          path: 'create',
          element: (
            <AccessGuard endpoint="mailingsAdd">
              <BreadcrumbsSet name="Новая рассылка">
                <MailingPage />
              </BreadcrumbsSet>
            </AccessGuard>
          ),
        },
        {
          path: ':mailingId',
          element: (
            <AccessGuard endpoint="mailingIndex">
              <IsExistsContainer<Mailings> useFetch={useGetMailing}>
                {mailing => <BreadcrumbsSet name={mailing.name} />}
              </IsExistsContainer>
            </AccessGuard>
          ),
          children: [{index: true, element: <MailingPage />}],
        },
      ],
    },
  ],
};

export default router;
