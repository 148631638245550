import {PropsWithChildren} from 'react';
import {Outlet} from 'react-router';

import LoadingScreen from '__theme/components/LoadingScreen';

import {useGetUser} from 'modules/users/pages/UserCardInfo/hook';
import {UserCardHeader} from 'modules/users/components/UserCardHeader';
import {GoalReportModal} from 'modules/users/components/GoalReportModal';
import {CommentsModal} from 'modules/users/components/CommentsModal';
import {CommonPage} from 'components/ListPage';

export const UserCardLayout = ({children}: PropsWithChildren<{}>) => {
  const {isLoading, isUninitialized, data} = useGetUser();
  if (!data) return null;
  if (isUninitialized && isLoading) return <LoadingScreen />;

  const user = data.account;
  const heading = `${user.name || ''} ${user.surname || ''}`;
  return (
    <>
      <CommonPage title="Пользователи" heading={heading} maxWidth="xl">
        <UserCardHeader user={user} />
        {children ? <>{children}</> : <Outlet />}
      </CommonPage>
      <GoalReportModal />
      <CommentsModal />
    </>
  );
};
