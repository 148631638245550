import {useEffect, useState} from 'react';
import {useAppDispatch} from 'store';
import {useForm} from 'react-hook-form';
import {useSnackbar} from 'notistack';
import {yupResolver} from '@hookform/resolvers/yup';

import {Button, Stack, TextField, Typography} from '@mui/material';

import {authApi} from 'api';
import {yupObject, yupStringRequired} from 'utils/validation';

import {Form} from 'components/Form';

import {ConfirmCode} from '../ConfirmCode';
import {startSesstion} from '../../store/actions';
import {fields, initials, validation} from './fields';

const schema = yupObject({
  login: yupStringRequired.test('login', 'Некорретное значение', value => {
    if (!value) return false;
    const isValidEmail = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(value);
    const isValidPhone = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(value);
    return isValidEmail || isValidPhone;
  }),
}).required();

export const LoginBlock = () => {
  const [request] = authApi.useLoginRouteRequestCodeMutation();
  const [login, {isSuccess, data}] = authApi.useLoginRouteLoginMutation();
  const {enqueueSnackbar} = useSnackbar();
  const dispatch = useAppDispatch();

  const [mode, setMode] = useState<'login' | 'phone' | 'email' | 'confirm'>('login');
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: {errors},
  } = useForm<{login: string}>({resolver: yupResolver(schema) as any});
  const onSubmitRequest = handleSubmit(async data => {
    const result = await request({body: data});
    if ('data' in result) {
      setMode('confirm');
    } else if ('error' in result) {
      enqueueSnackbar((result.error as any)?.data?.message || 'Не удалось отправить код', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  });

  const onSubmitLogin = (values: typeof initials) => login({body: values});
  useEffect(() => {
    if (isSuccess && data) dispatch(startSesstion(data.accessToken));
  }, [isSuccess, data, dispatch]);
  return (
    <>
      {mode === 'login' && (
        <Stack spacing={1}>
          <Form
            initials={initials}
            onSubmit={onSubmitLogin}
            validation={validation}
            fields={fields}
            buttonsStructure={[
              {
                text: 'Войти',
                type: 'submit',
              },
            ]}
          />
          <Button onClick={() => setMode('phone')}>Войти по номеру телефона/имейлу</Button>
        </Stack>
      )}
      {['email', 'phone'].includes(mode) && (
        <Stack spacing={1}>
          <form onSubmit={onSubmitRequest}>
            <Stack spacing={1}>
              {mode === 'phone' && (
                <TextField {...register('login')} defaultValue="+7" label="Телефон" variant="outlined" />
              )}
              {mode === 'email' && <TextField {...register('login')} label="Email" variant="outlined" />}
              <Typography variant="subtitle2" color="error.main">
                {errors?.login?.message}
              </Typography>
              <Button type="submit" variant="contained" size="medium">
                Выслать код
              </Button>
            </Stack>
          </form>
          <Stack spacing={0.5}>
            {mode === 'email' && (
              <Button
                onClick={() => {
                  setMode('phone');
                  setValue('login', '+7');
                }}
              >
                Войти по номеру телефона
              </Button>
            )}
            {mode === 'phone' && (
              <Button
                onClick={() => {
                  setMode('email');
                  setValue('login', '');
                }}
              >
                Войти по Email адресу
              </Button>
            )}
            <Button onClick={() => setMode('login')}>Войти по логину</Button>
          </Stack>
        </Stack>
      )}
      {mode === 'confirm' && (
        <ConfirmCode login={getValues('login')} request={(login: string) => request({body: {login}})} />
      )}
    </>
  );
};
