import {useParams} from 'react-router';
import {skipToken} from '@reduxjs/toolkit/query';

import {Stack, Typography} from '@mui/material';
import LoadingScreen from '__theme/components/LoadingScreen';

import {usersApi} from 'api';

import {UserQuestsTable} from '../../components/UserQuestsTable';

export const UserCardQuests = () => {
  const {userId} = useParams<'userId'>();
  const {data: stagesData} = usersApi.endpoints.stagesIndex.useQuery(userId ? {usersId: [userId]} : skipToken);
  const stages = stagesData?.data || [];

  if (!stagesData) return <LoadingScreen />;
  return (
    <Stack spacing={3}>
      <Stack borderRadius={2} sx={{boxShadow: theme => theme.customShadows.z16}}>
        <Typography variant="h6" p={3}>
          Активные квесты
        </Typography>
        <UserQuestsTable data={stages} />
      </Stack>
      {/*<Stack borderRadius={2} sx={{boxShadow: theme => theme.customShadows.z16}}>*/}
      {/*  <Typography variant="h6" p={3}>*/}
      {/*    Доступные квесты*/}
      {/*  </Typography>*/}
      {/*</Stack>*/}
    </Stack>
  );
};
