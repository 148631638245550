import {lazy} from 'react';
import {adminApi} from 'api';
import {Navigate, RouteObject, useParams} from 'react-router';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {PATH_APP} from 'router/routes';

import {AccessGuard} from 'modules/auth/containers';
import IsExistsContainer from 'components/IsExistsContainer';
import {BreadcrumbsSet} from 'components/Breadcrumbs/BreadcrumbsSet';
import {Groups} from 'api/generated/admin-api';
import dashboardRouter from 'modules/dashboard/routes';
import {DashboardContextProvider} from 'modules/dashboard/containers';
import {getGroupPath} from './utils';

const GroupCard = lazy(() => import('./pages/GroupCard'));
const GroupsList = lazy(() => import('./pages/GroupsList'));

const useGetGroup = () => {
  const {groupId} = useParams<'groupId'>();
  return adminApi.useGroupIndexQuery(groupId ? {groupId} : skipToken);
};

const router: RouteObject = {
  path: 'groups',
  element: (
    <AccessGuard endpoint="groupsIndex">
      <BreadcrumbsSet name="Группы" href={PATH_APP.group.list} />
    </AccessGuard>
  ),
  children: [
    {index: true, element: <Navigate to="./list" replace />},
    {
      path: 'list',
      children: [
        {index: true, element: <GroupsList />},
        {
          path: ':groupId',
          element: (
            <AccessGuard endpoint="groupIndex">
              <IsExistsContainer<Groups> useFetch={useGetGroup}>
                {group => (
                  <DashboardContextProvider groupId={[group._id!].concat(group.subGroupsId || [])}>
                    <BreadcrumbsSet name={group.name} href={getGroupPath(group._id!)} />
                  </DashboardContextProvider>
                )}
              </IsExistsContainer>
            </AccessGuard>
          ),
          children: [{index: true, element: <GroupCard />}, dashboardRouter()],
        },
      ],
    },
  ],
};

export default router;
