import {minutesToMilliseconds} from 'date-fns/esm';
import {useSelector} from 'react-redux';
import {useAppDispatch} from 'store';
// import {DynamicModuleLoader} from 'redux-dynamic-modules';
import {FunctionComponent, PropsWithChildren, useEffect} from 'react';

import {adminApi} from 'api';
import {useMyId} from 'utils';
import {useProfileIndexQuery} from 'api/generated/admin-api';

import {TAuthProvider} from './types';
// import {getAuthModule} from '../../module';
import {MODULE_NAME} from '../../constants';
import {restoreSession, destroySession} from '../../store/actions';

const AuthGetRoutes = ({children}: PropsWithChildren<{}>) => {
  const myId = useMyId();
  const {isLoading} = adminApi.endpoints.appRoutes.useQuery(undefined, {
    skip: !myId,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    pollingInterval: minutesToMilliseconds(10),
  });

  if (isLoading) return null;
  return <>{children}</>;
};

const AuthProvider: FunctionComponent<TAuthProvider> = ({children}) => {
  const dispatch = useAppDispatch();
  const {data, error} = useProfileIndexQuery();
  const isAuthInit = useSelector((state: any) => state[MODULE_NAME].authInit);

  useEffect(() => {
    if (error && 'status' in error && error.status) dispatch(destroySession());
    if (data) dispatch(restoreSession());
  }, [error, data, dispatch]);

  if (!isAuthInit) return null;
  return <>{children}</>;
};

export const AuthProviderLoader: FunctionComponent<TAuthProvider> = ({children}) => {
  return (
    // <DynamicModuleLoader modules={[getAuthModule()]}>
    <AuthProvider>
      <AuthGetRoutes>{children}</AuthGetRoutes>
    </AuthProvider>
    // </DynamicModuleLoader>
  );
};

export default AuthProviderLoader;
