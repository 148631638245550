import {FC} from 'react';
import {Link} from 'react-router-dom';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {Icon} from '@iconify/react';
import eyeIcon from '@iconify/icons-ant-design/eye-fill';

import {croppedImagePath} from 'utils';
import {EntityPreviewProps} from './types';

export const EntityPreview: FC<EntityPreviewProps> = ({fileId, title, subTitle, to, noBorder, p, target}) => {
  return (
    <Stack
      to={to}
      target={target}
      component={Link}
      direction="row"
      spacing={2}
      sx={{
        p,
        alignItems: 'top',
        borderWidth: noBorder ? 0 : 1,
        borderRadius: 1,
        borderStyle: 'solid',
        borderColor: 'grey.300',
        textDecoration: 'none',
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: 'grey.200',
        },
      }}
    >
      {fileId && (
        <Box
          mt={0.25}
          flexShrink={1}
          component="img"
          width={40}
          height={40}
          sx={{borderRadius: 1}}
          src={croppedImagePath(fileId, {ratio: '1:1', maxWidth: 100})}
        />
      )}
      <Stack direction="row" width={1} alignItems="center" justifyContent="space-between" spacing={2}>
        <Stack spacing={0}>
          <Typography variant="body2" sx={{fontWeight: 600}} color="black">
            {title}
          </Typography>
          {subTitle && (
            <Typography variant="body2" color="text.secondary">
              {subTitle}
            </Typography>
          )}
        </Stack>
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          width={24}
          height={24}
          color="black"
          borderRadius="50%"
          bgcolor="background.neutral"
          sx={{'&:hover': {color: 'primary.main'}}}
        >
          <Icon icon={eyeIcon} width={16} height={16} />
        </Box>
      </Stack>
    </Stack>
  );
};
