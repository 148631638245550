import {skipToken} from '@reduxjs/toolkit/dist/query';

import {adminApi} from 'api';
import {Form} from 'components/Form';
import {WidgetVisualFormProps} from 'modules/widgets/types';

import {fields, validation} from './fields';
import {useCreateWidgetVisual} from './createWidgetVisual.hook';

export const WidgetVisualForm = ({visualId, onReset, onSuccess}: WidgetVisualFormProps) => {
  const {data} = adminApi.endpoints.widgetsVisualIndex.useQuery(visualId ? {visualId} : skipToken);
  const {onSubmit, initials} = useCreateWidgetVisual(data, onSuccess);

  return <Form fields={fields} onSubmit={onSubmit} validation={validation} onReset={onReset} initials={initials} />;
};
