import {useController} from 'react-hook-form';
import Box from '@mui/system/Box';
import {UploadMultiFile, UploadSingleFile} from '__theme/components/upload';

import {useFileUpload} from './hooks';
import {FileUploadProps} from './types';
import {CommonFieldProps} from '../types';
import VideoUpload from './VideoUpload';

const acceptByVariant = {
  image: 'image/*',
  video: '.mov, .mp4',
  file: '.doc, .docx, .csv, .xls, .xlsx, .pdf',
};

export const FileUpload = (props: CommonFieldProps & FileUploadProps) => {
  const {onDrop, files, removeAll} = useFileUpload(props);
  const {label, multiple, name, variant = 'image'} = props;
  const {
    field: {value},
    fieldState: {error},
  } = useController({name});

  if (props.variant === 'video') return <VideoUpload {...props} />;
  return (
    <Box>
      <Box sx={{typography: 'body1', mb: 2}}>{label}</Box>
      {!multiple && (
        <UploadSingleFile
          error={Boolean(error)}
          onDrop={onDrop}
          file={files[0]}
          fileId={value}
          accept={acceptByVariant[variant]}
          sx={{height: 150}}
        />
      )}
      {multiple && (
        <UploadMultiFile
          onDrop={onDrop}
          error={Boolean(error)}
          files={files}
          showPreview
          accept={acceptByVariant[variant]}
          onRemove={() => {}}
          onRemoveAll={removeAll}
        />
      )}
    </Box>
  );
};

export default FileUpload;
