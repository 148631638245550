import {useCallback, useState} from 'react';
import {createPortal} from 'react-dom';
import {$getNodeByKey, $getSelection, $isRangeSelection, LexicalEditor, LexicalNode} from 'lexical';
import {$getNearestNodeOfType} from '@lexical/utils';

import {FloatingToolbar} from 'components/LexicalComposer/components/FloatingToolbar';

import {BlockNode} from './BlockNode';
import {UpdateBlockColorButton} from '../ToolbarPlugin';
import {getSelectedNode} from '../../utils';
import {IconButton, Stack} from '@mui/material';
import {Icon} from '@iconify/react';
import deleteIcon from '@iconify/icons-material-symbols/delete-outline-sharp';

export const useFloatingBlockToolbar = (anchorElem: HTMLElement | null, editor: LexicalEditor) => {
  const findBlockNode = useCallback((selectedNode: LexicalNode) => $getNearestNodeOfType(selectedNode, BlockNode), []);
  const [blockKey, setBlockKey] = useState<string | undefined>(undefined);
  const updateToolbar = useCallback((setState: (value: boolean) => void) => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) return;
    const node = $getNearestNodeOfType(getSelectedNode(selection), BlockNode);
    setState(!!node);
    setBlockKey(node?.getKey());
  }, []);

  const onDelete = useCallback(() => {
    editor.update(() => {
      if (!blockKey) return;
      $getNodeByKey(blockKey)?.remove();
    });
  }, [blockKey, editor]);

  if (!anchorElem) return null;
  return createPortal(
    <FloatingToolbar anchorElem={anchorElem} getParentNode={findBlockNode} setToolbarState={updateToolbar}>
      <Stack direction="row" gap={0.5} p={0.5}>
        <UpdateBlockColorButton editor={editor} selectedElementKey={blockKey} />
        <IconButton size="small" onClick={onDelete} color="error">
          <Icon icon={deleteIcon} />
        </IconButton>
      </Stack>
    </FloatingToolbar>,
    anchorElem
  );
};
