import ReactApexChart from 'react-apexcharts';
import {merge} from 'lodash';

import {Card, CardHeader, Box, useTheme} from '@mui/material';
import {BaseOptionChart} from '__theme/components/charts';
import {fNumber} from '__theme/utils/formatNumber';

import {adminApi} from 'api';

export const PaymentsStatusChart = () => {
  const theme = useTheme();
  const {data} = adminApi.endpoints.paymentsStatsStatusSummary.useQuery();

  if (!data) return null;

  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      theme.palette.success.main,
      theme.palette.chart.blue[0],
      theme.palette.chart.violet[0],
      theme.palette.warning.main,
      theme.palette.chart.red[0],
    ],
    labels: ['Оплаченные', 'Новые', 'returned', 'Ожидают подтверждения', 'Отклонённые'],
    stroke: {colors: [theme.palette.background.paper]},
    legend: {horizontalAlign: 'center'},
    dataLabels: {enabled: true, dropShadow: {enabled: false}},
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName: string) => fNumber(seriesName),
        title: {
          formatter: (seriesName: string) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: {donut: {labels: {show: false}}},
    },
  });
  const chartData = [0, 0, 0, 0, 0];
  data.forEach(i => {
    if (i.status === 'paid') chartData[0] += i.count;
    if (i.status === 'new') chartData[1] += i.count;
    if (i.status === 'returned') chartData[2] += i.count;
    if (i.status === 'wait_payment') chartData[3] += i.count;
    if (i.status === 'error' || i.status === 'declined') chartData[4] += i.count;
  });

  return (
    <Card>
      <CardHeader
        title="Платежи по статусу"
        // subheader="subheader"
      />
      <Box>
        <ReactApexChart type="pie" series={chartData} options={chartOptions} height={280} />
      </Box>
    </Card>
  );
};
