import {
  DOMConversionMap,
  EditorConfig,
  ElementNode,
  LexicalNode,
  NodeKey,
  SerializedElementNode,
  Spread,
} from 'lexical';
import {addClassNamesToElement} from '@lexical/utils';

export type SerializedBlockNode = Spread<
  {
    color: string;
  },
  SerializedElementNode
>;

export class BlockNode extends ElementNode {
  __color: string;

  constructor(color: string, key?: NodeKey) {
    super(key);
    this.__color = color;
  }

  static getType(): string {
    return 'block';
  }

  static clone(node: BlockNode): BlockNode {
    return new BlockNode(node.__color, node.__key);
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = document.createElement('div');
    dom.style.backgroundColor = this.__color;
    dom.classList.add('color-block');
    if (typeof config.theme.layoutContainer === 'string') addClassNamesToElement(dom, config.theme.layoutContainer);
    return dom;
  }

  updateDOM(prevNode: BlockNode, dom: HTMLElement): boolean {
    if (prevNode.__color !== this.__color) {
      dom.style.backgroundColor = this.__color;
    }
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return {};
  }

  static importJSON(json: SerializedBlockNode): BlockNode {
    return $createBlockNode(json.color);
  }

  canBeEmpty(): boolean {
    return false;
  }

  exportJSON(): SerializedBlockNode {
    return {
      ...super.exportJSON(),
      color: this.__color,
      type: 'block',
      version: 1,
    };
  }

  getColor(): string {
    return this.getLatest().__color;
  }

  setColor(color: string) {
    this.getWritable().__color = color;
  }
}

export function $createBlockNode(keyword: string): BlockNode {
  return new BlockNode(keyword);
}

export function $isBlockNode(node: LexicalNode | null | undefined): boolean {
  return node instanceof BlockNode;
}
