import {useCallback, useMemo} from 'react';
import {skipToken} from '@reduxjs/toolkit/query';

import {api as adminApi} from 'api/generated/admin-api';
import {api as usersApi, EditPublicationDto, Publications} from 'api/generated/users-api';

export const useUserPublications = (userId?: string) => {
  const {data: user} = adminApi.endpoints.userIndex.useQuery(userId ? {userId} : skipToken);
  const streamId = user?.stats?.streamsId;
  const {data: publicationsData} = usersApi.endpoints.publicationsIndex.useQuery(
    userId && user && streamId ? {userId, streamId, sort: 'created_date'} : skipToken
  );
  const {data: publicationsDataDeleted} = usersApi.endpoints.publicationsIndex.useQuery(
    userId && user && streamId ? {userId, streamId, sort: 'created_date', isDeleted: true} : skipToken
  );

  return useMemo(() => {
    if (!publicationsData?.data || !publicationsDataDeleted?.data) return null;
    return [...publicationsData.data, ...publicationsDataDeleted.data];
  }, [publicationsData, publicationsDataDeleted]);
};

export const usePublicationActions = (post: Publications) => {
  const [deletePostAction, {isLoading: isDeleteLoading}] = usersApi.endpoints.publicationSafeDelete.useMutation();
  const [restorePostAction, {isLoading: isRestoreLoading}] = usersApi.endpoints.publicationSafeRestore.useMutation();
  const [updatePostAction, {isLoading: isUpdateLoading}] = usersApi.endpoints.publicationPatch.useMutation();
  const isLoading = isDeleteLoading || isRestoreLoading || isUpdateLoading;

  const postId = `${post._id}`;

  const updateAction = useCallback(
    async (editPublicationDto: EditPublicationDto) => {
      if (isLoading) return;
      await updatePostAction({postId, editPublicationDto});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [postId, isLoading]
  );

  const deleteAction = useCallback(async () => {
    if (isLoading) return;
    await deletePostAction({postId});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId, isLoading]);

  const restoreAction = useCallback(async () => {
    if (isLoading) return;
    await restorePostAction({postId});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId, isLoading]);

  return {deleteAction, restoreAction, updateAction};
};
