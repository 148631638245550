import {TableRow, TableRowProps} from '@mui/material';

export const DataTableRow = ({children, onClick, sx, ...props}: TableRowProps) => (
  <TableRow
    onClick={onClick}
    sx={
      onClick
        ? {
            cursor: 'pointer',
            bgcolor: 'background.default',
            transition: 'background-color .2s',
            '&:hover': {bgcolor: 'background.neutral'},
            ...sx,
          }
        : sx
    }
    {...props}
  >
    {children}
  </TableRow>
);
