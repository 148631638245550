import {Stack, Typography} from '@mui/material';

import {Publications} from 'api/generated/users-api';

import {Form} from 'components/Form';
import {useConfirmDialog} from 'components/Dialog';

import {fields, validation} from './fields';
import {usePublicationForm} from './hook';

export const PublicationForm = ({post}: {post: Publications}) => {
  const {initials, onSubmit, onRestore, onDelete} = usePublicationForm(post);
  const [ConfirmDelete, openConfirmDelete] = useConfirmDialog({
    onConfirm: onDelete,
    title: 'Подтвердить удаление?',
  });

  return (
    <>
      <ConfirmDelete />
      <Stack p={3} spacing={1.5} boxShadow={theme => theme.customShadows.z16} borderRadius={2}>
        <Typography variant="h4">Публикация</Typography>
        <Form
          fields={fields}
          initials={initials}
          validation={validation}
          onSubmit={onSubmit}
          buttonsStructure={[
            post.deletedAt
              ? {
                  text: 'Восстановить',
                  color: 'secondary',
                  onClick: onRestore,
                }
              : {
                  text: 'Удалить',
                  color: 'error',
                  onClick: openConfirmDelete,
                },
            {
              text: 'Сохранить',
              type: 'submit',
            },
          ]}
        />
      </Stack>
    </>
  );
};
