import {createPortal} from 'react-dom';
import {useCallback, useMemo, useState} from 'react';

import {$setBlocksType} from '@lexical/selection';
import {$createHeadingNode} from '@lexical/rich-text';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';

import {$getSelection, $isRangeSelection, LexicalEditor, TextNode} from 'lexical';
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  useBasicTypeaheadTriggerMatch,
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import {INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND} from '@lexical/list';

import {Box, ListItem, ListItemIcon, ListItemText, MenuList} from '@mui/material';
import {Icon, IconifyIcon} from '@iconify/react';
// import paragraphIcon from '@iconify/icons-material-symbols/article-outline-rounded';
import h1Icon from '@iconify/icons-material-symbols/format-h1-rounded';
import h2Icon from '@iconify/icons-material-symbols/format-h2-rounded';
import h3Icon from '@iconify/icons-material-symbols/format-h3-rounded';
import fileIcon from '@iconify/icons-material-symbols/file-upload';
import videoIcon from '@iconify/icons-material-symbols/video-file-outline-rounded';
import imageIcon from '@iconify/icons-material-symbols/imagesmode-outline-rounded';
import bulletListIcon from '@iconify/icons-material-symbols/format-list-bulleted-rounded';
import quoteIcon from '@iconify/icons-material-symbols/format-quote-outline-rounded';
import legendIcon from '@iconify/icons-material-symbols/legend-toggle';
import blockIcon from '@iconify/icons-material-symbols/code-blocks-rounded';
import documentIcon from '@iconify/icons-material-symbols/edit-document';

import {INSERT_VIDEO_COMMAND} from '../VideoPlugin';
import {INSERT_QUOTE_COMMAND} from '../QuotePlugin';
import {INSERT_BLOCK_COMMAND} from '../BlockPlugin';
import {INSERT_IMAGE_COMMAND} from '../ImagesPlugin';
import {INSERT_LEGEND_COMMAND} from '../LegendPlugin';
import {INSERT_FILE_COMMAND} from '../FilesPlugin';
import {INSERT_CONTENT_COMMAND} from '../ContentPlugin';

const headingsIcons = {1: h1Icon, 2: h2Icon, 3: h3Icon};

class ComponentPickerOption extends MenuOption {
  // What shows up in the editor
  title: string;

  // Icon for display
  icon?: IconifyIcon;

  // For extra searching.
  keywords: Array<string>;

  // What happens when you select this option?
  onSelect: (queryString: string) => void;

  constructor(
    title: string,
    options: {
      icon?: IconifyIcon;
      keywords?: Array<string>;
      onSelect: (queryString: string) => void;
    }
  ) {
    super(title);
    this.title = title;
    this.keywords = options.keywords || [];
    this.icon = options.icon;
    this.onSelect = options.onSelect.bind(this);
  }
}

const ComponentPickerMenuItem = ({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: ComponentPickerOption;
}) => {
  return (
    <ListItem
      tabIndex={-1}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={'typeahead-item-' + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      sx={{backgroundColor: isSelected ? 'primary.lighter' : 'white', width: 300}}
    >
      {option.icon && (
        <ListItemIcon>
          <Icon icon={option.icon} />
        </ListItemIcon>
      )}
      <ListItemText className="text">{option.title}</ListItemText>
    </ListItem>
  );
};
const getBaseOptions = (editor: LexicalEditor) => {
  return [
    // new ComponentPickerOption('Paragraph', {
    //   icon: paragraphIcon,
    //   keywords: ['normal', 'paragraph', 'p', 'text'],
    //   onSelect: () =>
    //     editor.update(() => {
    //       const selection = $getSelection();
    //       if ($isRangeSelection(selection)) $setBlocksType(selection, () => $createParagraphNode());
    //     }),
    // }),
    ...([1, 2, 3] as const).map(
      n =>
        new ComponentPickerOption(`Заголовок ${n}`, {
          icon: headingsIcons[n],
          keywords: ['heading', 'header', `h${n}`],
          onSelect: () =>
            editor.update(() => {
              const selection = $getSelection();
              if ($isRangeSelection(selection)) $setBlocksType(selection, () => $createHeadingNode(`h${n}`));
            }),
        })
    ),
    new ComponentPickerOption('Список', {
      icon: bulletListIcon,
      keywords: ['list', 'bullet list'],
      onSelect: () => editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined),
    }),
    new ComponentPickerOption('Нумерованный список', {
      icon: bulletListIcon,
      keywords: ['list', 'number list'],
      onSelect: () => editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined),
    }),
    new ComponentPickerOption('Изображение', {
      icon: imageIcon,
      keywords: ['image', 'картинка', 'изображение', 'шьфпу', 'rfhnbyrf', 'bpj,hf;tybt'],
      onSelect: () => editor.dispatchCommand(INSERT_IMAGE_COMMAND, {mode: 'block', captionsEnabled: true}),
    }),
    new ComponentPickerOption('Файл для скачивания', {
      icon: fileIcon,
      keywords: ['file', 'ашду', 'afqk', 'файл'],
      onSelect: () => editor.dispatchCommand(INSERT_FILE_COMMAND, undefined),
    }),
    new ComponentPickerOption('Видео', {
      icon: videoIcon,
      keywords: ['video', 'vimeo', 'вимео', 'видео', 'dblej', 'dbvtj'],
      onSelect: () => editor.dispatchCommand(INSERT_VIDEO_COMMAND, undefined),
    }),
    new ComponentPickerOption('Цветной блок', {
      icon: blockIcon,
      keywords: ['block'],
      onSelect: () => editor.dispatchCommand(INSERT_BLOCK_COMMAND, undefined),
    }),
    new ComponentPickerOption('Цитата', {
      icon: quoteIcon,
      keywords: ['quote'],
      onSelect: () => editor.dispatchCommand(INSERT_QUOTE_COMMAND, {withImage: false}),
    }),
    new ComponentPickerOption('Цитата с картинкой', {
      icon: quoteIcon,
      keywords: ['quote'],
      onSelect: () => editor.dispatchCommand(INSERT_QUOTE_COMMAND, {withImage: true}),
    }),
    new ComponentPickerOption('Легенда', {
      icon: legendIcon,
      keywords: ['legend'],
      onSelect: () => editor.dispatchCommand(INSERT_LEGEND_COMMAND, undefined),
    }),
    new ComponentPickerOption('Материал', {
      icon: documentIcon,
      keywords: ['content', 'vfnthbfk', 'материал', 'cjyntyn', 'контент'],
      onSelect: () => editor.dispatchCommand(INSERT_CONTENT_COMMAND, undefined),
    }),
  ];
};

export const ComponentPickerPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const [queryString, setQueryString] = useState<string | null>(null);
  const checkForTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0,
  });
  const options = useMemo(() => {
    const baseOptions = getBaseOptions(editor);

    if (!queryString) {
      return baseOptions;
    }

    const regex = new RegExp(queryString, 'i');

    return [
      // ...getDynamicOptions(editor, queryString),
      ...baseOptions.filter(option => regex.test(option.title) || option.keywords.some(keyword => regex.test(keyword))),
    ];
  }, [editor, queryString]);

  const onSelectOption = useCallback(
    (
      selectedOption: ComponentPickerOption,
      nodeToRemove: TextNode | null,
      closeMenu: () => void,
      matchingString: string
    ) => {
      editor.update(() => {
        nodeToRemove?.remove();
        selectedOption.onSelect(matchingString);
        closeMenu();
      });
    },
    [editor]
  );
  return (
    <>
      <LexicalTypeaheadMenuPlugin<ComponentPickerOption>
        onQueryChange={setQueryString}
        onSelectOption={onSelectOption}
        triggerFn={checkForTriggerMatch}
        options={options}
        menuRenderFn={(anchorElementRef, {selectedIndex, selectOptionAndCleanUp, setHighlightedIndex}) =>
          anchorElementRef.current && options.length
            ? createPortal(
                <Box mt={2.5} sx={{boxShadow: 3}} bgcolor="white" width={300}>
                  <MenuList>
                    {options.map((option, i: number) => (
                      <ComponentPickerMenuItem
                        index={i}
                        isSelected={selectedIndex === i}
                        onClick={() => {
                          setHighlightedIndex(i);
                          selectOptionAndCleanUp(option);
                        }}
                        onMouseEnter={() => {
                          setHighlightedIndex(i);
                        }}
                        key={option.key}
                        option={option}
                      />
                    ))}
                  </MenuList>
                </Box>,
                anchorElementRef.current
              )
            : null
        }
      />
    </>
  );
};
