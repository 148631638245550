import {useParams} from 'react-router';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {usersApi} from 'api';

export const useGetUser = () => {
  const {userId} = useParams<'userId'>();

  return usersApi.useUserIndexQuery(userId ? {userId} : skipToken);
};

export default useGetUser;
