/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback} from 'react';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {BooleanParam, StringParam, useQueryParams} from 'use-query-params';

import {Dialog, DialogContent, DialogTitle, LinearProgress} from '@mui/material';

import {adminApi} from 'api';
import {Form} from 'components/Form';

import {fields, validation} from './fields';
import {useCreateReaction} from './hook';

export type ReactionFormProps = {
  blockId?: string;
  onReset?: () => void;
  onCreate?: () => void;
};
type ReactionFormDialogProps = Pick<ReactionFormProps, 'onCreate'>;

export const ReactionForm = ({blockId, onReset}: ReactionFormProps) => {
  const {data, isFetching} = adminApi.endpoints.reactionsBlockIndex.useQuery(blockId ? {blockId} : skipToken);
  const {onSubmit, initials} = useCreateReaction(data);

  if (isFetching && !data) return <LinearProgress />;
  return <Form fields={fields} initials={initials} validation={validation} onReset={onReset} onSubmit={onSubmit} />;
};

const queryShape = {createReactionBlock: BooleanParam, editReactionBlockId: StringParam};
export const useReactionForm = (): [
  (props: ReactionFormDialogProps) => JSX.Element,
  () => void,
  (blockId: string) => void
] => {
  const [{editReactionBlockId, createReactionBlock}, setQuery] = useQueryParams(queryShape);
  const open = useCallback(() => setQuery({createReactionBlock: true}, 'replace'), []);
  const edit = useCallback((blockId: string) => setQuery({editReactionBlockId: blockId}, 'replace'), []);
  const close = useCallback(() => setQuery({}, 'replace'), []);

  const FormContainer = useCallback(
    (props: ReactionFormDialogProps) => (
      <Dialog
        open={Boolean(createReactionBlock) || Boolean(editReactionBlockId)}
        maxWidth="sm"
        fullWidth
        onClose={close}
      >
        <DialogTitle>{editReactionBlockId ? 'Редактирование блока реакций' : 'Новый блок реакций'}</DialogTitle>
        <DialogContent dividers>
          <ReactionForm blockId={editReactionBlockId as string | undefined} onReset={close} {...props} />
        </DialogContent>
      </Dialog>
    ),
    [createReactionBlock, editReactionBlockId, close]
  );

  return [FormContainer, open, edit];
};
