import {startsWith} from 'lodash';
import {ElementNode} from 'lexical';
import {$createLinkNode} from '@lexical/link';

import {realmPlugin, system, coreSystem, LexicalExportVisitor, MdastImportVisitor} from '@mdxeditor/editor';

import {$createContentNode, $isContentNode, ContentNode} from './ContentNode';
import {$createQuestPointNode, QuestPointNode} from '../questPointBlock/QuestPointNode';

export const LexicalContentVisitor: LexicalExportVisitor<ContentNode, any> = {
  testLexicalNode: $isContentNode,
  visitLexicalNode({actions, lexicalNode}) {
    actions.addAndStepInto('link', {
      url: `${lexicalNode.__type}::${lexicalNode.__contentId}`,
      title: lexicalNode.__contentId,
    });
  },
};

export const MdastContentVisitor: MdastImportVisitor<any> = {
  testNode: 'link',
  visitNode({mdastNode, actions, lexicalParent}) {
    if (startsWith(mdastNode.url, `${ContentNode.getType()}::`))
      (lexicalParent as ElementNode).append($createContentNode({contentId: mdastNode.url.split('::')[1]}));
    else if (startsWith(mdastNode.url, `${QuestPointNode.getType()}::`)) {
      (lexicalParent as ElementNode).append($createQuestPointNode({pointId: mdastNode.url.split('::')[1]}));
    } else {
      actions.addAndStepInto(
        $createLinkNode(mdastNode.url, {
          title: mdastNode.title,
        })
      );
    }
  },
};

const contentBlockSystem = system(
  (realm, [{insertDecoratorNode}]) => {
    const insertContent = realm.node<string>();
    realm.sub(insertContent, contentId => {
      realm.pub(insertDecoratorNode, () => $createContentNode({contentId}));
    });

    return {insertContent};
  },
  [coreSystem]
);

export const [contentBlockPlugin, contentPluginHooks] = realmPlugin({
  id: 'contentBlock',
  systemSpec: contentBlockSystem,
  init(realm) {
    realm.pubKey('addImportVisitor', MdastContentVisitor);
    realm.pubKey('addLexicalNode', ContentNode);
    realm.pubKey('addExportVisitor', LexicalContentVisitor);
  },
});
