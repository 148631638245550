import {
  $createParagraphNode,
  $isElementNode,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  ElementNode,
  LexicalNode,
  RangeSelection,
  SerializedElementNode,
} from 'lexical';
import {$isLegendContainerNode} from './LegendContainerNode';

export type LegendTitlePayload = string;
type SerializedCollapsibleTitleNode = SerializedElementNode;

export function convertSpanElement(): DOMConversionOutput | null {
  const node = $createLegendTitleNode();
  return {
    node,
  };
}

export class LegendTitleNode extends ElementNode {
  static getType(): string {
    return 'legend-title';
  }

  static clone(node: LegendTitleNode): LegendTitleNode {
    return new LegendTitleNode(node.__key);
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('span');
    dom.classList.add('legend__title');
    return dom;
  }

  updateDOM(): boolean {
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      summary: () => {
        return {
          conversion: convertSpanElement,
          priority: 1,
        };
      },
    };
  }

  static importJSON(): LegendTitleNode {
    return $createLegendTitleNode();
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('summary');
    return {element};
  }

  exportJSON(): SerializedCollapsibleTitleNode {
    return {
      ...super.exportJSON(),
      type: 'legend-title',
      version: 1,
    };
  }

  insertNewAfter(_: RangeSelection, restoreSelection = true): ElementNode {
    const containerNode = this.getParentOrThrow();

    if (!$isLegendContainerNode(containerNode)) {
      throw new Error('LegendTitleNode expects to be child of LegendContainerNode');
    }

    const nextNode = containerNode.getNextSibling();
    if ($isElementNode(nextNode)) return nextNode;
    else {
      const paragraph = $createParagraphNode();
      containerNode.insertAfter(paragraph, restoreSelection);
      return paragraph;
    }
  }

  collapseAtStart(): boolean {
    this.getParentOrThrow().insertBefore(this);
    return true;
  }
}

export function $createLegendTitleNode(): LegendTitleNode {
  return new LegendTitleNode();
}

export function $isLegendTitleNode(node: LexicalNode | null | undefined): node is LegendTitleNode {
  return node instanceof LegendTitleNode;
}
