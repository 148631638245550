import {skipToken} from '@reduxjs/toolkit/dist/query';

import {adminApi} from 'api';
import {Form} from 'components/Form';
import {WidgetFormProps} from 'modules/widgets/types';

import {fields, validation} from './fields';
import {useCreateWidget} from './createWidget.hook';

export const WidgetForm = ({widgetId, onReset, onSuccess}: WidgetFormProps) => {
  const {data} = adminApi.endpoints.widgetIndex.useQuery(widgetId ? {widgetId} : skipToken);
  const {onSubmit, initials} = useCreateWidget(data, onSuccess);

  return <Form fields={fields} onSubmit={onSubmit} validation={validation} onReset={onReset} initials={initials} />;
};
