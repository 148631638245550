import {useParams} from 'react-router';
import {adminApi} from 'api';
import {skipToken} from '@reduxjs/toolkit/query';

const useWidgetAttrsIndex = adminApi.endpoints.widgetAttrsIndex.useQuery;
const useWidgetRulesIndex = adminApi.endpoints.widgetRulesIndex.useQuery;

export const useGetWidgetOptionsByParam = () => {
  const {widgetId} = useParams<'widgetId'>();
  const arg = widgetId ? {widgetId} : skipToken;

  return [useWidgetAttrsIndex(arg), useWidgetRulesIndex(arg)];
};
