import {WidgetDto} from 'api/generated/admin-api';
import {yupBooleanRequired, yupObject, yupStringOfOptionsRequired, yupStringRequired} from 'utils/validation';

import {FieldStructure} from 'components/Form/types';

const typeOptions = [
  {value: 'table', name: 'Таблица'},
  {value: 'chart', name: 'Чарт'},
  {value: 'diagram', name: 'Диаграмма'},
  {value: 'radar', name: 'Радар'},
  {value: 'pie_chart', name: 'Пай-чарт'},
  {value: 'slider', name: 'Слайдер'},
];

export const fields: FieldStructure<WidgetDto>[] = [
  {
    type: 'text',
    name: 'name',
    label: 'Название',
  },
  {
    type: 'select',
    name: 'type',
    label: 'Тип',
    options: typeOptions,
  },
  {
    type: 'checkbox',
    name: 'enabled',
    label: 'Активный',
  },
];

export const initialValues: WidgetDto = {
  name: '',
  type: 'slider',
  enabled: true,
};

export const validation = yupObject({
  name: yupStringRequired,
  type: yupStringOfOptionsRequired(typeOptions),
  enabled: yupBooleanRequired,
});
