import {WidgetVisualsDesignCustomOptions} from './types';

export const widgetItemsTemplates: {name: string; options: WidgetVisualsDesignCustomOptions}[] = [
  {
    name: 'Основной текст',
    options: {
      elementType: 'typography',
      containerOptions: {
        mb: 0.5,
      },
      typographyOptions: {
        variant: 'body1',
      },
    },
  },
  {
    name: 'Лейбл',
    options: {
      elementType: 'typography',
      value: 'Заголовок',
      containerOptions: {
        mb: 1,
      },
      typographyOptions: {
        variant: 'subtitle1',
        color: 'neutral.30',
      },
    },
  },
  {
    name: 'Картинка',
    options: {
      elementType: 'image',
      containerOptions: {
        mt: 0.5,
      },
      imageOptions: {
        aspectRatio: '1:1',
        openLightBoxOnPress: true,
        size: 400,
      },
    },
  },
];
