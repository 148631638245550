// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_APP = '/app';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

// export const PATH_PAGE = {
//   comingSoon: '/coming-soon',
//   maintenance: '/maintenance',
//   pricing: '/pricing',
//   payment: '/payment',
//   about: '/about-us',
//   contact: '/contact-us',
//   faqs: '/faqs',
//   page404: '/404',
//   page500: '/500',
//   components: '/components',
// };

export const PATH_APP = {
  root: ROOTS_APP,
  general: {
    app: path(ROOTS_APP, '/app'),
  },
  user: {
    root: path(ROOTS_APP, '/users'),
    list: {
      root: path(ROOTS_APP, '/users/list'),
    },
    mailings: {
      root: path(ROOTS_APP, '/users/mailings'),
      create: path(ROOTS_APP, '/users/mailings/create'),
    },
  },
  goals: {
    root: path(ROOTS_APP, '/goals'),
    list: {
      root: path(ROOTS_APP, '/goals/list'),
    },
    types: {
      root: path(ROOTS_APP, '/goals/types'),
    },
  },
  streams: {
    root: path(ROOTS_APP, '/users/streams'),
    list: path(ROOTS_APP, '/users/streams/list'),
    create: path(ROOTS_APP, '/users/streams/create'),
  },
  group: {
    root: path(ROOTS_APP, '/users/groups'),
    list: path(ROOTS_APP, '/users/groups/list'),
  },
  tasks: {
    root: path(ROOTS_APP, '/tasks'),
    tasks: {
      list: path(ROOTS_APP, '/tasks/list'),
      create: path(ROOTS_APP, '/tasks/list/create'),
    },
    scripts: {
      root: path(ROOTS_APP, '/tasks/scripts'),
      addNew: path(ROOTS_APP, '/tasks/scripts/create'),
    },
    categories: {
      root: path(ROOTS_APP, '/tasks/categories'),
    },
    templates: path(ROOTS_APP, '/tasks/templates'),
    blocks: {
      root: path(ROOTS_APP, '/tasks/blocks'),
      create: path(ROOTS_APP, '/tasks/blocks/create'),
    },
    widgets: {
      root: path(ROOTS_APP, '/tasks/widgets'),
      list: path(ROOTS_APP, '/tasks/widgets/list'),
      create: path(ROOTS_APP, '/tasks/widgets/create'),
    },
    widgetsVisuals: {
      root: path(ROOTS_APP, '/tasks/widgetsVisuals'),
      list: path(ROOTS_APP, '/tasks/widgetsVisuals/list'),
      create: path(ROOTS_APP, '/tasks/widgetsVisuals/create'),
    },
    collectors: {
      root: path(ROOTS_APP, '/tasks/collectors'),
      list: path(ROOTS_APP, '/tasks/collectors/list'),
      create: path(ROOTS_APP, '/tasks/collectors/create'),
    },
    rewards: {
      root: path(ROOTS_APP, '/tasks/rewards'),
    },
    quests: {
      root: path(ROOTS_APP, '/tasks/quests/list'),
      create: path(ROOTS_APP, '/tasks/quests/list/create'),
    },
    contents: {
      root: path(ROOTS_APP, '/tasks/contents'),
      list: {
        root: path(ROOTS_APP, '/tasks/contents/list'),
        create: path(ROOTS_APP, '/tasks/contents/list/create'),
      },
    },
  },
  payments: {
    root: path(ROOTS_APP, '/payments'),
    receiptsServices: {
      root: path(ROOTS_APP, '/payments/receiptsServices'),
      create: path(ROOTS_APP, '/payments/receiptsServices/create'),
    },
    stats: {
      root: path(ROOTS_APP, '/payments/stats'),
    },
    services: {
      root: path(ROOTS_APP, '/payments/services'),
      create: path(ROOTS_APP, '/payments/services/create'),
    },
  },
  settings: {
    root: path(ROOTS_APP, '/settings'),
    access: {
      root: path(ROOTS_APP, '/settings/access'),
      create: path(ROOTS_APP, '/settings/access/create'),
    },
    main: path(ROOTS_APP, '/settings/main'),
    policy: path(ROOTS_APP, '/settings/policy'),
    agreement: path(ROOTS_APP, '/settings/agreement'),
    blockers: {
      list: path(ROOTS_APP, '/settings/blockers'),
      create: path(ROOTS_APP, '/settings/blockers/create'),
    },
    reactions: {
      root: path(ROOTS_APP, '/settings/reactions'),
    },
  },
  catalogs: {
    root: path(ROOTS_APP, '/catalogs'),
    tags: path(ROOTS_APP, '/catalogs/tags'),
    rewards: path(ROOTS_APP, '/catalogs/rewards'),
    reactions: path(ROOTS_APP, '/catalogs/reactions'),
    goalTypes: path(ROOTS_APP, '/catalogs/goalTypes'),
    taskCategories: path(ROOTS_APP, '/catalogs/taskCategories'),
    contentCategories: path(ROOTS_APP, '/catalogs/contentCategories'),
  },
  control: {
    root: path(ROOTS_APP, '/control'),
    claims: path(ROOTS_APP, '/control/claims'),
  },
  // eCommerce: {
  //   root: path(ROOTS_DASHBOARD, '/e-commerce'),
  //   shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
  //   product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
  //   productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  //   list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
  //   newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
  //   editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
  //   checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
  //   invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
  // },
  // blog: {
  //   root: path(ROOTS_DASHBOARD, '/blog'),
  //   posts: path(ROOTS_DASHBOARD, '/blog/posts'),
  //   post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
  //   postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  //   newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
  // },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
