import {usersApi} from 'api';
import {isArray} from 'lodash';
import React, {useMemo} from 'react';
import {Box, CircularProgress, Stack} from '@mui/material';
import {NumberParam, useQueryParam, withDefault} from 'use-query-params';

import {useIsUserQueryExists} from './hook';
import {APIListProps, APIListProviderProps} from './types';

const LIMIT = 10;

function generateShortHash(str: string) {
  let hash = 0;
  if (str.length === 0) return hash.toString();

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32-bit integer
  }

  // Convert to a string and take the last 6 characters
  return Math.abs(hash).toString().slice(-6);
}

function useList<Query, Result, Item>({
  useFetch,
  params,
  getData,
  getTotal,
  queryName,
  limit: defaultLimit = LIMIT,
}: Pick<APIListProps<Query, Result, Item>, 'useFetch' | 'params' | 'getData' | 'getTotal' | 'queryName' | 'limit'>) {
  const [offset, setOffset] = useQueryParam(
    `offset-${generateShortHash(`${queryName}.${JSON.stringify(params)}`)}`,
    withDefault(NumberParam, 0)
  );
  const [limit] = useQueryParam(
    `limit-${generateShortHash(`${queryName}.${JSON.stringify(params)}`)}`,
    withDefault(NumberParam, defaultLimit)
  );

  const response = useFetch({...params, limit, offset: limit * offset} as any);
  const items = useMemo(() => {
    if (response.data && 'data' in (response.data as any)) return (response.data as any)?.data || [];
    if (!getData && isArray(response.data || [])) return response.data || [];
    if (getData) getData((response as any).data);
    return [];
  }, [response, getData]) as any[];

  const reset = () => setOffset(() => 0);
  const next = () => setOffset(offset => (offset || 0) + 1);
  const previous = () => setOffset(offset => (offset || 0) - 1);

  return {
    items,
    next,
    previous,
    reset,
    isFetching: response.isFetching,
    page: offset + 1,
    totalPages: getTotal ? Math.ceil((getTotal(response.data as Result) || 0) / LIMIT) : undefined,
  };
}

const defaultKeyExtractor = (item: any) => item._id! as string;

function APIList<Query, Result, Item>({
  renderItem,
  keyExtractor = defaultKeyExtractor,
  spacing = 0,
  Header,
  Footer,
  enableOverlayLoader,
  ...props
}: APIListProps<Query, Result, Item>) {
  const {items, isFetching, ...rest} = useList<Query, Result, Item>(props);

  return (
    <Box position="relative">
      <Stack spacing={spacing}>
        {Header && <Header {...rest} />}
        {items.map((item, index) => (
          <React.Fragment key={keyExtractor(item)}>
            {renderItem({item, index, itemId: keyExtractor(item)})}
          </React.Fragment>
        ))}
        {Footer && <Footer {...rest} />}
      </Stack>
      {isFetching && enableOverlayLoader && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          bgcolor="rgba(255, 255, 255, .4)"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export function UsersAPIList<T extends keyof typeof usersApi.endpoints, Item>({
  queryName,
  ...props
}: APIListProviderProps<T, Item>) {
  const useFetch = useIsUserQueryExists(queryName);
  if (!useFetch) return null;
  return <APIList useFetch={useFetch as any} queryName={queryName} {...props} />;
}

export default UsersAPIList;
