import {useParams} from 'react-router';
import {adminApi} from 'api';
import {skipToken} from '@reduxjs/toolkit/query';

export const useGetWidgetVisualProps = () => {
  const {visualId} = useParams<'visualId'>();
  return adminApi.endpoints.widgetsVisualPropsIndex.useQuery(
    visualId
      ? {
          visualId,
          populates: ['widget', 'widgetAttr'],
        }
      : skipToken
  );
};
