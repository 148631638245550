import {FC} from 'react';
import {DragDropContext, Draggable, Droppable, DropResult} from '@hello-pangea/dnd';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import DragHandle from '@mui/icons-material/DragHandle';

import {adminApi} from 'api';
import {WidgetVisualIdProp} from 'modules/widgets/types';
import {WidgetVisualsDesignForm} from '../WidgetVisualsPropForm';

export const WidgetVisualsDesignList: FC<WidgetVisualIdProp> = ({visualId}) => {
  const {data: props} = adminApi.endpoints.widgetsVisualDesignsIndex.useQuery({visualId});
  const [patchPositions] = adminApi.endpoints.widgetsVisualDesignsChangePositions.useMutation();
  if (!props) return null;

  const changePositions = (data: DropResult) => {
    if (data.destination) {
      const propsId = props.map(i => `${i._id}`);
      const removed = propsId.splice(data.source.index, 1);
      propsId.splice(data.destination.index, 0, removed[0]);
      patchPositions({visualId, iDs: {_id: propsId}});
    }
  };

  return (
    <DragDropContext onDragEnd={changePositions}>
      <CardContent>
        <Droppable droppableId={`widget_attrs${visualId}`}>
          {p => (
            <Stack
              spacing={3}
              divider={<Divider orientation="horizontal" flexItem />}
              ref={p.innerRef}
              {...p.droppableProps}
            >
              {props.map((prop, index) => (
                <Draggable key={prop._id} draggableId={`${prop._id}`} index={index}>
                  {p => (
                    <Stack direction="row" alignItems="center" spacing={1} ref={p.innerRef} {...p.draggableProps}>
                      <Box {...p.dragHandleProps}>
                        <Box component={DragHandle} color="grey.500" />
                      </Box>
                      <WidgetVisualsDesignForm design={prop} visualId={visualId} />
                    </Stack>
                  )}
                </Draggable>
              ))}
            </Stack>
          )}
        </Droppable>
      </CardContent>
    </DragDropContext>
  );
};
