import {useCallback, useState} from 'react';
import {applySortFilter, getComparator} from 'components/DataTable/utils';

export const useDataTableSort = <T>(filterFuncs?: ((arg: T) => boolean)[]) => {
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');

  const onRequestSort = useCallback(
    (property: string) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [orderBy, order]
  );

  const applyFilters = useCallback(
    (items: T[]) => {
      let filtered = applySortFilter(items, getComparator(order, orderBy));
      if (filterFuncs?.length) {
        filtered = filtered.filter(item => {
          for (let i = 0; i <= filterFuncs.length - 1; i++) {
            const isValid = filterFuncs[i](item);
            if (!isValid) return false;
          }
          return true;
        });
      }
      return filtered;
    },
    [filterFuncs, order, orderBy]
  );

  return {
    applyFilters,
    onRequestSort,
    orderBy,
    order,
  };
};

export const useDataTablePaginationProps = (options?: {rowsPerPageOptions?: number[]; rowsPerPageDefault?: number}) => {
  const {rowsPerPageOptions = [5, 10, 20, 40], rowsPerPageDefault = 20} = options || {};
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageDefault);
  const [page, setPage] = useState(0);

  const onRowsPerPageChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const onPageChange = useCallback((_: any, newPage: number) => setPage(newPage), []);

  const applyPageFilter = useCallback(
    <T>(rows: T[]): T[] => rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage]
  );

  return {
    applyPageFilter,
    paginationProps: {
      rowsPerPageOptions,
      component: 'div',
      rowsPerPage,
      page,
      onPageChange,
      onRowsPerPageChange,
    },
  };
};
