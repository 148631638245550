import {ContentCategoryDto} from 'api/generated/admin-api';
import {yupBoolean, yupObject, yupString, yupStringRequired} from 'utils/validation';
import {FieldStructure} from 'components/Form/types';

export const fields: FieldStructure[] = [
  {
    type: 'text',
    name: 'name',
    label: 'Название',
  },
  {
    type: 'text',
    name: 'backgroundColor',
    label: 'Цвет фона',
  },
  {
    type: 'file',
    name: 'fileId',
    variant: 'image',
    label: 'Иконка',
  },
  {
    type: 'checkbox',
    name: 'enabled',
    label: 'Активный',
  },
];

export const validation = yupObject({
  name: yupStringRequired,
  backgroundColor: yupString,
  fileId: yupString,
  enabled: yupBoolean,
});

export const initialValues: ContentCategoryDto = {name: '', enabled: true, backgroundColor: '', fileId: ''};
