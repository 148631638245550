import {Outlet} from 'react-router';
import {useState, useCallback, PropsWithChildren} from 'react';

import {PATH_APP} from 'router/routes';
import {BreadcrumbsSet} from './BreadcrumbsSet';
import {BreadcrumbItem, BreadcrumbsContextValue} from './types';
import {BreadcrumbsContext, BreadcrumbsSetterContext} from './context';

export const BreadcrumbsProvider = ({children, ...item}: PropsWithChildren<BreadcrumbItem>) => {
  const [state, setState] = useState<BreadcrumbsContextValue>([{name: 'Главная', href: PATH_APP.root}]);
  const addItem = useCallback((item: BreadcrumbItem, doRemove?: boolean) => {
    setState(state => {
      if (doRemove) return [...state].filter(stateItem => JSON.stringify(stateItem) !== JSON.stringify(item));

      return [...state, item].sort((a, b) => (!a.href ? 1 : !b.href ? -1 : a.href.length > b.href.length ? 1 : -1));
    });
  }, []);

  return (
    <BreadcrumbsContext.Provider value={state}>
      <BreadcrumbsSetterContext.Provider value={addItem}>
        <BreadcrumbsSet {...item}>{children ? <>{children}</> : <Outlet />}</BreadcrumbsSet>
      </BreadcrumbsSetterContext.Provider>
    </BreadcrumbsContext.Provider>
  );
};
