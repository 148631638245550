import {useCallback} from 'react';
import {pick} from 'lodash';

import {adminApi} from 'api';
import {ContentCategoryDto, ContentsCategories} from 'api/generated/admin-api';
import {initialValues} from './fields';

export const useCreateContentCategory = (onSuccess: () => void, data?: ContentsCategories) => {
  const [update] = adminApi.endpoints.contentsCategoryUpdate.useMutation();
  const [create] = adminApi.endpoints.contentsCategoriesAdd.useMutation();
  const onSubmit = useCallback(
    async (inputData: ContentCategoryDto) => {
      let result;
      if (data && data._id) result = await update({categoryId: data._id, contentCategoryDto: inputData});
      else result = await create({contentCategoryDto: inputData});
      onSuccess();
      return result;
    },
    [data, update, create, onSuccess]
  );

  const initials = data ? (pick(data, Object.keys(initialValues)) as ContentCategoryDto) : initialValues;

  return {initials, onSubmit};
};

export default useCreateContentCategory;
