import {Box, Card} from '@mui/material';
import {DataGrid, GridColDef, GridRow} from '@mui/x-data-grid';

import {adminApi} from 'api';
import {getRowId} from 'utils';

import {CommonPage} from './Page';
import {useList, useIsQueryExists, useFiltersWithQueryParams} from './hook';
import {ListPageProps, ListPageProviderProps} from './types';
import {CustomToolbar} from './CustomToolbar';
import {LinkRow} from './LinkRow';

function ListPage<Query, Result>({
  useFetch,
  params,
  columns,
  getItems,
  onItemClick,
  actions,
  rowLinkToPrefix,
  rowLinkCustom,
  ...props
}: ListPageProps<Query, Result>) {
  const {data, isLoading, isFetching} = useList<Query, Result>({useFetch, params, getItems});
  const {onResetFilters, dataGridProps} = useFiltersWithQueryParams();

  return (
    <CommonPage {...props}>
      <Card>
        {actions && <Box p={2}>{actions}</Box>}
        <Box height="100%" display="flex">
          <Box flexGrow={1}>
            <DataGrid
              {...dataGridProps}
              rows={data}
              autoHeight
              rowsPerPageOptions={[10, 20, 50, 100]}
              loading={isLoading || isFetching}
              columns={columns.filter(Boolean) as GridColDef[]}
              disableSelectionOnClick
              getRowId={getRowId}
              onRowClick={onItemClick ? ({row}) => onItemClick(row) : undefined}
              components={{
                Toolbar: CustomToolbar,
                Row: onItemClick
                  ? GridRow
                  : props => <LinkRow custom={rowLinkCustom} prefix={rowLinkToPrefix} {...props} />,
              }}
              componentsProps={{toolbar: {onResetFilters}}}
              sx={{
                '& .MuiDataGrid-toolbarContainer': {
                  paddingBottom: 0,
                  backgroundColor: 'background.paper',
                  flexDirection: 'row-reverse',
                },
              }}
            />
          </Box>
        </Box>
      </Card>
    </CommonPage>
  );
}

function ListPageProvider<T extends keyof typeof adminApi.endpoints>({queryName, ...props}: ListPageProviderProps<T>) {
  const useFetch = useIsQueryExists(queryName);
  if (!useFetch) return null;
  return <ListPage useFetch={useFetch as any} {...props} />;
}

export default ListPageProvider;
