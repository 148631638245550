import {
  $createParagraphNode,
  $isParagraphNode,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  ElementNode,
  LexicalNode,
  RangeSelection,
  SerializedElementNode,
} from 'lexical';
import {$isQuoteContainerNode} from './QuoteContainerNode';

export type QuoteAuthorPayload = string;
type SerializedCollapsibleTitleNode = SerializedElementNode;

export function convertSpanElement(): DOMConversionOutput | null {
  const node = $createQuoteAuthorNode();
  return {
    node,
  };
}

export class QuoteAuthorNode extends ElementNode {
  static getType(): string {
    return 'quote-author';
  }

  static clone(node: QuoteAuthorNode): QuoteAuthorNode {
    return new QuoteAuthorNode(node.__key);
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('span');
    dom.classList.add('quote__author');
    return dom;
  }

  updateDOM(): boolean {
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      summary: () => {
        return {
          conversion: convertSpanElement,
          priority: 1,
        };
      },
    };
  }

  static importJSON(): QuoteAuthorNode {
    return $createQuoteAuthorNode();
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('summary');
    return {element};
  }

  exportJSON(): SerializedCollapsibleTitleNode {
    return {
      ...super.exportJSON(),
      type: 'quote-author',
      version: 1,
    };
  }

  insertNewAfter(_: RangeSelection, restoreSelection = true): ElementNode {
    const containerNode = this.getParentOrThrow();

    if (!$isQuoteContainerNode(containerNode)) {
      throw new Error('QuoteAuthorNode expects to be child of QuoteContainerNode');
    }

    const nextNode = containerNode.getNextSibling();

    if ($isParagraphNode(nextNode)) return nextNode;
    else {
      const paragraph = $createParagraphNode();
      containerNode.insertAfter(paragraph, restoreSelection);
      return paragraph;
    }
  }

  collapseAtStart(): boolean {
    this.getParentOrThrow().insertBefore(this);
    return true;
  }
}

export function $createQuoteAuthorNode(): QuoteAuthorNode {
  return new QuoteAuthorNode();
}

export function $isQuoteAuthorNode(node: LexicalNode | null | undefined): node is QuoteAuthorNode {
  return node instanceof QuoteAuthorNode;
}
