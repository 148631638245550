import {FieldStructure} from 'components/Form/types';
import {yupObject, yupString, yupStringOfOptionsRequired} from 'utils/validation';

const typeOptions: {name: string; value: string}[] = [{name: 'Страница notion', value: 'notionPage'}];

export const fields: FieldStructure[] = [
  {type: 'text', name: 'value', label: 'Значение'},
  {
    type: 'select',
    name: 'type',
    label: 'Тип',
    options: typeOptions,
  },
];

export const validation = yupObject({
  value: yupString,
  type: yupStringOfOptionsRequired(typeOptions),
});

const defaultValues = {value: '', type: 'notionPage'};

export const initials = (structure?: any[]) =>
  structure ? {value: structure?.[0]?.value || '', type: structure?.[0]?.type || 'notionPage'} : defaultValues;
