import {FC} from 'react';
import {ControllerFieldState} from 'react-hook-form';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/EditOutlined';
import {useTheme} from '@mui/material/styles';

export type CommonRenderFieldProps = {
  value?: string;
  label?: string;
  onClick?: () => void;
} & Pick<ControllerFieldState, 'error'>;

export const CommonRenderField: FC<CommonRenderFieldProps> = ({label, value, error, onClick}) => {
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === 'dark';
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      position="relative"
      spacing={1}
      px={2}
      height={56}
      borderRadius={1}
      onClick={onClick}
      sx={{
        border: '1px solid',
        borderColor: error ? 'error.main' : 'grey.50032',
        cursor: 'pointer',
        '&:hover': !error ? {borderColor: isDarkTheme ? 'white' : 'black'} : undefined,
      }}
    >
      {value && (
        <Typography variant="body2" fontSize={16} sx={{maxHeight: 20, overflow: 'hidden'}}>
          {value}
        </Typography>
      )}
      <Typography
        variant="body1"
        color={error ? 'error.main' : 'grey.500'}
        bgcolor="background.paper"
        px={0.25}
        sx={Boolean(value) ? {top: -10, left: 2, position: 'absolute', fontSize: 12} : undefined}
      >
        {label}
      </Typography>
      <IconButton size="small">
        <EditIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};
