import {startsWith} from 'lodash';
import {ElementNode} from 'lexical';
import {realmPlugin, system, coreSystem, LexicalExportVisitor, MdastImportVisitor} from '@mdxeditor/editor';

import {$createQuestPointNode, $isQuestPointNode, QuestPointNode} from './QuestPointNode';

export const LexicalQuestPointVisitor: LexicalExportVisitor<QuestPointNode, any> = {
  testLexicalNode: $isQuestPointNode,
  visitLexicalNode({actions, lexicalNode}) {
    actions.addAndStepInto('link', {
      url: `${lexicalNode.__type}::${lexicalNode.__pointId}`,
      title: lexicalNode.__pointId,
    });
  },
};

export const MdastQuestPointVisitor: MdastImportVisitor<any> = {
  testNode: 'link',
  visitNode({mdastNode, lexicalParent}) {
    if (startsWith(mdastNode.url, `${QuestPointNode.getType()}::`))
      (lexicalParent as ElementNode).append($createQuestPointNode({pointId: mdastNode.url.split('::')[1]}));
  },
};

const questPointBlockSystem = system(
  (realm, [{insertDecoratorNode}]) => {
    const insertQuestPoint = realm.node<string>();
    realm.sub(insertQuestPoint, pointId => {
      realm.pub(insertDecoratorNode, () => $createQuestPointNode({pointId}));
    });

    return {insertQuestPoint};
  },
  [coreSystem]
);

export const [questPointBlockPlugin, questPointPluginHooks] = realmPlugin({
  id: 'questPointBlock',
  systemSpec: questPointBlockSystem,
  init(realm) {
    realm.pubKey('addImportVisitor', MdastQuestPointVisitor);
    realm.pubKey('addLexicalNode', QuestPointNode);
    realm.pubKey('addExportVisitor', LexicalQuestPointVisitor);
  },
});
