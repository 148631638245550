import {useState, MouseEvent} from 'react';
import {skipToken} from '@reduxjs/toolkit/query';
import {format, parseISO} from 'date-fns';

import {Box, IconButton, Stack, TableCell, Typography} from '@mui/material';
import CommentOpenIcon from '@mui/icons-material/CommentOutlined';
import CommentCloseIcon from '@mui/icons-material/CommentsDisabledOutlined';
import Label from '__theme/components/Label';

import {usersApi} from 'api';
import {Comments} from 'api/generated/users-api';

import {UserItem} from 'components/UserItem';
import {MenuButton} from 'components/MenuButton';
import {DataTableRow} from 'components/DataTable/DataTableRow';
import useDialog, {useConfirmDialog} from 'components/Dialog';

import {CommentModal} from './CommentModal';

const Row = ({
  comment,
  openBranch,
  toggleBranch,
  onDelete,
  onRestore,
}: {
  comment: Comments;
  openBranch?: boolean;
  toggleBranch?: (e: MouseEvent<HTMLButtonElement>) => void;
  onDelete: (id: string) => void;
  onRestore: () => void;
}) => {
  const [Dialog, open, close] = useDialog();
  const [ConfirmDelete, openConfirmDelete] = useConfirmDialog({
    onConfirm: () => onDelete(`${comment._id}`),
    title: 'Подтвердить удаление?',
  });
  return (
    <>
      <ConfirmDelete />
      <Dialog title="Комментарий">
        <CommentModal comment={comment} onClose={close} onDelete={openConfirmDelete} onRestore={onRestore} />
      </Dialog>
      <DataTableRow
        onClick={open}
        sx={comment?.branchCommentId ? {borderLeft: '3px solid', borderColor: 'primary.main', pl: 1} : undefined}
      >
        <TableCell sx={{wordBreak: 'break-all'}}>{comment.content}</TableCell>
        <TableCell>
          <Typography variant="subtitle2" color="text.primary">
            {comment.createdAt ? format(parseISO(comment.createdAt), 'MMM d y') : ''}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {comment.createdAt ? format(parseISO(comment.createdAt), 'p') : ''}
          </Typography>
        </TableCell>
        <TableCell>{comment.user && <UserItem user={comment.user} />}</TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            {comment.deletedAt && (
              <Label color="error" sx={{width: 'fit-content'}}>
                deleted
              </Label>
            )}
            {toggleBranch && !!comment.branchCommentsCount && (
              <IconButton onClick={toggleBranch}>
                <Box component={openBranch ? CommentCloseIcon : CommentOpenIcon} />
              </IconButton>
            )}
            <MenuButton
              buttons={[
                comment.deletedAt
                  ? {label: 'Восстановить', onClick: onRestore}
                  : {label: 'Удалить', onClick: openConfirmDelete},
                {label: 'Информация', onClick: open},
              ]}
            />
          </Stack>
        </TableCell>
      </DataTableRow>
    </>
  );
};

export const CommentsTableRow = ({item: comment}: {item: Comments}) => {
  const {referenceName, referenceId, subCommentsCount} = comment as any;
  const {data} = usersApi.endpoints.commentsIndex.useQuery(
    comment._id && subCommentsCount
      ? {referenceName, referenceId, commentId: comment._id, sort: 'created_asc'}
      : skipToken
  );
  const [deleteComment] = usersApi.endpoints.commentSafeDelete.useMutation();
  const [restoreComment] = usersApi.endpoints.commentSafeRestore.useMutation();

  const [openBranch, setOpenBranch] = useState(false);
  const toggleBranch = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpenBranch(p => !p);
  };
  const onDelete = (commentId: string) => deleteComment({commentId});
  const onRestore = (commentId: string) => restoreComment({commentId});

  const replies = data?.data || [];
  return (
    <>
      <Row
        comment={comment}
        openBranch={openBranch}
        toggleBranch={toggleBranch}
        onDelete={onDelete}
        onRestore={() => onRestore(`${comment._id}`)}
      />

      {openBranch &&
        replies.map(i => <Row key={i._id} comment={i} onDelete={onDelete} onRestore={() => onRestore(`${i._id}`)} />)}
    </>
  );
};
