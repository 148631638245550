import {useCallback, useMemo} from 'react';
import isFunction from 'lodash/isFunction';

import {useController, useFormContext} from 'react-hook-form';

import {QueryEndpoints} from 'components/DataList/types';
import useDialogSelector from 'components/DialogSelector';
import {CommonFieldProps} from '../types';
import {DialogSelectProps} from './types';
import {CommonRenderField} from '../RenderField';
import {adminApi} from 'api';
import {Button, Stack} from '@mui/material';
import {EntityPreview} from 'components/EntityPreview';

export const DialogSelect = ({
  label,
  name,
  queryName,
  columns,
  params,
  getData,
  getName,
  multiple,
  getPath,
  displayVariant = 'input',
}: DialogSelectProps & CommonFieldProps) => {
  const {watch} = useFormContext();
  const [Selector, openSelector] = useDialogSelector();
  const {
    field: {value, onChange},
    fieldState: {error},
  } = useController({name, defaultValue: multiple ? [] : ''});

  const values = watch();
  const realParams = useMemo(() => {
    if (isFunction(params)) return params(values);
    return params;
  }, [values, params]);
  const onConfirm = useCallback((idArray: string[]) => onChange(multiple ? idArray : idArray[0]), [multiple, onChange]);
  // @ts-ignore
  const {data: rawCurrentData} = adminApi.endpoints[queryName as keyof QueryEndpoints].useQuery({
    _id: value,
    ...realParams,
  } as any);
  const data = getData ? getData(rawCurrentData) : (rawCurrentData as any)?.data;

  return (
    <>
      <Selector
        title={label}
        params={realParams}
        columns={columns}
        getData={getData}
        onConfirm={onConfirm}
        queryName={queryName}
        initialSelection={multiple ? value : [value]}
        single={!multiple}
      />
      {(displayVariant === 'input' || multiple) && (
        <CommonRenderField
          label={label}
          value={(data || []).map(getName).join(', ')}
          error={error}
          onClick={openSelector}
        />
      )}
      {displayVariant === 'card' && !multiple && (
        <Stack spacing={2}>
          {data?.[0] && (
            <EntityPreview title={getName(data[0])} to={getPath ? getPath(data[0]._id!) : '/'} p={2} target="_blank" />
          )}
          <Button variant="outlined" size="small" onClick={openSelector}>
            {label}
          </Button>
        </Stack>
      )}
    </>
  );
};
