import {pick} from 'lodash';

import {adminApi} from 'api';
import {PaymentsServiceDto, PaymentsServices} from 'api/generated/admin-api';
import {
  yupBooleanRequired,
  yupObject,
  yupString,
  yupStringJSON,
  yupStringOfOptionsRequired,
  yupStringRequired,
} from 'utils/validation';

import {paymentServicesTypes} from 'modules/payments/utils';
import {FieldStructure} from 'components/Form/types';

const useReceiptsServicesOptions = () => {
  const {data} = adminApi.endpoints.receiptsServicesIndex.useQuery({});
  return (data?.data || []).map(i => ({
    name: i.name,
    value: i._id,
  }));
};

export const fields: FieldStructure[] = [
  {
    fields: [
      {
        type: 'text',
        name: 'name',
        label: 'Название',
      },
      {
        type: 'select',
        name: 'type',
        label: 'Тип',
        options: paymentServicesTypes,
      },
      {
        type: 'select',
        name: 'receiptServiceId',
        label: 'Чековый сервис',
        options: useReceiptsServicesOptions,
      },
    ],
  },
  {
    type: 'text',
    name: 'description',
    label: 'Описание',
    variant: 'richText',
  },
  {
    type: 'file',
    name: 'fileId',
    variant: 'image',
    label: 'Иконка',
  },
  {
    type: 'text',
    name: 'settings',
    label: 'Настройки (JSON строка)',
    variant: 'text',
  },
  {
    type: 'checkbox',
    name: 'enabled',
    label: 'Активный',
  },
];

export const validation = yupObject({
  name: yupStringRequired,
  type: yupStringOfOptionsRequired(paymentServicesTypes),
  text: yupString,
  enabled: yupBooleanRequired,
  settings: yupStringJSON,
});

const defaultValues: PaymentsServiceDto = {
  name: '',
  type: 'crypto',
  settings: '',
  enabled: true,
  description: '',
  fileId: undefined,
  receiptServiceId: undefined,
};

export const initials = (data?: PaymentsServices): PaymentsServiceDto => ({
  ...defaultValues,
  ...pick(data, Object.keys(defaultValues)),
});
