import {FunctionComponent} from 'react';
import {Navigate, Outlet, useRoutes} from 'react-router';
import {useMyId} from 'utils';
// import {Route, RouteComponentProps, Switch, useRouteMatch} from 'react-router-dom';
import {ModuleLayout} from '../../components';
import {AuthLogin} from '../../containers';

const AuthGuard = () => {
  const myId = useMyId();
  if (myId) return <Navigate to="/app" />;
  return <Outlet />;
};

export const AuthLayout: FunctionComponent<{}> = () => {
  const routes = useRoutes([
    {
      element: <AuthGuard />,
      children: [
        {
          path: '/',
          element: <AuthLogin />,
        },
        {
          path: '/restore',
          element: <AuthLogin />,
        },
        {
          path: '/register',
          element: <AuthLogin />,
        },
      ],
    },
  ]);

  return <ModuleLayout>{routes}</ModuleLayout>;
};

export default AuthLayout;
