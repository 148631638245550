import {PATH_APP} from 'router/routes';
import {enchanceColumns, nameColumn} from 'utils';
import {Templates, TemplatesOptions} from 'api/generated/admin-api';

export const templateTypes = [
  {value: 'custom', name: 'Комплексный тип данных'},
  {value: 'string', name: 'Однострочное поле ввода'},
  {value: 'text', name: 'Многострочное поле ввода'},
  {value: 'number', name: 'Числовое поле ввода'},
  {value: 'score', name: 'Рейтинг'},
  {value: 'select', name: 'Выбор из списка'},
  {value: 'multi_select', name: 'Множественный выбор из списка'},
  {value: 'file', name: 'Файл'},
  {value: 'photo', name: 'Фотография'},
  {value: 'video', name: 'Видео'},
  {value: 'stage', name: 'Выполнение квеста'},
];

export const templateListColumns = enchanceColumns<Templates>([
  {field: 'name', flex: 0.2, headerName: 'Название'},
  {field: 'description', flex: 0.2, headerName: 'Описание'},
  {
    field: 'type',
    flex: 0.2,
    headerName: 'Тип данных',
    type: 'singleSelect',
    valueOptions: templateTypes.map(item => item.name),
    valueGetter: ({row}: {row: Templates}) => templateTypes.find(item => item.value === row.type)?.name || '',
  },
]);

export const templateOptionsColumns = enchanceColumns<TemplatesOptions>(
  [
    nameColumn,
    {
      field: 'description',
      headerName: 'Описание',
      type: 'string',
      flex: 0.2,
    },
  ],
  {hideEnabled: true}
);

export const getTaskPath = (taskId?: string) => `${PATH_APP.tasks.tasks.list}/${taskId}`;
export const getTemplatePath = (templateId?: string) => `${PATH_APP.tasks.templates}/${templateId}`;
