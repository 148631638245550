import {useParams} from 'react-router';
import pick from 'lodash/pick';
import {skipToken} from '@reduxjs/toolkit/query';

import {adminApi} from 'api';
import {WidgetsRules} from 'api/generated/admin-api';
import {yupObject, yupString, yupStringOfOptionsRequired, yupStringRequired} from 'utils/validation';

import {WidgetRuleFormDto} from 'modules/widgets/types';
import {FieldStructure} from 'components/Form/types';

const ruleOptions = [
  {value: 'eq', name: 'Равен'},
  {value: 'lte', name: 'Меньше либо равен'},
  {value: 'gte', name: 'Больше либо равен'},
];

const useAttrsIdSelectOptions = () => {
  const {widgetId} = useParams<'widgetId'>();
  const {data: attrs} = adminApi.endpoints.widgetAttrsIndex.useQuery(widgetId ? {widgetId} : skipToken);
  return (attrs || []).map(attr => ({value: `${attr._id}`, name: attr.name}));
};

export const fields = (directionRow?: boolean): FieldStructure<WidgetRuleFormDto>[] => [
  {
    direction: directionRow ? 'row' : 'column',
    fields: [
      {
        type: 'text',
        name: 'name',
        label: 'Название',
      },
      {
        type: 'select',
        variant: 'autocomplete',
        name: 'sourceAttrId',
        label: 'sourceAttrId',
        options: useAttrsIdSelectOptions,
      },
      {
        type: 'select',
        name: 'rule',
        label: 'Правило',
        options: ruleOptions,
      },
      {
        type: 'select',
        variant: 'autocomplete',
        name: 'targetAttrId',
        label: 'targetAttrId',
        options: useAttrsIdSelectOptions,
      },
    ],
  },
];

const initialValues: WidgetRuleFormDto = {
  name: '',
  rule: 'gte',
  sourceAttrId: '',
  targetAttrId: '',
};

export const initials = (rule?: WidgetsRules) =>
  (rule ? pick(rule, Object.keys(initialValues)) : initialValues) as WidgetRuleFormDto;

export const validation = yupObject({
  name: yupString,
  rule: yupStringOfOptionsRequired(ruleOptions),
  sourceAttrId: yupStringRequired,
  targetAttrId: yupStringRequired,
});
