import {pick} from 'lodash';

import {api as adminApi} from 'api/generated/admin-api';
import {api as usersApi, Goals} from 'api/generated/users-api';
import {
  yupArrayStrings,
  yupNumberRequired,
  yupObject,
  yupString,
  yupStringOfOptions,
  yupStringRequired,
} from 'utils/validation';

import {GoalFormType} from 'modules/users/containers/GoalForm/types';
import {FieldStructure} from 'components/Form/types';

const getGoalTypeOptions = () => {
  const {data: goalTypes} = adminApi.endpoints.goalsTypesIndex.useQuery({});
  return (goalTypes?.data || []).map(i => ({
    name: i.name,
    value: i._id,
  }));
};
const getGoalTagsOptions = () => {
  const {data: goalTags} = usersApi.endpoints.tagsIndex.useQuery({type: 'personal_quality'});
  return (goalTags || []).map(i => ({
    name: i.name,
    value: i._id,
  }));
};
const privacyOptions = [
  {
    name: 'Видна только пользователю',
    value: 'private',
  },
  {
    name: 'Видна команде',
    value: 'groups',
  },
  {
    name: 'Видна подписчикам',
    value: 'groups',
  },
  {
    name: 'Видна команде и подписчикам',
    value: 'groups',
  },
  {
    name: 'Видна всем',
    value: 'public',
  },
];

export const fields: FieldStructure[] = [
  {
    fields: [
      {
        type: 'text',
        name: 'name',
        label: 'Название',
      },
      {
        type: 'select',
        name: 'goalTypeId',
        label: 'Категория',
        options: getGoalTypeOptions,
      },
    ],
  },
  {
    fields: [
      {
        type: 'select',
        name: 'personalQualityTagsId',
        label: 'Качества развития',
        multiple: true,
        options: getGoalTagsOptions,
      },
      {
        type: 'text',
        variant: 'number',
        name: 'rewardValue.value',
        label: 'Деньги',
      },
    ],
  },
  {
    fields: [
      {
        type: 'dateTime',
        name: 'createdAt',
        label: 'Дата создания',
        disabled: true,
        onlyDate: true,
      },
      {
        type: 'dateTime',
        name: 'planFinishDate',
        label: 'Срок',
        onlyDate: true,
      },
    ],
  },
  {
    fields: [
      {
        type: 'select',
        name: 'privacy',
        label: 'Приватность',
        options: privacyOptions,
      },
      {
        type: 'select',
        name: 'status',
        label: 'Статус',
        disabled: true,
        options: [
          {
            name: 'Черновик',
            value: 'draft',
          },
          {
            name: 'Активная',
            value: 'new',
          },
          {
            name: 'Выполненная',
            value: 'completed',
          },
          {
            name: 'Архивная',
            value: 'archived',
          },
          {
            name: 'Проваленная',
            value: 'failed',
          },
        ],
      },
    ],
  },
  {
    type: 'text',
    variant: 'richText',
    name: 'description',
    label: 'Описание',
  },
];

const initialValues: GoalFormType = {
  name: '',
  goalTypeId: undefined,
  personalQualityTagsId: [],
  rewardValue: {
    value: 0,
  },
  createdAt: '',
  planFinishDate: '',
  privacy: '',
  status: '',
  description: '',
};

export const getInitials = (goal: Goals): GoalFormType =>
  goal
    ? {
        ...initialValues,
        ...pick(goal, Object.keys(initialValues)),
        personalQualityTagsId: (goal.tagsSet || []).map(i => i.tagId),
      }
    : initialValues;

export const validation = yupObject({
  personalQualityTagsId: yupArrayStrings,
  name: yupStringRequired,
  description: yupString,
  goalTypeId: yupString,
  rewardValue: yupObject({
    value: yupNumberRequired,
  }),
  privacy: yupStringOfOptions(privacyOptions),
  planFinishDate: yupString,
});
