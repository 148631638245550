import {Button, Card, CardContent, CardHeader} from '@mui/material';

import {adminApi} from 'api';

import {NotionPageViewer} from 'modules/contents/containers/NotionPageViewer';
import useDialog from 'components/Dialog';
import {Form} from 'components/Form';

import {fields, initials, validation} from './fields';

export const ContentStructure = ({contentId, structure}: {contentId: string; structure: any[]}) => {
  const isEmpty = !structure.length || !structure?.[0]?.value;
  const [Dialog, openDialog] = useDialog();
  const [patch] = adminApi.endpoints.contentPatch.useMutation();
  const updateStructure = (data: {type: string; value: string}) =>
    patch({contentId, contentPartialDto: {structure: data.value ? [data] : []}});

  return (
    <>
      <Dialog title="Добавить">
        <Form fields={fields} validation={validation} onSubmit={updateStructure} initials={initials(structure)} />
      </Dialog>
      <Card>
        <CardHeader
          title="Содержание материала"
          action={
            <Button variant="contained" size="small" onClick={openDialog}>
              {isEmpty ? 'Добавить' : 'Изменить'} содержание
            </Button>
          }
        />
        <CardContent>
          {isEmpty && 'Материал пуст'}
          {structure.map(item => (
            <div key={JSON.stringify(item)}>
              {item.type === 'notionPage' && <NotionPageViewer pageId={item.value as string} />}
            </div>
          ))}
        </CardContent>
      </Card>
    </>
  );
};
