import {useParams} from 'react-router-dom';
import {adminApi} from 'api';
import {skipToken} from '@reduxjs/toolkit/dist/query';

const useGetRewardHook = adminApi.endpoints.rewardIndex.useQuery;
export const useGetReward = () => {
  const {rewardId} = useParams<'rewardId'>();
  return useGetRewardHook(rewardId ? {rewardId: `${rewardId}`} : skipToken);
};

export default useGetReward;
