import {Box} from '@mui/material';
import {FunctionComponent, PropsWithChildren} from 'react';

export const AuthLayout: FunctionComponent<PropsWithChildren<{}>> = ({children}) => (
  <Box width="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
    <Box>{children}</Box>
  </Box>
);

export default AuthLayout;
