import saveIcon from '@iconify/icons-eva/save-outline';
import trashIcon from '@iconify/icons-eva/trash-outline';
import refreshIcon from '@iconify/icons-eva/refresh-outline';

import {FC, useCallback} from 'react';

import {adminApi} from 'api';

import {Form} from 'components/Form';
import {FormResetFnProp} from 'components/Form/types';
import {WidgetVisualIdProp, WidgetVisualDesignProp, WidgetVisualDesignFormDto} from 'modules/widgets/types';

import {designValidation, designInitials as getDesignInitials, designFields} from './fields';

export const WidgetVisualsDesignForm: FC<WidgetVisualIdProp & Partial<WidgetVisualDesignProp & FormResetFnProp>> = ({
  design: prop,
  onReset,
  visualId,
}) => {
  const [patch] = adminApi.endpoints.widgetsVisualDesignPatch.useMutation();
  const [add] = adminApi.endpoints.widgetsVisualDesignsAdd.useMutation();
  const [destroy] = adminApi.endpoints.widgetsVisualDesignDelete.useMutation();
  const onRemove = () => {
    if (prop?._id) destroy({visualId, designId: prop._id});
  };

  const onSubmit = useCallback(
    (data: WidgetVisualDesignFormDto) => {
      if (!data.visualPropId) data.visualPropId = undefined;
      if (prop)
        return patch({
          visualId,
          designId: `${prop._id}`,
          widgetVisualDesignPartialDto: data,
        });
      return add({
        visualId,
        widgetVisualDesignDto: data,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visualId, add, prop, patch]
  );

  return (
    <Form
      fields={designFields(visualId)}
      initials={getDesignInitials(prop)}
      validation={designValidation}
      buttonsPosition={prop ? 'right' : 'bottom'}
      iconButtons={Boolean(prop)}
      onSubmit={onSubmit}
      onReset={onReset}
      buttonsStructure={[
        {type: 'submit', text: 'Сохранить', display: 'icon', icon: saveIcon},
        {type: 'reset', text: 'Сбросить', color: 'error', display: 'icon', icon: refreshIcon},
        prop
          ? {
              onClick: onRemove,
              text: 'Удалить',
              display: 'icon',
              icon: trashIcon,
              color: 'error',
            }
          : undefined,
      ]}
      sx={{width: '100%'}}
    />
  );
};
