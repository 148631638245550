import {FC, useEffect} from 'react';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';

import {LexicalProps} from '../types';

export const OnChangePlugin: FC<LexicalProps> = ({onChange}) => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerUpdateListener(({editorState}) => {
      // console.log(editorState.toJSON());
      onChange(editorState);
    });
  }, [editor, onChange]);

  return null;
};
