import * as yup from 'yup';

const requiredText = 'Обязательное поле';
const numberTypeText = 'Значение должно быть числом';
const numberPositiveText = 'Значение должно быть больше 0';
const stringJsonIncorrect = 'Некорретный JSON';

/* STRING */
export const yupString = yup.string();
export const yupStringRequired = yup.string().required(requiredText);

export const yupStringOfOptions = <T>(options: (T & {value: string | number})[]) =>
  yupString.oneOf(options.map(option => option.value.toString()));

export const yupStringOfOptionsNullable = <T>(options: (T & {value: string | number})[]) =>
  yup
    .mixed()
    .nullable()
    .oneOf(options.map(option => option.value.toString()));

export const yupStringOfOptionsRequired = <T>(options: (T & {value: string | number})[]) =>
  yupStringRequired.oneOf(options.map(option => option.value.toString()));

export const yupStringJSON = yup.string().test('stringJSON', stringJsonIncorrect, (value?: string) => {
  if (!value) return true;
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
});

/* BOOLEAN */
export const yupBoolean = yup.boolean();
export const yupBooleanRequired = yup.boolean().required(requiredText);

/* NUMBER */
export const yupNumber = yup.number();
export const yupNumberRequired = yup
  .number()
  // .transform((_, val) => (val === Number(val) ? val : val === '' ? null : val))
  .nullable()
  .typeError(numberTypeText)
  .required(requiredText);
export const yupNumberOrEmptyString = yup
  .number()
  .nullable()
  .transform((_, val) => (val === Number(val) ? val : null))
  .notRequired()
  .typeError(numberTypeText);
export const yupNumberOrEmptyStringPositive = yupNumberOrEmptyString.positive(numberPositiveText);
export const yupNumberOrEmptyStringNullable = yup
  .number()
  .transform((_, val) => (val === Number(val) ? val : null))
  .nullable()
  .typeError(numberTypeText);

/* ARRAY */
export const yupArray = yup.array();
export const yupArrayStrings = yup.array().of(yup.string());
export const yupArrayStringsRequired = yup.array().of(yup.string()).min(1, requiredText);

export const yupArrayStringsOfOptions = <T>(options: (T & {value: string | number})[]) =>
  yupArray.of(yupString.oneOf(options.map(option => option.value.toString())));

export const yupArrayStringsOfOptionsRequired = <T>(options: (T & {value: string | number})[]) =>
  yupArrayStringsOfOptions(options).min(1, requiredText);

/* OBJECT */
export const yupObject = (schema: yup.ObjectShape) => yup.object(schema);
export const yupAnyObject = yup.object();

/* DATE */
export const yupDate = yup.date();

/* OTHER */
export const yupUndefined = yup.string().transform(() => undefined);
