import {DOMConversionMap, ElementNode, LexicalNode, SerializedElementNode, Spread} from 'lexical';

export type QuoteContainerPayload = string;

export type SerializedQuoteContainerNode = Spread<{}, SerializedElementNode>;

export class QuoteContainerNode extends ElementNode {
  static getType(): string {
    return 'quote-container';
  }

  static clone(node: QuoteContainerNode): QuoteContainerNode {
    return new QuoteContainerNode(node.__key);
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('div');
    dom.classList.add('quote__container');
    return dom;
  }

  updateDOM(): boolean {
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return {};
  }

  static importJSON(): QuoteContainerNode {
    return $createQuoteContainerNode();
  }

  canBeEmpty(): boolean {
    return false;
  }

  exportJSON(): SerializedQuoteContainerNode {
    return {
      ...super.exportJSON(),
      type: 'quote-container',
      version: 1,
    };
  }
}

export function $createQuoteContainerNode(): QuoteContainerNode {
  return new QuoteContainerNode();
}

export function $isQuoteContainerNode(node: LexicalNode | null | undefined): node is QuoteContainerNode {
  return node instanceof QuoteContainerNode;
}
