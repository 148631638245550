import {Outlet} from 'react-router';

import {Suspense, useEffect, useMemo, useRef} from 'react';

import {QueryParamProvider} from 'use-query-params';
import {useLocation, useNavigate, useRoutes, Navigate} from 'react-router-dom';

import NotFound from '__theme/pages/Page404';
import {AuthGuard, AuthLayout} from 'modules/auth';
import DashboardLayout from '__theme/layouts/dashboard';
import LoadingScreen from '__theme/components/LoadingScreen';

import usersRouter from 'modules/users';
import tasksRouter from 'modules/tasks';
import controlRouter from 'modules/control';
import catalogsRouter from 'modules/catalogs';
import paymentsRouter from 'modules/payments';
import settingsRouter from 'modules/settings';

export const MainRouter = () => {
  const routes = useRoutes([
    {
      index: true,
      element: <Navigate to="/app" />,
    },
    {
      path: 'auth',
      element: <AuthLayout />,
    },
    {
      path: 'app',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
      children: [paymentsRouter, controlRouter, settingsRouter, catalogsRouter, usersRouter, tasksRouter],
    },
    {
      path: '*',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {path: '404', element: <NotFound />},
        {path: '*', element: <Navigate to="/404" replace />},
      ],
    },
  ]);

  return routes;
};

const RouteAdapter = ({children}: {children?: any}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = useRef<string | undefined>();

  const adaptedHistory = useMemo(
    () => ({
      replace(newLocation: any) {
        navigate(newLocation, {replace: true, state: newLocation.state});
      },
      push(newLocation: any) {
        navigate(newLocation, {replace: false, state: newLocation.state});
      },
    }),
    [navigate]
  );

  useEffect(() => {
    if (currentPath.current !== location.pathname) {
      window.scrollTo(0, 0);
      currentPath.current = location.pathname;
    }
  }, [location]);

  return children({history: adaptedHistory, location});
};

export const AppRouter = () => {
  return (
    <QueryParamProvider ReactRouterRoute={RouteAdapter}>
      <MainRouter />
    </QueryParamProvider>
  );
};

export default AppRouter;
