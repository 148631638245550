/* eslint-disable @typescript-eslint/no-unused-vars */
import {useParams} from 'react-router';
import {CommonPage} from 'components/ListPage';
import {WidgetVisualsStructure} from 'modules/widgets/containers';

export const WidgetVisualPage = () => {
  const {visualId} = useParams<'visualId'>();
  if (!visualId) return null;

  return (
    <CommonPage maxWidth="xl">
      <WidgetVisualsStructure visualId={visualId} />
    </CommonPage>
  );
};
