import {Button, Stack} from '@mui/material';
import {useFormContext} from 'react-hook-form';

import {RenderFieldProps} from 'components/Form';
import {widgetItemsTemplates} from './configurations';
import {WidgetVisualsDesignCustomOptions} from './types';

type RenderProp = (props: RenderFieldProps<any>) => JSX.Element;

export const SelectDesignTemplate: RenderProp = () => {
  const {setValue} = useFormContext();
  const onChange = (customJSON: WidgetVisualsDesignCustomOptions) => () =>
    setValue('customJSONString', JSON.stringify(customJSON, undefined, 4));

  return (
    <Stack direction="row" spacing={1}>
      {widgetItemsTemplates.map(item => (
        <Button variant="outlined" key={item.name} onClick={onChange(item.options)}>
          {item.name}
        </Button>
      ))}
    </Stack>
  );
};
