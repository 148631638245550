import {AccessDto} from 'api/generated/admin-api';
import {yupBooleanRequired, yupObject, yupStringRequired} from 'utils/validation';
import {FieldStructure} from 'components/Form/types';

export const initialValues: AccessDto = {
  name: '',
  enabled: true,
};

export const fields: FieldStructure[] = [
  {
    type: 'text',
    name: 'name',
    label: 'Название',
  },
  {
    type: 'checkbox',
    name: 'enabled',
    label: 'Активный',
  },
];

export const validation = yupObject({
  name: yupStringRequired,
  enabled: yupBooleanRequired,
});
