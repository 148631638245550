import {QueryDefinition} from '@reduxjs/toolkit/dist/query';
import {ApiEndpointQuery} from '@reduxjs/toolkit/dist/query/core/module';
import {put, select, call} from 'typed-redux-saga';

export function* callRTK<Query>(
  endpoint: ApiEndpointQuery<QueryDefinition<Query, any, any, any, any>, any>,
  query: Query
) {
  const request = yield* put(yield* call(endpoint.initiate, query) as any);
  yield request;
  return yield* select(endpoint.select(query));
}
