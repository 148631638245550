import {Fragment, PropsWithChildren} from 'react';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';
import isFunction from 'lodash/isFunction';
import {useFormContext} from 'react-hook-form';

import {Field} from './Field';
import {isBlock} from '../utils';
import {FieldStructure} from '../types';
import {FieldsBlock} from './FieldsBlock';

export const ConditionField: React.FC<PropsWithChildren<Required<Pick<FieldStructure<any>, 'showIf'>>>> = ({
  showIf,
  children,
}) => {
  const {watch} = useFormContext();
  if (isFunction(showIf) && showIf(watch())) return <>{children || null}</>;
  else if (typeof showIf === 'string') {
    const value = watch(showIf);
    if (!isNil(value) && (typeof value === 'number' || Boolean(value))) return <>{children || null}</>;
  }
  return null;
};

export const Fields = ({fields}: {fields: FieldStructure<any>[]}) => (
  <>
    {fields.map(item => {
      const key = JSON.stringify(omit(item, ['render', 'showIf']));
      if (isNil(item.showIf) || (typeof item.showIf === 'boolean' && item.showIf))
        return <Fragment key={key}>{isBlock(item) ? <FieldsBlock {...item} /> : <Field {...item} />}</Fragment>;
      if (!item.showIf) return null;
      return (
        <ConditionField showIf={item.showIf} key={key}>
          {isBlock(item) ? <FieldsBlock {...item} /> : <Field {...item} />}
        </ConditionField>
      );
    })}
  </>
);
