import {Controller} from 'react-hook-form';
import CheckboxMui from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import {CheckboxProps} from './types';
import {CommonFieldProps} from '../types';

export const Checkbox = ({label, name}: CheckboxProps & CommonFieldProps) => {
  return (
    <FormControlLabel
      labelPlacement="end"
      control={
        <Controller name={name} render={props => <CheckboxMui {...props.field} checked={!!props.field.value} />} />
      }
      label={label}
    />
  );
};
