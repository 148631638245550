import {MAvatar} from './@material-extend';
import {MAvatarProps} from './@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';
import {useGetCurrentUser} from 'modules/auth/hooks/useIsAuthentificated';
import {croppedImagePath} from 'utils';

// ----------------------------------------------------------------------

export default function MyAvatar({...other}: MAvatarProps) {
  const user = useGetCurrentUser();

  return (
    <MAvatar
      alt={user?.name}
      color={user?.fileId ? 'default' : createAvatar(user?.name || '').color}
      src={user?.fileId ? croppedImagePath(user.fileId, {ratio: '1:1', maxWidth: 200}) : undefined}
      {...other}
    >
      {createAvatar(user?.name || '').name}
    </MAvatar>
  );
}
