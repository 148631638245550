import {Link as RouterLink} from 'react-router-dom';
import {styled} from '@mui/material/styles';
// material
import {Box, Button, Typography, Container} from '@mui/material';
// components
import Page from '../components/Page';
import {SeverErrorIllustration} from '../assets';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function Page403() {
  return (
    <RootStyle title="403 Доступ запрещен">
      <Container>
        <Box sx={{maxWidth: 480, margin: 'auto', textAlign: 'center'}}>
          <Typography variant="h3" paragraph>
            403 Доступ запрещен
          </Typography>
          <Typography sx={{color: 'text.secondary'}}>
            Вы недостаточно хороши, чтобы сюда посмотреть. Прастити
          </Typography>

          <SeverErrorIllustration sx={{height: 260, my: {xs: 5, sm: 10}}} />

          <Button to="/" size="large" variant="contained" component={RouterLink}>
            На главную
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
}
