import chroma from 'chroma-js';

import {createPortal} from 'react-dom';
import {ColorResult, GithubPicker} from 'react-color';
import {FC, useCallback, useEffect, useRef, useState} from 'react';

import {Icon} from '@iconify/react';
import quoteIcon from '@iconify/icons-material-symbols/format-quote-sharp';
import imageIcon from '@iconify/icons-material-symbols/image-sharp';
import boldIcon from '@iconify/icons-material-symbols/format-bold-sharp';
import italicIcon from '@iconify/icons-material-symbols/format-italic-sharp';

import {Box, IconButton} from '@mui/material';
import {$getNearestNodeOfType} from '@lexical/utils';
import {$getNodeByKey, FORMAT_TEXT_COMMAND, LexicalEditor} from 'lexical';

import {IS_APPLE, useClickOutside} from 'components/LexicalComposer/utils';
import {INSERT_IMAGE_COMMAND} from '../ImagesPlugin';
import {INSERT_QUOTE_COMMAND} from '../QuotePlugin';
import {INSERT_LEGEND_COMMAND} from '../LegendPlugin';
import {$isBlockNode, BlockNode, INSERT_BLOCK_COMMAND, UPDATE_BLOCK_COMMAND} from '../BlockPlugin';

type ToggleToolbarButtonProps = {
  isActive?: boolean;
  editor: LexicalEditor;
};

export const ItalicButton: FC<ToggleToolbarButtonProps> = ({isActive, editor}) => (
  <IconButton
    size="small"
    color={isActive ? 'primary' : 'default'}
    title={IS_APPLE ? 'Italic (⌘I)' : 'Italic (Ctrl+I)'}
    onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')}
    aria-label={`Format text as italics. Shortcut: ${IS_APPLE ? '⌘I' : 'Ctrl+I'}`}
  >
    <Icon icon={italicIcon} />
  </IconButton>
);

export const BoldButton: FC<ToggleToolbarButtonProps> = ({isActive, editor}) => (
  <IconButton
    size="small"
    color={isActive ? 'primary' : 'default'}
    title={IS_APPLE ? 'Bold (⌘B)' : 'Bold (Ctrl+B)'}
    onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
    aria-label={`Format text as bold. Shortcut: ${IS_APPLE ? '⌘B' : 'Ctrl+B'}`}
  >
    <Icon icon={boldIcon} />
  </IconButton>
);

export const UpdateBlockColorButton: FC<{editor: LexicalEditor; selectedElementKey?: string | null}> = ({
  editor,
  selectedElementKey,
}) => {
  const [blockColor, setBlockColor] = useState<string | undefined>();
  const [opened, setOpened] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const portalContentRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(portalContentRef, () => setOpened(false));

  const updateBlockColor = useCallback(
    (color: ColorResult) => {
      editor.update(() => {
        if (selectedElementKey) {
          const node = $getNodeByKey(selectedElementKey);
          if ($isBlockNode(node)) {
            editor.dispatchCommand(UPDATE_BLOCK_COMMAND, {
              nodeKey: selectedElementKey,
              color: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a || 1})`,
            });
            setBlockColor(color.hex);
          } else {
            editor.dispatchCommand(INSERT_BLOCK_COMMAND, color.hex);
          }
          setOpened(false);
        }
      });
    },
    [selectedElementKey, editor]
  );

  useEffect(() => {
    editor.update(() => {
      if (selectedElementKey) {
        const node = $getNodeByKey(selectedElementKey);
        if (node && $isBlockNode(node)) setBlockColor(node.getColor());
        else if (node) {
          const parentBlock = $getNearestNodeOfType<BlockNode>(node, BlockNode);
          setBlockColor(parentBlock?.getColor());
        } else setBlockColor(undefined);
      } else setBlockColor(undefined);
    });
  }, [selectedElementKey, editor]);

  return (
    <>
      {opened &&
        ref.current &&
        createPortal(
          <Box position="absolute" zIndex={100} ref={portalContentRef}>
            <GithubPicker
              triangle="hide"
              onChange={updateBlockColor}
              colors={[
                '#E15241',
                '#D63864',
                '#9031AA',
                '#4350AF',
                '#4994EC',
                '#54B9D1',
                '#67AD5B',
                '#97C15C',
                '#FCEC60',
                '#F19D38',
                '#EC6337',
                '#74564A',
              ]
                .map(color => chroma(color).alpha(0.11).hex('rgba'))
                .concat(chroma('00152F').alpha(0.03).hex('rgba'))}
            />
          </Box>,
          ref.current
        )}
      <Box
        ref={ref}
        onClick={() => setOpened(true)}
        sx={{
          borderRadius: 20,
          width: 28,
          height: 28,
          backgroundColor: blockColor,
          cursor: 'pointer',
          borderStyle: 'inset',
          borderColor: 'transparent',
          borderWidth: 3,
          transition: 'border-color .2s',
          '&:hover': {borderColor: 'primary.light'},
        }}
      />
    </>
  );
};

export const InsertImageButton: FC<ToggleToolbarButtonProps> = ({editor}) => {
  return (
    <>
      <IconButton
        size="small"
        color="default"
        title="Insert Image"
        onClick={() => editor.dispatchCommand(INSERT_IMAGE_COMMAND, {mode: 'block', captionsEnabled: true})}
        aria-label="Insert text into composer"
      >
        <Icon icon={imageIcon} />
      </IconButton>
    </>
  );
};

export const InsertQuoteButton: FC<ToggleToolbarButtonProps> = ({editor}) => {
  return (
    <IconButton
      size="small"
      color="default"
      title="Вставить цитату"
      onClick={() => editor.dispatchCommand(INSERT_QUOTE_COMMAND, {withImage: false})}
      aria-label="Вставить цитату в материал"
    >
      <Icon icon={quoteIcon} />
    </IconButton>
  );
};

export const InsertLegendButton: FC<ToggleToolbarButtonProps> = ({editor}) => {
  return (
    <IconButton
      size="small"
      color="default"
      title="Вставить легенду"
      onClick={() => editor.dispatchCommand(INSERT_LEGEND_COMMAND, undefined)}
      aria-label="Вставить легенду в материал"
    >
      <Icon icon={quoteIcon} />
    </IconButton>
  );
};
