import _ from 'lodash/fp';
import {useState, useCallback} from 'react';

import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import {ConfirmDialogProps, ConfirmHookProps} from './types';

export function useConfirmDialog<T>({
  onConfirm,
  ...dialogProps
}: ConfirmHookProps<T>): [(p: ConfirmDialogProps<T>) => JSX.Element, (p?: T) => void] {
  const [open, setOpen] = useState<T | boolean>(false);
  const onOpen = useCallback((p?: T | boolean) => setOpen(p || true), []);

  const Content = useCallback(
    (props: ConfirmDialogProps<T>) => {
      const {
        text,
        title,
        btnTitle,
        cancelBtnTitle,
        align = 'left',
        btnType = 'error',
      } = Object.assign(dialogProps, props);
      const close = () => setOpen(false);
      const confirmHandler = () => _.compose(close, onConfirm)(typeof open !== 'boolean' ? open : undefined);

      return (
        <Dialog
          open={!!open}
          onClose={close}
          onBackdropClick={close}
          maxWidth="xs"
          fullWidth
          PaperProps={{sx: {borderRadius: 2}}}
        >
          <Box sx={{p: 3, pb: 0}}>
            {title && (
              <Typography variant="h4" align={align}>
                {typeof title === 'string' ? title : title(typeof open !== 'boolean' ? open : undefined)}
              </Typography>
            )}
            {text && (
              <Typography variant="body2" align={align} sx={{mt: 2}} whiteSpace="pre-line">
                {text}
              </Typography>
            )}
          </Box>
          <DialogActions sx={{p: 3, justifyContent: align === 'center' ? 'center' : 'flex-end'}}>
            <Button
              size="small"
              variant="contained"
              sx={{px: 3}}
              onClick={close}
              color={btnType === 'error' ? 'primary' : 'secondary'}
            >
              {cancelBtnTitle || 'Отмена'}
            </Button>
            <Button variant="contained" color={btnType} size="small" onClick={confirmHandler} sx={{px: 3}}>
              {btnTitle || 'Да'}
            </Button>
          </DialogActions>
        </Dialog>
      );
    },
    [open, onConfirm, dialogProps]
  );

  return [Content, onOpen];
}
