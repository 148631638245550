import {ElementNode, LexicalNode, NodeKey, SerializedElementNode, Spread} from 'lexical';

export interface ImageContainerPayload {
  key?: NodeKey;
}

export type SerializedImageContainerNode = Spread<{}, SerializedElementNode>;

export class ImageContainerNode extends ElementNode {
  static getType(): string {
    return 'image-container';
  }

  static clone(node: ImageContainerNode): ImageContainerNode {
    return new ImageContainerNode(node.__key);
  }

  static importJSON(): ImageContainerNode {
    return $createImageContainerNode();
  }

  exportJSON(): SerializedImageContainerNode {
    return {
      ...super.exportJSON(),
      type: 'image-container',
      version: 1,
    };
  }

  canBeEmpty(): boolean {
    return false;
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('div');
    dom.classList.add('image__container');
    return dom;
  }

  updateDOM(): false {
    return false;
  }
}

export function $createImageContainerNode(): ImageContainerNode {
  return new ImageContainerNode();
}

export function $isImageContainerNode(node: LexicalNode | null | undefined): node is ImageContainerNode {
  return node instanceof ImageContainerNode;
}
