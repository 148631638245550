import {Outlet} from 'react-router';
import {PropsWithChildren} from 'react';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

import Page from '__theme/components/Page';
import HeaderBreadcrumbs from '__theme/components/HeaderBreadcrumbs';
import {useBreadcrumbs} from 'components/Breadcrumbs/context';

import {CommonPageProps} from './types';

export const CommonPage = ({title, heading, action, children, maxWidth = 'lg'}: PropsWithChildren<CommonPageProps>) => {
  const pageTitle = !title && !heading ? undefined : [title, heading].filter(Boolean).join(': ');
  const breadcrumbs = useBreadcrumbs();

  return (
    <Page title={pageTitle}>
      <Container maxWidth={maxWidth}>
        <HeaderBreadcrumbs
          heading={breadcrumbs[breadcrumbs.length - 1]?.name || ''}
          links={breadcrumbs}
          action={action}
        />
        <Stack spacing={2}>{children || <Outlet />}</Stack>
      </Container>
    </Page>
  );
};
