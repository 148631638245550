import {Form} from 'components/Form';

import {Contents} from 'api/generated/admin-api';

import {fields, initials, validation} from './fields';
import {ContentFormFieldsType} from './types';

type ContentItemFormProps = {
  onSubmit: (data: ContentFormFieldsType) => void;
  content?: Contents;
};

export const ContentItemForm = ({onSubmit, content}: ContentItemFormProps) => {
  return <Form fields={fields()} validation={validation} onSubmit={onSubmit} initials={initials(content)} />;
};
