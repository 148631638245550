import {BreadcrumbsSet} from 'components/Breadcrumbs';
import {FC, PropsWithChildren, lazy} from 'react';
import {Outlet, RouteObject} from 'react-router';

const DashboardModule = lazy(() => import('./pages/Dashboard'));
const PublicationPage = lazy(() => import('./pages/Publication'));
// const UserPage = lazy(() => import('./pages/User'));

export const dashboardRouter = (OuterLayout?: FC<PropsWithChildren>): RouteObject => ({
  path: 'dashboard',
  element: OuterLayout ? (
    <OuterLayout>
      <BreadcrumbsSet name="Дашборд группы">
        <DashboardModule />
      </BreadcrumbsSet>
    </OuterLayout>
  ) : (
    <BreadcrumbsSet name="Дашборд группы">
      <DashboardModule />
    </BreadcrumbsSet>
  ),
  children: [
    {
      index: true,
      element: <div />,
    },
    {
      path: 'p',
      element: <Outlet />,
      children: [
        {
          path: ':postId',
          element: <PublicationPage />,
        },
      ],
    },
    // {
    //   path: 'u',
    //   element: <Outlet />,
    //   children: [
    //     {
    //       path: ':userId',
    //       element: <UserPage />,
    //     },
    //   ],
    // },
  ],
});

export default dashboardRouter;
