import {Theme} from '@mui/material/styles';

// ----------------------------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Grid(theme: Theme) {
  return {
    MuiGrid: {
      styleOverrides: {},
    },
  };
}
