// routes
import {PATH_APP} from 'router/routes';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{width: '100%', height: '100%'}} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Дашборды',
    items: [
      {
        title: 'app',
        path: PATH_APP.general.app,
        icon: ICONS.dashboard,
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Основное',
    items: [
      {
        title: 'Пользователи',
        path: PATH_APP.user.root,
        icon: ICONS.user,
        children: [
          {title: 'Список пользователей', path: PATH_APP.user.list.root, access: 'usersIndex'},
          {title: 'Потоки', path: PATH_APP.streams.root, access: 'streamsIndex'},
          {title: 'Группы', path: PATH_APP.group.root, access: 'groupsIndex'},
          {title: 'Рассылки', path: PATH_APP.user.mailings.root, access: 'mailingsIndex'},
        ],
      },
      {
        title: 'Задания',
        path: PATH_APP.tasks.root,
        icon: ICONS.banking,
        children: [
          {title: 'Квесты', path: PATH_APP.tasks.quests.root, access: 'questsIndex'},
          {title: 'Задания', path: PATH_APP.tasks.tasks.list, access: 'tasksIndex'},
          {title: 'Сценарии', path: PATH_APP.tasks.scripts.root, access: 'scriptsIndex'},
          {title: 'Шаблоны', path: PATH_APP.tasks.templates, access: 'templatesIndex'},
          {title: 'Материалы', path: PATH_APP.tasks.contents.list.root, access: 'contentsIndex'},
          {title: 'Виджеты', path: PATH_APP.tasks.widgets.root, access: 'widgetsIndex'},
          {title: 'Визуализации виджетов', path: PATH_APP.tasks.widgetsVisuals.root, access: 'widgetsVisualsIndex'},
          {title: 'Награды', path: PATH_APP.catalogs.rewards, access: 'rewardsIndex'},
          // {title: 'Коллекторы данных', path: PATH_APP.tasks.collectors.root, access: 'collectorsIndex'},
        ],
      },
      {
        title: 'Платежи',
        path: PATH_APP.payments.root,
        icon: ICONS.ecommerce,
        children: [
          {title: 'Статистика', path: PATH_APP.payments.stats.root, access: 'paymentsStatsPartnersDynamic'},
          {title: 'Платежные системы', path: PATH_APP.payments.services.root, access: 'paymentsServicesIndex'},
          {title: 'Чековые сервисы', path: PATH_APP.payments.receiptsServices.root, access: 'receiptsServicesIndex'},
        ],
      },
      {
        title: 'Модерация',
        path: PATH_APP.control.root,
        icon: ICONS.blog,
        children: [{title: 'Жалобы', path: PATH_APP.control.claims, access: 'claimsDataIndex'}],
      },
      {
        title: 'Справочники',
        path: PATH_APP.catalogs.root,
        icon: ICONS.booking,
        children: [
          {title: 'Теги', path: PATH_APP.catalogs.tags, access: 'tagsIndex'},
          {title: 'Блоки реакций', path: PATH_APP.catalogs.reactions, access: 'reactionsBlockIndex'},
          {title: 'Категории целей', path: PATH_APP.catalogs.goalTypes, access: 'goalsTypesIndex'},
          {title: 'Категории заданий', path: PATH_APP.catalogs.taskCategories, access: 'tasksCategoriesIndex'},
          {title: 'Категории материалов', path: PATH_APP.catalogs.contentCategories, access: 'contentsCategoriesIndex'},
        ],
      },
      {
        title: 'Настройки',
        path: PATH_APP.settings.root,
        icon: ICONS.booking,
        children: [
          {title: 'Настройки доступа', path: PATH_APP.settings.access.root, access: 'accessIndex'},
          {title: 'Основные настройки', path: PATH_APP.settings.main, access: 'settingsIndex'},
          {title: 'Блокировки', path: PATH_APP.settings.blockers.list, access: 'blockersIndex'},
          {title: 'Пользовательское соглашение', path: PATH_APP.settings.agreement, access: 'settingsIndex'},
          {title: 'Согласие на обработку персональных данных', path: PATH_APP.settings.policy, access: 'settingsIndex'},
        ],
      },

      // MANAGEMENT : E-COMMERCE
      // {
      //   title: 'e-commerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     {title: 'shop', path: PATH_DASHBOARD.eCommerce.shop},
      //     {title: 'product', path: PATH_DASHBOARD.eCommerce.productById},
      //     {title: 'list', path: PATH_DASHBOARD.eCommerce.list},
      //     {title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct},
      //     {title: 'edit', path: PATH_DASHBOARD.eCommerce.editById},
      //     {title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout},
      //     {title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice},
      //   ],
      // },

      // // MANAGEMENT : BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     {title: 'posts', path: PATH_DASHBOARD.blog.posts},
      //     {title: 'post', path: PATH_DASHBOARD.blog.postById},
      //     {title: 'new post', path: PATH_DASHBOARD.blog.newPost},
      //   ],
      // },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">2</Label>,
  //     },
  //     {title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat},
  //     {title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar},
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //   ],
  // },
];

export default sidebarConfig;
