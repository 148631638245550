import {createApi} from '@reduxjs/toolkit/query/react';
import {default as customBaseQuery} from '../queries/files';
export const api = createApi({
  reducerPath: 'files',
  baseQuery: customBaseQuery,
  tagTypes: [],
  endpoints: build => ({
    rootUpload: build.mutation<RootUploadApiResponse, RootUploadApiArg>({
      query: queryArg => ({
        url: `/`,
        method: 'POST',
        body: queryArg.body,
        params: {
          relationId: queryArg.relationId,
          relationType: queryArg.relationType,
        },
      }),
    }),
    rootIndex: build.query<RootIndexApiResponse, RootIndexApiArg>({
      query: () => ({url: `/`}),
    }),
    rootCreateDummy: build.mutation<RootCreateDummyApiResponse, RootCreateDummyApiArg>({
      query: queryArg => ({
        url: `/dummy`,
        method: 'POST',
        body: queryArg.createDummyDto,
      }),
    }),
    rootJsonDocs: build.query<RootJsonDocsApiResponse, RootJsonDocsApiArg>({
      query: () => ({url: `/docs.json`}),
    }),
    rootDocs: build.query<RootDocsApiResponse, RootDocsApiArg>({
      query: () => ({url: `/docs.html`}),
    }),
    rootSwagger: build.query<RootSwaggerApiResponse, RootSwaggerApiArg>({
      query: () => ({url: `/swagger.html`}),
    }),
    fileInfo: build.query<FileInfoApiResponse, FileInfoApiArg>({
      query: queryArg => ({url: `/file_${queryArg.fileId}`}),
    }),
    fileDownload: build.query<FileDownloadApiResponse, FileDownloadApiArg>({
      query: queryArg => ({url: `/file_${queryArg.fileId}/download`}),
    }),
    fileStream: build.query<FileStreamApiResponse, FileStreamApiArg>({
      query: queryArg => ({url: `/file_${queryArg.fileId}/stream`}),
    }),
    fileTimecodes: build.query<FileTimecodesApiResponse, FileTimecodesApiArg>({
      query: queryArg => ({url: `/file_${queryArg.fileId}/timecodes`}),
    }),
    filePreview: build.query<FilePreviewApiResponse, FilePreviewApiArg>({
      query: queryArg => ({url: `/file_${queryArg.fileId}/preview`}),
    }),
    fileCrop: build.query<FileCropApiResponse, FileCropApiArg>({
      query: queryArg => ({
        url: `/file_${queryArg.fileId}/crop`,
        params: {ratio: queryArg.ratio, maxWidth: queryArg.maxWidth},
      }),
    }),
    fileJpg: build.query<FileJpgApiResponse, FileJpgApiArg>({
      query: queryArg => ({url: `/file_${queryArg.fileId}/image.jpg`}),
    }),
    fileUpdateVideo: build.mutation<FileUpdateVideoApiResponse, FileUpdateVideoApiArg>({
      query: queryArg => ({
        url: `/file_${queryArg.fileId}/video`,
        method: 'PUT',
        body: queryArg.fileUpdateDto,
      }),
    }),
  }),
});
export type RootUploadApiResponse = /** status 200 Список загруженных файлов */ Files[];
export type RootUploadApiArg = {
  /** Сообщения после указанной даты */
  relationId?: string;
  /** Сообщения до указанной даты */
  relationType?: 'preview' | 'jpg' | 'crop';
  /** file form */
  body: {
    file?: Blob[];
  };
};
export type RootIndexApiResponse = /** status 200 Список маршрутов */ RouteElement[];
export type RootIndexApiArg = void;
export type RootCreateDummyApiResponse = /** status 200 Созданный объект файла */ Files;
export type RootCreateDummyApiArg = {
  createDummyDto: CreateDummyDto;
};
export type RootJsonDocsApiResponse = /** status 200 Документация OpenAPI */ string;
export type RootJsonDocsApiArg = void;
export type RootDocsApiResponse = /** status 200 Интерфейс для работы с документацией */ string;
export type RootDocsApiArg = void;
export type RootSwaggerApiResponse = /** status 200 Интерфейс для работы с документацией */ string;
export type RootSwaggerApiArg = void;
export type FileInfoApiResponse = /** status 200 Информация о файле */ Files;
export type FileInfoApiArg = {
  /** Идентификатор */
  fileId: string;
};
export type FileDownloadApiResponse = unknown;
export type FileDownloadApiArg = {
  /** Идентификатор */
  fileId: string;
};
export type FileStreamApiResponse = unknown;
export type FileStreamApiArg = {
  /** Идентификатор */
  fileId: string;
};
export type FileTimecodesApiResponse = /** status 200 Информация о таймкодах файла */ Timecodes[];
export type FileTimecodesApiArg = {
  /** Идентификатор */
  fileId: string;
};
export type FilePreviewApiResponse = unknown;
export type FilePreviewApiArg = {
  /** Идентификатор */
  fileId: string;
};
export type FileCropApiResponse = unknown;
export type FileCropApiArg = {
  /** Идентификатор */
  fileId: string;
  /** Пропорции обрезки */
  ratio?: '1:1' | '16:9' | '4:5' | '4:3' | 'default';
  /** Максимальная ширина */
  maxWidth?: number;
};
export type FileJpgApiResponse = unknown;
export type FileJpgApiArg = {
  /** Идентификатор */
  fileId: string;
};
export type FileUpdateVideoApiResponse = unknown;
export type FileUpdateVideoApiArg = {
  /** Идентификатор */
  fileId: string;
  fileUpdateDto: FileUpdateDto;
};
export type Files = {
  name: string;
  path: string;
  type: string;
  size: number;
  location?: string;
  s3?: boolean;
  blurhash?: string;
  s3Status?: 'falied' | 'received' | 'converting' | 'converted';
  height?: number;
  width?: number;
  relationType?: 'preview' | 'jpg' | 'crop';
  relationParams?: string;
  relationId?: string;
  userId?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};
export type ErrorMessage = {
  status: number;
  message: string;
  data?: object;
};
export type AccessPoint = {
  method?: string;
  path?: string;
  prefix?: string;
};
export type RouteElement = {
  AccessAdminControl?: AccessPoint[];
  method: string;
  path: string;
  operationId: string;
};
export type CreateDummyDto = {
  name?: string;
  type?: string;
  s3?: boolean;
  height?: number;
  width?: number;
};
export type Timecodes = {
  description?: string;
  time: number;
  fileId: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  enabled: boolean;
};
export type FileUpdateDto = {
  name?: string;
  path?: string;
  type?: string;
  s3?: boolean;
  s3Status?: 'falied' | 'received' | 'converting' | 'converted';
};
export const {
  useRootUploadMutation,
  useRootIndexQuery,
  useRootCreateDummyMutation,
  useRootJsonDocsQuery,
  useRootDocsQuery,
  useRootSwaggerQuery,
  useFileInfoQuery,
  useFileDownloadQuery,
  useFileStreamQuery,
  useFileTimecodesQuery,
  useFilePreviewQuery,
  useFileCropQuery,
  useFileJpgQuery,
  useFileUpdateVideoMutation,
} = api;
