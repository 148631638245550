import {FieldStructure} from 'components/Form/types';
import {yupObject, yupString} from 'utils/validation';

export const fields: FieldStructure[] = [
  {
    type: 'text',
    name: 'login',
    label: 'Логин',
  },
  {
    type: 'text',
    variant: 'password',
    name: 'password',
    label: 'Пароль',
  },
];

export const initials = {login: '', password: ''};

export const validation = yupObject({
  login: yupString,
  password: yupString,
});
