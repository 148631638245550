import {adminApi, usersApi} from 'api';
import {useMemo} from 'react';
import {isArray} from 'lodash';
import {DEFAULT_LIMIT} from 'utils/constants';
import {DataListProps, EndpointName, UsersEndpointName} from './types';

export function useList<Query, Result>({
  useFetch,
  params,
  debug,
  getData,
  disableLimit,
}: Pick<DataListProps<Query, Result>, 'useFetch' | 'params' | 'debug' | 'getData' | 'disableLimit'>) {
  const actualParams = useMemo(() => {
    if (!disableLimit) return {...(params || {}), limit: DEFAULT_LIMIT};
    return params || {};
  }, [disableLimit, params]);
  const {data: response, isFetching, isLoading} = useFetch(actualParams as Query);

  // eslint-disable-next-line no-console
  if (debug && process.env.NODE_ENV !== 'production') console.log(response);
  const data = useMemo(
    () => (getData && response ? getData(response) : isArray(response) ? response : (response as any)?.data || []),
    [getData, response]
  );
  return {data, isFetching, isLoading};
}

export function useIsQueryExists<T extends EndpointName>(queryName: T) {
  const fetchFn = adminApi.endpoints[queryName];
  if (fetchFn && 'useQuery' in fetchFn) return fetchFn.useQuery;
  return null;
}

export function useIsUserQueryExists<T extends UsersEndpointName>(queryName: T) {
  const fetchFn = usersApi.endpoints[queryName];
  if (fetchFn && 'useQuery' in fetchFn) return fetchFn.useQuery;
  return null;
}

export default useList;
