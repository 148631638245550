import {useEffect} from 'react';

import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {mergeRegister, $insertNodeToNearestRoot, $findMatchingParent} from '@lexical/utils';
import {
  $createParagraphNode,
  $createTextNode,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_LOW,
  createCommand,
  ElementNode,
  KEY_ARROW_DOWN_COMMAND,
  KEY_ARROW_LEFT_COMMAND,
  KEY_ARROW_RIGHT_COMMAND,
  KEY_ARROW_UP_COMMAND,
  LexicalNode,
} from 'lexical';

import {$createQuoteAuthorNode, $isQuoteAuthorNode, QuoteAuthorNode} from './QuoteAuthorNode';
import {$createQuoteContentNode, $isQuoteContentNode, QuoteContentNode} from './QuoteContentNode';
import {$createQuoteContainerNode, $isQuoteContainerNode, QuoteContainerNode} from './QuoteContainerNode';

import './styles.css';
import {$createImageNode, $isImageNode} from '../ImagesPlugin';

export const INSERT_QUOTE_COMMAND = createCommand<{withImage?: boolean}>('INSERT_QUOTE_COMMAND');

const onEscapeUp = () => {
  const selection = $getSelection();
  if ($isRangeSelection(selection) && selection.isCollapsed() && selection.anchor.offset === 0) {
    const container = $findMatchingParent(selection.anchor.getNode(), $isQuoteContainerNode);

    if ($isQuoteContainerNode(container)) {
      const parent = container.getParent<ElementNode>();
      if (
        parent !== null &&
        parent.getFirstChild<LexicalNode>() === container &&
        selection.anchor.key === container.getFirstDescendant<LexicalNode>()?.getKey()
      ) {
        container.insertBefore($createParagraphNode());
      }
    }
  }

  return false;
};

const onEscapeDown = () => {
  const selection = $getSelection();
  if ($isRangeSelection(selection) && selection.isCollapsed()) {
    const container = $findMatchingParent(selection.anchor.getNode(), $isQuoteContainerNode);

    if ($isQuoteContainerNode(container)) {
      const parent = container.getParent<ElementNode>();
      if (parent !== null && parent.getLastChild<LexicalNode>() === container) {
        const lastDescendant = container.getLastDescendant<LexicalNode>();
        if (
          lastDescendant !== null &&
          selection.anchor.key === lastDescendant.getKey() &&
          selection.anchor.offset === lastDescendant.getTextContentSize()
        ) {
          container.insertAfter($createParagraphNode());
        }
      }
    }
  }

  return false;
};

export const QuotePlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([QuoteContainerNode, QuoteContentNode, QuoteAuthorNode])) {
      throw new Error('QuotePlugin: QuoteNode, QuoteContentNode or QuoteAuthorNode not registered on editor');
    }

    return mergeRegister(
      // если у QuoteAuthorNode нет QuoteContainerNode в качестве родительского элемента,
      // то заменяем тип ноды на параграф
      editor.registerNodeTransform(QuoteAuthorNode, node => {
        const parent = node.getParent<ElementNode>();
        if (!$isQuoteContainerNode(parent)) {
          node.replace($createParagraphNode().append(...node.getChildren<LexicalNode>()));
          return;
        }
      }),
      // если у QuoteContentNode нет QuoteContainerNode в качестве родительского элемента,
      // то заменяем переносим ноду на уровень вверх и удаляем текущий QuoteContentNode
      editor.registerNodeTransform(QuoteContentNode, node => {
        const parent = node.getParent<ElementNode>();
        if (!$isQuoteContainerNode(parent)) {
          const children = node.getChildren<LexicalNode>();
          for (const child of children) {
            node.insertBefore(child);
          }
          node.remove();
        }
      }),
      // если внутри QuoteContainerNode есть QuoteContentNode и QuoteAuthorNode, то удаляем QuoteContainerNode
      editor.registerNodeTransform(QuoteContainerNode, node => {
        const children = node.getChildren<LexicalNode>();
        const quoteWithImage =
          children.length === 3 &&
          $isImageNode(children[0]) &&
          $isQuoteContentNode(children[1]) &&
          $isQuoteAuthorNode(children[2]);
        const quoteWithoutImage =
          children.length === 2 && $isQuoteContentNode(children[0]) && $isQuoteAuthorNode(children[1]);

        if (!quoteWithImage && !quoteWithoutImage) {
          for (const child of children) {
            node.insertBefore(child);
          }
          node.remove();
        }
      }),
      editor.registerCommand(KEY_ARROW_DOWN_COMMAND, onEscapeDown, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_ARROW_RIGHT_COMMAND, onEscapeDown, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_ARROW_UP_COMMAND, onEscapeUp, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_ARROW_LEFT_COMMAND, onEscapeUp, COMMAND_PRIORITY_LOW),
      editor.registerCommand(
        INSERT_QUOTE_COMMAND,
        ({withImage}) => {
          editor.update(() => {
            const content = $createQuoteContentNode().append($createParagraphNode());
            const container = $createQuoteContainerNode();

            if (withImage) container.append($createImageNode({mode: 'round'}));
            container.append(
              content,
              $createQuoteAuthorNode().append($createTextNode('Введите имя автора цитаты').setMode('token'))
            );
            $insertNodeToNearestRoot(container);
            content.select();
          });
          return true;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor]);

  return null;
};
