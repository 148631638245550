import {lazy} from 'react';
import {Navigate, RouteObject, useParams} from 'react-router';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {adminApi} from 'api';
import {PATH_APP} from 'router/routes';

import {AccessGuard} from 'modules/auth/containers';
import contentsRouter from 'modules/contents/routes';
import questsRouter from 'modules/events/routes';
import widgetsRouter from 'modules/widgets/routes';

import {BreadcrumbsProvider} from 'components/Breadcrumbs';
import {IsExistsContianer} from 'components/IsExistsContainer';
import {ListPage} from 'components/ListPage';

import {templateListColumns} from './utils';
import {useGetTemplate} from './pages/TemplateForm/hook';
import {useGetCollector} from './pages/CollectorForm/hook';
import {BreadcrumbsSet} from 'components/Breadcrumbs/BreadcrumbsSet';
import {Collectors, Scripts, Tasks, Templates} from 'api/generated/admin-api';

const TaskForm = lazy(() => import('./pages/TaskForm'));
const TasksList = lazy(() => import('./pages/TasksList'));
const ScriptPage = lazy(() => import('./pages/ScriptPage'));
const ScriptsList = lazy(() => import('./pages/ScriptsList'));
const TemplateForm = lazy(() => import('./pages/TemplateForm'));
const CollectorForm = lazy(() => import('./pages/CollectorForm'));
const CollectorsList = lazy(() => import('./pages/CollectorsList'));

const useGetTask = () => {
  const {taskId} = useParams<'taskId'>();
  return adminApi.useTaskIndexQuery(taskId ? {taskId} : skipToken);
};
const useGetScript = () => {
  const {scriptId} = useParams<'scriptId'>();
  return adminApi.useScriptIndexQuery(scriptId ? {scriptId} : skipToken);
};

export const routes: RouteObject = {
  path: 'tasks',
  element: <BreadcrumbsProvider name="Задания" href={PATH_APP.tasks.root} />,
  children: [
    {index: true, element: <Navigate to="./list" replace />},
    questsRouter,
    ...widgetsRouter,
    contentsRouter,
    {
      path: 'list',
      element: (
        <AccessGuard endpoint="tasksIndex">
          <BreadcrumbsSet name="Список заданий" href={PATH_APP.tasks.tasks.list} />
        </AccessGuard>
      ),
      children: [
        {index: true, element: <TasksList />},
        {
          path: 'create',
          element: (
            <AccessGuard endpoint="tasksAdd">
              <BreadcrumbsSet name="Новое задание" href={PATH_APP.tasks.tasks.create} />
            </AccessGuard>
          ),
          children: [{index: true, element: <TaskForm />}],
        },
        {
          path: ':taskId',
          element: (
            <AccessGuard endpoint="taskIndex">
              <IsExistsContianer<Tasks> useFetch={useGetTask}>
                {task => <BreadcrumbsSet name={task.name} />}
              </IsExistsContianer>
            </AccessGuard>
          ),
          children: [{index: true, element: <TaskForm />}],
        },
      ],
    },
    {
      path: 'scripts',
      element: (
        <AccessGuard endpoint="scriptsIndex">
          <BreadcrumbsSet name="Сценарии" href={PATH_APP.tasks.scripts.root} />
        </AccessGuard>
      ),
      children: [
        {index: true, element: <ScriptsList />},
        {
          path: 'create',
          element: (
            <AccessGuard endpoint="scriptsAdd">
              <BreadcrumbsSet name="Новый сценарий" />
            </AccessGuard>
          ),
          children: [{index: true, element: <ScriptPage />}],
        },
        {
          path: ':scriptId',
          element: (
            <AccessGuard endpoint="scriptsAdd">
              <IsExistsContianer<Scripts> useFetch={useGetScript}>
                {script => <BreadcrumbsSet name={script.name} />}
              </IsExistsContianer>
            </AccessGuard>
          ),
          children: [{index: true, element: <ScriptPage />}],
        },
      ],
    },
    {
      path: 'templates',
      element: (
        <AccessGuard endpoint="templatesIndex">
          <BreadcrumbsSet name="Шаблоны" href={PATH_APP.tasks.templates} />
        </AccessGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <ListPage params={{}} heading="Шаблоны" columns={templateListColumns()} queryName="templatesIndex" />
          ),
        },
        {
          path: ':templateId',
          element: (
            <AccessGuard endpoint="templateIndex">
              <IsExistsContianer<Templates> useFetch={useGetTemplate}>
                {template => <BreadcrumbsSet name={template.name} />}
              </IsExistsContianer>
            </AccessGuard>
          ),
          children: [{index: true, element: <TemplateForm />}],
        },
      ],
    },
    {
      path: 'collectors',
      element: (
        <AccessGuard endpoint="collectorsIndex">
          <BreadcrumbsSet name="Коллекторы данных" href={PATH_APP.tasks.collectors.list} />
        </AccessGuard>
      ),
      children: [
        {
          index: true,
          element: <Navigate to="./list" replace />,
        },
        {
          path: 'list',
          children: [
            {index: true, element: <CollectorsList />},
            {
              path: ':collectorId',
              element: (
                <AccessGuard endpoint="collectorIndex">
                  <IsExistsContianer<Collectors> useFetch={useGetCollector}>
                    {collector => <BreadcrumbsSet name={collector.name} />}
                  </IsExistsContianer>
                </AccessGuard>
              ),
              children: [{index: true, element: <CollectorForm />}],
            },
          ],
        },
      ],
    },
  ],
};

export default routes;
