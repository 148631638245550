import {DOMConversionMap, ElementNode, LexicalNode, SerializedElementNode, Spread} from 'lexical';

export type LegendContainerPayload = string;

export type SerializedLegendContainerNode = Spread<{}, SerializedElementNode>;

export class LegendContainerNode extends ElementNode {
  static getType(): string {
    return 'legend-container';
  }

  static clone(node: LegendContainerNode): LegendContainerNode {
    return new LegendContainerNode(node.__key);
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('div');
    dom.classList.add('legend__container');
    return dom;
  }

  updateDOM(): boolean {
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return {};
  }

  static importJSON(): LegendContainerNode {
    return $createLegendContainerNode();
  }

  /**
   * @returns {boolean} Whether or not this node can be empty.
   */
  canBeEmpty(): boolean {
    return false;
  }

  exportJSON(): SerializedLegendContainerNode {
    return {
      ...super.exportJSON(),
      type: 'legend-container',
      version: 1,
    };
  }
}

export function $createLegendContainerNode(): LegendContainerNode {
  return new LegendContainerNode();
}

export function $isLegendContainerNode(node: LexicalNode | null | undefined): node is LegendContainerNode {
  return node instanceof LegendContainerNode;
}
