import {filesApi} from 'api';
import {RootUploadApiResponse} from 'api/generated/files-api';
import {isArray} from 'lodash';
import {useCallback} from 'react';
import {useController} from 'react-hook-form';
import {croppedImagePath, filePath} from 'utils';
import {CommonFieldProps} from '../types';
import {FileUploadProps} from './types';

export const useUploadImage = (onSuccess: (data: RootUploadApiResponse, files?: File[]) => void) => {
  const [upload] = filesApi.useRootUploadMutation();
  const onDrop = useCallback(
    async (files: File[]) => {
      const body = new FormData();
      files.forEach(file => body.append('file', file));
      const result = await upload({body: body as any});
      // TODO: сделать нормальный механизм обработки файлов
      if ('data' in result) onSuccess(result.data, files);
    },
    [upload, onSuccess]
  );

  return {onDrop};
};

export const useFileUpload = ({name, multiple, imageOptions, variant}: CommonFieldProps & FileUploadProps) => {
  const [upload] = filesApi.useRootUploadMutation();
  const {
    field: {onChange, value},
  } = useController({name});

  const onDrop = useCallback(
    async (files: File[]) => {
      const body = new FormData();
      files.forEach(file => body.append('file', file));
      const result = await upload({body: body as any});
      if ('data' in result) onChange(!multiple ? result.data[0]._id : result.data.map(item => item._id));
    },
    [upload, onChange, multiple]
  );

  const removeAll = () => onChange(multiple ? [] : '');

  const arrValue = isArray(value) ? value : value ? [value] : [];
  const files = arrValue.map(file =>
    variant === 'image' && imageOptions?.ratio
      ? croppedImagePath(file, {ratio: imageOptions.ratio, maxWidth: 1000})
      : filePath(file)
  );

  return {onDrop, files, removeAll};
};
