import {FC, useEffect} from 'react';
import {$getNodeByKey, COMMAND_PRIORITY_EDITOR, COMMAND_PRIORITY_LOW, createCommand, NodeKey} from 'lexical';
import {mergeRegister, $insertNodeToNearestRoot} from '@lexical/utils';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';

import {$createImageNode, $isImageNode, ImageNode, ImagePayload} from './ImageNode';

export const INSERT_IMAGE_COMMAND =
  createCommand<Pick<ImagePayload, 'mode' | 'captionsEnabled'>>('INSERT_IMAGE_COMMAND');
export const UPLOAD_IMAGE_COMMAND = createCommand<Omit<ImagePayload, 'mode'> & {nodeKey: NodeKey}>(
  'UPLOAD_IMAGE_COMMAND'
);

export const ImagesPlugin: FC = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error('ImagesPlugin: ImageNode not registered on editor');
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_IMAGE_COMMAND,
        ({mode, captionsEnabled}) => {
          const imageNode = $createImageNode({mode, captionsEnabled});
          $insertNodeToNearestRoot(imageNode);
          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        UPLOAD_IMAGE_COMMAND,
        ({nodeKey, ...payload}) => {
          const imageNode = $getNodeByKey(nodeKey);
          if (!$isImageNode(imageNode)) return false;
          if (payload.fileId) imageNode.setFileId(payload.fileId);
          if (payload.width && payload.height) imageNode.setWidthAndHeight(payload.width, payload.height);
          return true;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor]);

  return null;
};
