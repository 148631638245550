import {NavigateFunction, useNavigate} from 'react-router';
import {skipToken} from '@reduxjs/toolkit/query';
import {Card, CardContent} from '@mui/material';

import {adminApi} from 'api';
import {ReceiptsServices} from 'api/generated/admin-api';
import {Form} from 'components/Form';

import {useReceiptsServiceAction} from './hooks';
import {fields, validation, initials} from './fields';

export const ReceiptsServiceForm = ({
  onSuccess,
  serviceId,
}: {
  onSuccess?: (data: ReceiptsServices, navigate: NavigateFunction) => void;
  serviceId?: string;
}) => {
  const navigate = useNavigate();
  const {data: service} = adminApi.endpoints.receiptsServiceIndex.useQuery(serviceId ? {serviceId} : skipToken);
  const action = useReceiptsServiceAction(serviceId);
  return (
    <Card>
      <CardContent>
        <Form
          fields={fields}
          onSubmit={async data => {
            const result = await action(data);
            if (result && onSuccess) onSuccess(result, navigate);
            return result;
          }}
          initials={initials(service)}
          validation={validation}
        />
      </CardContent>
    </Card>
  );
};
